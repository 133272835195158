// BackToTopButton.js
import { Box, IconButton } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Box display={showButton ? 'block' : 'none'} position="fixed" bottom="6" right="6">
            <IconButton
                onClick={handleClick}
                bg="#6FA82F"
                icon={<ArrowUpIcon />}
                color="white"
                position="fixed"
                bottom="100"
                right="4"
                borderRadius="full"
                boxShadow="md"
                _hover={{ bg: 'black' }}
            >
            </IconButton>
        </Box>
    );
};

export default BackToTopButton;
