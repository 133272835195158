import React from 'react';
import { Helmet } from 'react-helmet';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import AboutUsSection from '../components/AboutPageComponents/AboutUs';
import WithBackgroundImage from '../components/AboutPageComponents/Top';
import UserChatButton from '../components/Chat/ChatButton'

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | About Us" content="KnitSilk | About Us" />
                <title>KnitSilk | About Us</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <WithBackgroundImage />
            <AboutUsSection />
            <UserChatButton/>
            <Footer />
        </>
    )
}

export default AboutPage;
