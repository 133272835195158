import { Box, Flex, Heading, Text, Link, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
const Success = () => {
  const [loading, setLoading] = useState(false);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [orderid, setOrderid] = useState("");
  const toast = useToast();
  // const orderId = localStorage.getItem('newOrderId');
  const userToken = localStorage.getItem('userToken');



  // let orderId =Cookies.get('newOrderId')||""

  let getRecentOrderId = async () => {
    try {
        let userToken = localStorage.getItem('userToken');
        let response = await axios.get("https://api.digiblocks.tech/user-last-orders", {
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        });

        if (response.data.id) {
            console.log(`Recent Order ID: ${response.data.id}`);
           await updateIsPaidStatus(response.data.id)
           setOrderid(response.data.id)
            // You can do additional processing with the order ID here
        } else if (response.data.msg) {
            console.log(response.data.msg);
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};

  const updateIsPaidStatus = async (orderId) => {
    try {
     let res= await axios.put(`https://api.digiblocks.tech/order/${orderId}/pay`,
        { isPaid: true },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      console.log(res);

      // Send order email
      await axios.post('https://api.digiblocks.tech/sendOrderMail', { orderID: orderId });

      // Remove items from cart
      await axios.post('https://api.digiblocks.tech/removeitems', {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });

      setOrderUpdated(true);
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while processing your request. Please try again.",
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (orderId) {
    //   setLoading(true);
    //   updateIsPaidStatus(orderId);
    // } else {
    //   toast({
    //     title: "Error",
    //     description: "No order ID found.",
    //     status: "error",
    //     duration: 6000,
    //     isClosable: true,
    //     position: "bottom",
    //   });
    // }
    getRecentOrderId()

  }, []);

  return (
    <Flex align="center" justify="center" h="100vh" bg="gray.100">
      <Box textAlign="center" p={4} bg="white" rounded="md" shadow="md" maxW="90%" width="400px">
        <Heading as="h4" size="lg" mb={4}>Payment Successful</Heading>
        {orderUpdated ? (
          <>
            <Text fontSize="lg" mb={4}>Thank you for your order! Please check your email for confirmation.</Text>
            <Text fontSize="lg" mb={4}>Order Id : {orderid}</Text>
            <Link href="/" color="blue.500" fontSize="lg">
              Back to Home
            </Link>
          </>
        ) : (
          <Text fontSize="lg" mb={4}>Processing your order...</Text>
        )}
        {loading && <Text mt={4}>Loading...</Text>}
      </Box>
    </Flex>
  );
};

export default Success;
