import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Input,
  Text,
  Heading,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../Context/AppContext';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [allusers, setallusers] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const { setAdminAuth, adminAuth, setRefresh, refresh, postAdminStatus } = useContext(AppContext);

  let getAllSubUsers = async () => {
    try {
      const response = await axios.get("https://api.digiblocks.tech/api/getsubusers");
      setallusers(response.data)
    } catch (error) {
      console.error('Error fetching sub-users:', error);
    }
  }

  useEffect(() => {
    getAllSubUsers();
    let x = JSON.parse(sessionStorage.getItem("adminstatus"))
    if (x === true) {
      navigate("/manager/dashboard");
      setAdminAuth(true)
    } else {
      setAdminAuth(false)
    }
  }, [])

  if (adminAuth) {
    navigate("/manager/dashboard")
  }

  let handleLogin = () => {

    allusers.map((user) => {
      if(user.email==email && user.password==password ){
        sessionStorage.setItem("adminstatus", true);
        sessionStorage.setItem("admintype", user.userRole);
        setRefresh(!refresh)
        setAdminAuth(true)
        navigate("/manager/dashboard");
      }
    })
    
  }

  return (
    <>
      <Helmet>
        <meta name="Knitsilk | Admin Login" content="Knitsilk | Admin Login" />
        <title>Knitsilk | Admin</title>
      </Helmet>
      <Box
        minHeight="100vh"
        bg={"#6FA82F"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          p={"4%"}
          borderRadius="lg"
          w={{ base: '90%', sm: '80%', md: '60%', lg: '50%' }}
          textAlign="center"
          border="1px solid white"
        >
          <Text color="white">Hey Admin !!! Nice to See You Again</Text>
          <Heading as="h2" size="xl" color="white" mb="4">
            Welcome Back
          </Heading>
          <Flex direction={"column"} gap={"32px"}>
            <FormLabel color="white" fontSize="xl">
              Email
            </FormLabel>
            <Input
              type="email"
              value={email}
              bg={"lightgray"}
              color="black"
              fontSize={"20px"}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="admin@example.com"
              size="md"
            />
            <Input
              type="password"
              value={password}
              bg={"lightgray"}
              color="black"
              fontSize={"20px"}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              size="md"
            />

            <Button onClick={handleLogin}>Login</Button>
          </Flex>

        </Box>
      </Box>
    </>
  );
};

export default AdminLogin;
