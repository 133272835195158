const cardsData = [
    {
      imageUrl:
        "https://m.media-amazon.com/images/S/aplus-media-library-service-media/105dd551-4486-4a35-9168-de874d0cca8a.__CR0,0,3456,3456_PT0_SX300_V1___.jpg",
      heading: "Created with tender care",
      description:
        'Our "Recycled Sari Silk Yarn," a true labor of love that embodies both beauty and sustainability. Each strand of this exquisite Yarn is crafted with utmost care and attention, using remnants of vibrant sari silk that have been thoughtfully repurposed. Weaving together colors and patterns from different cultures, this yarns carries a rich history and a unique story within its fibers. By choosing our Recycled Sari Silk yarn, you are adorning your creations with a touch of elegance. Let your creativity flourish with this heartfelt yarn, made with love and a commitment to a greener future.',
    },
    {
      imageUrl:
        "https://m.media-amazon.com/images/S/aplus-media-library-service-media/aa4599ed-d3d2-416c-9c0d-a401fca19ef4.__CR0,0,3456,3456_PT0_SX300_V1___.jpg",
      heading: "Real relationships",
      description:
        'Our "Recycled Sari Silk Yarn" is more than just a product; it represents genuine relationships with skilled artisans and labors. We take immense pride in fostering connections with the individuals who handcraft each strand of this exquisite Yarn. Through fair trade practices, we ensure that these talented workers receive fair wages and safe working conditions, empowering them to support their families and communities. By choosing our "Recycled Sari Silk yarn," you are not only embracing sustainable materials but also supporting the livelihoods of these hardworking individuals. It is a testament to the power of real relationships, where each purchase creates a positive impact and brings beauty to your creative projects',
    },
    {
      imageUrl:
        "https://m.media-amazon.com/images/S/aplus-media-library-service-media/a9c982fc-6a16-41d3-9b7b-510ebb27ff17._CR0,0,3600,3600_PT0_SX300_V1__.jpg",
      heading: "Supporting Families",
      description:
        "Many of the women we employ have overcome economic hardship. When we hear one of our artisans is in need, we provide education, housing, and medical financial support.",
    },
  ];

  export default cardsData;