// Import necessary dependencies
import React from 'react';
import { Box, Heading, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Button, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; 

const B2BFAQSection = () => {
    // Style for the container
    const containerStyle = {
        backgroundColor: 'white',
        padding: '2rem',
        color:'black',
    };

    // Style for the heading
    const headingStyle = {
        fontSize: '2rem',
        fontFamily: 'DM Serif Display',
        fontWeight: 'bold',
        marginBottom: '1rem',
        color: '#6FA82F',
    };


    const faqs = [
        { question: 'Who is KnitSilk?', answer: 'KnitSilk is a brand owned by M. Jiju Silk Mills, a leading manufacturer and a reliable name in the global silk industry since 1997.' },
        { question: 'What products does KnitSilk offer?', answer: 'KnitSilk offers a wide range of luxurious, colorful, and handmade silk yarns and blends. Their products include Sari Silk Ribbon, Recycled Sari Silk Ribbon Yarn, KnitSilk Pure Silk Viscose Blend Yarn, Recycled Sari Silk Bulky Yarn, Silk Wool, Tussah Silk Yarn, Silk Cord Braided Sari Silk Ribbon Cording, and Bamboo Silk Stoles/Dupatta.' },
        { question: 'Where is KnitSilk based?', answer: 'KnitSilk has its manufacturing units based with traditional women & men who hand spin their fibers and yarns in Bangalore, India.' },
        { question: 'Does KnitSilk ship internationally?', answer: 'Yes, KnitSilk ships their products globally.' },
        { question: 'How can I place a bulk order?', answer: 'You can place a bulk order by contacting KnitSilk (link to send inquiry).' },
        { question: 'What is the quality assurance process at KnitSilk?', answer: 'KnitSilk maintains the highest standards of quality at every level of its method to ensure excellent production.' },
        { question: 'How does KnitSilk handle promotions?', answer: 'The process is different for each promotion, depending on the offer. Some promotions require a coupon code, some are applied automatically at checkout, some may require you to select a gift, etc.' },
    ];

    return (
        <Box style={containerStyle}>
            <VStack align="center" spacing="4">
                <Heading style={headingStyle}>FAQs</Heading>
                <Flex w="90%" direction="column" align="stretch">
                    <Accordion allowToggle>
                        {faqs.map((faq, index) => (
                            <AccordionItem color="black" key={index}>
                                <AccordionButton>
                                    <Box fontSize='1.2rem'
                                        marginBottom='1rem'
                                        fontWeight={"bold"}
                                        color="black"
                                        flex="1" textAlign="left">
                                        {faq.question}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel
                                    fontSize='1.2rem'
                                    marginBottom='1rem'
                                    pb={4}>{faq.answer}</AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Flex>
                <Button _hover={{ bg: '#232424' }}
                    bg={'#6FA82F'}
                    fontSize={['14px', '14px', '18px']}
                    p={['25px', '30px']}
                    color={'white'}
                    borderRadius={'0'}
                    m={'auto'}
                    mt={'30px'} as={Link} to="/faq">Find out more FAQs</Button>
            </VStack>
        </Box>
    );
};

export default B2BFAQSection;
