import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  VStack,
  Button,
  Image,
  Text,
  useToast,
  Spinner,
  Flex,
  Tooltip,
  Fade,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { FaShoppingCart } from 'react-icons/fa';
import { IoLogoUsd } from 'react-icons/io';
import { FaRupeeSign, FaEuroSign, FaPoundSign, FaYenSign } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Nav from '../HomePageComponents/Nav';
import Footer from '../HomePageComponents/Footer';
import TopBanner from '../HomePageComponents/TopBanner';
import { AppContext } from '../../Context/AppContext';
import { useContext } from 'react';
import DogsComponent from '../DogsComponent';
import { BsArrowRight } from 'react-icons/bs';

const currencyIcons = {
  INR: <FaRupeeSign />,
  USD: <IoLogoUsd />,
  EUR: <FaEuroSign />,
  GBP: <FaPoundSign />,
  JPY: <FaYenSign />,
  CAD: <IoLogoUsd />,
};

const Wishlist = () => {
  const userToken = localStorage.getItem('userToken');
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productDataFetched, setProductDataFetched] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const userCurrency = localStorage.getItem('userCurrency');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { setCartLength, setRefresh, refresh, setWishlistLength } = useContext(
    AppContext
  );

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/products');
      setAllProducts(response.data);
      setProductDataFetched(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getWishlistData = async () => {
    try {
      const response = await axios.get(
        'https://api.digiblocks.tech/getwishlistitems',
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const wishlistProductIds = response.data.data.map(
        (wishlistItem) => wishlistItem.productId
      );
      const filteredWishlistProducts = allProducts.filter((product) =>
        wishlistProductIds.includes(product._id)
      );

      setWishlistProducts(filteredWishlistProducts);
    } catch (error) {
      console.error('Error fetching wishlist data:', error);
    }
  };

  const getWishlistLength = async () => {
    try {
      const response = await axios.get(
        'https://api.digiblocks.tech/getwishlistitems',
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setWishlistLength(response.data.data.length);
    } catch (error) {
      console.error('Error fetching wishlist length:', error);
    }
  };

  const getCartLength = async () => {
    let response = await axios.get('https://api.digiblocks.tech/getcartitems', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    setCartLength(response.data.data.length);
  };

  const addtocartfunctionGrid = async (id, e) => {
    setRefresh(!refresh);
    const userToken = localStorage.getItem('userToken');
    e.stopPropagation();
    if (userToken) {
        try {
            const data = await axios.post(
                'https://api.digiblocks.tech/addtocart',
                { productId: id },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (data.data.message === 'Item added to cart successfully.') {
                setRefresh(!refresh);
                toast({
                    title: 'Added to Cart.',
                    description: 'Item added to cart successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else if (data.data.msg === 'Product already in cart') {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in cart.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
            getCartLength();
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    } else {
        // Get current cart from cookies
        const cart = JSON.parse(Cookies.get('guestCart') || '[]');

        // Add product to cart
        if (!cart.includes(id)) {
            cart.push(id);
            Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
            toast({
                title: "Product added to the cart.",
                description: 'Please login to proceed to checkout.',
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Already in Cart.',
                description: 'Product already in the cart.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        }
    }
};


  const removeItemFromWishlist = async (productId) => {
    try {
      const response = await axios.delete(
        `https://api.digiblocks.tech/removefromwishlist/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.msg === 'Item removed from Wishlist successfully.') {
        const updatedWishlistProducts = wishlistProducts.filter(
          (item) => item._id !== productId
        );
        setWishlistProducts(updatedWishlistProducts);

        toast({
          title: 'Item removed from Wishlist',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        getWishlistLength();
        setRefreshData((prev) => !prev);
      } else {
        console.error(
          'Error removing item from Wishlist:',
          response.data.msg
        );
        toast({
          title: 'Error',
          description: 'Error removing item from Wishlist. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error removing item from Wishlist:', error);
      toast({
        title: 'Error',
        description: 'Error removing item from Wishlist. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (userToken && !productDataFetched) {
      fetchData();
    }
  }, [userToken, productDataFetched, refreshData]);

  useEffect(() => {
    if (userToken && productDataFetched) {
      getWishlistData();
    }
  }, [userToken, productDataFetched, allProducts, refreshData]);

  return (
    <>
      <TopBanner />
      <Nav />
      {wishlistProducts.length === 0 && !loading && (
        <Box m={"auto"} textAlign="center" p={4}>
          {userToken ? (
            <Text fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
              Your wishlist is empty!
            </Text>
          ) : (
            <Text onClick={() => navigate("/login")} cursor={"pointer"} fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
              Please Login First !!!
            </Text>
          )}

          <DogsComponent />
          <Link to="/products">
            <Button
              borderRadius={0}
              rightIcon={<BsArrowRight />}
              _hover={{ bg: "#232424" }}
              variant="outline"
              alignItems={"center"}
              mt={8}
              color="white"
              size="lg"
              p="4"
              backgroundColor="#6FA82F"
            >
              Continue Shopping
            </Button>
          </Link>
        </Box>

      )}
      <Grid
        p={4}
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 2fr)', 'repeat(4, 1fr)']}
        gap={4}
        maxWidth={"80%"}
        margin={"auto"}
        my={8}
      >
        {loading && (
          <Box gridColumn="span 3" textAlign="center">
            <Spinner size="xl" color="green.500" />
          </Box>
        )}
        {wishlistProducts.map((product) => (
          <Fade in key={product._id}>
            <Box
              textAlign={"center"}
              alignItems={"center"}
              position="relative"
              onClick={() => {
                navigate(`/products/${product._id}`);
              }}
              borderWidth="1px"
              borderRadius="lg"
              p={2}
              transition="transform 0.2s"
              _hover={{ transform: 'scale(1.05)' }}
            >
              <VStack
                zIndex={999}
                position="absolute"
                top={3}
                right={3}
                spacing={2}
              >
                <Tooltip label="Remove from Wishlist" bg={"white"} color={"red"} placement="left">
                  <Button
                    size="sm"
                    borderRadius="full"
                    colorScheme='red'
                    _hover={{ color: "white", bg: "red" }}
                    color={"red"}
                    bg={"none"}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeItemFromWishlist(product._id);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </VStack>
              <Image
                m="auto"
                src={product?.photos[0]}

                maxH="200px"
                alt={product.title}
              />
              <Text mt={4} fontSize="lg" fontWeight="bold" textAlign="center" noOfLines={2}>
                {product.title}
              </Text>
              <Flex width={"40%"} justifyContent={"space-around"} alignContent={"center"} margin={"auto"} textAlign={"center"} alignItems={"center"}>
                <Text m={"auto"}>
                  {currencyIcons[userCurrency]}
                </Text>
                <Text fontWeight={"semibold"} m={"auto"} ml={"-5"}> {product[`price${userCurrency}`]}
                </Text>
              </Flex>
              <Text>{product.category}</Text>
              <Tooltip label="Add to Cart" bg={"white"} color={"#6FA82F"} placement="right">
                <Button
                  colorScheme="green"
                  bg="#e8eee4"
                  color="#6FA82F"
                  borderRadius={0}
                  mt={4}
                  mb={4}
                  _hover={{ bg: '#6FA82F', color: 'white' }}
                  rightIcon={<FaShoppingCart />}
                  onClick={(e) => {
                    e.stopPropagation();
                    addtocartfunctionGrid(product._id);
                  }}
                >
                  Move to Cart
                </Button>
              </Tooltip>
            </Box>
          </Fade>
        ))}
      </Grid>
      <Footer />
    </>
  );
};

export default Wishlist;
