import {
    Box,
    Heading,
    Image,
    Text,
    Divider,
    HStack,
    Tag,
    Wrap,
    WrapItem,
    useColorModeValue,
    Container,
    VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';


const BlogTags = (props) => {
    const { marginTop = 0, tags } = props

    return (
        <HStack spacing={2} marginTop={marginTop}>
            {tags.map((tag) => {
                return (
                    <Tag size={'md'} variant="solid" colorScheme="green" key={tag}>
                        {tag}
                    </Tag>
                )
            })}
        </HStack>
    )
}



const ArticleList = () => {
    return (
        <Container maxW={'1200px'} p="12">
            <Heading as="h2" fontSize="46px"
                color="#6FA82F"
                fontFamily="DM Serif Display"
                fontWeight="400">Silk Blog</Heading>
            <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="space-between">
                <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    alignItems="center">
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%">
                        <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                transform="scale(1.0)"
                                src="https://i.postimg.cc/c1fw76zV/blog1.jpg"
                                alt="some good alt text"
                                objectFit="contain"
                            />
                        </Box>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(green.600 1px, transparent 1px)',
                                'radial(green.300 1px, transparent 1px)',
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                    marginTop={{ base: '3', sm: '0' }}>
                    <BlogTags tags={['Silk', 'Yarn', 'Ribbons', 'Fabrics', 'Blends']} />
                    <Heading marginTop="1">
                        <Text textDecoration="none" fontSize="32px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400" _hover={{ textDecoration: 'none' }}>
                            Unleash Creativity with Our Premium Textiles
                        </Text>
                    </Heading>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg">
                        Explore a world of creative possibilities with our high-quality silk, yarn, ribbons, fabrics, and blends. Elevate your artistic endeavors with luxurious silk, versatile yarn, decorative ribbons, fine fabrics, and innovative blends. Unleash your imagination and craft exceptional pieces with our premium materials.
                    </Text>
                </Box>
            </Box>
            <Heading as="h2" fontSize="46px"
                color="#6FA82F"
                fontFamily="DM Serif Display"
                fontWeight="400" marginTop="5">
                Latest articles
            </Heading>
            <Divider marginTop="5" />
            <Wrap spacing="5%" marginTop="5">

                <WrapItem marginTop="5" bg={"#f6faf2"} borderRadius={'lg'} p={4} width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src={
                                        'https://www.symfonieyarns.com/images/blog/knitting-with-silk-yarns-large.jpg'
                                    }
                                    alt="some text"
                                    objectFit="contain"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Mulberry Silk', 'Non-Mulberry Silk']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}
                                >
                                All About Silk
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            There are four types of natural silk which are commercially known and produced in the world. Among them mulberry silk is the most important and contributes as much as 90 per cent of world production, therefore, the term "silk" in general refers to the silk of the mulberry silkworm.
                        </Text>
                        <Link to={"/blogs/all-about-silk"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/Ghc8kKGy/blog6.jpg"
                                    alt="some text"

                                    width="100%"
                                    objectFit="cover"
                                    height="200px"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Yarn Weight', 'Yarn Type']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Estimating How much yarn you need ?
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            If you're not working directly from a knitting pattern, then use this chart to estimate your yarn needs. This table shows you some typical knitting patterns and yardage approximations for a variety of sizes.
                        </Text>
                        <Link to={"/blogs/estimating-yarn"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src={
                                        'https://static.fibre2fashion.com/Newsresource/images/283/shutterstock-472818301_295013.jpg'
                                    }
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Yardage', 'Weight', 'Fiber']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                How to choose yarn for your project ?
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            When choosing yarn, remember that yarns, garment shapes, and stitch patterns must work together for a successful knitting or crochet project. Choosing yarn can be hard when there are so many yarn selections available
                        </Text>
                        <Link to={"/blogs/choose-yarn"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src={
                                        'https://d2culxnxbccemt.cloudfront.net/craft/content/uploads/a/general/wp-content/uploads/2015/11/8092_Clover-Takumi-Interchangeable-Circular-Knitting-Needles-Set-1436494517455.jpg'
                                    }
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Knitting Needles', 'Single Pointed']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Identifying Knitting Needles
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            Single-pointed, extra-long wooden needles, ideal for jackets and pullovers when using the English method. The wood absorbs humidity from hands and is good for keeping slippery yarns in place. Plastic needles are light-weight and rather cheap.
                        </Text>
                        <Link to={"/blogs/identify-needles"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/y8pZ6RJS/blog7.jpg"
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Lustre', 'Weave', 'Pattern']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Identifying Silk
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            It seems that more and more silk fabrics are appearing in the market. Customers have big doubts about its purity when they want to buy 100% natural silk home.
                        </Text>
                        <Link to={"/blogs/identify-silk"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src={
                                        'https://d2culxnxbccemt.cloudfront.net/craft/content/uploads/a/general/wp-content/uploads/2015/11/3681_-1432674263049.jpg'
                                    }
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Single Point', 'Double Point', 'Circular']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Knitting Needle Types
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            One thing is for sure: with the variety of knitting needles available today, knitting has been perfected and has evolved to a creative art! Knitting needles have developed from simple sticks to the diverse assortment available on the market today.
                        </Text>
                        <Link to={"/blogs/needles-types"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/66DwKp55/blog8.jpg"
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Abbreviations', 'Terminology']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Knitting Patterns
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            Knitting has its own writing method, so when you look at knitting patterns you may see a variety of unfamiliar terms and abbreviations. Once you know the following list of common knitting abbreviations,
                        </Text>
                        <Link to={"/blogs/knitting-patterns"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src={
                                        'https://magdamakes.com/wp-content/uploads/media/img_1224-e1551229977489-600x450.webp'
                                    }
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Sizing Up', 'Needles']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Selecting Knitting Needles
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            Knitting needles come in a stunning assortment of materials and sizes to mesh with your knitting style, the particular project you're working on, your aesthetics, and your budget.
                        </Text>
                        <Link to={"/blogs/selecting-knitting-needles"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem  bg={"#f6faf2"} borderRadius={'lg'} p={4} marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/rFmyVP5Q/blog9.jpg"
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Wool', 'Silk']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Types of Yarn Fibers
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            All types of yarn for knitting or crocheting are made from natural or synthetic fibers. Different types of yarn fibers have specific qualities - some good, some not so good.
                        </Text>
                        <Link to={"/blogs/yarn-types"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/rsgpn6xt/blog10.jpg"
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Ball', 'Skein', 'Hank']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Type Of Yarn Packaging
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            Yarn is packaged (or put up) in different forms: balls, skeins (rhymes with canes), and hanks. The differences in yarn packaging shouldn't have much effect on the yarn you choose.
                        </Text>
                        <Link to={"/blogs/yarn-packaging"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
                <WrapItem bg={"#f6faf2"} borderRadius={'lg'} p={4}  marginTop="5" width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
                    <Box w="100%">
                        <Box borderRadius="lg" overflow="hidden">
                            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                                <Image
                                    transform="scale(1.0)"
                                    src="https://i.postimg.cc/8cy1dRgR/blog11.jpg"
                                    alt="some text"
                                    objectFit="cover"
                                    height="200px"
                                    width="100%"
                                    transition="0.3s ease-in-out"
                                    _hover={{
                                        transform: 'scale(1.05)',
                                    }}
                                />
                            </Box>
                        </Box>
                        <BlogTags tags={['Yarn', 'Weights']} marginTop={3} />
                        <Heading fontSize="xl" marginTop="2">
                            <Text textDecoration="none" fontSize="24px"
                                color="#6FA82F"
                                fontFamily="DM Serif Display"
                                fontWeight="400" _hover={{ textDecoration: 'none' }}>
                                Guide to yarn weights
                            </Text>
                        </Heading>
                        <Text as="p" fontSize="md" marginTop="2">
                            Explore the world of yarn weights and discover how they impact your knitting and crochet projects.
                            Learn about the different yarn thicknesses and how to choose the perfect yarn for your next masterpiece.
                        </Text>
                        <Link to={"/blogs/yarn-weight"}> <Text as="p" fontSize="md" _hover={{ color: "#232424" }} fontWeight={"semibold"} color={"#6FA82F"} >... Read More</Text></Link>

                    </Box>
                </WrapItem>
            </Wrap>
            <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
                <Heading as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                    Explore the World of Creativity at knitsilk.com
                </Heading>
                <Text as="p" fontSize="lg">
                    Welcome to KnitSilk.com, your gateway to a world of creativity and craftsmanship. We take immense pride in offering a wide range of premium materials, from luxurious silk and versatile yarn to exquisite ribbons, fabrics, and unique blends. Whether you're a seasoned artisan or just starting your creative journey, our products are meticulously curated to elevate your projects. At KnitSilk.com, we're committed to fueling your inspiration, enabling you to craft exceptional pieces, and bringing your artistic visions to life.
                </Text>
                <Text as="p" fontSize="lg">
                    Our silk collection boasts the finest quality, perfect for creating elegant garments and accessories. Explore a rainbow of yarn colors and textures, each telling a unique story and waiting to be woven into your next masterpiece. Elevate your gift wrapping and décor with our enchanting ribbons, or dive into our diverse fabric selection to craft everything from clothing to home decor. For those who seek unique combinations, our blends offer endless possibilities.
                </Text>
            </VStack>

        </Container>
    )
}

export default ArticleList;