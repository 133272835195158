import React, { useState, useEffect } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Box,
    Input,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useToast,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    IconButton,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';

export default function EncourageBiggerOrders() {
    const [isOpen, setIsOpen] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [currentDiscount, setCurrentDiscount] = useState(null);
    const [discount, setDiscount] = useState('');
    const [priceINR, setPriceINR] = useState('');
    const [priceUSD, setPriceUSD] = useState('');
    const [priceEUR, setPriceEUR] = useState('');
    const [priceGBP, setPriceGBP] = useState('');
    const [priceCAD, setPriceCAD] = useState('');
    const [priceAUD, setPriceAUD] = useState('');
    const [priceJPY, setPriceJPY] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [exchangeRates, setExchangeRates] = useState({});
    const toast = useToast();

    const onOpen = () => setIsOpen(true);
    const onClose = () => {
        setIsOpen(false);
        setCurrentDiscount(null);
        setDiscount('');
        setPriceINR('');
        setPriceUSD('');
        setPriceEUR('');
        setPriceGBP('');
        setPriceCAD('');
        setPriceAUD('');
        setPriceJPY('');
        setStartDate('');
        setEndDate('');
    };

    const fetchDiscounts = async () => {
        try {
            const response = await axios.get('https://api.digiblocks.tech/api/discounts');
            setDiscounts(response.data);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to fetch discounts.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchExchangeRates = async () => {
        try {
            const response = await axios.get('https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_1tt8wr2ipN8IvW2CeX3dgfWzv5lrOwWH4yIxz6ts&currencies=EUR,USD,CAD,GBP,JPY,AUD');
            setExchangeRates(response.data.data);
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to fetch exchange rates.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchDiscounts();
        fetchExchangeRates();
    }, []);

    useEffect(() => {
        if (priceUSD && exchangeRates.USD) {
            const usdValue = parseFloat(priceUSD);
            setPriceEUR((usdValue * exchangeRates.EUR).toFixed(2));
            setPriceGBP((usdValue * exchangeRates.GBP).toFixed(2));
            setPriceCAD((usdValue * exchangeRates.CAD).toFixed(2));
            setPriceAUD((usdValue * exchangeRates.AUD).toFixed(2));
            setPriceJPY((usdValue * exchangeRates.JPY).toFixed(2));
        }
    }, [priceUSD, exchangeRates]);

    const handleCreateDiscount = async () => {
        if (!discount || !startDate || !endDate || (!priceINR && !priceUSD && !priceEUR && !priceGBP && !priceCAD && !priceAUD && !priceJPY)) {
            toast({
                title: 'Error',
                description: 'All fields are required.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response1 = await axios.get(
                "https://api.digiblocks.tech/api/discounts"
              );
             
              
            if(response1.data.length){
                toast({
                    title: 'Warning',
                    description: 'One Big Discount is already available Delete it or Edit it.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }
            const response = await axios.post('https://api.digiblocks.tech/api/discounts', {
                discount,
                priceINR,
                priceUSD,
                priceEUR,
                priceGBP,
                priceCAD,
                priceAUD,
                priceJPY,
                startDate,
                endDate,
            });

            if (response.status === 200) {
                toast({
                    title: 'Success',
                    description: 'Discount created successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                fetchDiscounts();
                onClose();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create discount.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleUpdateDiscount = async () => {
        if (!discount || !startDate || !endDate || (!priceINR && !priceUSD && !priceEUR && !priceGBP && !priceCAD && !priceAUD && !priceJPY)) {
            toast({
                title: 'Error',
                description: 'All fields are required.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.put(`https://api.digiblocks.tech/api/discounts/${currentDiscount._id}`, {
                discount,
                priceINR,
                priceUSD,
                priceEUR,
                priceGBP,
                priceCAD,
                priceAUD,
                priceJPY,
                startDate,
                endDate,
            });

            if (response.status === 200) {
                toast({
                    title: 'Success',
                    description: 'Discount updated successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchDiscounts();
                onClose();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update discount.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDeleteDiscount = async (id) => {
        try {
            const response = await axios.delete(`https://api.digiblocks.tech/api/discounts/${id}`);
            if (response.status === 200) {
                toast({
                    title: 'Success',
                    description: 'Discount deleted successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchDiscounts();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to delete discount.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleEditDiscount = (discount) => {
        setCurrentDiscount(discount);
        setDiscount(discount.discount);
        setPriceINR(discount.priceINR || '');
        setPriceUSD(discount.priceUSD || '');
        setPriceEUR(discount.priceEUR || '');
        setPriceGBP(discount.priceGBP || '');
        setPriceCAD(discount.priceCAD || '');
        setPriceAUD(discount.priceAUD || '');
        setPriceJPY(discount.priceJPY || '');
        setStartDate(discount.startDate.split('T')[0]);
        setEndDate(discount.endDate.split('T')[0]);
        onOpen();
    };

    return (
        <Box maxW="100%" p={8}>
            <Flex justifyContent="space-between" mb={4}>
                <Heading size={["sm", "md", "xl"]}>Encourage Big Orders</Heading>
                <Button
                    bg="black"
                    borderRadius="none"
                    size="sm"
                    colorScheme="green"
                    onClick={onOpen}
                >
                    Create Discount
                </Button>
            </Flex>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Discount %</Th>
                        <Th>Price INR</Th>
                        <Th>Price USD</Th>
                        <Th>Start Date</Th>
                        <Th>End Date</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {discounts.map((discount) => (
                        <Tr key={discount._id}>
                            <Td>{discount.discount}</Td>
                            <Td>{discount.priceINR || 'N/A'}</Td>
                            <Td>{discount.priceUSD || 'N/A'}</Td>
                            <Td>{new Date(discount.startDate).toLocaleDateString()}</Td>
                            <Td>{new Date(discount.endDate).toLocaleDateString()}</Td>
                            <Td>
                                <IconButton
                                    icon={<EditIcon />}
                                    mr={2}
                                    onClick={() => handleEditDiscount(discount)}
                                />
                                <IconButton
                                    icon={<DeleteIcon />}
                                    onClick={() => handleDeleteDiscount(discount._id)}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{currentDiscount ? 'Edit Discount' : 'Create Discount'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>Discount %</FormLabel>
                                <Input
                                    type="number"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price INR</FormLabel>
                                <Input
                                    type="text"
                                    value={priceINR}
                                    placeholder="INR"
                                    onChange={(e) => setPriceINR(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price USD</FormLabel>
                                <Input
                                    type="text"
                                    value={priceUSD}
                                    placeholder="USD"
                                    onChange={(e) => setPriceUSD(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price EUR</FormLabel>
                                <Input
                                    type="text"
                                    value={priceEUR}
                                    placeholder="EUR"
                                    readOnly
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price GBP</FormLabel>
                                <Input
                                    type="text"
                                    value={priceGBP}
                                    placeholder="GBP"
                                    readOnly
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price CAD</FormLabel>
                                <Input
                                    type="text"
                                    value={priceCAD}
                                    placeholder="CAD"
                                    readOnly
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price AUD</FormLabel>
                                <Input
                                    type="text"
                                    value={priceAUD}
                                    placeholder="AUD"
                                    readOnly
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price JPY</FormLabel>
                                <Input
                                    type="text"
                                    value={priceJPY}
                                    placeholder="JPY"
                                    readOnly
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Start Date</FormLabel>
                                <Input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>End Date</FormLabel>
                                <Input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={currentDiscount ? handleUpdateDiscount : handleCreateDiscount}>
                            {currentDiscount ? 'Update' : 'Create'}
                        </Button>
                        <Button variant="outline" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
