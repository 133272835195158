import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Text,
  IconButton,
  Flex,
  Icon,
  Select,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { FaTrash, FaTruck } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AppContext } from '../../Context/AppContext';
import DogsComponent from '../DogsComponent';

const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const CartSidebar = ({ isOpen, onClose }) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [guestCartProducts, setGuestCartProducts] = useState([]);
  let [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem('userToken');
  const userCurrency = localStorage.getItem('userCurrency');
  const { refresh, setRefresh, cartLength, setCartLength, auth, setAuth, cartTotalPrice, setCartTotalPrice } = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();


  const getCartData = async () => {
    const responses = await axios.get("https://api.digiblocks.tech/products");

    try {
      setLoading(true);
      const response = await axios.get("https://api.digiblocks.tech/getcartitems", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const cartItems = response.data.data;

      const productIdsInCart = cartItems.map(cartItem => cartItem.productId);
      const productsInCart = responses.data.filter(product => productIdsInCart.includes(product._id));

      const updatedProductsInCart = productsInCart.map(product => {
        const cartItem = cartItems.find(cartItem => cartItem.productId === product._id);
        if (cartItem) {
          return { ...product, quantity: cartItem.quantity };
        }
        return product;
      });

      let x = updatedProductsInCart.reduce((acc, product) => {
        return acc + ((product[`price${userCurrency}`]-(product[`price${userCurrency}`]*product?.discount).toFixed(2)/100).toFixed(2) * Number(product.quantity).toFixed(2));
      }, 0);

      localStorage.setItem("totalPrice", x);
      setCartTotalPrice(x);
      setTotalPrice(x);
      setCartProducts(updatedProductsInCart);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getGuestCartData = async () => {
    try {
      const guestCart = JSON.parse(Cookies.get('guestCart') || '[]');
      const responses = await axios.get("https://api.digiblocks.tech/products");
      const productsInGuestCart = responses.data.filter(product => guestCart.includes(product._id));

      setGuestCartProducts(productsInGuestCart);
    } catch (error) {
      console.error("Error fetching guest cart data:", error);
    }
  };

  useEffect(() => {
    if (userToken) {
      getCartData();
    } else {
      getGuestCartData();
    }
  }, [userToken, refresh]);

  const updateQuantity = async (productId, newQuantity) => {
    try {
      setLoading(true);
      const response = await fetch('https://api.digiblocks.tech/updatequantity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify({
          productId: productId,
          quantity: newQuantity
        }),
      });

      if (!response.ok) {
        throw new Error(`Error updating quantity: ${response.statusText}`);
      }

      const responseData = await response.json();
      getCartData();
      if (responseData.msg === "Quantity updated successfully.") {
        const updatedCartProducts = cartProducts.map(item =>
          item._id === productId ? { ...item, quantity: newQuantity } : item
        );

        setCartProducts(updatedCartProducts);

        toast({
          title: "Quantity Updated",
          description: "Quantity updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Error updating quantity:", responseData.msg);
        toast({
          title: "Error",
          description: responseData.message || "Error updating quantity.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast({
        title: "Error",
        description: "Error updating quantity.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  let getcartLength = async () => {
    let userToken = localStorage.getItem("userToken") || "";
    if (userToken) {
      let response = await axios.get("https://api.digiblocks.tech/getcartitems", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setCartLength(response.data.data.length);
    }
  };

  const removeItem = async (productId) => {
    try {
      setLoading(true);
      const response = await axios.delete(`https://api.digiblocks.tech/removeitem/${productId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      setRefresh(!refresh);
      if (response.data.msg.includes('deleted')) {
        getCartData();
        const updatedCartProducts = cartProducts.filter(item => item._id !== productId);
        setCartProducts(updatedCartProducts);
        toast({
          title: "Item Removed",
          description: "Item removed from the cart.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error('Error removing item:', response.data.msg);
        toast({
          title: "Error",
          description: response.data.msg || "Error removing item.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error removing item:', error);
      toast({
        title: "Error",
        description: "Error removing item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      getcartLength();
    }
  };

  const removeItemFromGuestCart = (productId) => {
    try {
      const guestCart = JSON.parse(Cookies.get('guestCart') || '[]');
      const updatedGuestCart = guestCart.filter(id => id !== productId);
      Cookies.set('guestCart', JSON.stringify(updatedGuestCart));
      setGuestCartProducts(prevProducts => prevProducts.filter(product => product._id !== productId));
      setRefresh(!refresh);
      toast({
        title: "Item Removed",
        description: "Item removed from the cart.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // getCartData();
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast({
        title: "Error",
        description: "Error removing item from cart.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Drawer placement="right" size={['sm', 'sm', 'md']} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent m={0} p={0} >
        <DrawerCloseButton color="black" />
        <DrawerHeader>
          <Flex direction={"column"} gap={3}>
            <Text m={0}>Your Cart</Text>
            {!loading && userToken && (<>   <Flex justifyContent="space-between" alignItems="center" fontSize={["16px", "", "25px"]} fontWeight={600}>
              <Text>Sub Total:</Text>
              <Text display={"flex"} alignItems={"center"} textAlign={"center"}>
                {currencyIcons[userCurrency]} {(Number(totalPrice)).toFixed(2)}
              </Text>
            </Flex></>)}
          </Flex>

        </DrawerHeader>
        {(auth || !auth) && (
          <DrawerBody as={Flex} gap={3} direction={"column"} overflowY={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgb(11,168,47)",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              scrollBehavior: "smooth",
              height: "calc(100vh - 120px)",
            }}
          >
            {loading && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="green.500" size="xl" />}
            {auth && cartProducts.length > 0 && cartProducts.map((item, i) => (
              <Box border="1px solid gray"
                mb={i == cartProducts.length - 1 ? "200px" : ""}
                borderRadius={"10px"}
                key={item._id} p={2} borderBottom="1px solid #ccc"
              >
                <Flex direction={"column"}>
                  <Flex direction={["row"]} gap={4} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                    <Image src={item.photos[0]} alt={item.title} boxSize="100px" objectFit="cover" />
                    <Flex alignItems={"flex-start"} gap={1} >
                      <Text m={0} mt={2}>
                        {currencyIcons[userCurrency]}
                      </Text>
                      <Text m={0} display={"flex"} mt={2} textAlign={"center"} fontSize={["12px", "14px"]}>
                        {(item[`price${userCurrency}`]-(item[`price${userCurrency}`]*item.discount).toFixed(2)/100).toFixed(2)}
                      </Text>
                    </Flex>
                    <Flex direction={"column"} gap={2}>
                      <Select
                        value={item.quantity}
                        w={16}
                        size={"sm"}
                        onChange={(e) => updateQuantity(item._id, parseInt(e.target.value))}
                      >
                        {Array.from({ length: item.qtyInStock }, (_, index) => index + 1).map((qty) => (
                          <option key={qty} value={qty}>
                            {qty}
                          </option>
                        ))}
                      </Select>
                      <IconButton
                        size={"sm"}
                        color="red"
                        aria-label="Remove item"
                        icon={<FaTrash />}
                        variant="ghost"
                        onClick={() => removeItem(item._id)}
                      />
                    </Flex>
                    <Box ml={[10, 10, 0]}>
                      <Text display={"flex"} m={0} alignItems={"center"} textAlign={"center"}>
                      {((item[`price${userCurrency}`]-(item[`price${userCurrency}`]*item.discount).toFixed(2)/100).toFixed(2)* Number(item.quantity)).toFixed(2)}
                    
                      </Text>
                    </Box>
                    <Text fontSize={["12px", "16px"]} lineHeight={1.5}>
                      {item.title.split(' ').slice(0, 17).join(' ')}...
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}

            {!auth && guestCartProducts.length > 0 && guestCartProducts.map((item, i) => (
              <Box border="1px solid gray"
                mb={i == guestCartProducts.length - 1 ? "200px" : ""}
                borderRadius={"10px"}
                key={item._id} py={2} borderBottom="1px solid #ccc"
              >
                <Flex direction={"column"}>
                  <Flex direction={["row"]} gap={4} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                    <Image
                      src={item.photos[0]}
                      alt={item.name}
                      w="100px"
                      mt="1"
                      h="100px"
                      borderRadius="15px"
                      transition="transform 0.3s ease-in-out"
                      _hover={{ transform: "scale(1.2)" }}
                    />

                    <Flex w={"60%"} direction={"column"} gap={3}>
                      <Flex>
                        <Text color="black" textAlign={["start", "center", "center"]} fontSize={["16px", "12px", "15px"]} fontWeight={600} noOfLines={2} >{item.title}</Text>
                      </Flex>
                      <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <Text textAlign={["start", "center", "center"]} fontSize={["14px", "12px", "15px"]} fontWeight={500}>
                          {currencyIcons[userCurrency]}{"  "}{item[`price${userCurrency}`]}
                        </Text>
                        <IconButton
                          size="sm"
                          colorScheme="red"
                          aria-label="Remove item"
                          icon={<FaTrash />}
                          onClick={() => removeItemFromGuestCart(item._id)}
                          isDisabled={loading}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            ))}
            {
              (cartProducts.length == 0 && guestCartProducts.length===0) &&
              <>
                <Text fontWeight={"bold"} textAlign={"center"}>Your cart is empty.</Text>
                <DogsComponent />
              </>
            }
          </DrawerBody>
        )}
        <Box position={"fixed"} mr="2" width={"98%"} bottom={0}>
          <Link to="/products">
            <Button
              borderRadius={0}
              _hover={{ bg: "#232424" }}
              variant="outline"
              width="100%"
              color="white"
              size="lg"
              backgroundColor="#6FA82F"
              onClick={onClose}
            >
              Continue Shopping
            </Button>
          </Link>
          <>
            <Text bg={"white"} textAlign={"center"} m={0} fontSize={["12px", "14px", "16px"]}>
              <Icon as={FaTruck} mr={2} /> Shipping + taxes calculated at checkout
            </Text>
            <Button
              borderRadius={0}
              _hover={{ bg: "#232424" }}
              variant="outline"
              width="100%"
              color="white"
              size="lg"
              backgroundColor="#6FA82F"
              onClick={() => {
                if (userToken) {
                  onClose();
                  navigate("/checkout");
                } else {
                  toast({
                    title: "Please Login First",
                    description: "Without login you can't process further.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
            >
              Proceed to Checkout
            </Button>
          </>

        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default CartSidebar;
