import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableCaption,
  Select,
  VStack,
  Center,
  useToast,
  Tooltip,
  Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react";
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { GiReturnArrow } from "react-icons/gi";
import { SiFsecure } from "react-icons/si";
import { TbTruckDelivery } from "react-icons/tb";
import React, { useEffect, useState } from "react";
import Magnifier from "react-magnifier";
import { motion } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ShareButton from "./ShareButton";
import EmbeddedWebsite from '../HomePageComponents/Reviews.jsx'
import { AppContext } from '../../Context/AppContext';
import { useContext } from 'react';
import RecentlyViewed from '../HomePageComponents/RecentlyViewed.jsx'
import anim from '../../assets/LOADING.mp4';
import cardsData from "./cardsData.js";
import ChatWithAdmin from "../../pages/ChatWithAdmin.jsx";
const userCurrency = localStorage.getItem("userCurrency");



const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const DetailsPageComponent = () => {
  let { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);

  let { id } = useParams();
  let [imgarr, setImgarr] = useState([]);
  let [product, setProduct] = useState({});
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const [relatedProducts, setRelatedProducts] = useState([]);
  let [imge, setimge] = useState(1);
  let [state, setstate] = useState(1);
  let toast = useToast();
  const [loading, setLoading] = useState(false);
  const [shippingFee, setShippingFee] = useState(null);
  const [skuproducts, setskuproducts] = useState([]);

  let getrelatedskus = async (sku) => {
    let response = await axios.get(`https://api.digiblocks.tech/products/sku/${sku}`)
    setskuproducts(response.data)

  }
  const getSingleProduct = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.digiblocks.tech/products/${id}`);
      setProduct(response.data);
      setCategory(response.data.category);
      setImgarr(response.data.photos);
      setLoading(false);
      getrelatedskus(response.data.sku);
    } catch (error) {
      console.error("Error fetching single product:", error);
    }
  };
  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const response = await fetch(
          `https://api.digiblocks.tech/products/categories?category=${category}`
        );
        if (response.ok) {
          const data = await response.json();
          const limitedProducts = data.slice(0, 4);
          setRelatedProducts(limitedProducts);
        } else {
          console.error("Failed to fetch products by category");
        }
      } catch (error) {
        console.error("Error fetching products by category", error);
      }
    };
    fetchProductsByCategory();
  }, [category]);
  useEffect(() => {
    getSingleProduct();
    fetchIPDetails();
  }, [refresh]);

  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const fetchIPDetails = async () => {
    const response = await axios.get('https://ipapi.co/json/');
    fetchData(response.data);
  }

  const fetchData = async (d) => {
    try {
      if (d.country === "IN" || d.country === "US") {
        const response = await axios.get(`https://api.digiblocks.tech/shipping-profiles/country/${d.region}`);
        setShippingFee(response.data);
      } else {
        const response = await axios.get(`https://api.digiblocks.tech/shipping-profiles/country/${d.country}`);
        setShippingFee(response.data);

      }
    } catch (error) {
      setShippingFee(null);
    }
  };

  const addToWishlistFunction = async (id) => {
    let userToken = localStorage.getItem('userToken');
    if (!userToken) {
      toast({
        title: 'Please login first to add this item to your wishlist.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(
        'https://api.digiblocks.tech/addtowishlist',
        {
          productId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      toast({
        title: 'Item added to wishlist successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      getwishlistlength();
    } catch (error) {
      console.error('Error adding to wishlist:', error);

      toast({
        title: 'Failed to add item to wishlist. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  let getwishlistlength = async () => {
    let userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      let response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setWishlistLength(response.data.data.length);
    }
  };

  const productDetails = [
    { label: "Color", value: product.color ? product.color : "" },
    { label: "Primary Color", value: product.primaryColor ? product.primaryColor : "" },
    { label: "Secondary Color", value: product.secondaryColor ? product.secondaryColor : "" },
    { label: "Craft Type", value: product.craftType ? product.craftType.join(", ") : "" },
    { label: "Yarn Weight", value: product.yarnWeight ? `${product.yarnWeight} / ${product.otherNames}` : "" },
    { label: "Materials", value: product.materials ? product.materials.join(", ") : "" },
    { label: "Material", value: product.material ? product.material : "" },
    { label: "Primary Material", value: product.primaryMaterial ? product.primaryMaterial : "" },
    { label: "Secondary Material", value: product.secondaryColor ? product.secondaryMaterial : "" },
    { label: "Item Weight", value: product.itemWeight ? product.itemWeight : "" },
    { label: "Item Size", value: product.itemSize ? product.itemSize : "" },
    { label: "Item Dimensions", value: product.productLength ? `${product.productLength} * ${product.productWidth} * ${product.productHeight} ${product.productDimensionsUnit}` : "" },
    { label: "Make Content (Type)", value: product.makeContent ? product.makeContent : "" },
    { label: "Wash Care", value: product.care ? product.care : "" },
    { label: "Yards", value: product.yardage ? product.yardage : "" },
    { label: "Needle Size", value: product.needleSize ? `${product.needleSize} mm` : "" },
    { label: "Hook Size", value: product.hookSize ? `${product.hookSize} mm` : "" },
    { label: "Length", value: product.lengthUnit ? `${product.length} ${product.lengthUnit}` : product.length },
    { label: "Width", value: product.widthUnit ? `${product.width} ${product.widthUnit}` : product.width },
    { label: "Skeins", value: product.skeins ? product.skeins : "" },
    { label: "Meter per 100g", value: product.meterPer100g ? product.meterPer100g : "" },
    { label: "Hand Painted", value: product.handPainted ? "Yes" : "No" },
    { label: "Hand Dyed", value: product.handDyed ? "Yes" : "No" },
    { label: "Hand Made", value: product.handMade ? "Yes" : "No" },
    { label: "Organic", value: product.organic ? "Yes" : "No" },
    { label: "Cut to Size", value: product.cutToSize ? "Yes" : "No" },
    { label: "Manufacturer", value: "M.Jiju Silk Mills" },
  ].filter(detail => detail.value !== undefined && detail.value !== null && detail.value !== "");

  const [selectedColor, setSelectedColor] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  let userToken = localStorage.getItem('userToken');

  const handleColorClick = (color) => {
    setSelectedColor(color);
    setIsImageModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedColor(null);
    setIsImageModalOpen(false);
  };
  let getcartLength = async () => {
    let userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      let response = await axios.get('https://api.digiblocks.tech/getcartitems', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setCartLength(response.data.data.length);
    }
  };

  const addtocartfunctionGrid = async (id, e) => {
    setRefresh(!refresh);
    const userToken = localStorage.getItem('userToken');
    e.stopPropagation();
    if (userToken) {
      try {
        const data = await axios.post(
          'https://api.digiblocks.tech/addtocart',
          { productId: id },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (data.data.message === 'Item added to cart successfully.') {
          setRefresh(!refresh);
          toast({
            title: 'Added to Cart.',
            description: 'Item added to cart successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        } else if (data.data.msg === 'Product already in cart') {
          toast({
            title: 'Already in Cart.',
            description: 'Product already in cart.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
        getcartLength();
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    } else {
      // Get current cart from cookies
      const cart = JSON.parse(Cookies.get('guestCart') || '[]');

      // Add product to cart
      if (!cart.includes(id)) {
        cart.push(id);
        Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
        toast({
          title: "Product added to the cart.",
          description: 'Please login to proceed to checkout.',
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Already in Cart.',
          description: 'Product already in the cart.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${product.title} - Premium Quality | KnitSilk`}</title>
      </Helmet>
      {loading ? (
        <Flex height="60vh" alignItems="center" justifyContent="center">
          <video autoPlay loop muted width="700">
            <source src={anim} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        </Flex>
      ) : product && product.photos ? (
        <>
          <>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ type: "spring", damping: 25, stiffness: 120 }}
            >
              <Flex
                w={"100%"}
                mt={"40px auto"}
                position={"relative"}

                px={["0", "0", "5%"]}
                direction={["column", "column", "row"]}
              >
                <VStack zIndex={999} position={"absolute"} top={3} right={3}>
                  <Button
                    size={"sm"}
                    borderRadius={"50%"}
                    onClick={() => {
                      toast({
                        title: "Added to wishlist",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                    }}
                  >
                    <FaHeart color="rgb(111,168,47)" />
                  </Button>
                  <ShareButton shareUrl={`https://knitsilk.com/products/${product?._id}`} shareTitle={product?.title}
                  />
                </VStack>
                <Flex w={["100%", "", "40%"]} p={"0px 5"} direction={"column"} >
                  {imgarr &&
                    imgarr.map((e, i) => {
                      return (
                        <>
                          {imge === i + 1 && (
                            <Box p={5}>
                              <Magnifier
                                src={e}
                                zoomFactor={1.5} // Zoom factor, how much to zoom the image
                                mgShape="square" // Set the shape of the magnifier lens to square
                                style={{ maxWidth: "250px", maxHeight: "420px" }}
                              />
                            </Box>

                          )}
                        </>
                      );
                    })}
                  <Flex
                    justifyContent={"flex-start"}
                    gap={"20px"}
                    direction={"row"}
                    flexWrap={"wrap"}
                    p={"15px"}
                  >
                    {imgarr &&
                      imgarr.map((e, i) => {
                        return (
                          <>
                            <Image
                              alt="Uploaded"
                              border={
                                imge === i + 1
                                  ? "3px solid rgb(111,168,47)"
                                  : "1px dashed rgb(111,168,47)"
                              }
                              _hover={{ cursor: "pointer" }}
                              onClick={() => {
                                setimge(i + 1);
                              }}
                              src={e}
                              h={["50px", "60px", "70px"]}
                              w={["50px", "60px", "70px"]}
                            ></Image>
                          </>
                        );
                      })}
                  </Flex>
                </Flex>
                <Flex p={4} w={["100%", "", "60%"]} direction={"row"} gap={"10px"}>
                  <Box>
                    <Text
                      fontSize={["16px", "18px", "22px"]}
                      fontWeight={"bold"}
                      // letterSpacing={[1, 1, 2]}
                      // fontFamily={"DM Serif Display"}
                      m={0}
                      width={["100%", "", "93%"]}
                    >
                      {product.title}
                    </Text>
                    <hr style={{ zIndex: "20" }}></hr>

                    <Flex
                      justifyContent={"space-between"}
                      direction={["column", "", "row"]}
                      m={["auto", "", "0"]}
                      w={"100%"}

                    >
                      <Flex direction={"column"} w={["90%", "", "60%"]} gap={3}>

                        <Flex gap={"10px"} flex={2}>
                          <Text
                            m={0}
                            flex={1}
                            fontWeight={"bold"}
                          >

                            Price
                          </Text>
                          <Text m={0} flex={1}>
                            <Flex >
                              <Text
                                display={"flex"}
                                alignItems={"center"}
                                fontSize={"18px"}
                                fontWeight={500}
                                gap={"10px"}
                                m={0}
                              >
                                {currencyIcons[userCurrency]}&nbsp;
                                {product[`price${userCurrency}`]}
                              </Text>
                            </Flex>
                          </Text>
                        </Flex>
                        <Flex gap={"10px"} flex={2}>
                          <Text
                            m={0}
                            flex={1}
                            fontWeight={"bold"}
                          >
                            {" "}
                            Color
                          </Text>
                          <Text m={0} flex={1}>
                            {product.color}
                          </Text>
                        </Flex>


                        {
                          product.yardage && (<Flex gap={"10px"} flex={2}>
                            <Text
                              m={0}
                              fontWeight={"bold"}
                              flex={1}
                            >
                              {" "}
                              Length
                            </Text>
                            <Text m={0} flex={1}>
                              {product.yardage} Yards
                            </Text>
                          </Flex>)
                        }
                        <Flex gap={"10px"} flex={2}>
                          <Text
                            m={0}
                            fontWeight={"bold"}
                            flex={1}
                          >
                            {" "}
                            Materials
                          </Text>
                          <Text m={0} flex={1}>
                            {product.primaryMaterial},
                            {product.secondaryMaterial}
                          </Text>
                        </Flex>
                        {
                          product.productLength && (
                            <Flex gap={"10px"} flex={2}>
                              <Text
                                m={0}
                                fontWeight={"bold"}
                                flex={1}
                              >
                                {" "}
                                Item Dimensions
                              </Text>
                              <Text m={0} flex={1}>
                                {product.productLength} * {product.productWidth} *
                                {product.productHeight}
                                {" "}{product.productDimensionsUnit}
                              </Text>
                            </Flex>
                          )
                        }
                        {
                          product.care && (
                            <Flex gap={"10px"} flex={2}>
                              <Text
                                flex={1}
                                m={0}
                                fontWeight={"bold"}

                              >

                                Wash Care
                              </Text>
                              <Text flex={1} m={0} >
                                {product.care}
                              </Text>
                            </Flex>
                          )
                        }
                      </Flex>

                      <Flex

                        w={["80%", "", "40%"]}
                        gap={2}
                        p={"0 20px"}
                        direction={["column"]}
                        m="auto"
                        mt={4}
                      >
                        <Box as={Flex} justifyContent={"flex-start"}>
                          <Flex gap={2}>
                            <Text
                              fontWeight={"bold"}
                              fontSize={"18x"}
                            >
                              Quantity: &nbsp;
                            </Text>
                            <Select w={"50%"} size={"sm"}>
                              {Array.from({ length: product.qtyInStock }).map(
                                (_, index) => (
                                  <option key={index}>{index + 1}</option>
                                )
                              )}
                            </Select>
                            <Text
                              w={"50%"}
                              fontSize={"16px"}
                            >
                              {product.qtyInStock > 0
                                ? "In Stock"
                                : "Out Of stock"}
                            </Text>
                          </Flex>
                        </Box>
                        <Button
                          colorScheme="green"
                          bg="#e8eee4"
                          color="#6FA82F"
                          borderRadius={0}
                          mt={4}
                          mb={4}
                          onClick={(e) => {
                            addtocartfunctionGrid(id, e);

                          }}
                          _hover={{ bg: "#6FA82F", color: "white" }}
                          rightIcon={<FaShoppingCart />}
                        >
                          Add to Cart
                        </Button>
                        <Button
                          colorScheme="green"
                          bg="#6FA82F"
                          color="white"
                          borderRadius={0}
                          onClick={(e) => {
                            addtocartfunctionGrid(id, e);
                            navigate('/checkout')
                          }}
                          mt={4}
                          mb={4}
                          _hover={{ bg: "black", color: "white" }}
                          rightIcon={<FaShoppingCart />}
                        >
                          Buy Now
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex p={1} m={["auto", "", ""]} justifyContent={"space-between"}>
                      <Box>
                        {product.colorVariations && product.colorVariations.length > 0 && (
                          <Box>
                            <Text
                              textAlign={"left"}
                              fontWeight={"bold"}
                              fontSize={"18px"}
                            >
                              Colors :
                            </Text>
                            {product.colorVariations.map((variation) => (
                              <Tooltip
                                key={variation._id}
                                bg="white"
                                color="black"
                                label={
                                  <>
                                    <div>{`Price: ${variation.price}`}</div>
                                    <div>{`Color: ${variation.color}`}</div>
                                  </>
                                }
                                hasArrow
                                placement="top"
                              >
                                <Button
                                  m={1}
                                  border="1px solid gray"
                                  p="1"
                                  size={"sm"}
                                  borderRadius={"full"}
                                  colorScheme={variation.color}
                                  bg={variation.color}
                                  onClick={() => handleColorClick(variation.color)}
                                >
                                </Button>
                              </Tooltip>
                            ))}
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {product.lengthVariations && product.lengthVariations.length > 0 && (
                          <Box>
                            <Text
                              textAlign={"left"}
                              fontWeight={"bold"}
                              fontSize={"18px"}
                            >
                              Length :
                            </Text>
                            {product.lengthVariations.map((variation) => (
                              <Tooltip
                                key={variation._id}
                                bg="white"
                                color="black"
                                label={
                                  <>
                                    <div>{`Price: ${variation.price}`}</div>
                                    <div>{`Length: ${variation.value}`}{" "}{variation.length}</div>
                                  </>
                                }
                                hasArrow
                                placement="top"
                              >
                                <Button
                                  m={1}
                                  border="1px dashed gray"
                                  p="2"
                                  size={"sm"}
                                  colorScheme="green"
                                  bg="#e8eee4"
                                  color="#6FA82F"
                                  borderRadius={0}
                                  _hover={{ bg: "#6FA82F", color: "white" }}
                                >
                                  {variation.value}{" "}{variation.length}
                                </Button>
                              </Tooltip>
                            ))}
                          </Box>
                        )}
                      </Box>

                      <Modal isOpen={isImageModalOpen} onClose={handleCloseModal}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalCloseButton />
                          <ModalBody>
                            {selectedColor && (
                              <Image src={product.colorVariations.find(variation => variation.color === selectedColor)?.linkedphoto} alt={`Linked Photo - ${selectedColor}`} />
                            )}
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Flex>
                    {/* variations section */}
                    <Box>
                      {skuproducts.length > 1 && <Text fontWeight={"bold"} fontSize={"22px"} textDecor={"underline"}>Variations</Text>}
                      <Flex direction={"row"} flexWrap={"wrap"} gap={5} p={2}>
                        {
                          skuproducts.length > 0 && skuproducts.map((product, i) => {
                            return product?._id !== id ? <Box onClick={() => {
                              navigate(`/products/${product._id}`)
                              setRefresh(!refresh)
                            }} key={i} border={"1px dashed rgb(111,168,47)"} p={2}>
                              <Image height={"90px"} w={"100px"} src={product.photos[0]}></Image>
                              <Text

                                textAlign={"center"}

                                fontWeight={600}

                                m={0}
                                mt={2}
                              >
                                {currencyIcons[userCurrency]}&nbsp;
                                {product[`price${userCurrency}`]}
                              </Text>
                              <Text textAlign={"center"} fontWeight={600} m={0}>{product?.color && product.color}</Text>

                              <Text textAlign={"center"} m={0}>{product?.qtyUnit && product.qtyUnit} {product?.qtyVariationValue && product.qtyVariationValue}</Text>
                              <Text textAlign={"center"} m={0}>{product?.lengthVariationValue && product.lengthVariationValue} {product?.lengthVariationUnit && product.lengthVariationUnit}</Text>
                            </Box> : <></>

                          })
                        }
                      </Flex>
                    </Box>





                    <hr></hr>
                    <Flex p={1} m={["auto", "", ""]} width={["100%", "80%", "100%"]} justifyContent={"space-between"}>
                      <Box >
                        <GiReturnArrow fontSize={"50px"} />
                        <Text>7days Returnable</Text>
                      </Box>
                      <Box>
                        <SiFsecure fontSize={"50px"} />
                        <Text>Secure Transaction</Text>
                      </Box>
                      <Box>
                        <TbTruckDelivery fontSize={"50px"} />
                        <Text>Fastest Delivery</Text>
                      </Box>
                    </Flex>
                    <hr></hr>
                    {
                      userToken && (
                        <Flex justifyContent={["space-between", "", "flex-end"]} gap="20px">
                          <Text>Having Trouble ?</Text>
                          <ChatWithAdmin />
                        </Flex>

                      )
                    }
                    {product.bulletPoints.length > 0 && <VStack align="start" mt={2}>
                      <Text fontWeight={"bold"}>About This Item :</Text>
                      <ul>
                        {product.bulletPoints &&
                          product.bulletPoints.map((point, index) => (
                            <Text as="li" key={index}>
                              {point}
                            </Text>
                          ))}
                      </ul>
                    </VStack>}
                  </Box>
                </Flex>
              </Flex>
            </motion.div>
            {/* image hover section */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={animationVariants}
            >
              <Flex
                bg={"#F9F9F9"}
                mt={"100px"}
                w={"100%"}
                margin={"auto"}
                p={["10px", "20px", "30px"]}
                direction={"column"}
              >
                <Flex
                  w={"100%"}
                  margin={"auto"}
                  px={["0", "0", "5%"]}
                  direction={["column", "column", "row"]}
                  gap={[30, ,]}
                >
                  <Box w={["100%", "", "30%"]} p={3}>
                    {product.photos[0] && (
                      <Image
                        alt="Uploaded"
                        src={product.photos[0]}
                        m={["20px auto", "auto", "0px"]}
                        direction={["column", "column", "row"]}
                        w="100%"
                        maxW="250px"
                        maxH="420px"
                        display={{ base: 'none', md: 'none', lg: 'block' }}
                      />
                    )}
                  </Box>
                  <Flex w={["100%", "", "100%"]}
                    direction={"column"}  >
                    <Flex
                      w={["100%", "100%", "70%"]}

                      direction={["column", "column", "row"]}

                      justifyContent={["center", "center", "flex-start"]}
                      gap={[30, , 100]}
                    >
                      <Text
                        textDecoration={[
                          state === 1 ? "underline" : "none",
                          state === 1 ? "underline" : "none",
                          "none",
                        ]}
                        onClick={() => {
                          setstate(1);
                        }}
                        _hover={{ cursor: "pointer" }}
                        fontSize={"22px"}
                        fontWeight="bold"
                      // fontFamily={"DM Serif Display"}
                      >
                        Product Details
                      </Text>
                      <Text
                        onClick={() => {
                          setstate(3);
                        }}
                        _hover={{ cursor: "pointer" }}
                        fontSize={"22px"}
                        fontWeight="bold"
                        // fontFamily={"DM Serif Display"}
                        textDecoration={[
                          state === 3 ? "underline" : "none",
                          state === 3 ? "underline" : "none",
                          "none",
                        ]}
                      >
                        Shipping & Return
                      </Text>
                    </Flex>
                    {state === 1 && (
                      <Box width={["100%", "90%", "100%"]}  >
                        <Text
                          mt="10px"
                          fontSize="18px"
                          letterSpacing="1.1"
                          fontWeight={400}
                        >
                          {product.description
                            .split("\n")
                            .map((paragraph, index) => (
                              <p
                                key={index}
                                style={{
                                  marginBottom: "10px",
                                  textAlign: "justify",
                                }}
                              >
                                {paragraph}
                              </p>
                            ))}
                        </Text>
                      </Box>
                    )}
                    {state === 3 && (
                      <Box width={["100%", "90%", "100%"]} >
                        <Box >
                          <Text
                            fontSize={["18px", "20px", "22px"]}
                            mt={"20px"}
                            // fontFamily={"DM Serif Display"}
                            fontWeight={"bold"}
                          >
                            Shipping
                          </Text>
                          <Text
                            fontSize={["14px", "16px", "18px"]}
                            letterSpacing={"1.1"}
                          >
                            The item will be shipped from India. So it needs
                            few days to deliver. Our product is good at a
                            reasonable price and we believe you will worth it.
                            So please wait for it patiently! Thanks. Any
                            questions please kindly contact us and we promise to
                            work hard to help you to solve the problem.
                          </Text>

                          <Text
                            fontSize={["18px", "20px", "22px"]}
                            mt={"20px"}
                            fontWeight={"bold"}
                          // fontFamily={"DM Serif Display"}

                          >
                            About return request
                          </Text>
                          <Text
                            fontSize={["14px", "16px", "18px"]}
                            letterSpacing={"1.1"}
                          >
                            If you don't need the item with worry, you can
                            contact us then we will help you to solve the
                            problem, so please close the return request! Thanks.
                          </Text>

                          <Text
                            fontSize={["18px", "20px", "22px"]}
                            mt={"20px"}
                            // fontFamily={"DM Serif Display"}
                            fontWeight={"bold"}
                          >
                            Guarantee
                          </Text>
                          <Text
                            fontSize={["14px", "16px", "18px"]}
                            letterSpacing={"1.1"}
                          >
                            If it is the quality question, we will resend or
                            refund to you; If you receive damaged or wrong
                            items, please contact us and attach some pictures
                            about product, we will exchange a new correct item
                            to you after the confirmation.
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </motion.div>
          </>
          <Flex flexWrap={"wrap"} maxW="1200px" margin="auto" p={[1, , 0]}>
            <Table variant="simple" size="sm" w={["100%", "", ""]} >
              <TableCaption
                placement="top"
                fontFamily={"DM serif Display"}
                textAlign="left"
                fontSize="3xl"
                color={"rgb(111,168,47)"}
                fontWeight="bold"
              >
                Product Information
              </TableCaption>
              <Flex flex={2} w={"100%"} flexWrap={"wrap"} justifyContent={["", "", "space-between"]}>
                <Tbody w={["100%", "", "50%"]} flex={1}>
                  {productDetails.slice(0, productDetails.length / 2).map((detail, index) => (
                    <Tr key={index} >
                      <Td fontWeight="bold" w={["180px", "", ""]}>{detail.label}</Td>
                      <Td>{detail.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tbody w={["100%", "", "50%"]}>
                  {productDetails.slice(productDetails.length / 2).map((detail, index) => (
                    <Tr key={index}>
                      <Td w={["180px", "", ""]} fontWeight="bold">{detail.label}</Td>
                      <Td>{detail.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Flex>

            </Table>

          </Flex>
          {product.video && (
            <Box m="auto" p={10} width="100%">
              <Heading
                textAlign="center"
                // fontFamily={"DM serif Display"}
                mb={5}
              >
                Explore more about this Product
              </Heading>
              <video
                controls
                style={{ width: "100%" }}
                src={product.video}
              ></video>
            </Box>
          )}

          <Box marginTop="50px">
            <EmbeddedWebsite />
          </Box>

          <RecentlyViewed />

          <Box mt={'8%'} p={4} bg="white" maxW="1200px" mx="auto">
            {relatedProducts.length > 0 && (
              <Center>
                <Heading
                  style={{
                    color: '#6FA82F',
                    fontFamily: 'DM Serif Display',
                    fontSize: { base: '24px', md: '46px' },
                    lineHeight: { base: '32px', md: '58px' },
                    fontWeight: 400,
                  }}
                >
                  Related Products
                </Heading>
              </Center>
            )}
            <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 4 }} spacing={8} mt={8}>
              {relatedProducts.map(product => (
                <Box w={['80%', '90%', '100%']} m={'auto'} key={product._id} bg="#f9f9f9" shadow="md" p={4} position="relative">
                  <Flex p={2} direction={'column'} alignItems={'center'} gap={5} position={'absolute'} top={0} right={0}>
                    <Button
                      zIndex={'999'}
                      _hover={{ transform: 'scale(1.3)', transition: 'transform 0.3s ease-in-out' }}
                      size={'md'}
                      borderRadius={'50%'}
                      onClick={(e) => {
                        e.stopPropagation();
                        addToWishlistFunction(product._id);
                      }}
                    >
                      <FaHeart color="rgb(111,168,47)" />
                    </Button>
                    <ShareButton shareUrl={`https://knitsilk.com/products/${product._id}`} shareTitle={product.title} />
                  </Flex>
                  <Center>
                    <Image
                      src={product.photos[0]}
                      alt={product.title}
                      transition="transform 0.2s"
                      _hover={{ transform: 'scale(1.1)' }}
                      height="200px"
                      onClick={() => {
                        setRefresh(!refresh)
                        navigate(`/products/${product._id}`);
                      }}
                    />
                  </Center>
                  <Text mt={4} fontFamily='DM Serif Display' fontSize="lg" textAlign="center">
                    {product.title.split(' ').slice(0, 8).join(' ')}...
                  </Text>
                  <Center>
                    <Text display={'flex'} gap={"5px"} alignItems={'center'} fontSize={'18px'} fontWeight={500}>
                      {currencyIcons[userCurrency]} {product[`price${userCurrency}`]}
                    </Text>
                  </Center>
                  <Text m={0} textAlign={"center"} fontSize={'16px'}>
                    {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
                  </Text>
                  <Center>
                    <Button
                      colorScheme="green"
                      bg="#e8eee4"
                      color="#6FA82F"
                      w="70%"
                      mt={4}
                      borderRadius={0}
                      mb={4}
                      _hover={{ bg: '#6FA82F', color: 'white' }}
                      rightIcon={<FaShoppingCart />}
                      isFullWidth
                      p={'0 70px'}
                      onClick={(e) => {
                        addtocartfunctionGrid(product._id, e);
                      }}
                    >
                      Add to Cart
                    </Button>


                  </Center>
                </Box>
              ))}
            </SimpleGrid>
          </Box>


          <Box maxW="1250px" margin={"auto"} my="2%">
            <Flex flexWrap="wrap" justifyContent="space-between">
              {cardsData.map((data, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="md"
                  p={4}
                  mx={"auto"}
                  mb={4}
                  maxW="sm"
                  w={{ base: "100%", md: "30%" }}
                  bg="white"
                >
                  <Image
                    src={data.imageUrl}
                    alt={data.heading}
                    h="auto"
                    w="100%"
                  />
                  <Text
                    fontSize="xl"
                    mt={4}
                    // fontFamily={"DM serif Display"}
                    fontWeight="bold"
                  >
                    {data.heading}
                  </Text>
                  <Text mt={2}>{data.description}</Text>
                </Box>
              ))}
            </Flex>
          </Box>

          <Center mt={12} >
            <Button
              _hover={{ bg: "#232424" }}
              bg={"#6FA82F"}
              fontSize={["14px", "14px", "18px"]}
              p={["25px", "30px"]}
              color={"white"}
              borderRadius={"0"}
              m={"auto"}
              mb={"50px"}
              onClick={() => navigate("/products")}
            >
              <Flex gap={"10px"} alignItems={"center"}>
                <Text mt={3}>Explore More</Text>
                <BsArrowRight />
              </Flex>
            </Button>
          </Center>
        </>
      ) : (
        <Heading>Product Not Found !!!</Heading>
      )}
    </>
  );
};

export default DetailsPageComponent;
