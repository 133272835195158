import React from 'react'

const StripeFailure = () => {
  return (
    <div>
      Failure
    </div>
  )
}

export default StripeFailure
