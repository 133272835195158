import React, { useState, useEffect } from 'react';
import {
  Box,
  Badge,
  Image,
  Text,
  Collapse,
  Button,
  Flex,
  Grid,
  Heading,
  ChakraProvider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  const [isReadMore, setIsReadMore] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.digiblocks.tech/blogs');
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleReadMore = (blogId) => {
    setIsReadMore((prevIsReadMore) => ({
      ...prevIsReadMore,
      [blogId]: !prevIsReadMore[blogId],
    }));
  };

  return (
    <Box maxWidth="1200px" m="auto">
      {
        blogs &&
        <Heading
          mx="10"
          as="h2"
          fontSize="32px"
          color="#6FA82F"
          fontFamily="DM Serif Display"
          fontWeight="400"
        >
          Recent Blogs
        </Heading>
      }
      <Grid
        templateColumns={{ base: '100%', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
        gap={4}
        py={10}
        maxW={'1200px'}
        p={4}
        margin={'auto'}
      >
        {blogs.map((blog) => (
          <MotionBox
            key={blog._id}
            borderRadius="md"
            overflow="hidden"
            m="4"
            bg={'#f6faf2'}
            p="4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <Image
                src={blog.imageUrl}
                alt={blog.title}
                borderRadius="md"
                objectFit="cover"
                m="auto"
                height="200px"
              />
            </motion.div>
            <Box p={4}>
              <Text
                color="#6FA82F"
                fontFamily="DM Serif Display"
                fontWeight="400"
                _hover={{ textDecoration: 'none' }}
                fontSize="xl"
                mb={2}
              >
                {blog.title}
              </Text>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Badge colorScheme="green">
                    {new Date(blog.createdAt).toLocaleDateString()}
                  </Badge>
                </Box>
                <Button
                  size="sm"
                  _hover={{ bg: '#232424' }}
                  bg={'#6FA82F'}
                  color={'white'}
                  borderRadius={'0'}
                  fontSize={'smaller'}
                  onClick={() => toggleReadMore(blog._id)}
                  colorScheme="green"
                >
                  {isReadMore[blog._id] ? 'Read Less' : 'Read More'}
                </Button>
              </Flex>
              <Collapse startingHeight={60} in={isReadMore[blog._id]}>
                <Text as="p" fontSize="md" mt={4}>
                  {blog.content}
                </Text>
              </Collapse>
            </Box>
          </MotionBox>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogCard;
