import React, { useState } from 'react';
import { ChakraProvider, Text, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const YarnPackaging = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box
                    width="90%"
                    bg={"#f6faf2"}
                    padding="20px"
                    overflowY="auto"
                    border="2px solid #C9F8E8"
                    mb={5}
                    borderRadius="10px"
                    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                >

                    <Heading
                        as="h2"
                        fontSize="32px"
                        color="#6FA82F"
                        fontFamily="DM Serif Display"
                        borderBottom="2px solid #74D69E"
                        paddingBottom="10px"
                        mb={4}
                    >
                        Yarn Packaging: Shapes and Tips
                    </Heading>

                    <Text fontSize="lg" mb={4}>
                        Yarn packaging comes in different forms, each with its own unique characteristics. While the choice of packaging doesn't affect the yarn itself, it can impact your knitting or crocheting experience. Let's explore the common yarn packaging shapes and some useful tips.
                    </Text>

                    <Box display="flex" flexDirection="column" alignItems="start" mb={4}>
                        <Text fontSize="lg" fontWeight="bold" mb={2}>1. Ball:</Text>
                        <Text fontSize="lg" mb={2}>
                            Yarn is neatly wrapped into a spherical shape, making it easy to work with.
                        </Text>
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="start" mb={4}>
                        <Text fontSize="lg" fontWeight="bold" mb={2}>2. Skein:</Text>
                        <Text fontSize="lg" mb={2}>
                            Yarn is loosely twisted into a skein, ready for knitting or crocheting once you find the end. Convenient for quick starts.
                        </Text>
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="start" mb={4}>
                        <Text fontSize="lg" fontWeight="bold" mb={2}>3. Hank:</Text>
                        <Text fontSize="lg" mb={2}>
                            Yarn is wound into a large circle and then folded. Hank yarn needs to be wound into a ball before use, or you'll end up with a tangled mess.
                        </Text>
                        <Text fontSize="lg" mb={2}>
                            <strong>Tip:</strong> To keep your yarn organized, pull the yarn end from the inside of the skein or ball. This prevents it from rolling around as you work.
                        </Text>
                    </Box>

                    <Text fontSize="lg">
                        Whether you prefer the convenience of balls and skeins or enjoy the process of winding hank yarn, understanding yarn packaging shapes ensures a smoother crafting experience.
                    </Text>
                </Box>


            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default YarnPackaging;
