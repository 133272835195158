import React from 'react';
import { Helmet } from 'react-helmet';
import { ChakraProvider } from '@chakra-ui/react';
import Address from '../components/Checkout.jsx/Address';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import UserChatButton from '../components/Chat/ChatButton'

const Checkout = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Checkout" content="KnitSilk | Checkout" />
                <title>KnitSilk | Checkout</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Address />
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    );
}

export default Checkout;
