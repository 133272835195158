import React from 'react';
import { Helmet } from 'react-helmet';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import AllProducts from '../components/ProductsPageComponents/AllProducts';
import UserChatButton from '../components/Chat/ChatButton'

const ProductsPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Products" content="KnitSilk | Products" />
                <title>KnitSilk | Products</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <AllProducts />
            <UserChatButton/>
            <Footer />
        </>
    );
}

export default ProductsPage;
