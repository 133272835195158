import {
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Text,
    Table,
    Tbody,
    Tr,
    Td,
    TableCaption,
    VStack,
    Spinner,
    Tooltip,
    Modal, ModalOverlay, ChakraProvider, ModalContent, ModalCloseButton, ModalBody
} from "@chakra-ui/react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import Magnifier from "react-magnifier";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import AdminNav from './AdminNav'
import axios from "axios";

const SingleListing = () => {
    let { id } = useParams();
    let [imgarr, setImgarr] = useState([]);
    let [product, setProduct] = useState({});
    const navigate = useNavigate();
    let [imge, setimge] = useState(1);
    let [state, setstate] = useState(1);
    const [loading, setLoading] = useState(false);

    const getSingleProduct = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.digiblocks.tech/products/${id}`);
            setProduct(response.data);
            setImgarr(response.data.photos);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching single product:", error);
        }
    };
    useEffect(() => {
        getSingleProduct();
    }, []);

    const animationVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
    };

    const productDetails = [
        { label: "Color", value: product.color ? product.color : "" },
        { label: "Primary Color", value: product.primaryColor ? product.primaryColor : "" },
        { label: "Secondary Color", value: product.secondaryColor ? product.secondaryColor : "" },
        { label: "Craft Type", value: product.craftType ? product.craftType.join(", ") : "" },
        { label: "Yarn Weight", value: product.yarnWeight ? `${product.yarnWeight} / ${product.otherNames}` : "" },
        { label: "Materials", value: product.materials ? product.materials.join(", ") : "" },
        { label: "Material", value: product.material ? product.material : "" },
        { label: "Primary Material", value: product.primaryMaterial ? product.primaryMaterial : "" },
        { label: "Secondary Material", value: product.secondaryColor ? product.secondaryMaterial : "" },
        { label: "Item Weight", value: product.itemWeight ? product.itemWeight : "" },
        { label: "Item Size", value: product.itemSize ? product.itemSize : "" },
        { label: "Item Dimensions", value: product.productLength ? `${product.productLength} * ${product.productWidth} * ${product.productHeight}` : "" },
        { label: "Make Content (Type)", value: product.makeContent ? product.makeContent : "" },
        { label: "Wash Care", value: product.care ? product.care : "" },
        { label: "Yards", value: product.yardage ? product.yardage : "" },
        { label: "Needle Size", value: product.needleSize ? `${product.needleSize} mm` : "" },
        { label: "Hook Size", value: product.hookSize ? `${product.hookSize} mm` : "" },
        { label: "Length", value: product.lengthUnit ? `${product.length} ${product.lengthUnit}` : product.length },
        { label: "Width", value: product.widthUnit ? `${product.width} ${product.widthUnit}` : product.width },
        { label: "Skeins", value: product.skeins ? product.skeins : "" },
        { label: "Meter per 100g", value: product.meterPer100g ? product.meterPer100g : "" },
        { label: "Hand Painted", value: product.handPainted ? "Yes" : "No" },
        { label: "Hand Dyed", value: product.handDyed ? "Yes" : "No" },
        { label: "Hand Made", value: product.handMade ? "Yes" : "No" },
        { label: "Organic", value: product.organic ? "Yes" : "No" },
        { label: "Cut to Size", value: product.cutToSize ? "Yes" : "No" },
        { label: "Item Dimensions", value: product.productLength ? `${product.productLength} * ${product.productWidth} * ${product.productHeight}` : "" },
        { label: "Manufacturer", value: "M.Jiju Silk Mills" },
    ].filter(detail => detail.value !== undefined && detail.value !== null && detail.value !== "");

    const [selectedColor, setSelectedColor] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    const handleColorClick = (color) => {
        setSelectedColor(color);
        setIsImageModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedColor(null);
        setIsImageModalOpen(false);
    };

    return (
        <ChakraProvider>
            <AdminNav />
            <Box ml={["0px", "0px", "15%"]} w={"85%"} >
                <Box my="4">
                    {loading ? (
                        <Flex height="60vh" alignItems="center" justifyContent="center">
                            <Spinner size="xl" color="green.500" />
                        </Flex>
                    ) : product && product.photos ? (
                        <>
                            <>
                                <motion.div
                                    initial={{ opacity: 0, y: -100 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ type: "spring", damping: 25, stiffness: 120 }}
                                >
                                    <Flex
                                        w={"100%"}
                                        mt={"40px auto"}
                                        position={"relative"}

                                        px={["0", "0", "5%"]}
                                        direction={["column", "column", "row"]}
                                    >
                                        <Flex w={["100%", "", "40%"]} p={"0px 5"} direction={"column"} >
                                            {imgarr &&
                                                imgarr.map((e, i) => {
                                                    return (
                                                        <>

                                                            {imge === i + 1 && (
                                                                <Box p={5}>
                                                                    <Magnifier
                                                                        src={e}
                                                                        zoomFactor={1.5}
                                                                        mgShape="square"
                                                                        style={{maxWidth:"250px" ,maxHeight:"420px"}}

                                                                    />
                                                                </Box>

                                                            )}


                                                        </>
                                                    );
                                                })}
                                            <Flex
                                                justifyContent={"flex-start"}
                                                gap={"20px"}
                                                direction={"row"}
                                                flexWrap={"wrap"}
                                                p={"15px"}
                                            >
                                                {imgarr &&
                                                    imgarr.map((e, i) => {
                                                        return (
                                                            <>
                                                                <Image
                                                                    alt="Uploaded"
                                                                    border={
                                                                        imge == i + 1
                                                                            ? "3px solid rgb(111,168,47)"
                                                                            : "1px dashed rgb(111,168,47)"
                                                                    }
                                                                    _hover={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setimge(i + 1);
                                                                    }}
                                                                    src={e}
                                                                    h={["50px", "60px", "70px"]}
                                                                    w={["50px", "60px", "70px"]}
                                                                ></Image>
                                                            </>
                                                        );
                                                    })}
                                            </Flex>
                                        </Flex>
                                        <Flex p={4} w={["100%", "", "60%"]} direction={"row"} gap={"10px"}>

                                            <Box>
                                                <Flex>

                                                    <Text
                                                        fontSize={["16px", "18px", "20px"]}
                                                        fontWeight={500}
                                                        fontFamily={"DM Serif Display"}
                                                        m={0}
                                                        width={["100%", "", "93%"]}
                                                    >
                                                        {product.title}
                                                    </Text>
                                                    <Button
                                                        color="white"
                                                        bg="black"
                                                        borderRadius={"none"}
                                                        _hover={{ bg: "black", color: "white" }}
                                                        leftIcon={<FaLongArrowAltLeft />}
                                                        onClick={() => navigate(`/manager/listing`)}
                                                    >
                                                        Back To Listings
                                                    </Button>
                                                </Flex>

                                                <hr style={{ zIndex: "20" }}></hr>

                                                <Flex
                                                    justifyContent={"space-between"}
                                                    direction={["column", "", "row"]}
                                                    m={["auto", "", "0"]}
                                                    w={"100%"}

                                                >
                                                    <Flex direction={"column"} w={["90%", "", "60%"]} gap={3}>

                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                flex={1}
                                                                fontWeight={"bold"}
                                                            >

                                                                Price INR
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                <Flex >
                                                                    <Text
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        fontSize={"18px"}
                                                                        fontWeight={500}
                                                                        gap={"10px"}
                                                                        m={0}
                                                                    >
                                                                        ₹ {product[`priceINR`]}
                                                                    </Text>
                                                                </Flex>
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                flex={1}
                                                                fontWeight={"bold"}
                                                            >

                                                                Price USD
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                <Flex >
                                                                    <Text
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        fontSize={"18px"}
                                                                        fontWeight={500}
                                                                        gap={"10px"}
                                                                        m={0}
                                                                    >
                                                                        $                        {product[`priceUSD`]}
                                                                    </Text>
                                                                </Flex>
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                flex={1}
                                                                fontWeight={"bold"}
                                                            >
                                                                {" "}
                                                                Color
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                {product.color}
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2} >
                                                            <Text
                                                                m={0}
                                                                flex={1}
                                                                fontWeight={"bold"}
                                                            >
                                                                {" "}
                                                                Material
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                {product.primaryMaterial}
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                fontWeight={"bold"}
                                                                flex={1}
                                                            >
                                                                {" "}
                                                                Length
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                {product.yardage} Yards
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                fontWeight={"bold"}
                                                                flex={1}
                                                            >
                                                                {" "}
                                                                Fabric Type
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                {product.primaryMaterial},
                                                                {product.secondaryMaterial}
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                m={0}
                                                                fontWeight={"bold"}
                                                                flex={1}
                                                            >
                                                                {" "}
                                                                Item Dimensions
                                                            </Text>
                                                            <Text m={0} flex={1}>
                                                                {product.productLength} * {product.productWidth} *
                                                                {product.productHeight}
                                                            </Text>
                                                        </Flex>
                                                        <Flex gap={"10px"} flex={2}>
                                                            <Text
                                                                flex={1}
                                                                m={0}
                                                                fontWeight={"bold"}

                                                            >

                                                                Wash Care
                                                            </Text>
                                                            <Text flex={1} m={0} >
                                                                {product.care}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex

                                                        w={["80%", "", "40%"]}
                                                        gap={2}
                                                        p={"0 20px"}
                                                        direction={["column"]}
                                                        m="auto"
                                                        mt={4}
                                                    >
                                                        <Box as={Flex} justifyContent={"flex-start"}>
                                                            <Flex gap={2}>
                                                                <Text
                                                                    fontWeight={"bold"}
                                                                    fontSize={"18x"}
                                                                >
                                                                    Quantity: &nbsp;
                                                                </Text>
                                                                <Text>{product.qtyInStock}</Text>
                                                                <Text
                                                                    w={"50%"}
                                                                    fontSize={"16px"}
                                                                >
                                                                    {product.qtyInStock > 0
                                                                        ? "In Stock"
                                                                        : "Out Of stock"}
                                                                </Text>
                                                            </Flex>
                                                        </Box>                                            </Flex>

                                                </Flex>

                                                <Flex p={1} m={["auto", "", ""]} justifyContent={"space-between"}>
                                                    <Box>
                                                        {product.colorVariations && product.colorVariations.length > 0 && (
                                                            <Box>
                                                                <Text
                                                                    textAlign={"left"}
                                                                    fontWeight={"bold"}
                                                                    fontSize={"18px"}
                                                                >
                                                                    Colors :
                                                                </Text>
                                                                {product.colorVariations.map((variation) => (
                                                                    <Tooltip
                                                                        key={variation._id}
                                                                        bg="white"
                                                                        color="black"
                                                                        label={
                                                                            <>
                                                                                <div>{`Price: ${variation.price}`}</div>
                                                                                <div>{`Color: ${variation.color}`}</div>
                                                                            </>
                                                                        }
                                                                        hasArrow
                                                                        placement="top"
                                                                    >
                                                                        <Button
                                                                            m={1}
                                                                            border="1px solid gray"
                                                                            p="1"
                                                                            size={"sm"}
                                                                            borderRadius={"full"}
                                                                            colorScheme={variation.color}
                                                                            bg={variation.color}
                                                                            onClick={() => handleColorClick(variation.color)}
                                                                        >
                                                                        </Button>
                                                                    </Tooltip>
                                                                ))}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box>
                                                        {product.lengthVariations && product.lengthVariations.length > 0 && (
                                                            <Box>
                                                                <Text
                                                                    textAlign={"left"}
                                                                    fontWeight={"bold"}
                                                                    fontSize={"18px"}
                                                                >
                                                                    Length :
                                                                </Text>
                                                                {product.lengthVariations.map((variation) => (
                                                                    <Tooltip
                                                                        key={variation._id}
                                                                        bg="white"
                                                                        color="black"
                                                                        label={
                                                                            <>
                                                                                <div>{`Price: ${variation.price}`}</div>
                                                                                <div>{`Length: ${variation.value}`}{" "}{variation.length}</div>
                                                                            </>
                                                                        }
                                                                        hasArrow
                                                                        placement="top"
                                                                    >
                                                                        <Button
                                                                            m={1}
                                                                            border="1px dashed gray"
                                                                            p="2"
                                                                            size={"sm"}
                                                                            colorScheme="green"
                                                                            bg="#e8eee4"
                                                                            color="#6FA82F"
                                                                            borderRadius={0}
                                                                            _hover={{ bg: "#6FA82F", color: "white" }}
                                                                        >
                                                                            {variation.value}{" "}{variation.length}
                                                                        </Button>
                                                                    </Tooltip>
                                                                ))}
                                                            </Box>
                                                        )}
                                                    </Box>

                                                    <Modal isOpen={isImageModalOpen} onClose={handleCloseModal}>
                                                        <ModalOverlay />
                                                        <ModalContent>
                                                            <ModalCloseButton />
                                                            <ModalBody>
                                                                {selectedColor && (
                                                                    <Image src={product.colorVariations.find(variation => variation.color === selectedColor)?.linkedphoto} alt={`Linked Photo - ${selectedColor}`} />
                                                                )}
                                                            </ModalBody>
                                                        </ModalContent>
                                                    </Modal>
                                                </Flex>
                                                {product.bulletPoints.length > 0 && <VStack align="start" mt={2}>
                                                    <Text fontWeight={"bold"}>About This Item :</Text>
                                                    <ul>
                                                        {product.bulletPoints &&
                                                            product.bulletPoints.map((point, index) => (
                                                                <Text as="li" key={index}>
                                                                    {point}
                                                                </Text>
                                                            ))}
                                                    </ul>
                                                </VStack>}
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </motion.div>
                                {/* image hover section */}
                                <motion.div
                                    initial="hidden"
                                    animate="visible"
                                    variants={animationVariants}
                                >
                                    <Flex
                                        bg={"#F9F9F9"}
                                        mt={"100px"}
                                        w={"100%"}
                                        margin={"auto"}
                                        p={["10px", "20px", "30px"]}
                                        direction={"column"}
                                    >
                                        <Flex
                                            w={"100%"}
                                            margin={"auto"}
                                            px={["0", "0", "5%"]}
                                            direction={["column", "column", "row"]}
                                            gap={[30, ,]}
                                        >
                                            <Flex w={["100%", "", "100%"]}
                                                direction={"column"}  >
                                                <Flex
                                                    w={["100%", "100%", "70%"]}

                                                    direction={["column", "column", "row"]}

                                                    justifyContent={["center", "center", "flex-start"]}
                                                    gap={[30, , 100]}
                                                >
                                                    <Text
                                                        textDecoration={[
                                                            state == 1 ? "underline" : "none",
                                                            state == 1 ? "underline" : "none",
                                                            "none",
                                                        ]}
                                                        onClick={() => {
                                                            setstate(1);
                                                        }}
                                                        _hover={{ cursor: "pointer" }}
                                                        fontSize={"22px"}
                                                        fontFamily={"DM Serif Display"}
                                                    >
                                                        Product Description
                                                    </Text>
                                                </Flex>

                                                <Box width={["100%", "90%", "100%"]}  >
                                                    <Text
                                                        mt="10px"
                                                        fontSize="18px"
                                                        letterSpacing="1.1"
                                                        fontWeight={400}
                                                    >
                                                        {product.description
                                                            .split("\n")
                                                            .map((paragraph, index) => (
                                                                <p
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom: "10px",
                                                                        textAlign: "justify",
                                                                    }}
                                                                >
                                                                    {paragraph}
                                                                </p>
                                                            ))}
                                                    </Text>
                                                </Box>


                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </motion.div>
                            </>
                            <Flex flexWrap={"wrap"} maxW="1200px" margin="auto" p={[1, , 0]}>
                                <Table variant="simple" size="sm" w={["100%", "", ""]} >
                                    <TableCaption
                                        placement="top"
                                        color="black"
                                        textAlign="left"
                                        fontSize={"22px"}
                                        fontFamily={"DM Serif Display"}
                                    >
                                        Product Information
                                    </TableCaption>
                                    <Flex flex={2} w={"100%"} flexWrap={"wrap"} justifyContent={["", "", "space-between"]}>
                                        <Tbody w={["100%", "", "50%"]} flex={1}>
                                            {productDetails.slice(0, productDetails.length / 2).map((detail, index) => (
                                                <Tr key={index} >
                                                    <Td fontWeight="bold" w={["180px", "", ""]}>{detail.label}</Td>
                                                    <Td>{detail.value}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                        <Tbody w={["100%", "", "50%"]}>
                                            {productDetails.slice(productDetails.length / 2).map((detail, index) => (
                                                <Tr key={index}>
                                                    <Td w={["180px", "", ""]} fontWeight="bold">{detail.label}</Td>
                                                    <Td>{detail.value}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Flex>

                                </Table>

                            </Flex>
                            {product.video && (
                                <Box m="auto" p={10} width="100%">
                                    <Heading
                                        textAlign="center"
                                        fontFamily={"DM serif Display"}
                                        mb={5}
                                    >
                                        Explore more about this Product
                                    </Heading>
                                    <video
                                        controls
                                        style={{ width: "100%" }}
                                        src={product.video}
                                    ></video>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Heading>Product Not Found !!!</Heading>
                    )}
                </Box>
            </Box>
        </ChakraProvider>

    );
};

export default SingleListing;
