import React, { useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image, IconButton, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const ImageSlider = () => {
    const settings = useMemo(() => ({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        lazyLoad: 'ondemand', // Enable lazy loading
    }), []);

    const images = useMemo(() => [
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186270/1_ycyd1y.png",
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186303/2_zixysz.png",
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186246/3_w54sxe.png",
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186278/4_rbo47y.png",
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186290/5_s8rdck.png",
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186302/6_kfqvy7.png"
    ], []);

    const navigate = useNavigate();

    const navigateToPage = (index) => {
        switch (index) {
            case 0:
                navigate("/products?category=Ribbons&subcategories=All");
                break;
            case 1:
                navigate("/products?category=Scarves&subcategories=All");
                break;
            case 2:
                navigate("/products?category=Home Decor&subcategories=All");
                break;
            case 3:
                navigate("/products?category=Yarns&subcategories=All");
                break;
            case 4:
                navigate("/products?category=Yarns&subcategories=All");
                break;
            case 5:
                navigate("/products?category=Fibres&subcategories=All");
                break;
            default:
                break;
        }
    };

    const sliderRef = React.useRef();

    return (
        <Box px={2} margin={"auto"} position="relative">
            <Slider ref={sliderRef} {...settings}>
                {images.map((image, i) => (
                    <Box key={i} my={8} position="relative">
                        <Image
                            as="img"
                            m={"auto"}
                            w={"100%"}
                            alt="Uploaded"
                            objectFit={"fill"}
                            onClick={() => navigateToPage(i)}
                            src={image}
                            fallback={<Skeleton height="300px" />}
                            _hover={{ cursor: "pointer" }}
                        />
                        <IconButton
                            position="absolute"
                            left={0}
                            top="50%"
                            bg="white"
                            borderRadius={"100%"}
                            color={"green"}
                            transform="translateY(-50%)"
                            icon={<ChevronLeftIcon />}
                            display={["none", null, "block"]}
                            _hover={{ cursor: "pointer" }}
                            onClick={() => sliderRef.current.slickPrev()}
                            variant="ghost"
                            aria-label="Previous"
                        />
                        <IconButton
                            position="absolute"
                            right={0}
                            top="50%"
                            bg="white"
                            display={["none", null, "block"]}
                            borderRadius={"100%"}
                            color={"green"}
                            _hover={{ cursor: "pointer" }}
                            transform="translateY(-50%)"
                            icon={<ChevronRightIcon />}
                            onClick={() => sliderRef.current.slickNext()}
                            variant="ghost"
                            aria-label="Next"
                        />
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default React.memo(ImageSlider);
