import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import AdminNav from './AdminNav'
import SingleReturnRequestPage from './SingleReturnRequestPage'



const SingleReturnRequest = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"}>
          <SingleReturnRequestPage/>
        </Box>
      </Flex>
    </>
  )
}

export default SingleReturnRequest;