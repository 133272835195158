import React from 'react';
import { Helmet } from 'react-helmet';
import { ChakraProvider } from '@chakra-ui/react';
import ArticleList from '../components/BlogPageComponents/Blog';
import Nav from '../components/HomePageComponents/Nav';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Footer from '../components/HomePageComponents/Footer';
import BlogCard from '../components/BlogPageComponents/BlogCard';
import UserChatButton from '../components/Chat/ChatButton'

const BlogPage = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Blog" content="KnitSilk | Blog" />
                <title>KnitSilk | Blog</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <ArticleList />
            <BlogCard />
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    );
}

export default BlogPage;
