import React, { useEffect } from 'react';
import './EmbeddedWebsite.css'; // Import a separate CSS file for styling

const EmbeddedWebsite = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widgets.sociablekit.com/etsy-reviews/widget.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='embedded-website-container'>
            <h1 className='embedded-website-heading'>See What Our Valuable Customers Are Saying</h1>
            <div className='sk-ww-etsy-reviews' data-embed-id='25417145'></div>
        </div>
    );
};

export default EmbeddedWebsite;
