import React from 'react'
import AdminNav from './AdminNav'
import { Box, Flex } from '@chakra-ui/react'
import ContactQueries from './ContactQueries'


const Integration = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"} >
        <ContactQueries/>
        </Box>
      </Flex>
    </>
  )
}

export default Integration
