import React from 'react'
import AdminNav from './AdminNav'
import { Box, Flex } from '@chakra-ui/react'
import SubscribersList from './SubscribersList'

const Subscribers = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"} >
          <SubscribersList />
        </Box>
      </Flex>
    </>
  )
}

export default Subscribers
