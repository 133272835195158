import React from 'react';
import { Helmet } from 'react-helmet';
import { ChakraProvider } from '@chakra-ui/react';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import Offers from '../components/Offers/Offers';
import UserChatButton from '../components/Chat/ChatButton'

const OffersPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Special Offers" content="KnitSilk | Special Offers" />
                <title>KnitSilk | Special Offers</title>
            </Helmet>
            <ChakraProvider>
                <TopBanner />
                <Nav />
                <Offers />
                <UserChatButton />
                <Footer />
            </ChakraProvider>
        </>
    );
}

export default OffersPage;
