import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    Input,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
    Box,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';

const ColorSettings = () => {
    const [colors, setColors] = useState([]);
    const [newColor, setNewColor] = useState('');
    const [editingColor, setEditingColor] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllColors, setShowAllColors] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();

    useEffect(() => {
        fetchColors();
    }, []);

    const fetchColors = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/colors');
            const data = await response.json();
            setColors(data.map(color => ({ ...color })));
        } catch (error) {
            console.error('Error fetching colors:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewColor(e.target.value);
        setIsButtonDisabled(!e.target.value.trim());
    };

    const handleAddColor = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/colors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ colors: newColor }),
            });

            if (response.ok) {
                setNewColor('');
                setIsButtonDisabled(true); // Disable the button after adding a color
                fetchColors();
                showToast('Color added successfully', 'success');
            } else {
                console.error('Error adding color:', response.statusText);
                showToast('Error adding color', 'error');
            }
        } catch (error) {
            console.error('Error adding color:', error);
            showToast('Error adding color', 'error');
        }
    };

    const handleDeleteColor = async (color) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/colors/${color._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchColors();
                showToast('Color deleted successfully', 'success');
            } else {
                console.error('Error deleting color:', response.statusText);
                showToast('Error deleting color', 'error');
            }
        } catch (error) {
            console.error('Error deleting color:', error);
            showToast('Error deleting color', 'error');
        }
    };

    const handleEditColor = (color) => {
        setEditingColor(color._id);
        setEditedValue(color.colors);
    };

    const handleSaveEdit = async (color) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/colors/${color._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ colors: editedValue }),
            });

            if (response.ok) {
                setEditingColor(null);
                fetchColors();
                showToast('Color updated successfully', 'success');
            } else {
                console.error('Error updating color:', response.statusText);
                showToast('Error updating color', 'error');
            }
        } catch (error) {
            console.error('Error updating color:', error);
            showToast('Error updating color', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box border={"1px solid teal"} borderRadius={"3xl"} p={4} my={4}>
            <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Colors</Text>
            <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                <Flex>
                    <Input
                        width={"100%"}
                        placeholder="Enter a new color"
                        value={newColor}
                        borderRadius={"2xl"}
                        onChange={handleInputChange}
                    />
                    <Button
                        ml={2}
                        borderRadius={"2xl"}
                        onClick={handleAddColor}
                        colorScheme="green"
                        isDisabled={isButtonDisabled}
                    >
                        Add
                    </Button>
                </Flex>
                <Flex>
                    <Switch
                        colorScheme="green"
                        size="lg"
                        mt={["10px", "10px", "0px"]}
                        m={["auto", "", ""]}
                        fontWeight={"semibold"}
                        isChecked={showAllColors}
                        onChange={() => setShowAllColors(!showAllColors)}
                    >
                        Show All Colors
                    </Switch>
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                {colors.map((color) => (
                    <Tag
                        key={color._id}
                        m={1}
                        size="lg"
                        variant="solid"
                        colorScheme="blue"
                        borderRadius="full"
                        bg={"darkblue"}
                        _hover={{ cursor: 'pointer', opacity: 0.8 }}
                        display={showAllColors ? 'flex' : editingColor === color._id ? 'flex' : 'none'}
                    >
                        {editingColor === color._id ? (
                            <>
                                <Input
                                    value={editedValue}
                                    onChange={(e) => setEditedValue(e.target.value)}
                                    borderRadius={"full"}
                                    marginRight={2}
                                />
                                <IconButton
                                    bg={"none"}
                                    icon={<FaSave />}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleSaveEdit(color)}
                                    borderRadius={"full"}
                                />
                                <TagCloseButton color="white" onClick={() => setEditingColor(null)} />
                            </>
                        ) : (
                            <>
                                <TagLabel>{color.colors}</TagLabel>
                                <TagCloseButton color="white" onClick={() => handleDeleteColor(color)} />
                                <IconButton
                                    icon={<FaEdit />}
                                    bg={"none"}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleEditColor(color)}
                                    borderRadius={"full"}
                                    ml={2}
                                />
                            </>
                        )}
                    </Tag>
                ))}
            </Flex>
        </Box>
    );
};

export default ColorSettings;
