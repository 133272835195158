import { Box, Button, useToast, HStack, Divider, ChakraProvider, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import TopBanner from '../HomePageComponents/TopBanner';
import Nav from '../HomePageComponents/Nav';
import Footer from '../HomePageComponents/Footer';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";

import AOS from "aos";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const LoginSignup = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
        });
    }, []);

    const navigate = useNavigate();
    const { refresh, setRefresh } = useContext(AppContext);

    const [show, setshow] = useState(true);
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showLoginPasswordSignup, setShowLoginPasswordSignup] = useState(false);
    // login
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    // signup
    const [signupName, setSignupName] = useState("");
    const [signupEmail, setSignupEmail] = useState("");
    const [SignupPassword, setSignupPassword] = useState("");
    const [SignupPassword2, setSignupPassword2] = useState("");

    const [otpSent, setOtpSent] = useState(false);
    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpInput, setOtpInput] = useState('');


    // reset states
    let [emailR, setEmailR] = useState("")
    let [otpR, setOtpR] = useState("")
    let [showReset, setShowReset] = useState(false)
    let [sucOTP, setSucOTP] = useState(false)
    let [showNewPassword, setShowNewPassword] = useState(false)
    let [newPassword, setNewPassword] = useState("")
    let [finalPass, setFinalPass] = useState("")
    let [finalPassConfirm, setFinalPassConfirm] = useState("")

    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let timerId;
        if (timer > 0) {
            timerId = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [timer]);



    const handleSendOtp = async () => {
        setOtpSent(true);
        setTimer(45);
        try {
            const response = await axios.post('https://api.digiblocks.tech/emailVerification/generate-otp', { email: signupEmail });
            if (response.data.success) {
                setOtpSent(true);
                toast({
                    title: "OTP Sent",
                    description: "OTP sent successfully. Please check your email.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast({
                title: "Error",
                description: "Failed to send OTP. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const verifyOtp = async () => {
        const otp = otpInputs.join("");
        // Verify OTP logic here
        try {
            const response = await axios.post('https://api.digiblocks.tech/emailVerification/verify-otp', { email: signupEmail, otp });
            if (response.data.success) {
                setOtpVerified(true);
                signupFunction();
                setshow(true);
                toast({
                    title: "OTP Verified",
                    description: "OTP verification successful.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                setOtpVerified(false);
                toast({
                    title: "Invalid OTP",
                    description: "Please enter a valid OTP.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast({
                title: "Error",
                description: "Failed to verify OTP. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSendOtpReset = async () => {
        try {
            const response = await axios.post('https://api.digiblocks.tech/emailVerification/generate-otp', { email: emailR });
            if (response.data.success) {
                setSucOTP(true)
                toast({
                    title: "OTP Sent",
                    description: "OTP sent successfully. Please check your email.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setSucOTP(false)
            console.error('Error sending OTP:', error);
            toast({
                title: "Error",
                description: "Failed to send OTP. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const verifyOtpReset = async () => {
        const otp = otpInputs.join("");
        // Verify OTP logic here
        try {
            const response = await axios.post('https://api.digiblocks.tech/emailVerification/verify-otp', { email: emailR, otp: otpR });
            if (response.data.success) {
                setShowNewPassword(true)
                setSucOTP(false)
                setShowReset(false)
                toast({
                    title: "OTP Verified",
                    description: "Password Reset successful.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                setShowNewPassword(false)
                toast({
                    title: "Invalid OTP",
                    description: "Please enter a valid OTP.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setShowNewPassword(false)
            console.error('Error verifying OTP:', error);
            toast({
                title: "Error",
                description: "Failed to verify OTP. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    let handleResetPassword = () => {
        verifyOtpReset()
    }

    let handleForgotPassword = async () => {
        setShowReset(!showReset)
    }

    let handleFinalReset = () => {
        if (finalPass == finalPassConfirm) {
            saveInDB(finalPass)
        } else {
            alert("Passwords do not match");
        }
        // change in db basic user

    }


    let saveInDB = async (newPassword) => {
        let response = await axios.post("https://api.digiblocks.tech/reset", { newPassword, email: emailR });
        if (response?.data?.msg == "password reset success") {

            toast({
                title: "Successfully reset password",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setShowReset(false)
            setSucOTP(false)
            setShowNewPassword(false)

            //use this email to send the confirmation mail for reset password
            let emailResponse = await axios.post("https://api.digiblocks.tech/resetPasswordEmail", { email: emailR });

        } else {
            toast({
                title: "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }


    const toast = useToast();

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const signupFunction = async () => {
        if (!signupName || !signupEmail || !SignupPassword || !SignupPassword2) {
            toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (SignupPassword !== SignupPassword2) {
            toast({
                title: "Passwords do not match",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const data = await axios.post("https://api.digiblocks.tech/signup", {
                name: signupName,
                email: signupEmail,
                password: SignupPassword,
            });

            if (data.data.msg.includes("pls login")) {
                toast({
                    title: "Please log in",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
            } else if (data.data.msg.includes("error")) {
                toast({
                    title: "Error",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                await axios.post("https://api.digiblocks.tech/welcome/accountCreation", {
                    userName: signupName,
                    email: signupEmail,
                });
                toast({
                    title: "Sign up successful",
                    description: "Now you can log in with the same credentials",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Signup Error:", error);
            toast({
                title: "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleGoogleLoginSuccess = async (credentialResponse) => {
        try {
            const decodedCredentials = jwtDecode(credentialResponse.credential);
            setshow(false);

            // Send the decoded credentials to your backend
            const data = await axios.post("https://api.digiblocks.tech/login/google", {
                name: decodedCredentials?.name,
                email: decodedCredentials?.email,
                picture: decodedCredentials.picture,
            });
            localStorage.setItem(
                "googleuser",
                JSON.stringify({
                    name: decodedCredentials?.name,
                    email: decodedCredentials?.email,
                    picture: decodedCredentials.picture,
                })
            );

            localStorage.setItem("userToken", data.data.token);
            setRefresh(!refresh);
            toast({
                title: `Welcome to KnitSilk, ${decodedCredentials?.name}!`,
                description: "You have successfully signed in to KnitSilk.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate("/");

        } catch (error) {
            console.error("Error saving credentials:", error);
        }
    };

    let handleFacebook = async (profile) => {
        setshow(false);
        const data = await axios.post("https://api.digiblocks.tech/login/google", {
            name: profile?.name,
            email: profile?.email,
            picture: profile.picture,
        });
        localStorage.setItem(
            "googleuser",
            JSON.stringify({
                name: profile?.name,
                email: profile?.email,
                picture: profile?.picture?.data?.url,
            })
        );

        localStorage.setItem("userToken", data.data.token);
        setRefresh(!refresh);
        navigate("/");
    };

    const handleGoogleLoginError = () => {
        toast({
            title: "Google login failed",
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    };

    const loginFunction = async () => {
        if (!loginEmail || !loginPassword) {
            toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (isValidEmail(loginEmail)) {
            try {
                const data = await axios.post("https://api.digiblocks.tech/login", {
                    email: loginEmail,
                    password: loginPassword,
                });

                if (data.data.msg === "login success") {
                    localStorage.setItem("userToken", data.data.token);
                    localStorage.setItem("basicuser", JSON.stringify({ email: loginEmail }));
                    setRefresh(!refresh);
                    toast({
                        title: "Login success",
                        status: "success",
                        duration: 3000,
                        position:"top",
                        isClosable: true,
                    });
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                } else if (data.data.msg === "invalid credentials") {
                    toast({
                        title: "Invalid credentials",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (data.data.msg === "sign up first") {
                    toast({
                        title: "Sign up first",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error("Login Error:", error);
                toast({
                    title: "An error occurred",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else {
            toast({
                title: "Invalid Email",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Flex
                direction={["column", "column", "row"]}
                gap={["100px", "100px", "10px"]}
                w={"100%"}
                mt={"2%"}
                mb={"6%"}
            >
                <Box w={["100%", "100%", "50%"]} position={"relative"}>
                    <Text
                        fontSize={["18px", "30px", "35px"]}
                        letterSpacing={1}
                        fontFamily="DM Serif Display"
                        top={["0", "10px", "0px"]}
                        display={["none", "block", "block"]}
                        position={"absolute"}
                        w={"100%"}
                        m={"auto"}
                        textAlign={"center"}
                    >
                        Experience
                    </Text>
                    <Text
                        fontSize={["20px", "30px", "35px"]}
                        letterSpacing={1}
                        display={["none", "block", "block"]}
                        transform={"rotate(270deg)"}
                        position={"absolute"}
                        left={"-30px"}
                        fontFamily="DM Serif Display"
                        top={"35%"}
                    >
                        30+ years of{" "}
                    </Text>
                    <Text
                        fontSize={["18px", "30px", "35px"]}
                        letterSpacing={1}
                        fontFamily="DM Serif Display"
                        display={["block", "none", "none"]}
                        gap={0}
                        position={"absolute"}
                        w={"100%"}
                        m={"auto"}
                        textAlign={"center"}
                    >
                        30+ years of Experience
                    </Text>
                    <Image
                        w={["100%", "100%", "90%"]}
                        alt="Uploaded"
                        src={
                            "https://assets.website-files.com/64cb64ee93daf9d341522213/64cb73bddae4f2e5a04b6f95_about-us-bg-image.png"
                        }
                    ></Image>
                    <Box
                        overflow={"hidden"}
                        position={"absolute"}
                        w={["60%", "60%", "80%"]}
                        top={"15%"}
                        left={"18%"}
                    >
                        <Image
                            alt="Uploaded"
                            _hover={{
                                transform: "scale(1.1)",
                                transition: "transform 0.3s ease",
                            }}
                            src="https://i.postimg.cc/FRWqDD1H/close-up-woman-crocheting-according-model.jpg"
                        ></Image>
                    </Box>
                </Box>
                {/* login */}
                {show && (
                    <Box w={["100%", "100%", "50%"]} pr="5%">
                        <Flex p={["10px", "20px", "30px"]} gap={"30px"} direction={"column"}>
                            <Text
                                fontSize={["22px", "32px", "52px"]}
                                fontWeight={500}
                                fontFamily="DM Serif Display"
                            >
                                LogIn
                            </Text>
                            <Text fontSize={"18px"} color={"#6FA82F"}>Knitsilk: Crafting Dreams, Weaving Futures.</Text>
                            <Flex display={showReset || showNewPassword ? "none" : "flex"} alignItems="center" gap={2}>
                                <Input onChange={(e) => {
                                    setLoginEmail(e.target.value)
                                }} placeholder="Email" type="email" padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>
                                <ViewIcon display={"hidden"} color={"white"} >

                                </ViewIcon>
                            </Flex>
                            <Flex display={showReset || showNewPassword ? "none" : "flex"} alignItems="center" gap={2}>
                                <Input onChange={(e) => {
                                    setLoginPassword(e.target.value)
                                }} placeholder="Password" type={showLoginPassword ? "text" : "password"} padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>
                                {showLoginPassword ? <ViewIcon onClick={() => setShowLoginPassword(false)}>

                                </ViewIcon> : <ViewOffIcon onClick={() => setShowLoginPassword(true)}>

                                </ViewOffIcon>}
                            </Flex>


                            <Flex fontSize={["14px", "16px", "18px"]} justifyContent="space-between" flexDirection="column" p={4} bg="#f9f9f9" borderRadius="none" boxShadow="md">
                                <Flex gap="5px" alignItems="center" justifyContent="center">
                                    <Text
                                        _hover={{ textDecor: "underline", cursor: "pointer", color: "#6FA82F" }}
                                        m={0}
                                        onClick={handleForgotPassword}
                                        color="#6FA82F"
                                    >
                                        Forgot Password
                                    </Text>
                                </Flex>
                                <Box display={showReset ? "block" : "none"} mt={4}>
                                    <Input
                                        placeholder="Enter Your Email"
                                        type="email"
                                        onChange={(e) => setEmailR(e.target.value)}
                                        mb={2}
                                        borderColor="#6FA82F"
                                        borderRadius="none"
                                        _focus={{ borderColor: "#6FA82F" }}
                                    />
                                    <Button
                                        onClick={handleSendOtpReset}
                                        bg="#6FA82F"
                                        color="white"
                                        borderRadius="none"
                                        _hover={{ bg: "#5e9028" }}
                                        w="full"
                                    >
                                        Verify Email
                                    </Button>
                                </Box>
                                {sucOTP && (
                                    <Box mt={4}>
                                        <Input
                                            placeholder="Enter OTP"
                                            onChange={(e) => setOtpR(e.target.value)}
                                            mb={2}
                                            borderRadius="none"
                                            borderColor="#6FA82F"
                                            _focus={{ borderColor: "#6FA82F" }}
                                        />
                                        <Button
                                            onClick={handleResetPassword}
                                            bg="#6FA82F"
                                            color="white"
                                            borderRadius="none"
                                            _hover={{ bg: "#5e9028" }}
                                            w="full"
                                        >
                                            Verify OTP
                                        </Button>
                                    </Box>
                                )}
                                {showNewPassword && (
                                    <Box mt={4}>
                                        <Input
                                            placeholder="Enter New Password"
                                            onChange={(e) => setFinalPass(e.target.value)}
                                            mb={2}
                                            type="password"
                                            borderRadius="none"
                                            borderColor="#6FA82F"
                                            _focus={{ borderColor: "#6FA82F" }}
                                        />
                                        <Input
                                            placeholder="Confirm New Password"
                                            onChange={(e) => setFinalPassConfirm(e.target.value)}
                                            mb={2}
                                            type="password"
                                            borderRadius="none"
                                            borderColor="#6FA82F"
                                            _focus={{ borderColor: "#6FA82F" }}
                                        />
                                        <Button
                                            onClick={handleFinalReset}
                                            bg="#6FA82F"
                                            borderRadius="none"
                                            color="white"
                                            _hover={{ bg: "#5e9028" }}
                                            w="full"
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                )}
                            </Flex>
                            <Button
                                display={showReset || showNewPassword ? "none" : "flex"}
                                bg={"rgb(35,36,36)"}
                                color={"white"}
                                _hover={{ bg: "#5e9028" }}
                                padding={"25px 20px"}
                                border={"1px solid black"}
                                borderRadius={0}
                                onClick={() => {
                                    loginFunction();
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                placeholder="Name"
                                variant={"outline"}
                                padding={"25px 20px"}
                                border={"1px solid black"}
                                borderRadius={0}
                                onClick={() => {
                                    setshow(false);
                                }}
                            >
                                Sign Up
                            </Button>
                            <HStack>
                                <Divider borderBottom={"1px solid gray"} />
                                <Text mt={2.5} textStyle="md" whiteSpace="nowrap">
                                    or continue with
                                </Text>
                                <Divider borderBottom={"1px solid gray"} />
                            </HStack>
                            <Flex justifyContent={"center"} direction={["column", "", "row"]} gap={3} alignItems={"center"} m="auto" w={"100%"}>
                                <GoogleLogin
                                    onSuccess={handleGoogleLoginSuccess}
                                    onError={handleGoogleLoginError}
                                />

                                <LoginSocialFacebook
                                    appId="1918824338551792"
                                    onResolve={(response) => {
                                        handleFacebook(response.data)
                                    }}
                                    onReject={(error) => {
                                        console.log(error);
                                    }}
                                >
                                    <FacebookLoginButton />
                                </LoginSocialFacebook>
                            </Flex>
                        </Flex>
                    </Box>
                )}
                {/* signup */}
                {!show && (
                    <Box w={["100%", "100%", "50%"]} pr="5%">
                        <Flex p={["10px", "20px", "30px"]} gap={"30px"} direction={"column"}>
                            <Text
                                fontSize={["22px", "32px", "52px"]}
                                fontWeight={500}
                                fontFamily="DM Serif Display"
                            >
                                Sign Up
                            </Text>
                            <Text fontSize={"18px"} color={"#6FA82F"}> Knitsilk: Yarns with a Purpose, Threads of Tradition</Text>
                            <Input onChange={(e) => {
                                setSignupName(e.target.value)
                            }} placeholder="Name" type="text" padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>
                            <Input onChange={(e) => {
                                setSignupEmail(e.target.value)
                            }} placeholder="Email" type="email" padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>

                            <Flex alignItems="center" gap={2}>
                                <Input onChange={(e) => {
                                    setSignupPassword(e.target.value)
                                }} placeholder="Password" type={showLoginPasswordSignup ? "text" : "password"} padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>
                                {showLoginPasswordSignup ? <ViewIcon onClick={() => setShowLoginPasswordSignup(false)}>

                                </ViewIcon> : <ViewOffIcon onClick={() => setShowLoginPasswordSignup(true)}>

                                </ViewOffIcon>}
                            </Flex>


                            <Flex alignItems="center" gap={2}>
                                <Input onChange={(e) => {
                                    setSignupPassword2(e.target.value)
                                }} placeholder="Confirm Password" type={showLoginPasswordSignup ? "text" : "password"} padding={"25px 20px"} border={"1px solid black"} borderRadius={0}></Input>

                                <ViewIcon display={"hidden"} color={"white"} >

                                </ViewIcon>
                            </Flex>

                            {otpSent && !otpVerified && (
                                <Box m="auto" textAlign="center">
                                    <Flex alignItems="center" justifyContent="center" gap={2}>
                                        <Input
                                            value={otpInput}
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                if (input.length <= 4) {
                                                    setOtpInput(input);
                                                    const newOtpInputs = input.split('').slice(0, 4);
                                                    setOtpInputs(newOtpInputs);
                                                }
                                            }}
                                            onPaste={(e) => {
                                                const pastedData = e.clipboardData.getData('text');
                                                if (pastedData.length === 4) {
                                                    setOtpInput(pastedData);
                                                    const newOtpInputs = pastedData.split('').slice(0, 4);
                                                    setOtpInputs(newOtpInputs);
                                                }
                                            }}
                                            placeholder="Enter OTP"
                                            maxLength={4}
                                            width="200px"
                                            height="50px"
                                        />

                                        <Button
                                            onClick={verifyOtp}
                                            bg={"rgb(35,36,36)"}
                                            color={"white"}
                                            padding={"20px 20px"}
                                            colorScheme = "blackAlpha"
                                            border={"1px solid black"}
                                            borderRadius={0}
                                            isDisabled={otpInput.length !== 4}
                                        >
                                            Verify OTP
                                        </Button>
                                    </Flex>

                                    <Flex justifyContent="center" mt={4}>
                                        <Button
                                            onClick={handleSendOtp}
                                            bg={"rgb(35,36,36)"}
                                            color={"white"}
                                            colorScheme = "blackAlpha"
                                            padding={"20px 20px"}
                                            border={"1px solid black"}
                                            borderRadius={0}
                                            isDisabled={timer > 0}
                                        >
                                            {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                                        </Button>
                                    </Flex>
                                </Box>
                            )}

                            {!otpSent && (
                                <Button
                                    onClick={handleSendOtp}
                                    bg={"rgb(35,36,36)"}
                                    color={"white"}
                                    _hover={{ bg: "#5e9028" }}
                                    padding={"25px 20px"}
                                    border={"1px solid black"}
                                    borderRadius={0}
                                >
                                    Get OTP On Email
                                </Button>
                            )}
                            <Button
                                placeholder="Name"
                                bg={"rgb(35,36,36)"}
                                color={"white"}
                                padding={"25px 20px"}
                                display="none"
                                border={"1px solid black"}
                                borderRadius={0}
                                _hover={{ bg: "#5e9028" }}
                                isDisabled={!otpVerified}
                                onClick={() => {
                                    if (SignupPassword !== SignupPassword2) {
                                        toast({
                                            title: "Password Mismatch",
                                            description: "Please make sure your passwords match.",
                                            status: "error",
                                            duration: 5000,
                                            isClosable: true,
                                        });
                                    } else {
                                        signupFunction();
                                    }
                                }}
                            >
                                Sign Up
                            </Button>
                            <Text textAlign={"center"} fontSize={"18px"}>Already have an account?</Text>
                            <Button
                                placeholder="Name"
                                variant={"outline"}
                                padding={"25px 20px"}
                                border={"1px solid black"}
                                borderRadius={0}
                                _hover={{ bg: "#5e9028", color: "white" }}
                                onClick={() => {
                                    setshow(true);
                                }}
                            >
                                Login
                            </Button>
                            <HStack>
                                <Divider borderBottom={"1px solid gray"} />
                                <Text mt={2.5} textStyle="md" whiteSpace="nowrap">
                                    or continue with
                                </Text>
                                <Divider borderBottom={"1px solid gray"} />
                            </HStack>
                            <Flex justifyContent={"center"} direction={["column", "", "row"]} gap={3} alignItems={"center"} m="auto" w={"100%"}>
                                <GoogleLogin
                                    onSuccess={handleGoogleLoginSuccess}
                                    onError={handleGoogleLoginError}
                                />

                                <LoginSocialFacebook
                                    appId="884783293013691"
                                    onResolve={(response) => {
                                        handleFacebook(response.data)
                                    }}
                                    onReject={(error) => {
                                        console.log(error);
                                    }}
                                >
                                    <FacebookLoginButton />
                                </LoginSocialFacebook>
                            </Flex>
                        </Flex>

                    </Box>
                )}
            </Flex>
            <Footer />
        </ChakraProvider>
    );
};

export default LoginSignup;