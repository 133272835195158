import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { FaWhatsapp, FaFacebook, FaPinterest, FaTwitter, FaInstagram, FaEnvelope, FaCopy } from 'react-icons/fa';

const ShareButton = ({ shareUrl, shareTitle }) => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleWhatsAppShare = () => {
    let msg = "See The Product below for more information 🧶";
    let thnksmsg = "Thanks, 😍 for visiting.";
    const message = `*${shareTitle}*\n\n *${msg}*\n\n  ${shareUrl} \n\n *${thnksmsg}*`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
    setIsModalOpen(false);
  };

  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank');
    setIsModalOpen(false);
  };

  const handlePinterestShare = () => {
    const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}&description=${encodeURIComponent(shareTitle)}`;
    window.open(url, '_blank');
    setIsModalOpen(false);
  };

  const handleTwitterShare = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`;
    window.open(url, '_blank');
    setIsModalOpen(false);
  };

  const handleInstagramShare = () => {
    // Instagram doesn't allow direct sharing, so you can open the Instagram page in a new tab/window
    window.open('https://www.instagram.com/', '_blank');
    setIsModalOpen(false);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent(`Check out this product: ${shareTitle}`);
    const body = encodeURIComponent(`I thought you might be interested in this product: ${shareUrl}`);
    const url = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = url;
    setIsModalOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(
      () => {
        toast({
          title: 'Link copied to clipboard!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      (err) => {
        console.error('Unable to copy to clipboard', err);
        toast({
          title: 'Error copying link!',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        zIndex={"99"}
        _hover={{ transform: "scale(1.3)", transition: "transform 0.3s ease-in-out" }}
        onClick={() => setIsModalOpen(true)}
        size="md"
        bg="rgb(232,238,228)"
        color={"rgb(111, 168, 47)"}
        borderRadius="50%"
      >
        <AiOutlineShareAlt size={20} />
      </Button>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="sm">
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <ModalHeader >
            <Heading fontSize="lg">Share on</Heading>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <VStack spacing={4} flexWrap="wrap">
              <Button
                w="100%"
                borderRadius="40px"
                onClick={handleWhatsAppShare}
                leftIcon={<FaWhatsapp />}
                colorScheme="green"
              // borderRadius={0}
              >
                Share on WhatsApp
              </Button>
              <Button
                w="100%"
                onClick={handleFacebookShare}
                leftIcon={<FaFacebook />}
                colorScheme="facebook"
                borderRadius={"40px"}
              >
                Share on Facebook
              </Button>
              <Button
                w="100%"
                onClick={handlePinterestShare}
                leftIcon={<FaPinterest />}
                colorScheme="red"
                borderRadius={"40px"}
              >
                Share on Pinterest
              </Button>
              <Button
                w="100%"
                onClick={handleTwitterShare}
                leftIcon={<FaTwitter />}
                colorScheme="twitter"
                borderRadius={"40px"}
              >
                Share on Twitter
              </Button>
              <Button
                w="100%"
                onClick={handleInstagramShare}
                leftIcon={<FaInstagram />}
                colorScheme="purple"
                borderRadius={"40px"}
              >
                Share on Instagram
              </Button>
              <Button
                w="100%"
                onClick={handleEmailShare}
                leftIcon={<FaEnvelope />}
                colorScheme="green"
                borderRadius={"40px"}
              >
                Share via Email
              </Button>
              <Button
                w="100%"
                onClick={handleCopyLink}
                leftIcon={<FaCopy />}
                colorScheme="teal"
                borderRadius={"40px"}
              >
                Copy Link
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
