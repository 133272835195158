const YarnTypes =
{
    "Single, Cobweb, Thread, Zephyr": {
        "name": "Single, Cobweb, Thread, Zephyr",
        "properties": {
            "needleSize": "1.5 - 2.5",
            "hookSize": "1.5 - 2.5",
            "wrapsPerInch": "40+ wpi",
            "yarnCounts": "8+",
            "meterPer100g": "More than 800",
            "otherNames": "0 or Lace"
        }
    },
    "Light Fingering, Sock, Baby": {
        "name": "Light Fingering, Sock, Baby",
        "properties": {
            "needleSize": "1.5 - 2.5",
            "hookSize": "1.5 - 2.5",
            "wrapsPerInch": "30 - 40 wpi",
            "yarnCounts": "6-8",
            "meterPer100g": "600 - 800",
            "otherNames": "0 or Lace"
        }
    },
    "Fingering Sock, Baby, ": {
        "name": "Fingering Sock, Baby",
        "properties": {
            "hookSize": "2.25 - 3.5",
            "needleSize": "2.0 - 3.0",
            "wrapsPerInch": "20 - 30 wpi",
            "yarnCounts": "5-6",
            "meterPer100g": "500 - 600",
            "otherNames": "1 or Super Fine"
        }
    },
    "Light Fingering, Sock, Baby": {
        "name": "Light Fingering, Sock, Baby",
        "properties": {
            "needleSize": "2.0 - 3.0",
            "hookSize": "2.25 - 3.5",
            "wrapsPerInch": "20 - 30 wpi",
            "yarnCounts": "5-6",
            "meterPer100g": "500 - 600",
            "otherNames": "1 or Super Fine"
        }
    },
    "Sport, Baby, ": {
        "name": "Sport, Baby",
        "properties": {
            "hookSize": "3.5 - 4.5",
            "needleSize": "3.0 - 4.0",
            "wrapsPerInch": "12 - 18 wpi",
            "yarnCounts": "2.5 - 3.5",
            "meterPer100g": "250 - 350",
            "otherNames": "2 or Fine"
        }
    },
    "Light Worsted, DK": {
        "name": "Light Worsted, DK",
        "properties": {
            "hookSize": "4.5 - 5.5",
            "needleSize": "4.0 - 4.5",
            "wrapsPerInch": "11 - 15 wpi",
            "yarnCounts": "2.0 - 2.5",
            "meterPer100g": "200 - 250",
            "otherNames": "3 or Light"
        }
    },
    "Worsted, Afghan,  4-ply ": {
        "name": "Worsted, Afghan,  4-ply ",
        "properties": {
            "hookSize": "5.5 - 6.5",
            "needleSize": "4.5 - 5.5",
            "wrapsPerInch": "9 - 12 wpi",
            "yarnCounts": "1.5 - 2.0",
            "meterPer100g": "120 - 200",
            "otherNames": "4 or Medium"
        }
    },
    "Craft, Rug": {
        "name": "Craft, Rug",
        "properties": {
            "hookSize": "6.5 - 9.0",
            "needleSize": "5.5 - 8.0",
            "wrapsPerInch": "6 - 8 wpi",
            "yarnCounts": "1.0 - 1.3",
            "meterPer100g": "100 - 130",
            "otherNames": "5 or Bulky"
        }
    },
    "Roving or Super Bulky": {
        "name": "Roving or Super Bulky",
        "properties": {
            "hookSize": ">9.0",
            "needleSize": ">8.0",
            "wrapsPerInch": "5 - 6 wpi",
            "yarnCounts": "below 1.0",
            "meterPer100g": "Less than 100",
            "otherNames": "6 or Super Bulky"
        }
    },
    "Roving or Jumbo": {
        "name": "Roving or Jumbo",
        "properties": {
            "hookSize": "15 mm and larger",
            "needleSize": "12.75 mm and larger",
            "wrapsPerInch": "N/A",
            "yarnCounts": "below 1.0",
            "meterPer100g": "Less than 100",
            "otherNames": "6 or Super Bulky"
        }
    }
}
export default YarnTypes;