import { Heading, Text } from '@chakra-ui/react'
import React from 'react'

const HelloWorld = () => {
    const orderId = localStorage.getItem('newOrderId');
    const userToken = localStorage.getItem('userToken');

    return (
        <>
            <Heading>OrderId : {orderId}</Heading>
            <Text>Token : {userToken}</Text>
        </>
    )
}

export default HelloWorld
