import React, { useContext, useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  useToast,
  Flex,
  Link,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import { FaEdit, FaTrash, FaSearch,FaEnvelope, FaCopy } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { AppContext } from '../../Context/AppContext';

// const ContactList = () => {
//   const [contactData, setContactData] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [statusFilter, setStatusFilter] = useState('all');
//   const [sortBy, setSortBy] = useState('desc');

//   const toast = useToast();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://api.digiblocks.tech/contact');
//         setContactData(response.data);
//       } catch (error) {
//         console.error('Error fetching contact data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleUpdateStatus = async (contactId) => {
//     try {
//       const response = await axios.patch(`https://api.digiblocks.tech/contact/${contactId}/status`, {
//         status: true,
//       });

//       setContactData((prevData) =>
//         prevData.map((contact) =>
//           contact._id === contactId ? { ...contact, status: response.data.status } : contact
//         )
//       );
//       toast({
//         title: 'Status Updated',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       console.error('Error updating contact status:', error);
//       toast({
//         title: 'Error',
//         description: 'Error updating contact status',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleDeleteContact = async (contactId) => {
//     try {
//       await axios.delete(`https://api.digiblocks.tech/contact/${contactId}`);

//       setContactData((prevData) => prevData.filter((contact) => contact._id !== contactId));
//       toast({
//         title: 'Contact Deleted',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       console.error('Error deleting contact:', error);
//       toast({
//         title: 'Error',
//         description: 'Error deleting contact',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value.toLowerCase());
//   };

//   const handleStatusFilterChange = (e) => {
//     setStatusFilter(e.target.value);
//   };

//   const handleSortChange = (e) => {
//     setSortBy(e.target.value);
//   };

//   const handleExportToExcel = () => {
//     const dataToExport = sortedContacts.map((contact) => ({
//       Name: contact.name,
//       Email: contact.email,
//       Phone: contact.phone,
//       Subject: contact.subject,
//       Message: contact.message,
//       Status: contact.status ? 'Resolved' : 'Pending',
//       'Created At': new Date(contact.createdAt).toLocaleString(),
//     }));

//     const ws = XLSX.utils.json_to_sheet(dataToExport);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'ContactQueries');
//     XLSX.writeFile(wb, 'contact_queries.xlsx');
//   };

//   const filteredContacts = contactData
//     .filter(
//       (contact) =>
//         contact.name.toLowerCase().includes(searchTerm) ||
//         contact.email.toLowerCase().includes(searchTerm) ||
//         contact.phone.includes(searchTerm)
//     )
//     .filter(
//       (contact) =>
//         statusFilter === 'all' ||
//         (statusFilter === 'resolved' && contact.status) ||
//         (statusFilter === 'pending' && !contact.status)
//     );

//   const sortedContacts = filteredContacts.sort((a, b) => {
//     const dateA = new Date(a.createdAt);
//     const dateB = new Date(b.createdAt);

//     return sortBy === 'desc' ? dateB - dateA : dateA - dateB;
//   });

//   return (
//     <ChakraProvider>
//       <Container maxW="container.lg" mt={10}>
//         <Flex justifyContent={"space-between"}>
//           <Heading as="h1" mb={6}>
//             Contact Queries
//           </Heading>
//           <Button onClick={handleExportToExcel} bg="black" borderRadius={0} colorScheme="teal" size="md">
//             Export to Excel
//           </Button>
//         </Flex>
//         <InputGroup mb={4}>
//           <InputLeftElement pointerEvents="none" children={<FaSearch />} />
//           <Input
//             type="text"
//             placeholder="Search by name, email, or phone"
//             onChange={handleSearch}
//           />
//         </InputGroup>
//         <Stack direction="row" mb={4}>
//           <Select placeholder="Filter by Status" onChange={handleStatusFilterChange}>
//             <option value="all">All</option>
//             <option value="resolved">Resolved</option>
//             <option value="pending">Pending</option>
//           </Select>
//           <Select placeholder="Sort by Time" onChange={handleSortChange}>
//             <option value="desc">Recent First</option>
//             <option value="asc">Oldest First</option>
//           </Select>
//         </Stack>
//         <SimpleGrid columns={[1, 2, 3]} gap={6}>
//           {sortedContacts.map((contact) => (
//             <Box key={contact._id} p={4} borderWidth="1px" borderRadius="md">
//               <Text fontSize="lg" fontWeight="bold" mb={2}>
//                 {contact.name}
//               </Text>
//               <Text>Email: {contact.email}</Text>
//               <Text>Phone: {contact.phone}</Text>
//               <Text>Subject: {contact.subject}</Text>
//               <Text>Message: {contact.message}</Text>
//               <Text>Status: {contact.status ? 'Resolved' : 'Pending'}</Text>
//               <Text>Created At: {new Date(contact.createdAt).toLocaleString()}</Text>
//               <IconButton
//                 icon={<FaTrash />}
//                 onClick={() => handleDeleteContact(contact._id)}
//                 variant="outline"
//                 colorScheme="red"
//                 size="sm"
//                 mt={2}
//               />
//               {!contact.status && (
//                 <IconButton
//                   icon={<FaEdit />}
//                   onClick={() => handleUpdateStatus(contact._id)}
//                   variant="outline"
//                   colorScheme="green"
//                   size="sm"
//                   mt={2}
//                   ml={2}
//                 />
//               )}
//             </Box>
//           ))}
//         </SimpleGrid>
//       </Container>
//     </ChakraProvider>
//   );
// };

const ContactList = () => {
  const [contactData, setContactData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState('desc');
  const { refresh, setRefresh } = useContext(AppContext);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.digiblocks.tech/contact');
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchData();
  }, [refresh]);

  const handleUpdateStatus = async (contactId, status) => {
    console.log('Updating contact ID:', contactId, 'to status:', status); // Debugging

    try {
        const response = await axios.patch(`https://api.digiblocks.tech/contact/${contactId}`, {
            status,
        });

        console.log('Response from update:', response.data);

        setRefresh(!refresh);
        toast({
            title: 'Status Updated',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    } catch (error) {
        console.error('Error updating contact status:', error);
        toast({
            title: 'Error',
            description: 'Error updating contact status',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
};


  const handleDeleteContact = async (contactId) => {
    try {
      await axios.delete(`https://api.digiblocks.tech/contact/${contactId}`);

      setContactData((prevData) => prevData.filter((contact) => contact._id !== contactId));
      toast({
        title: 'Contact Deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting contact:', error);
      toast({
        title: 'Error',
        description: 'Error deleting contact',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleExportToExcel = () => {
    const dataToExport = sortedContacts.map((contact) => ({
      Name: contact.name,
      Email: contact.email,
      Phone: contact.phone,
      Subject: contact.subject,
      Message: contact.message,
      Status: contact.status ? 'Resolved' : 'Pending',
      'Created At': new Date(contact.createdAt).toLocaleString(),
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ContactQueries');
    XLSX.writeFile(wb, 'contact_queries.xlsx');
  };

  const filteredContacts = contactData
    .filter(
      (contact) =>
        contact.name.toLowerCase().includes(searchTerm) ||
        contact.email.toLowerCase().includes(searchTerm) ||
        contact.phone.includes(searchTerm)
    )
    .filter(
      (contact) =>
        statusFilter === 'all' ||
        (statusFilter === 'resolved' && contact.status) ||
        (statusFilter === 'pending' && !contact.status)
    );

  const sortedContacts = filteredContacts.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return sortBy === 'desc' ? dateB - dateA : dateA - dateB;
  });

  return (
    <ChakraProvider>
      <Container maxW="container.lg" mt={10}>
        <Flex justifyContent={"space-between"}>
          <Heading as="h1" mb={6}>
            Contact Queries
          </Heading>
          <Button onClick={handleExportToExcel} bg="black" borderRadius={0} colorScheme="teal" size="md">
            Export to Excel
          </Button>
        </Flex>
        <InputGroup mb={4}>
          <InputLeftElement pointerEvents="none" children={<FaSearch />} />
          <Input
            type="text"
            placeholder="Search by name, email, or phone"
            onChange={handleSearch}
          />
        </InputGroup>
        <Stack direction="row" mb={4}>
          <Select placeholder="Filter by Status" onChange={handleStatusFilterChange}>
            <option value="all">All</option>
            <option value="resolved">Resolved</option>
            <option value="pending">Pending</option>
          </Select>
          <Select placeholder="Sort by Time" onChange={handleSortChange}>
            <option value="desc">Recent First</option>
            <option value="asc">Oldest First</option>
          </Select>
        </Stack>
        <SimpleGrid columns={[1, 2, 3]} gap={6}>
          {sortedContacts.map((contact) => (
            <Box key={contact._id} p={4} borderWidth="1px" borderRadius="md">
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                {contact.name}
              </Text>
              <Flex alignItems="center">
                <Text>Email: {contact.email}</Text>
                <IconButton
                  icon={<FaCopy />}
                  aria-label="Copy Email"
                  variant="outline"
                  colorScheme="green"
                  size="sm"
                  ml={2}
                  onClick={() => {
                    navigator.clipboard.writeText(contact.email);
                    toast({
                      title: "Email copied to clipboard!",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  }}
                />
              </Flex>
              <Text>Phone: {contact.phone}</Text>
              <Text>Subject: {contact.subject}</Text>
              <Text>Message: {contact.message}</Text>
              <Text>Status: {contact.status ? 'Resolved' : 'Pending'}</Text>
              <Text>Created At: {new Date(contact.createdAt).toLocaleString()}</Text>

              <Select
                value={contact.status ? 'resolved' : 'pending'}
                onChange={(e) => handleUpdateStatus(contact._id, e.target.value === 'resolved')}
                size="sm"
                mt={2}
              >
                <option value="resolved">Resolved</option>
                <option value="pending">Pending</option>
              </Select>

              <IconButton
                icon={<FaTrash />}
                onClick={() => handleDeleteContact(contact._id)}
                variant="outline"
                colorScheme="red"
                size="sm"
                mt={2}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </ChakraProvider>
  );
};

export default ContactList;
