import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Icon, Select,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DogsComponent from '../DogsComponent';
import ChatWithAdmin from '../../pages/ChatWithAdmin';
import { FaRegSadTear, FaShippingFast, FaExclamationTriangle } from 'react-icons/fa';
import { MdOutlineFeedback, MdOutlineInventory } from 'react-icons/md';


const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const MyOrders = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [time, setTime] = useState('');
  const navigate = useNavigate();
  const userCurrency = localStorage.getItem('userCurrency');
  const userToken = localStorage.getItem('userToken');
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [returnReason, setReturnReason] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [returnOrders, setReturnOrders] = useState(null);


  const getdata = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await axios.get('https://api.digiblocks.tech/order', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const paidOrders = response.data.filter(order => order.isPaid === true);
      setAllOrders(paidOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const getReturnOrderIds = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/order-return/orderIds');
      console.log(response.data)
      setReturnOrders(response.data)
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  const hasReturnRequest = (orderId) => {
    return returnOrders.includes(orderId);
  };

  const handleSubmitReturnRequest = async (order) => {
    try {
      const token = localStorage.getItem('userToken');
      if (!returnReason) {
        // Show relevant toast if returnReason is not provided
        toast({
          title: 'Return Reason Required',
          description: 'Please provide a reason for the return.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return; // Exit the function early
      }

      await axios.post(
        `https://api.digiblocks.tech/order-return/returnRequests`,
        {
          order,
          orderId: order._id,
          returnReason,
          additionalInfo,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );

      await axios.post(
        `https://api.digiblocks.tech/order-return-request-from-user`,
        {
          orderID: order._id,
          returnReason,
          additionalInfo,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );

      toast({
        title: 'Return Request Submitted',
        description: 'Your return request has been submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      console.log('Return request submitted successfully');
      // Optionally refresh the order data or give feedback to the user
    } catch (error) {
      toast({
        title: 'Error Submitting Return Request',
        description: 'There was an issue submitting your return request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      console.error('Error submitting return request:', error);
    }
  };


  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  const calculateDaysDifference = (deliveredAt) => {
    const deliveredDate = new Date(deliveredAt);
    const currentDate = new Date();
    const timeDifference = currentDate - deliveredDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference;
  };

  useEffect(() => {
    getdata();
    getReturnOrderIds();
  }, []);

  return (
    <>
      {userToken ? (
        <>
          <Flex m="auto" w={['90%', '', '70%']} align="center" justify="space-between">
            <Flex>
              <Heading fontFamily='DM Serif Display' textAlign="center" my={8}>
                My Orders 📦
              </Heading>
            </Flex>
            <Flex alignItems={"center"} direction={["column", "column", "row"]}>
              <ChatWithAdmin />
              <Button onClick={() => navigate('/my-account')}
                size="sm"
                borderRadius="none"
                colorScheme="green"
                bg="#6FA82F"
                width="150px"
                ml={"2"}
                _hover={{ bg: "black", color: "white" }}
              >
                <ArrowLeftIcon />&nbsp; My Account
              </Button>
            </Flex>

          </Flex>
          {allOrders.length === 0 ? (
            <Box textAlign="center">
              <Text fontWeight="bold" fontSize="xl">No orders yet 😔</Text>
              <DogsComponent />
              <Button colorScheme="green"
                _hover={{ bg: '#232424' }}
                bg={'#6FA82F'}
                fontSize={['14px', '14px', '18px']}
                p={['25px', '30px']}
                color={'white'}
                borderRadius={'0'}
                m={'auto'}
                my={'30px'}
                onClick={() => navigate('/products')}>
                Continue Shopping 🛒
              </Button>
            </Box>
          ) : (
            <Flex direction="column" gap={5}>
              {allOrders.reverse().map((order, index) => (
                <Box key={index} m="auto" w={['90%', '', '70%']}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          onClick={() => setTime(formatTime(order.timeOfPayment))}
                          _hover={{ bg: 'rgb(256,260,252)' }}
                          bg="rgb(246,250,242)"
                          color="black"
                        >
                          <Box as="span" flex="1" textAlign="left">
                            <Flex direction={['column', 'column', 'row']} justify="space-between" p={3}>
                              <Flex direction="column" gap={3} justify={['flex-start']}>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order ID: {order._id}
                                </Text>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order Date: {order.timeOfPayment.toString().substring(0, 10)}
                                </Text>
                              </Flex>
                              <Flex direction="column" gap={3}>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Total Amount: {currencyIcons[order.userCurrency]}{order.finalAmount}
                                </Text>
                                <Text m={0} fontSize={['15px', '15px', '18px']} fontWeight={600}>
                                  Order Status: {order.status === 'new' ? 'Order Placed 📦' : order.status === 'inprogress' ? 'Order Shipped 🚚' : 'Delivered ✅'}
                                </Text>
                              </Flex>
                              <Box p={3} display="flex">
                                <Flex flexDirection="column" gap={8}>
                                  {order.trackingUrl && (
                                    <Button
                                      size="sm"
                                      borderRadius="none"
                                      colorScheme="green"
                                      bg="#6FA82F"
                                      _hover={{ bg: "black", color: "white" }}
                                      onClick={() => window.open(order.trackingUrl, '_self')}
                                    >
                                      Track Order 🛤️
                                    </Button>
                                  )}
                                  <Button
                                    size="sm"
                                    borderRadius="none"
                                    colorScheme="green"
                                    bg="#6FA82F"
                                    width="150px"
                                    onClick={() => navigate(`/my-account/my-orders/${order._id}`)}
                                    _hover={{ bg: "black", color: "white" }}
                                  >
                                    Invoice 🧾
                                  </Button>
                                  <Button
                                    size="sm"
                                    borderRadius="none"
                                    colorScheme="green"
                                    bg="#6FA82F"
                                    width="150px"
                                    _hover={{ bg: "black", color: "white" }}
                                  >
                                    View Details 🔍
                                  </Button>
                                  {hasReturnRequest(order._id) ? (
                                    <Text color="green.500">Return Request Has been Submitted</Text>
                                  ) : (
                                    order.status === 'completed' && calculateDaysDifference(order.deliveredAt) <= 7 ? (
                                      <Button
                                        size="sm"
                                        borderRadius="none"
                                        colorScheme="red"
                                        bg="#FF5733"
                                        width="150px"
                                        _hover={{ bg: "black", color: "white" }}
                                        onClick={() => {
                                          setSelectedOrderId(order);
                                          setIsReturnModalOpen(true);
                                        }}
                                      >
                                        Return Request 🔄
                                      </Button>
                                    ) : (
                                      <Text fontSize="sm" color="red.500" fontWeight="bold">
                                        Return & Replacement Window Has Closed
                                      </Text>
                                    )
                                  )}

                                </Flex>
                              </Box>
                            </Flex>
                            <AccordionIcon />
                          </Box>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <>
                          {order.cartProducts.map((product, ind) => (
                            <Flex
                              p={3}
                              key={ind}
                              border="2px solid rgb(111, 168, 47)"
                              borderRadius="15px"
                              direction={['column', 'column', 'row']}
                              alignItems="center"
                              bg="white"
                              boxShadow="md"
                              mb={3}
                            >
                              <Box flexShrink={0}>
                                <Image
                                  h={['150px', '150px', '200px']}
                                  w={['100%', '100%', 'auto']}
                                  objectFit="cover"
                                  alt="Product"
                                  src={product.photos[0]}
                                  borderRadius="15px"
                                />
                              </Box>
                              <Flex flexDirection="column" ml={[0, 0, 3]} flex="1" mt={[3, 3, 0]}>
                                <Text fontWeight="bold" fontSize={['md', 'md', 'lg']} mb={2}>
                                  {product.title}
                                </Text>
                                <Flex alignItems="center" fontSize={['sm', 'sm', 'md']} mb={1}>
                                  <Text>Per Item Price: </Text>
                                  <Text fontWeight={600}>{currencyIcons[userCurrency]} {product[`price${userCurrency}`]}</Text>
                                </Flex>
                                <Flex alignItems="center" fontSize={['sm', 'sm', 'md']} mb={1}>
                                  <Text>Quantity: </Text>
                                  <Text fontWeight={600}>{product.quantity}</Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          ))}
                        </>
                        <Flex justifyContent="center">
                          <Button
                            bg="none"
                            _hover={{ bg: "none" }}
                            color="white"
                            textAlign="center"
                            onClick={() => { }}
                          >
                            <AccordionButton
                              size="sm"
                              textAlign="center"
                              borderRadius="none"
                              colorScheme="green"
                              bg="#6FA82F"
                              _hover={{ bg: "black", color: "white" }}
                            >
                              Close
                            </AccordionButton>
                          </Button>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              ))}
            </Flex>
          )}

          <Modal isOpen={isReturnModalOpen} onClose={() => setIsReturnModalOpen(false)}>
            <ModalOverlay />
            <ModalContent borderRadius="lg" boxShadow="2xl">
              <ModalHeader bg="#6FA82F" color="white" borderTopRadius="lg">
                <Flex align="center">
                  <Icon as={FaRegSadTear} mr={2} />
                  Return Request
                </Flex>
              </ModalHeader>
              <ModalCloseButton color="white" />
              <ModalBody py={6}>
                <Box mb={4}>
                  <Text fontSize="lg" mb={2} fontWeight="bold" color="gray.700">
                    Select Reason for Return <Icon as={MdOutlineFeedback} color="red.500" />
                  </Text>
                  <Select
                    placeholder="Choose a reason"
                    value={returnReason}
                    onChange={(e) => setReturnReason(e.target.value)}
                    icon={<FaExclamationTriangle />}
                    iconColor="#6FA82F"
                    focusBorderColor="#6FA82F"
                  >
                    <option value="Damaged Item">Damaged Item <Icon as={FaRegSadTear} color="red.500" /></option>
                    <option value="Received Wrong Item">Received Wrong Item <Icon as={FaShippingFast} color="blue.500" /></option>
                    <option value="Item Not as Described">Item Not as Described <Icon as={FaExclamationTriangle} color="yellow.500" /></option>
                    <option value="Quality Not as Expected">Quality Not as Expected <Icon as={MdOutlineFeedback} color="purple.500" /></option>
                    <option value="Size/Dimensions Issue">Size/Dimensions Issue <Icon as={MdOutlineInventory} color="green.500" /></option>
                    <option value="Received Extra Items">Received Less Items <Icon as={MdOutlineInventory} color="pink.500" /></option>
                  </Select>
                </Box>
                <Box>
                  <Text fontSize="lg" mb={2} fontWeight="bold" color="gray.700">
                    Additional Information <Icon as={FaRegSadTear} color="blue.500" />
                  </Text>
                  <Textarea
                    placeholder="Provide additional information (optional)"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    focusBorderColor="#6FA82F"
                    size="md"
                  />
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  leftIcon={<FaShippingFast />}
                  colorScheme="green"
                  bg="#6FA82F"
                  mr={3}
                  size={"sm"}
                  onClick={() => {
                    handleSubmitReturnRequest(selectedOrderId);
                    setIsReturnModalOpen(false);
                  }}
                >
                  Submit Return Request
                </Button>
                <Button variant="ghost" size="sm" colorScheme='red' onClick={() => setIsReturnModalOpen(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </>
      ) : (
        <>
          <Text textAlign="center" mt="4" onClick={() => navigate('/login')} cursor="pointer" fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
            Please Login First 🔒
          </Text>
          <DogsComponent />
        </>
      )}
    </>
  );
};

export default MyOrders;
