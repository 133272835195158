import React, { useState } from 'react';
import { ChakraProvider, Text, Flex, Image, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const AllAboutSilk = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box flex="1"
                    width="100%"
                    bg={"#f6faf2"}
                    padding="20px"
                    overflowY="auto"
                    border="2px solid #C9F8E8"
                    mb={5}
                >
                    <Heading as="h1" fontFamily="DM Serif Display" borderBottom="2px solid #74D69E"
                        paddingBottom="10px" color={"#6FA82F"} fontSize="32px" mb={4}>
                        Types of Silk
                    </Heading>

                    <Text fontSize="lg" mb={4}>
                        There are four types of natural silk which are commercially known and produced in the world. Among them, mulberry silk is the most important and contributes as much as 90 per cent of world production, therefore, the term "silk" in general refers to the silk of the mulberry silkworm. Three other commercially important types fall into the category of non-mulberry silks namely: Eri silk; Tasar silk; and Muga silk. There are also other types of non-mulberry silk, which are mostly wild and exploited in Africa and Asia, are Anaphe silk, Fagara silk, Coan silk, Mussel silk, and Spider silk.
                    </Text>

                    <Divider my={4} />

                    <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems="center">
                        <Box
                            flex="1"
                            pr={{ lg: 4 }}
                            order={{ base: 2, lg: 1 }}
                        >
                            <Heading as="h2" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                                Mulberry Silk
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                Bulk of the commercial silk produced in the world comes from this variety and often generally refers to mulberry silk. Mulberry silk comes from the silkworm, Bombyxmori which solely feeds on the leaves of the mulberry plant. These silkworms are completely domesticated and reared indoors. Mulberry silk contributes to around 90 percent of the world silk production.
                            </Text>
                        </Box>
                        <Box
                            flex="1"
                            order={{ base: 1, lg: 2 }}
                        >
                            <Image
                                src="https://i.shgcdn.com/3c08cfb7-888b-450f-ab28-8077574a060d/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
                                alt="Mulberry Silk"
                                mb={4}
                                maxW="100%"
                                h="auto"
                            />
                        </Box>
                    </Flex>

                    <Divider my={4} />
                    <Heading as="h2" fontSize="24px" mb={4} color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                        Non-Mulberry Silk
                    </Heading>

                    <Flex flexDir={{ base: 'column', lg: 'row' }} gap={"5%"} alignItems="center">

                        <Box
                            flex="1"
                            pr={{ lg: 4 }}
                            order={{ base: 2, lg: 2 }}
                        >

                            <Heading as="h2" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                                Tussah Silk
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                The tussah silkworms belong to the genus Antheraea and they are all wild silkworms. There are many varieties such as the Chinese tussah silkworm Antheraea pernyi Guerin which produces the largest quantity of non-mulberry silk in the world, the Indian tussah silkworm Antheraea mylitta Dury, next in importance, and the Japanese tasar silkworm Antheraea yamamai Querin which is peculiar to Japan and produces green silk thread.
                            </Text>
                        </Box>
                        <Box
                            flex="1"
                            order={{ base: 1, lg: 1 }}
                        >
                            <Image
                                src="https://i.etsystatic.com/6420904/r/il/00edc8/1535338233/il_1588xN.1535338233_90zf.jpg"
                                alt="Tussah Silk"
                                mb={4}
                                maxW="100%"
                                h="auto"
                            />
                        </Box>
                    </Flex>

                    <Divider my={4} />

                    <Flex flexDir={{ base: 'column', lg: 'row' }} gap={"5%"} alignItems="center">
                        <Box
                            flex="1"
                            pr={{ lg: 4 }}
                            order={{ base: 2, lg: 2 }}
                        >
                            <Heading as="h2" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                                Eri Silk
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                These belong to either of two species namely Samia ricini and Philosamia ricini. P.ricini (also called as castor silkworm) is a domesticated one reared on castor oil plant leaves to produce a white or brick-red silk popularly known as Eri silk.
                            </Text>
                        </Box>

                        <Box
                            flex="1"
                            order={{ base: 1, lg: 1 }}
                        >
                            <Image
                                src="https://cdn.shopify.com/s/files/1/0425/5173/8525/files/Eri_Silk_VS_WOOL.jpg?v=1636027022"
                                alt="Eri Silk"
                                mb={4}
                                maxW="100%"
                                h="auto"
                            />
                        </Box>
                    </Flex>

                    <Divider my={4} />

                    <Flex flexDir={{ base: 'column', lg: 'row' }} gap={"5%"} alignItems="center">
                        <Box
                            flex="1"
                            pr={{ lg: 4 }}
                            order={{ base: 2, lg: 2 }}
                        >
                            <Heading as="h2" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400">
                                Muga Silk
                            </Heading>
                            <Text fontSize="lg" mb={4}>
                                The muga silkworms (Antheraea assamensis) also belong to the same genus as tasar worms but produce an unusual golden-yellow silk thread which is very attractive and strong. These are found only in the state of Assam, India, and feed on Persea bombycina and Litsea monopetala leaves and those of other species.
                            </Text>
                        </Box>
                        <Box
                            flex="1"
                            order={{ base: 1, lg: 1 }}
                        >
                            <Image
                                src="https://qph.cf2.quoracdn.net/main-qimg-e4f1a00edf953ada7b9eff97ba9c4c77-lq"
                                alt="Muga Silk"
                                mb={4}
                                maxW="100%"
                                h="auto"
                            />
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default AllAboutSilk;
