// Import necessary dependencies
import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

// Define the component
const WelcomeSection = () => {
    // Style for the container
    const containerStyle = {
        backgroundColor: 'white',
        padding: '2rem'
    };

    // Style for the headings
    const headingStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        fontFamily: 'DM Serif Display',
        marginBottom: '1rem',
        color: '#6FA82F', // Updated heading color
    };

    // Style for the text
    const textStyle = {
        fontSize: '1.2rem',
        marginBottom: '1rem',
    };

    return (
        <Box style={containerStyle}>
            <VStack align="center" spacing="4">
                <Heading style={headingStyle}>Welcome to KnitSilk - Your Global Partner</Heading>
                <Text style={textStyle}>
                    At KnitSilk, we understand the value of high-quality materials in creating masterpieces. We specialize in
                    supplying pure silk products, perfect for a variety of arts and crafts projects. Our products are not just
                    materials, they are the heart of your creations.
                </Text>
                <Text style={textStyle} fontWeight="bold">Why Choose KnitSilk?</Text>
                <VStack align="start" spacing="2">
                    <Text style={textStyle}>Global Reach: We ship our products globally, ensuring that businesses around the world have access to our premium silk products.</Text>
                    <Text style={textStyle}>Quality You Can Trust: Our products are crafted with care and precision, ensuring that you receive only the best. We believe in the power of quality, and our pure silk products reflect that.</Text>
                    <Text style={textStyle}>Tailored For Your Business: Whether you're a small craft store or a large manufacturing unit, we can fulfill your silk needs. We cater to both B2C and B2B customers with equal dedication and professionalism.</Text>
                    <Text style={textStyle}>Competitive Pricing: At KnitSilk, we believe in fair pricing. We offer competitive rates for our premium products, ensuring that you get the best value for your money.</Text>
                    <Text style={textStyle}>Customer Support: Our customer support team is always ready to assist you with any queries or concerns. We believe in building strong relationships with our clients, and our support team is a testament to that commitment.</Text>
                </VStack>
                <Text style={textStyle}>Join us in our journey to bring the elegance and versatility of silk to the world of arts and crafts.</Text>
            </VStack>
        </Box>
    );
};

export default WelcomeSection;
