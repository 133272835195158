import React, { useState } from 'react';
import { ChakraProvider, Text, List, ListItem, Image, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const IdentifyNeedles = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%" border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading  borderBottom="2px solid #74D69E"
                    paddingBottom="10px" as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" mb={4}>
                        Types of Knitting Needles
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        There are different types of knitting needles available in a great variety of materials and sizes. The size of the needle depends on the diameter.
                    </Text>
                    <Image
                        src="https://www.knitsilk.com/images/silk-blog/blog-3.jpg"
                        alt="Knitting Needles"
                        maxW="100%"
                        margin={"auto"}
                        borderRadius={"30px"}
                        h="auto"
                        mb={4}
                    />
                    <Text fontSize="lg" mb={4}>
                        The picture here shows a few examples:
                    </Text>
                    <List spacing={2} mb={4}>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >1).</strong> Single-pointed, extra-long wooden needles, ideal for jackets and pullovers when using the English method. The wood absorbs humidity from hands and is good for keeping slippery yarns in place.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >2).</strong> Plastic needles are light-weight and rather cheap.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >3).</strong> Bamboo needles are also lightweight and smooth.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >4).</strong>Acrylic needles have a slippery surface and are a bit stronger than the plastic ones.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >5).</strong> Metal needles are good for rougher yarns. They are usually more expensive but last a lifetime.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >6).</strong> Double-pointed plastic and metal needles are used to knit circularly, usually socks, gloves, and beanies.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >7).</strong>  Round needles are also used to knit in the round. A flexible plastic cable connects two needle points. The cables are available in different lengths (usually 40, 60, 80, 120, or more cm).
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong >8).</strong>    Stitch holders are often used to hold a number of stitches that later on will be worked in a special way.
                            </Text>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default IdentifyNeedles;
