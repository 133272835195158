import { Box, Flex, Text, Table, Thead, Button, Tbody, Tr, Th, Td, Image, Stack } from '@chakra-ui/react';
import { FaTruck, FaMoneyBillAlt, FaClock, FaTag } from 'react-icons/fa';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/knitsilk black logo.png";
import html2pdf from 'html2pdf.js';
import { format } from "date-fns";
import DogsComponent from '../DogsComponent';
import { IoIosArrowBack } from 'react-icons/io';
import { FaDownload } from 'react-icons/fa';


const currencyIcons = {
    INR: '₹',
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    CAD: '$',
    AUD: '$',
};
const userCurrency = localStorage.getItem('userCurrency');
const userToken = localStorage.getItem('userToken');

const SIngleOrder = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const contentRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        const getSingleOrder = async () => {
            try {
                const response = await axios.post("https://api.digiblocks.tech/allOrders", { orderID: id });
                setOrder(response.data);
            } catch (error) {
                console.error('Error fetching single order:', error);
            }
        };

        getSingleOrder();
    }, [id]);

    const handleDownloadPDF = () => {
        const content = contentRef.current;

        if (content) {
            html2pdf(content);
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "MMMM dd, yyyy HH:mm:ss");
    };

    return (
        <>
            {
                userToken ? (
                    <Box my="6">
                        <Flex maxWidth={"1000px"} direction={["column", "column", "row"]} mx="auto" my="4" display={"flex"} justifyContent={"space-between"}>
                            <Button
                                bg="#6FA82F"
                                _hover={{ bg: "black", color: "white" }}
                                onClick={handleDownloadPDF} colorScheme='green' borderRadius={"none"}>
                                <FaDownload style={{ marginRight: '8px' }} />
                                Download Invoice
                            </Button>
                            <Button
                                bg="#6FA82F"
                                _hover={{ bg: "black", color: "white" }}
                                onClick={() => navigate("/my-account/my-orders")} colorScheme='green' borderRadius={"none"}>

                                <IoIosArrowBack style={{ marginRight: '8px' }} />
                                Back To Orders
                            </Button>
                        </Flex>
                        <Box p={10} border="1px solid #6FA82F" maxWidth={"1000px"} m="auto" boxShadow="lg" borderRadius="md" >
                            {order ? (
                                <>
                                    <Stack px="20px" ref={contentRef}>
                                        <Box>
                                            <Flex flexWrap="wrap" justifyContent="space-between">
                                                <Box w={["100%", "100%", "40%"]}>
                                                    <Box w={["100%", "", "48%"]} >
                                                        <Box flexBasis={['100%']}  >
                                                            <Image src={Logo} alt="Uploaded" py={3} />
                                                        </Box>
                                                        <Box ml={"4"} flexBasis={['100%']} mt={[3, 2]} >
                                                            <Text w={"100%"} >No. 71, Jumma Masjid Road, Ashira Avenue
                                                                Bangalore (560002), Karnataka, India</Text>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Flex w={["100%", "", "60%"]} justifyContent={"space-between"} flexWrap={"wrap"} textAlign={["right"]}>
                                                    <Box>
                                                        <Text fontWeight={"bold"} textDecor="underline" mb={2}>
                                                            Billing Info
                                                        </Text>
                                                        {Object.entries(order?.customerInfo?.billing || {}).map(([key, value], i) => (
                                                            <Flex key={i} justifyContent={"flex-end"}>
                                                                <Text m={0}>{value}</Text>
                                                            </Flex>
                                                        ))}
                                                    </Box>
                                                    {/* shipping info */}
                                                    <Box>
                                                        <Text fontWeight="bold" textDecor="underline" mb={2}>
                                                            Shipping Info
                                                        </Text>
                                                        {Object.entries(order?.customerInfo?.shipping || {}).map(([key, value], i) => (
                                                            <Flex key={i} justifyContent={"flex-end"}>
                                                                <Text m={0}>{value}</Text>
                                                            </Flex>
                                                        ))}
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Box>
                                        {/* order items for large screen */}
                                        <Box w={"100%"} display={["none", "none", "block"]}>
                                            <Text fontSize="xl" fontWeight="bold" mb={2} color="black.500">
                                                Order Item Details :
                                            </Text>
                                            <Table variant="simple" colorScheme="black">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Product</Th>
                                                        <Th>Price</Th>
                                                        <Th>Quantity</Th>
                                                        <Th>Total Price</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {order?.cartProducts?.map((product, i) => (
                                                        <Tr key={i}>
                                                            <Td>
                                                                <Flex align="center">
                                                                    <Image src={product.photos[0]} alt={product.title} w="40px" h="40px" borderRadius="md" mr={2} />
                                                                    <Text fontWeight="bold">{product.title}</Text>
                                                                </Flex>
                                                            </Td>
                                                            <Td>
                                                                <Flex alignItems={"center"} direction={"row"} gap={1}>
                                                                    <Text m={0}>{currencyIcons[userCurrency]}{" "}</Text>
                                                                    <Text m={0}> {product[`price${userCurrency}`]}</Text>
                                                                </Flex>
                                                            </Td>
                                                            <Td textAlign={"center"}>{product.quantity}</Td>
                                                            <Td>
                                                                <Flex alignItems={"center"} direction={"row"} gap={1}>
                                                                    <Text m={0}> {currencyIcons[userCurrency]}{" "}</Text>
                                                                    <Text m={0}> {(Number(product.quantity) * Number(product[`price${userCurrency}`])).toFixed(2)}</Text>
                                                                </Flex>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </Box>

                                        {/* order items for small screens and md screens */}
                                        <Box display={["block", "block", "none"]}>
                                            <Text fontSize="3xl" fontWeight="bold" mb={2} color="black.500">
                                                Order Item Details
                                            </Text>
                                            <Flex direction={"column"} gap={5}>
                                                {order?.cartProducts?.map((product, i) => (
                                                    <Flex direction={"column"} gap={3} key={i} w={"100%"} >
                                                        <Flex flex={"5"} justifyContent={"flex-start"} alignItems={"center"} w={["100%"]} gap={3}>
                                                            <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Image</Text>
                                                            <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Title</Text>
                                                            <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Quantity </Text>

                                                        </Flex>
                                                        <Flex flex={"5"} justifyContent={"flex-start"} alignItems={"center"} w={["100%"]} gap={3}>
                                                            <Image flex={"1"} src={product.photos[0]} alt={product.title} m={0} w="40px" h="40px" borderRadius="md" mr={2} />
                                                            <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">{product.title}</Text>
                                                            <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold" textAlign={"center"}>{product.quantity}</Text>
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        </Box>

                                        <Flex direction={["column", "column"]} justifyContent="space-between" gap={6}>
                                            <Box flex="1" bg="gray.100" p={4} borderRadius="md">
                                                <Text fontSize="xl" fontWeight="bold" mb={2} color="black.500">Shipping Details :</Text>
                                                <Box>
                                                    <Flex align="center" gap={2} mb={2}>
                                                        <FaTruck />
                                                        <Text mt="2">Shipping Method: {order?.shippingMethod}</Text>
                                                    </Flex>
                                                    <Flex align="center" gap={2}>
                                                        <FaMoneyBillAlt />
                                                        <Text>Shipping Charges: {currencyIcons[userCurrency]} {order?.shippingCharges}</Text>
                                                    </Flex>
                                                </Box>
                                            </Box>
                                            <Box flex="1" bg="gray.100" p={4} borderRadius="md">
                                                <Text fontSize="xl" fontWeight="bold" mb={2} color="black.500">Order Summary :</Text>
                                                <Box>
                                                    <Flex align="center" gap={2} mb={2}>
                                                        <FaTag />
                                                        <Text>Total Discount: {currencyIcons[userCurrency]} {order?.discount}</Text>
                                                    </Flex>
                                                    <Flex align="center" gap={2} mb={2}>
                                                        <FaMoneyBillAlt />
                                                        <Text>Total Amount Paid: {currencyIcons[userCurrency]} {order?.finalAmount}</Text>
                                                    </Flex>
                                                    <Flex align="center" gap={2} mb={2}>
                                                        <FaMoneyBillAlt />
                                                        <Text>Payment Method: {order?.paymentMethod}</Text>
                                                    </Flex>
                                                    <Flex align="center" gap={2}>
                                                        <FaClock />
                                                        <Text>Time of Payment: {formatDate(order?.timeOfPayment)}</Text>
                                                    </Flex>
                                                </Box>
                                            </Box>
                                        </Flex>


                                    </Stack>
                                </>
                            ) : (
                                <Text>Loading...</Text>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Text textAlign="center" mt="4" onClick={() => navigate("/login")} cursor={"pointer"} fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
                            Please Login First !!!
                        </Text>
                        <DogsComponent />
                    </>
                )}
            );
        </>
    );
};

export default SIngleOrder;
