import React, { useState } from 'react';
import {
    Container,
    Heading,
    VStack,
    Input,
    Textarea,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Text,
    useToast,
    Link as ChakraLink,
    Spinner
} from '@chakra-ui/react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import WelcomeSection from './WelcomeSection';
import B2BFAQSection from './B2BFAQSection'


const B2B = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        messages: '',
    });
    const [loading, setLoading] = useState(false);

    const toast = useToast();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        // Check if any of the form fields are empty
        if (!formData.name || !formData.email || !formData.phone || !formData.messages) {
            toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            await axios.post('https://api.digiblocks.tech/b2bInquiry', formData);

            toast({
                title: 'Form Submitted',
                description: 'We will get in touch with you soon!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            // Clear the form data after submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                messages: '',
            });
        } catch (error) {
            console.error('Error submitting B2B Inquiry:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false); // Set loading state back to false
        }
    };

    return (
        <Container my={"2%"} py={4} border={"1px solid #6FA82F"} maxW="container.lg">
            <WelcomeSection />
            <VStack padding='2rem' align="start" spacing={6}>
                <Heading as="h3" fontFamily='DM Serif Display' size={["md", "lg", "xl"]}>
                    Wholesale/B2B Inquiry
                </Heading>
                <Text
                    fontSize='1.2rem'
                    marginBottom='1rem'
                >
                    We look forward to hearing from you!
                    <br />
                    Please note: This form is only for our current and potential wholesale and bulk buy customers. If you are a retail customer, kindly use the{' '}
                    <b>
                        <ChakraLink color={"#6FA82F"} as={RouterLink} to="/contact">
                            contact us
                        </ChakraLink>
                    </b>{" "}
                    section of our website.
                </Text>
                <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                        type="text"
                        name="name"
                        variant='flushed'
                        border={"none"}
                        borderBottom={"1px solid #6FA82F"}
                        borderRadius={"none"}
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                    />
                </FormControl>
                <Flex width={"100%"} gap={6} justifyContent={"space-between"} direction={["column", "column", "row"]}>
                    <FormControl width={["100%", "100%", "48%"]} id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            variant='flushed'
                            name="email"
                            border={"none"}
                            borderBottom={"1px solid #6FA82F"}
                            borderRadius={"none"}
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                        />
                    </FormControl>
                    <FormControl width={["100%", "100%", "48%"]} id="phone" >
                        <FormLabel>Phone No.</FormLabel>
                        <Input
                            type="tel"
                            name="phone"
                            variant='flushed'
                            border={"none"}
                            borderBottom={"1px solid #6FA82F"}
                            borderRadius={"none"}
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                        />
                    </FormControl>
                </Flex>
                <FormControl id="messages">
                    <FormLabel>Messages (Remarks)</FormLabel>
                    <Textarea
                        name="messages"
                        border={"none"}
                        variant='flushed'
                        borderBottom={"1px solid #6FA82F"}
                        borderRadius={"none"}
                        value={formData.messages}
                        onChange={handleChange}
                        placeholder="Enter your remarks"
                    />
                </FormControl>
                <Flex w="100%" justify="flex-end">
                    <Button
                        colorScheme="green"
                        bg="#6FA82F"
                        color="white"
                        mt={4}
                        borderRadius={0}
                        mb={4}
                        _hover={{ bg: 'black', color: 'white' }}
                        onClick={handleSubmit}
                        disabled={loading} // Disable the button when loading is true
                    >
                        {loading ? <Spinner mr={2} /> : null} {/* Render spinner if loading is true */}
                        Submit
                    </Button>
                </Flex>
            </VStack>
            <B2BFAQSection />
        </Container>
    );
};

export default B2B;
