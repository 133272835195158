import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { BsFillBoxFill, BsFlower1 } from 'react-icons/bs'
import { HiChatBubbleLeftRight, HiPaintBrush } from 'react-icons/hi2'
import { FaFemale } from 'react-icons/fa'
const LogoFeatures = () => {
    return (
        <Box my={"2%"}>
            <Flex flexWrap={"wrap"} m={"auto"} w={"100%"} justifyContent={"space-evenly"} p={"20px"}>
                <Box>
                    <Box bg={"rgb(246,250,242)"} textAlign={"center"} p={"40px"} borderRadius={"50%"}>
                        <BsFlower1 color='rgb(111,168,47)' size={"50px"} style={{ margin: "auto" }} />
                    </Box>
                    <Text p={2} textAlign={"center"}>100% Pure Silk</Text>
                </Box>
                <Box>
                    <Box bg={"rgb(246,250,242)"} textAlign={"center"} p={"40px"} borderRadius={"50%"}>
                        <BsFillBoxFill color='rgb(111,168,47)' size={"50px"} style={{ margin: "auto" }} />
                    </Box>
                    <Text p={2} textAlign={"center"}>Quality</Text>
                </Box>
                <Box>
                    <Box bg={"rgb(246,250,242)"} textAlign={"center"} p={"40px"} borderRadius={"50%"}>
                        <HiChatBubbleLeftRight color='rgb(111,168,47)' size={"50px"} style={{ margin: "auto" }} />
                    </Box>
                    <Text p={2} textAlign={"center"}>Online Support</Text>
                </Box>
                <Box>
                    <Box bg={"rgb(246,250,242)"} textAlign={"center"} p={"40px"} borderRadius={"50%"}>
                        <FaFemale color='rgb(111,168,47)' size={"50px"} style={{ margin: "auto" }} />
                    </Box>
                    <Text p={2} textAlign={"center"}>Women <br></br>Empowerment
                    </Text>
                </Box>
                <Box>
                    <Box bg={"rgb(246,250,242)"} textAlign={"center"} p={"40px"} borderRadius={"50%"}>
                        <HiPaintBrush color='rgb(111,168,47)' size={"50px"} style={{ margin: "auto" }} />
                    </Box>
                    <Text p={2} textAlign={"center"}>Ethical Yarns
                    </Text>
                </Box>
           </Flex>
        </Box>
    )
}

export default LogoFeatures
