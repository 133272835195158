import React, { useState, useEffect } from "react";
import {
    Box,
    Container,
    Text,
    Badge,
    VStack,
    Heading,
    Spinner,
    Flex,
    Button,
} from "@chakra-ui/react";
import axios from "axios";
import * as XLSX from "xlsx";

const SubscribersList = () => {
    const [subscribers, setSubscribers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSubscribers = async () => {
            try {
                const response = await axios.get("https://api.digiblocks.tech/subscribe/subscribers");
                setSubscribers(response.data);
            } catch (error) {
                console.error("Error fetching subscribers:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscribers();
    }, []);

    const exportToExcel = () => {
        // Extract only email addresses
        const emails = subscribers.map((subscriber) => ({ email: subscriber.email }));

        // Create a worksheet from the emails
        const worksheet = XLSX.utils.json_to_sheet(emails, {
            header: ["email"],
        });

        // Create a workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribers");

        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, "subscribers.xlsx");
    };


    return (
        <Container maxW="container.lg">
            <Flex mt={"2%"} justifyContent={"space-between"}>
                <Heading as="h2" mb={6} textAlign="center">
                    Subscribers List
                </Heading>
                <Button onClick={exportToExcel} bg={"black"} borderRadius={"none"} size={"sm"} colorScheme="teal" mb={4}>
                    Export Emails to Excel
                </Button>
            </Flex>
            {loading ? (
                <Spinner size="lg" color="green" />
            ) : (
                <VStack align="start" spacing={4}>
                    {subscribers.length === 0 ? (
                        <Text>No subscribers found.</Text>
                    ) : (
                        subscribers.map((subscriber) => (
                            <Box
                                key={subscriber._id}
                                borderWidth="1px"
                                borderRadius="lg"
                                overflow="hidden"
                                p={4}
                                w="100%"
                            >
                                <VStack align="start" spacing={2}>
                                    <Text fontSize="lg" fontWeight="bold">
                                        {subscriber.email}
                                    </Text>
                                    <Badge colorScheme="green">Subscriber</Badge>
                                </VStack>
                            </Box>
                        ))
                    )}
                </VStack>
            )}
        </Container>
    );
};

export default SubscribersList;
