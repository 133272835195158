import React, { useState } from "react";
import { Box, Stack, Heading, Input, InputGroup, Text, Checkbox, Button, ChakraProvider, Flex, SimpleGrid, useToast, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import axios from "axios";
import AdminNav from "./AdminNav";
import { useNavigate } from 'react-router-dom';
import { FaLongArrowAltLeft } from "react-icons/fa";
import countriesData from './CountriresData'

const AddShippingProfile = () => {
    const [selectedCountries, setSelectedCountries] = useState({});
    const [ratemodel, setratemodel] = useState("perItem");
    const [shippingFees, setShippingFees] = useState({});
    const [transitTimes, setTransitTimes] = useState({});
    const [transitTimesExp, setTransitTimesExp] = useState({});
    const [shippingProfileName, setShippingProfileName] = useState("");
    const toast = useToast();
    const [countryOfOrigin, setCountryOfOrigin] = useState("");
    const [PINcode, setPINcode] = useState("");
    const [processingTime, setProcessingTime] = useState("");
    const [isCustomModalOpen, setCustomModalOpen] = useState(false);
    const [customProcessingTime, setCustomProcessingTime] = useState('');
    const [freeShippingEnabled, setFreeShippingEnabled] = useState(false);

    const navigate = useNavigate();

    const transitTimeOptions = {
        standard: ['3-7', '10-20', '20-28', '26-34'],
        expedited: ['1-2', '1-3', '2-4', '3-5', '5-9'],
    };
    const handleSelectAll = (region, subregion, checked) => {
        const updatedSelectedCountries = { ...selectedCountries };
        if (!updatedSelectedCountries[region]) {
            updatedSelectedCountries[region] = {};
        }
        if (!updatedSelectedCountries[region][subregion]) {
            updatedSelectedCountries[region][subregion] = [];
        }
        if (checked) {
            updatedSelectedCountries[region][subregion] = countriesData[region][subregion];
        } else {
            updatedSelectedCountries[region][subregion] = [];
        }
        setSelectedCountries(updatedSelectedCountries);
    };
    const handleCountryToggle = (region, subregion, country) => {
        const updatedSelectedCountries = { ...selectedCountries };
        if (!updatedSelectedCountries[region]) {
            updatedSelectedCountries[region] = {};
        }
        if (!updatedSelectedCountries[region][subregion]) {
            updatedSelectedCountries[region][subregion] = [];
        }
        const isSelected = updatedSelectedCountries[region][subregion].includes(country);
        if (isSelected) {
            updatedSelectedCountries[region][subregion] = updatedSelectedCountries[region][subregion].filter(
                (c) => c !== country
            );
        } else {
            updatedSelectedCountries[region][subregion].push(country);
        }
        setSelectedCountries(updatedSelectedCountries);
    };
    const handleProcessingTimeChange = (value) => {
        if (value === 'custom') {
            setCustomModalOpen(true);
        } else {
            setProcessingTime(value);
        }
    };
    const handleCustomProcessingTimeSave = () => {
        setProcessingTime(customProcessingTime);
        setCustomModalOpen(false);
    };
    const handleCustomModalClose = () => {
        setCustomModalOpen(false);
    };
    const handleShippingFeeChange = (subregion, type, value, shippingType) => {
        setShippingFees((prevFees) => ({
            ...prevFees,
            [subregion]: {
                ...prevFees[subregion],
                [shippingType]: {
                    ...prevFees[subregion]?.[shippingType],
                    [type]: value,
                },
            },
        }));
    };
    const handleTrasitChange = (subregion, type, value) => {
        setTransitTimes((prevTimes) => ({
            ...prevTimes,
            [subregion]: {
                ...prevTimes[subregion],
                [type]: value,
            },
        }));
    };

    const handleTrasitChange2 = (subregion, type, value) => {
        setTransitTimesExp((prevTimes) => ({
            ...prevTimes,
            [subregion]: {
                ...prevTimes[subregion],
                [type]: value,
            },
        }));
    };
    let handleratemodel = (e) => {
        setratemodel(e.target.value)
    }
    const handleSaveData = () => {
        const simplifiedData = {};
        Object.entries(selectedCountries).forEach(([region, subregions]) => {
            simplifiedData[region] = {};
            Object.entries(subregions).forEach(([subregion, countries]) => {
                simplifiedData[region][subregion] = {
                    countries: countries,
                    shippingFees: {},
                    transitTimesStandard: transitTimes[subregion]?.standard,
                    transitTimesExpedited: transitTimesExp[subregion]?.expedited,
                };

                // Check if India is selected and Free Shipping is enabled
                if (region === 'India' && freeShippingEnabled) {
                    // Set shipping fees for India to 0
                    simplifiedData[region][subregion].shippingFees = {
                        standard: {
                            perOrder: 0,
                            perItem: 0,
                        }
                    };
                } else {
                    // Set shipping fees based on user input
                    simplifiedData[region][subregion].shippingFees = {
                        standard: {
                            perOrder: shippingFees[subregion]?.standard?.perOrder || 0,
                            perItem: shippingFees[subregion]?.standard?.perItem || 0,
                        },
                        expedited: {
                            perOrder: shippingFees[subregion]?.expedited?.perOrder || 0,
                            perItem: shippingFees[subregion]?.expedited?.perItem || 0,
                        },
                    };
                }
            });
        });

        const requestData = {
            shippingProfileName,
            countryOfOrigin: 'India',
            PINcode: '560002',
            processingTime,
            data: simplifiedData,
        };

        if (!shippingProfileName || Object.keys(selectedCountries).length === 0) {
            toast({
                title: "Invalid Data",
                description: "Please provide a shipping profile name and select at least one region.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        axios.post("https://api.digiblocks.tech/shipping-profiles", requestData)
            .then(response => {
                toast({
                    title: "Shipping Profile Added",
                    description: "Your Shipping Profile has been saved successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(error => {
                toast({
                    title: "Error",
                    description: "There was an error saving your data.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.error("Error posting data:", error);
            });
    };


    return (
        <ChakraProvider>
            <AdminNav />
            <Box ml={['', '', '15%']} p={3} textAlign="center">
                <Flex px={2} justifyContent={"space-between"}>
                    <Heading as="h2" size="xl" mb={6}>
                        Template For Creating A New Shipping Profile
                    </Heading>
                    <Button size={"md"} bg={"black"} colorScheme='black' color={"white"} borderRadius={0} leftIcon={<FaLongArrowAltLeft />} onClick={() => navigate("/manager/allshippingprofile/")}>
                        Back to Shipping Profiles
                    </Button>
                </Flex>
                <Box p={4} borderWidth="1px" borderRadius="md">
                    <Text fontSize="lg" fontWeight="bold">
                        Shipping Profile Instructions for Admins:
                    </Text>
                    <Text>
                        When adding a shipping profile, set charges in the local currency:
                    </Text>
                    <Text>
                        USA: USD, India: INR, Japan: JPY, Australia: AUD, Canada: CAD, UK: GBP, EUR: EUR.
                    </Text>
                </Box>
                <Flex justifyContent={"flex-end"} direction={"column"} pr={2}>
                    <Text textAlign={"right"} fontWeight={700}>Rate Model</Text>
                    <Select alignSelf={"flex-end"} w={"120px"} onChange={handleratemodel}>
                        <option value={"per-item"} >Per Item</option>
                        <option value={"weight-based"} >Weight Based</option>
                    </Select>
                </Flex>
                <Flex border={"2px solid teal"} my={3} direction={["column", "column", "row"]} justifyContent={"space-between"} bg="white" p={4} borderRadius={"2xl"}>
                    <Box>
                        <Text fontWeight={"bold"} fontSize={"md"} textAlign={"left"}>Shipping Profile Name</Text>
                        <Input
                            type="text"
                            placeholder="Enter Shipping Profile Name"
                            value={shippingProfileName}
                            onChange={(e) => setShippingProfileName(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Text fontWeight={"bold"} fontSize={"md"} textAlign={"left"}>Country of Origin</Text>
                        <Input
                            type="text"
                            placeholder="Enter Country Of Origin"
                            value="India"
                            onChange={(e) => setCountryOfOrigin(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Text fontWeight={"bold"} fontSize={"md"} textAlign={"left"}>Origin PIN code</Text>
                        <Input
                            type="text"
                            placeholder="Enter PIN Code"
                            value="560002"
                            onChange={(e) => setPINcode(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Text fontWeight={"bold"} fontSize={"md"} textAlign={"left"}>Processing Time</Text>
                        <Select placeholder="Select Processing Time" onChange={(e) => handleProcessingTimeChange(e.target.value)}>
                            <option value="1 day">1 day</option>
                            <option value="1-2 days">1-2 days</option>
                            <option value="1-3 days">1-3 days</option>
                            <option value="3-5 days">3-5 days</option>
                            <option value="5-7 days">5-7 days</option>
                            <option value="custom">Custom</option>
                        </Select>
                    </Box>
                    {/* Custom Processing Time Modal */}
                    <Modal isOpen={isCustomModalOpen} onClose={handleCustomModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Custom Processing Time</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Input
                                    type="text"
                                    placeholder="Enter custom processing time"
                                    value={customProcessingTime}
                                    onChange={(e) => setCustomProcessingTime(e.target.value)}
                                    borderRadius="md"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="green" mr={3} onClick={handleCustomProcessingTimeSave}>
                                    Save
                                </Button>
                                <Button variant="ghost" onClick={handleCustomModalClose}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex >
                <Flex direction={"column"} gap={8}>
                    {Object.entries(countriesData).map(([region, subregions]) => (
                        <Flex key={region} direction="column" border="2px solid teal" bg="white" p={4} borderRadius="2xl" mb={4}>
                            <Flex justifyContent={"space-between"}>
                                <Heading size="lg" mb={2}>
                                    {region}
                                </Heading>
                                {region === 'India' && (
                                    <Button
                                        size="sm"
                                        colorScheme="teal"
                                        width="100px"
                                        borderRadius={"none"}
                                        onClick={() => setFreeShippingEnabled(!freeShippingEnabled)}
                                    >
                                        Free Shipping
                                    </Button>
                                )}
                            </Flex>
                            <Flex direction={"column"} gap={2} >
                                {Object.entries(subregions).map(([subregion, countries]) => (
                                    <Flex border={"2px solid lightgray"} bg="white" p={2} borderRadius={"2xl"} textAlign={"start"} flex={"8"} direction={["column", "column", "row"]} justifyContent={"space-between"}>

                                        <Box as={Flex} flex={4} justifyContent={"flex-start"} direction={"column"} key={subregion} mt={2}>
                                            <Text textAlign={"start"} fontWeight={"bold"}>{subregion}</Text>
                                            <Checkbox
                                                colorScheme="teal"
                                                fontWeight={600}
                                                isChecked={selectedCountries[region]?.[subregion]?.length === countries.length}
                                                onChange={(e) => handleSelectAll(region, subregion, e.target.checked)}
                                            >
                                                Select All ({subregion})
                                            </Checkbox>
                                            <SimpleGrid columns={3} mt={2}>
                                                {countries.map((country) => (
                                                    <Checkbox
                                                        key={country}
                                                        colorScheme="teal"
                                                        isChecked={selectedCountries[region]?.[subregion]?.includes(country)}
                                                        onChange={() => handleCountryToggle(region, subregion, country)}
                                                    >
                                                        {country}
                                                    </Checkbox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>
                                        <Flex flex={4} gap={2} direction={"row"} justifyContent={"space-between"}>
                                            {/* 2 */}

                                            <Box px={3} key={subregion} borderRadius={"xl"} border={"1px solid lightgray"}>
                                                <Text fontSize={"2xl"} fontWeight="bold">Shipping Fee:</Text>
                                                <Box p={2} >
                                                    {region !== 'India' || !freeShippingEnabled ? (
                                                        <>
                                                            <Text fontWeight="bold" alignItems={"flex-start"}>Standard :</Text>
                                                            <Stack direction="row" spacing={2} mt={2}>
                                                                <InputGroup size={"sm"}>

                                                                    <Input
                                                                        type="number"
                                                                        placeholder="Per Order"
                                                                        onChange={(e) =>
                                                                            handleShippingFeeChange(subregion, "perOrder", e.target.value, "standard")
                                                                        }
                                                                    />
                                                                </InputGroup>
                                                                {ratemodel === "perItem" ?
                                                                    <InputGroup size={"sm"}>

                                                                        <Input
                                                                            type="number"
                                                                            placeholder="Per Item"

                                                                            onChange={(e) =>
                                                                                handleShippingFeeChange(subregion, "perItem", e.target.value, "standard")
                                                                            }
                                                                        />
                                                                    </InputGroup> : <>
                                                                        <InputGroup size={"sm"}>

                                                                            <Input
                                                                                type="number"
                                                                                placeholder="Per Lb"

                                                                                onChange={(e) =>
                                                                                    handleShippingFeeChange(subregion, "perLb", e.target.value, "standard")
                                                                                }
                                                                            />
                                                                        </InputGroup>
                                                                    </>}
                                                            </Stack>
                                                        </>
                                                    ) : null}
                                                    <Text fontWeight="bold">Expedited :</Text>
                                                    <Stack direction="row" spacing={4} mt={2}>
                                                        <InputGroup size={"sm"} >

                                                            <Input
                                                                type="number"
                                                                placeholder="Per Order"
                                                                onChange={(e) =>
                                                                    handleShippingFeeChange(subregion, "perOrder", e.target.value, "expedited")
                                                                }
                                                            />
                                                        </InputGroup>
                                                        {ratemodel === "perItem" ? <InputGroup size={"sm"}>

                                                            <Input
                                                                type="number"
                                                                placeholder="Per Item"
                                                                onChange={(e) =>
                                                                    handleShippingFeeChange(subregion, "perItem", e.target.value, "expedited")
                                                                }
                                                            />
                                                        </InputGroup> : <>
                                                            <InputGroup size={"sm"}>

                                                                <Input
                                                                    type="number"
                                                                    placeholder="Per Lb"

                                                                    onChange={(e) =>
                                                                        handleShippingFeeChange(subregion, "perLb", e.target.value, "expedited")
                                                                    }
                                                                />
                                                            </InputGroup>
                                                        </>}
                                                    </Stack>
                                                </Box>
                                            </Box>

                                            {/* 3 */}
                                            <Box px={3} borderRadius={"xl"} border={"1px solid lightgray"}>
                                                <Text fontSize={"2xl"} fontWeight="bold">Transit Times:</Text>
                                                <Flex direction={"column"} gap={5}>
                                                    <Box>
                                                        <Text fontWeight="bold" alignItems={"flex-start"}>Standard :</Text>
                                                        <SimpleGrid columns={1}>
                                                            <Select onChange={(e) => {
                                                                handleTrasitChange(subregion, "standard", e.target.value)
                                                            }}>
                                                                <option value={""} >Select Transit Time</option>
                                                                {transitTimeOptions.standard.map((e) => {
                                                                    return <>
                                                                        <option value={e}>{e} Days</option>
                                                                    </>
                                                                })}
                                                            </Select>
                                                        </SimpleGrid>
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight="bold" alignItems={"flex-start"}>Expedited :</Text>

                                                        <SimpleGrid columns={1}>
                                                            <Select onChange={(e) => {
                                                                handleTrasitChange2(subregion, "expedited", e.target.value)
                                                            }}>
                                                                <option value={""} >Select Transit Time</option>
                                                                {transitTimeOptions.expedited.map((e) => {
                                                                    return <>
                                                                        <option value={e}>{e} Days</option>
                                                                    </>
                                                                })}
                                                            </Select>
                                                        </SimpleGrid>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex >
                    ))}
                </Flex>
                <Flex justifyContent={"space-between"}>
                    <Button borderRadius={0} colorScheme="red" onClick={() => window.location.reload()}>
                        Cancel
                    </Button>
                    <Button borderRadius={0} colorScheme="green" onClick={handleSaveData}>
                        Save
                    </Button>
                </Flex>
            </Box>
        </ChakraProvider>
    );
};

export default AddShippingProfile;

















