import { useState } from 'react';
import { Box, Flex, Image, useToast, Spinner, Input, Text, Textarea, Button } from '@chakra-ui/react';
import { FaLocationDot } from 'react-icons/fa6';
import { TiMessages } from 'react-icons/ti';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'


const ContactusComponent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if any of the form fields are empty
        if (!formData.name || !formData.email || !formData.message) {
            toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
    
        setLoading(true);
    
        try {
            await axios.post('https://api.digiblocks.tech/contact', formData);
            toast({
                title: 'Message Sent',
                description: 'Your message has been sent successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
            });
        } catch (error) {
            console.error('Error submitting message:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form. Please try again later.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Flex w="90%" direction={["column", "column", "row"]} margin={"auto"} justifyContent={"space-evenly"} p={"20px"}>
                <Box w={["100%", "100%", "40%"]} overflow="hidden">
                    <Image
                        w={"100%"}
                        _hover={{ transform: "scale(1.1)", transition: "transform 0.2s ease-in-out" }}
                        h={"100%"}
                        alt="Uploaded"
                        objectFit="cover"
                        src="https://i.postimg.cc/XvnzWTCv/contact1.png"
                        onClick={() => navigate('/products')}
                    ></Image>
                </Box>

                <Flex w={["100%", "100%", "50%"]}>
                    <Flex direction={"column"} gap={"30px"} justifyContent={"flex-start"}>
                        <Text fontSize={["22px", "32px", "42px"]} fontFamily={"DM Serif Display"} mt={["10px", "50px", "0"]}>
                            Get In Touch
                        </Text>
                        <Text fontSize={["16px", "18px", "20px"]}>
                            Have questions or suggestions? We'd love to hear from you!
                        </Text>

                        <Flex mt={"10px"} gap={"40px"} direction={["column", "row", "row"]}>
                            <FaLocationDot color='#6FA82F' size={"50px"} style={{ marginTop: "10px" }} />
                            <Flex direction={"column"}>
                                <Text fontSize={["18px", "22px", "32px"]} fontFamily={"DM Serif Display"}>
                                    Our Office
                                </Text>
                                <Text fontSize={["16px", "18px"]} color={"#6FA82F"} w={["100%", "60%"]}>
                                    No. 71, Jumma Masjid Road, Ashira Avenue
                                    Bangalore (560002), Karnataka, India
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex gap={"40px"} direction={["column", "row", "row"]}>
                            <TiMessages color='#6FA82F' size={"50px"} style={{ marginTop: "10px" }} />
                            <Flex direction={"column"}>
                                <Text fontSize={["18px", "22px", "32px"]} fontFamily={"DM Serif Display"}>
                                    Email us
                                </Text>
                                <Text fontSize={["16px", "18px"]} color={"#6FA82F"} w={["100%", "100%"]}>
                                    enquiry@knitsilk.com
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w={"90%"} m={"auto"} pt={"5%"} pb={"5%"} direction={["column", "column", "row"]} justifyContent={"space-between"}>
                <Flex m={"auto"} direction={"column"} p={["10px", "20px", "2%"]} gap={"30px"} w={["100%", "90%", "50%"]}>
                    <Text fontSize={["22px", "32px", "42px"]} fontFamily={"DM Serif Display"}>
                        Send a message
                    </Text>
                    <Text fontSize={["14px", "16px", "18px"]}>
                        We're here to assist you. Drop us a message, and we'll get back to you shortly!
                    </Text>

                    <form onSubmit={handleSubmit}>
                        <Flex gap={"25px"} direction={["column", "row", "row"]}>
                            <Input
                                name="name"
                                m={5}
                                variant='flushed'
                                border={"none"}
                                value={formData.name}
                                onChange={handleChange}
                                borderBottom={"1px solid #6FA82F"}
                                placeholder="Name*"
                                borderRadius={"none"}
                                required
                            />
                            <Input
                                name="email"
                                m={5}
                                variant='flushed'
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                                border={"none"}
                                borderBottom={"1px solid #6FA82F"}
                                placeholder="Email Address*"
                                borderRadius={"none"}
                                required
                            />
                        </Flex>
                        <Flex gap={"25px"} direction={["column", "row", "row"]}>
                            <Input
                                name="phone"
                                m={5}
                                variant='flushed'
                                value={formData.phone}
                                onChange={handleChange}
                                border={"none"}
                                borderBottom={"1px solid #6FA82F"}
                                placeholder="Phone*"
                                borderRadius={"none"}
                                required
                            />
                            <Input
                                name="subject"
                                m={5}
                                variant='flushed'
                                border={"none"}
                                value={formData.subject}
                                onChange={handleChange}
                                borderBottom={"1px solid #6FA82F"}
                                placeholder="Subject*"
                                borderRadius={"none"}
                            />
                        </Flex>
                        <Textarea
                            name="message"
                            m={5}
                            variant='flushed'
                            border={"none"}
                            value={formData.message}
                            onChange={handleChange}
                            borderRadius={0}
                            borderBottom={"1px solid #6FA82F"}
                            placeholder="Write You Message Here*"
                            maxHeight={"300px"}
                            mb={"20px"}
                            required
                        />
                        <Button
                            type="submit"
                            colorScheme="green"
                            ml={["30%", "40%", "90%"]}
                            mt={2}
                            
                            bg="#6FA82F"
                            color="white"
                            borderRadius={0}
                            _hover={{ bg: 'black', color: 'white' }}>
                            {loading ? <Spinner size="sm" color="white" /> : 'Submit'}
                        </Button>
                    </form>
                </Flex>
                <Box overflow="hidden" w={["100%", "100%", "40%"]}>
                    <Image
                        w={"100%"}
                        _hover={{ transform: "scale(1.1)", transition: "transform 0.2s ease-in-out" }}
                        h={"100%"}
                        alt="Uploaded"
                        objectFit="cover"
                        src="https://i.postimg.cc/ryH34nvh/contact2.png"
                        onClick={() => navigate('/products')}
                    ></Image>
                </Box>
            </Flex>
        </>
    );
};

export default ContactusComponent;
