import React from 'react'
import AdminNav from './AdminNav'
import { Box, ChakraProvider,Heading } from '@chakra-ui/react'
import BlogForm from './BlogForm'

const Stats = () => {
  return (
    <ChakraProvider>
      <AdminNav />
      <Box ml={["0px", "0px", "15%"]} w={"85%"} >
       <BlogForm/>
      </Box>
    </ChakraProvider>
  )
}

export default Stats
