import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Tooltip } from '@chakra-ui/react';
import axios from 'axios';

const BigOrderUi = () => {
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");

  const getData = async () => {
    let userCurrency = localStorage.getItem('userCurrency') || "INR";
    try {
      let response = await axios.get("https://api.digiblocks.tech//api/discounts");
      let discountData = response.data[0];
      setData(discountData);

      // Determine the price based on the user's currency and handle missing prices
      let price;
      switch (userCurrency) {
        case 'USD':
          price = discountData.priceUSD;
          break;
        case 'EUR':
          price = discountData.priceEUR;
          break;
        case 'GBP':
          price = discountData.priceGBP;
          break;
        case 'INR':
          price = discountData.priceINR;
          break;
        case 'CAD':
          price = discountData.priceCAD;
          break;
        case 'AUD':
          price = discountData.priceAUD;
          break;
        case 'JPY':
          price = discountData.priceJPY;
          break;
        default:
          price = discountData.priceINR;
      }

      // If price is null or undefined, set a default message or handle accordingly
      if (price) {
        setMessage(`Spend ${price} ${userCurrency} to get ${discountData.discount}% Off.`);
      } else {
        setMessage("This offer is not available for your currency.");
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data && (
        <Box mt={"40px"}>
        <Tooltip label={message} fontSize="md" >
          <VStack
            align="center"
            justify="center"
            minH="150px"
            w={"70%"}
            m={"auto"}
            mb={2}
            p={4}
            border="2px dashed rgb(111,168,47)"
            borderRadius="md"
            position="relative"
            transition="all 0.3s ease"
            _hover={{
              borderColor: 'rgb(141, 198, 77)',
              backgroundColor: '#EBF8FF',
            }}
          >
            <Text
              fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
              fontWeight="bold"
              color="rgb(111,168,47)"
              textAlign={"center"}
              letterSpacing={5}
              fontFamily={"DM serif Display"}
            >
            {data.discount?  `Eligible Orders get ${data.discount}% Off.`:'Loading Offers'}
            </Text>
          </VStack>
        </Tooltip>
        </Box>
      )}
    </>
  );
};

export default BigOrderUi;
