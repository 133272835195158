import React from 'react'
import DetailsPageComponent from '../components/ProductsPageComponents/ProductDetail'
import Nav from '../components/HomePageComponents/Nav'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Footer from '../components/HomePageComponents/Footer'
import UserChatButton from '../components/Chat/ChatButton'

const SingleProductPage = () => {
  return (
    <>
      <TopBanner />
      <Nav />
      <DetailsPageComponent />
      <UserChatButton/>
      <Footer />
    </>
  )
}

export default SingleProductPage
