import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Heading,
  ChakraProvider,
  Flex,
} from '@chakra-ui/react';
import TopBanner from '../HomePageComponents/TopBanner';
import Nav from '../HomePageComponents/Nav';
import Footer from '../HomePageComponents/Footer';

const Faq = () => {
  return (
    <ChakraProvider>
      <TopBanner />
      <Nav />
      <Box w="100%" mb={5} >
        <Box maxW="1200px" mx="auto" p={4}>
          <Heading fontFamily="DM Serif Display" as="h2" color={"#6FA82F"} size="2xl" textAlign="center">
            FAQ
          </Heading>
          <Text fontSize="lg" textAlign="center" mt={4}>
            Here are some frequently asked questions.
          </Text>
          <Flex gap="50px" direction="column">
            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Accounts</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} fontWeight="bold" color="black" >What is the advantage of registering for an account?</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    There are many perks to registering for an account! Accounts allow our Customer Care team to assist you more quickly and efficiently. They also allow you to view your order history, receive up to date order statuses, create and share wish lists, and store addresses for quicker checkouts.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} fontWeight="bold" color='black'>Can I create a wish list on knitsilk.com to share with others?</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} fontSize={["sm", "md", "lg"]}>
                    Yes! Simply log into your account (or register for a new account if you do not already have one), click on "wish lists" on the left hand column, and follow the prompts, being sure to check the "I want to be able to share this wish list" box if you wish to share it with friends and family. To add an item to your wish list, click the "add to wish list" button found on the item's listing. To view your wish list, return to 'my account" and select "wish lists". Once you open up your wish list, there is a shareable link at the bottom that you can send to friends and family via email, text, social media, etc.
                  </AccordionPanel>
                </AccordionItem>

                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Promotional Discounts</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} fontWeight="bold" color="black">How do I apply the current promotion to my order?</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    The process is different for each promotion, depending on the offer. Some promotions require a coupon code, some are applied automatically at checkout, some may require you to select a free gift, etc. Full details and instructions for the current promotion are always outlined at the top of the shopping cart screen.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black'  fontWeight="bold">I recently purchased an item and now it's on sale. Do you offer price-adjustments?</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} fontSize={["sm", "md", "lg"]}>
                    Each of our promotions are valid for specific time frames only and we are unable to apply promotional prices to orders outside of that time frame.
                  </AccordionPanel>
                </AccordionItem>

                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Coupon Codes</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Can I use more than one coupon code?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Only one coupon code may be applied per purchase.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Can I apply a coupon code I just received to a previous order?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Coupon codes must be entered during checkout to apply to an order. Likewise, each of our promotional codes are valid for specific time frames only, and we are unable to apply them to orders outside of that time frame.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        When/Where do I enter the coupon code?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Coupon codes must be entered into the box that appears during the final steps of the checkout process, and must be entered after all items have been added to the cart. We have found that some internet browsers do not display our coupon code box correctly. If this is the case, please try using another browser. Please remember that we cannot retroactively apply coupon codes for you.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        I entered a coupon code and it didn't apply, why not?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    There are several reasons why a coupon code may not apply. Possible reasons are: the code was entered before adding all items to the cart first, it is a one time use code that has already been used, the code has expired, an item being purchased is excluded from coupons, there is a minimum purchase amount required that has not been met, additional codes cannot be applied to promotional items, or the code entered is not valid on purchases.
                  </AccordionPanel>
                </AccordionItem>


                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Shipping</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Can I send part of my order to a different address?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We only ship to the shipping address provided at checkout and cannot ship to multiple addresses. Please consider placing multiple orders should you desire your items to ship to various locations.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]}  color='black' fontWeight="bold">
                        When can I expect my in-stock items to ship?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Please refer to the shipping section of our website for details.
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Pre-ordered & Back-ordered items</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Can I send part of my order to a different address?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We only ship to the shipping address provided at checkout and cannot ship to multiple addresses. Please consider placing multiple orders should you desire your items to ship to various locations.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        When can I expect my in-stock items to ship?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Please refer to the shipping section of our website for details.
                  </AccordionPanel>
                </AccordionItem>


                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px"  color="#6FA82F" fontFamily="DM Serif Display">Payment</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        What forms of payment do you accept?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We accept MasterCard, Visa, American Express, and PayPal. Please note that some credit card gift cards are not compatible with our software.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Who pays customs fees for international shipments?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Import duties, taxes, and charges are not included in the item price or shipping cost. These charges are the buyer's responsibility. Please check with your country's customs office to determine what these additional costs will be prior to purchasing.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Why isn't my credit card going through?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Please be sure that both the name and the credit card number is entered exactly as written on the credit card. For easier credit card processing, please first consider registering for an account on our website, being sure to enter your billing address exactly as it appears on your credit card statement.
                    International Customers: When registering for an account, if you are unable to enter the address exactly as your country requires, please send us an email via our contact page with your complete address and we will attempt to format it for you. If the issue continues, please consider using a different form of payment, such as PayPal.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Why is my credit card charged before my items ship?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Our software requires that we charge credit cards at the point of sale. Please refer to the Returns or Cancellations sections of our policies page should your order require a refund.
                  </AccordionPanel>
                </AccordionItem>


                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Order Confirmations & Packing Slips</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Why does my order confirmation show my item is more expensive than it shows on the website?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    By law, we are required to charge tax in our home country. Your item totals reflect the unit price plus tax.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Some of the items I ordered do not appear on my packing slip, where are they?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Sometimes when an item is back-ordered for an extended period of time, we split the order and create a second packing slip. This means you should expect another package. You will receive another email confirmation when your subsequent package ships.
                  </AccordionPanel>
                </AccordionItem>

                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Orders
              </Text>
              <Accordion mt={6} allowMultiple={false}>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Wait, I ordered the wrong color/quantity/product!
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We are happy to help! Simply send us an email via our contact page, and we will certainly do what we can to adjust your order for you. Please know that our goal is to ship orders as quickly as possible. For this reason, it is possible that your order may ship before we are able to adjust it for you. Our Customer Care team will be happy to assist you should this occur.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        How do I cancel my order that has not yet shipped?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    It is our goal to ship your order as quickly as possible, therefore it is possible that your order may ship before a cancellation request has been seen by our customer care department. Cancellation requests may be sent via our contact page, and if your order has not begun processing, we would be happy to honour your request at that time. Should your order ship before the request is honoured, we will process it as a return.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        I received something other than what I ordered, what do I do?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We're sorry to hear this! Although our fulfillment error rate is far below industry average, mistakes do sometimes happen. Please notify us via our contact page, and we'll get it sorted out for you!
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        My item is damaged, what do I do?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    Our apologies! Though each product is inspected for quality assurance before shipping, it is possible to receive a damaged item. For this reason it is important to open and inspect your items as soon as you receive them. Please notify us via our contact page within 7 business days of receipt of your package if there is anything wrong with your item(s). We cannot replace damaged items outside of this time frame.
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]}  color='black' fontWeight="bold">
                        What if I did not receive my package and tracking shows as delivered?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We are sorry to hear this! You will need to contact the appropriate postal service for assistance or contact us through e-mail. Knitsilk is not responsible for mail that is lost by the postal service. Thank you for your understanding.
                  </AccordionPanel>
                </AccordionItem>


                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text color="#6FA82F" fontSize="24px" fontFamily="DM Serif Display">Wholesale</Text>
              <Accordion mt={6} allowMultiple={false}>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        I'm interested in offering Knitsilk products in my boutique, do you have a wholesale program?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    We do! If you'd like to apply for a wholesale account, please visit our wholesale page to set up a customer account under the "Wholesale" tab, and you will be contacted within two business days with login information. Please note that a minimum of 25 items must be purchased in order to qualify for wholesale pricing.
                  </AccordionPanel>
                </AccordionItem>

                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>

            {/*  */}
            <Box>
              <Text fontSize="24px" color="#6FA82F"  fontFamily="DM Serif Display">Contact</Text>
              <Accordion mt={6} allowMultiple={false}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        Do you have a phone number?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    The best way to get in touch with us is through our contact page, as all emails are typically responded to within one business day. Thank you for your understanding.
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton >
                    <Box flex="1" textAlign="left">
                      <Text fontSize={["sm", "md", "lg"]} color='black' fontWeight="bold">
                        I sent a message via the contact page, why haven't I received a reply yet?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel fontSize={["sm", "md", "lg"]} pb={4}>
                    There are typically two reasons we've found that customers may not receive our reply. The most common reason is that our email has been automatically filtered to a junk/spam mail folder due to mail settings and filters set up on the email account. Please always be sure to check your junk/spam folder, in addition to your inbox. The other reason we typically find is that there was a typo in the email address when the contact form was filled out, and our reply was sent back to us as undeliverable. If you are still unable to locate an email reply from us within 2 business days, we ask that you please submit your inquiry again via our contact page.
                  </AccordionPanel>
                </AccordionItem>


                {/* Add more FAQ items following the same structure */}
              </Accordion>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Footer/>
    </ChakraProvider>
  );
};

export default Faq;

