import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  CSSReset,
  theme,
  Box,
  Button,
  FormControl,
  Heading,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  HStack,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const BlogForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [photo, setPhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [editBlogId, setEditBlogId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Fetch all blogs on component mount
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://api.digiblocks.tech/blogs');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []); // Empty dependency array to run the effect only once on mount

  const onPhotoDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 1) {
      toast({
        title: 'Error',
        description: 'Please insert exactly 1 image file.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      const formData = new FormData();
      formData.append('photos', acceptedFiles[0]);

      try {
        setUploading(true);
        const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setPhoto(response?.data?.photos[0]?.url);
      } catch (error) {
        console.error('Error uploading photos:', error);
        toast({
          title: 'Error',
          description: 'Error uploading photo. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: onPhotoDrop,
  });

  const isFormValid = title.trim() !== '' && content.trim() !== '' && photo !== null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.digiblocks.tech/blogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          content,
          photo,
        }),
      });

      if (response.ok) {
        toast({
          title: 'Success',
          description: 'Blog successfully added!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeCreateModal();
        // Fetch updated blogs after adding a new one
        const updatedBlogs = await axios.get('https://api.digiblocks.tech/blogs');
        setBlogs(updatedBlogs.data);
      } else {
        console.error('Failed to add blog');
        toast({
          title: 'Error',
          description: 'Failed to add blog. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'An error occurred. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openCreateModal = () => {
    setIsModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsModalOpen(false);
    setTitle('');
    setContent('');
    setPhoto(null);
    setUploading(false);
    setEditBlogId(null);
    setIsEditing(false);
  };

  const handleEdit = (blogId) => {
    const blogToEdit = blogs.find((blog) => blog._id === blogId);
    if (blogToEdit) {
      setTitle(blogToEdit.title);
      setContent(blogToEdit.content);
      setPhoto(blogToEdit.imageUrl);
      setEditBlogId(blogId);
      setIsEditing(true);
      setIsModalOpen(true);
    } else {
      console.error('Blog not found for editing');
    }
  };

  const handleDelete = async (blogId) => {
    try {
      setUploading(true);
      const response = await axios.delete(`https://api.digiblocks.tech/blogs/${blogId}`);
      if (response.ok) {
        toast({
          title: 'Success',
          description: 'Blog successfully deleted!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // Fetch updated blogs after deletion
        const updatedBlogs = await axios.get('https://api.digiblocks.tech/blogs');
        setBlogs(updatedBlogs.data);
      } else {
        console.error('Failed to delete blog');
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'An error occurred. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setUploading(true);
      const response = await axios.put(`https://api.digiblocks.tech/blogs/${editBlogId}`, {
        title,
        content,
        imageUrl: photo,
      });

      if (response.ok) {
        toast({
          title: 'Success',
          description: 'Blog successfully updated!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeCreateModal();
        // Fetch updated blogs after editing
        const updatedBlogs = await axios.get('https://api.digiblocks.tech/blogs');
        setBlogs(updatedBlogs.data);
      } else {
        console.error('Failed to update blog');
        toast({
          title: 'Error',
          description: 'Failed to update blog. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'An error occurred. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box maxW="100%" mx="auto" p={6}>
        <Flex width="100%" justifyContent="space-between" mb={6}>
          <Heading>Blogs Management</Heading>
          <Button colorScheme="teal" onClick={openCreateModal}>
            Create a New Blog
          </Button>
        </Flex>

        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
          {blogs.map((blog) => (
            <Box key={blog._id} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
              <Image m="auto" src={blog.imageUrl} alt={blog.title} maxH="200px" objectFit="cover" />
              <Box p={4}>
                <Heading fontSize="xl" mb={2}>
                  {blog.title}
                </Heading>
                <Box>{`${blog.content.split(' ').slice(0, 20).join(' ')}...`}</Box>
                <HStack mt={4}>
                  <Button colorScheme="teal" onClick={() => handleEdit(blog._id)}>
                    Edit
                  </Button>
                  <Button colorScheme="red" onClick={() => handleDelete(blog._id)}>
                    Delete
                  </Button>
                </HStack>
              </Box>
            </Box>
          ))}
        </SimpleGrid>

        <Modal isOpen={isModalOpen} onClose={closeCreateModal} size="md">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{isEditing ? 'Edit Blog' : 'Create a New Blog'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={isEditing ? handleUpdate : handleSubmit}>
                <FormControl mb={4}>
                  <FormLabel>Title</FormLabel>
                  <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Content</FormLabel>
                  <Textarea value={content} onChange={(e) => setContent(e.target.value)} required />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Image</FormLabel>
                  <Box
                    {...getRootProps()}
                    p={4}
                    borderWidth={2}
                    borderColor={isDragActive ? 'blue' : 'gray.300'}
                    borderStyle="dashed"
                    borderRadius="md"
                    textAlign="center"
                    cursor="pointer"
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the image here ...</p> : <p>Drag 'n' drop an image here, or click to select one</p>}
                  </Box>
                </FormControl>
                {photo && (
                  <Box mb={4}>
                    <Image src={photo} alt="Preview" maxH="200px" />
                  </Box>
                )}
                {uploading && <Spinner />}
                <Button type="submit" colorScheme="teal" mt={4} isDisabled={!isFormValid || uploading}>
                  {isEditing ? 'Update Blog' : 'Add Blog'}
                </Button>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default BlogForm;
