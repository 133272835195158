import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Table,
    Stack,
    Thead,
    Tbody,
    Tr,
    Th,
    Flex,
    Td,
    IconButton,
    Badge,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useToast,
    InputGroup,
} from '@chakra-ui/react';
import { FaEdit, FaTrash } from 'react-icons/fa';


const fetchCoupons = async () => {
    const response = await fetch('https://api.digiblocks.tech/discount/coupons');
    const data = await response.json();
    return data;
};

const addCoupon = async (newCoupon) => {
    const response = await fetch('https://api.digiblocks.tech/discount/coupons', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCoupon),
    });
    const data = await response.json();
    return data;
};

const updateCoupon = async ({ id, ...couponData }) => {
    const response = await fetch(`https://api.digiblocks.tech/discount/coupons/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(couponData),
    });
    const data = await response.json();
    return data;
};

const deleteCoupon = async (couponId) => {
    const response = await fetch(`https://api.digiblocks.tech/discount/coupons/${couponId}`, {
        method: 'DELETE',
    });
    const data = await response.json();
    return data;
};

const changeCouponStatus = async (couponId, newStatus) => {
    const response = await fetch(`https://api.digiblocks.tech/discount/coupons/${couponId}/change-status`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
    });
    const data = await response.json();
    return data;
};

const CouponManagement = () => {
    const [selectedCoupon, setSelectedCoupon] = useState({
        id: null,
        code: '',
        percentageDiscount: 0,
        validFrom: '',
        validTo: '',
        status: '',
    });

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const fetchCouponsData = async () => {
        try {
            const data = await fetchCoupons();
            setCoupons(data);
        } catch (error) {
            console.error('Error fetching coupons', error);
        }
    };

    const [coupons, setCoupons] = useState([]);

    React.useEffect(() => {
        fetchCouponsData();
    }, []);

    const createCoupon = async (newCoupon) => {
        try {
            await addCoupon(newCoupon);
            onClose();
            toast({
                title: 'Coupon added successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchCouponsData();
        } catch (error) {
            console.error('Error creating coupon', error);
        }
    };

    const updateCouponData = async (couponData) => {
        try {
            await updateCoupon(couponData);
            onClose();
            toast({
                title: 'Coupon updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchCouponsData();
        } catch (error) {
            console.error('Error updating coupon', error);
        }
    };

    const deleteCouponData = async (couponId) => {
        try {
            if (window.confirm('Are you sure you want to delete this coupon?')) {
                await deleteCoupon(couponId);
                toast({
                    title: 'Coupon deleted successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                fetchCouponsData();
            }
        } catch (error) {
            console.error('Error deleting coupon', error);
        }
    };

    const handleStatusChange = async (couponId, newStatus) => {
        try {
            await changeCouponStatus(couponId, newStatus);
            toast({
                title: `Coupon ${newStatus === 'Active' ? 'activated' : 'deactivated'} successfully`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchCouponsData();
        } catch (error) {
            console.error('Error changing coupon status', error);
        }
    };

    const handleEditClick = (coupon) => {
        setSelectedCoupon(coupon);
        onOpen();
    };

    const handleSubmit = (data) => {
        if (data.id) {
            updateCouponData(data);
        } else {
            createCoupon(data);
        }
    };

    return (
        <Box width={'100%'} p={8}>
            <Flex justifyContent={'space-between'}>
                <Heading size={["sm", "md", "xl"]} mb={8}>Coupon For First Time Buyer</Heading>
                <Button
                    bg={'black'}
                    borderRadius={'none'}
                    size={'sm'}
                    colorScheme="green"
                    mb={4}
                    onClick={onOpen}
                >
                    Add Coupon
                </Button>
            </Flex>

            {/* Responsive Stack for Small Screens */}
            <Stack spacing={4} display={{ base: 'flex', md: 'none' }}>
                {coupons.map((coupon) => (
                    <Box key={coupon._id} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
                        <Heading size="md" mb={2}>
                            {coupon.code}
                        </Heading>
                        <Badge
                            variant="solid"
                            colorScheme={coupon.status === 'Active' ? 'green' : 'red'}
                            mb={2}
                        >
                            {coupon.status}
                        </Badge>
                        <Box>
                            <strong>Percentage Discount: </strong>
                            {coupon.percentageDiscount}%
                        </Box>
                        <Box>
                            <strong>Valid From: </strong>
                            {formatDateTime(coupon.validFrom)}
                        </Box>
                        <Box>
                            <strong>Valid To: </strong>
                            {formatDateTime(coupon.validTo)}
                        </Box>
                        <Button
                            size="sm"
                            mt={2}
                            borderRadius="none"
                            colorScheme={coupon.status === 'Active' ? 'red' : 'green'}
                            onClick={() =>
                                handleStatusChange(
                                    coupon._id,
                                    coupon.status === 'Active' ? 'Inactive' : 'Active'
                                )
                            }
                        >
                            {coupon.status === 'Active' ? 'Deactivate' : 'Activate'}
                        </Button>
                        <Flex mt={2}>
                            <IconButton
                                colorScheme="blue"
                                aria-label="Edit"
                                icon={<FaEdit />}
                                onClick={() => handleEditClick(coupon)}
                                mr={2}
                            />
                            <IconButton
                                colorScheme="red"
                                aria-label="Delete"
                                icon={<FaTrash />}
                                onClick={() => deleteCouponData(coupon._id)}
                            />
                        </Flex>
                    </Box>
                ))}
            </Stack>

            {/* Table for Larger Screens */}
            <Table width={'100%'} variant="simple" display={{ base: 'none', md: 'table' }}>
                <Thead>
                    <Tr>
                        <Th>Code</Th>
                        <Th>Percentage Discount</Th>
                        <Th>Valid From</Th>
                        <Th>Valid To</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {coupons.map((coupon) => (
                        <Tr key={coupon._id}>
                            <Td>{coupon.code}</Td>
                            <Td>{coupon.percentageDiscount}%</Td>
                            <Td>{formatDateTime(coupon.validFrom)}</Td>
                            <Td>{formatDateTime(coupon.validTo)}</Td>
                            <Td>
                                <Badge
                                    variant="solid"
                                    colorScheme={coupon.status === 'Active' ? 'green' : 'red'}
                                >
                                    {coupon.status}
                                </Badge>
                            </Td>
                            <Td>
                                <IconButton
                                    colorScheme="blue"
                                    aria-label="Edit"
                                    icon={<FaEdit />}
                                    onClick={() => handleEditClick(coupon)}
                                    mr={2}
                                />
                                <IconButton
                                    colorScheme="red"
                                    aria-label="Delete"
                                    icon={<FaTrash />}
                                    onClick={() => deleteCouponData(coupon._id)}
                                    mr={2}
                                />
                                <Button
                                    size="sm"
                                    ml="4"
                                    borderRadius="none"
                                    colorScheme={coupon.status === 'Active' ? 'red' : 'green'}
                                    onClick={() =>
                                        handleStatusChange(
                                            coupon._id,
                                            coupon.status === 'Active' ? 'Inactive' : 'Active'
                                        )
                                    }
                                >
                                    {coupon.status === 'Active' ? 'Deactivate' : 'Activate'}
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <CouponModal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} selectedCoupon={selectedCoupon} />
        </Box>
    );
};

const CouponModal = ({ isOpen, onClose, onSubmit, selectedCoupon }) => {
    const [formData, setFormData] = useState({
        id: null,
        code: '',
        percentageDiscount: 0,
        validFrom: '',
        validTo: '',
    });

    useEffect(() => {
        if (selectedCoupon) {
            setFormData({
                id: selectedCoupon._id,
                code: selectedCoupon.code || '',
                percentageDiscount: selectedCoupon.percentageDiscount || 0,
                validFrom: selectedCoupon.validFrom || '',
                validTo: selectedCoupon.validTo || '',
            });
        }
    }, [selectedCoupon]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{formData.id ? 'Edit Coupon' : 'Add Coupon'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <FormControl isRequired mb={4}>
                            <FormLabel>Discount / Coupon Code</FormLabel>
                            <Input
                                type="text"
                                value={formData.code}
                                onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                            />
                        </FormControl>
                        <FormControl isRequired mb={4}>
                            <FormLabel>Percentage Discount</FormLabel>
                            <Input
                                type="number"
                                value={formData.percentageDiscount}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        percentageDiscount: parseInt(e.target.value, 10),
                                    })
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mb={4}>
                            <FormLabel>Valid From</FormLabel>
                            <InputGroup>
                                <Input
                                    type="date"
                                    value={formData.validFrom.split('T')[0]}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            validFrom: `${e.target.value}T${formData.validFrom.split('T')[1]}`,
                                        })
                                    }
                                />
                                <Input
                                    type="time"
                                    value={formData.validFrom.split('T')[1]}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            validFrom: `${formData.validFrom.split('T')[0]}T${e.target.value}`,
                                        })
                                    }
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl isRequired mb={4}>
                            <FormLabel>Valid Till</FormLabel>
                            <InputGroup>
                                <Input
                                    type="date"
                                    value={formData.validTo.split('T')[0]}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            validTo: `${e.target.value}T${formData.validTo.split('T')[1]}`,
                                        })
                                    }
                                />
                                <Input
                                    type="time"
                                    value={formData.validTo.split('T')[1]}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            validTo: `${formData.validTo.split('T')[0]}T${e.target.value}`,
                                        })
                                    }
                                />
                            </InputGroup>
                        </FormControl>
                        <Flex mb={4} mt={4} justifyContent={"space-between"}>
                            <Button
                                bg={"black"}
                                borderRadius={"none"}
                                size={"sm"}
                                colorScheme="green"
                                type="submit"
                            >
                                {formData.id ? 'Update Coupon' : 'Add Coupon'}
                            </Button>
                            <Button
                                borderRadius={"none"}
                                size={"sm"}
                                colorScheme="red"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CouponManagement;

function formatDateTime(dateTimeString) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return new Date(dateTimeString).toLocaleDateString(undefined, options);
}
