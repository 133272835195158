import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Tooltip,
    VStack,
    HStack,
    Flex,
    Input,
    Textarea,
    Select,
    Button,
    useToast,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    SimpleGrid,
    Image,
    Text,
    Spinner,
    Tag, TagLabel, TagCloseButton,
    AspectRatio,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Checkbox,
    Center,
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons';
import {
    FaRupeeSign,
    FaDollarSign,
    FaPoundSign,
    FaEuroSign,
    FaYenSign,
} from 'react-icons/fa';
import axios from 'axios';
import AdminNav from './AdminNav';
import { useDropzone } from 'react-dropzone';
import YarnTypes from './YarnTypes.js';
import { useNavigate } from 'react-router-dom';

const craftTypes = ['Knitting', 'Crochet', 'Weaving', 'DollMaking', 'NeedlePoint', 'Crewel', 'KidsCrafts', 'Macrame', 'RugMaking', 'PlasticCanvas',];

const AddNewListing = () => {
    const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
    const toast = useToast();
    const apiUrl = "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_1tt8wr2ipN8IvW2CeX3dgfWzv5lrOwWH4yIxz6ts&currencies=EUR%2CUSD%2CCAD%2CGBP%2CJPY%2CAUD";

    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
    const [selectedLength, setselectedLength] = useState("");
    const [viewInput, setViewInput] = useState(false);
    const [viewInputValue, setViewInputValue] = useState("");
    const [currindex, setcurrindex] = useState(0);
    const [variation, setVariation] = useState("");
    const [variationsLength, setVariationsLength] = useState([]);
    const [variationData, setVariationData] = useState([]);
    const [variationDataFibre, setVariationDataFibre] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedFibres, setSelectedFibres] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [materials, setMaterials] = useState([]);
    const [colorDetails, setColorDetails] = useState({});
    const [fibreDetails, setFibreDetails] = useState({});
    const [allColors, setallColors] = useState([]);
    const [allUnits, setAllUnits] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("")
    const [uploadedPhotos, setUploadedPhotos] = useState([]);
    const [uploadedVideos, setUploadedVideos] = useState([]);
    const [imageloading, setimageloading] = useState(false);
    const [videoloading, setvideoloading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [usdPrice, setUsdPrice] = useState("");
    const [respectivesubcategories, setrespectivesubcategories] = useState([]);
    const [cat, setCat] = useState([]);
    const [showdiscount, setshowdiscount] = useState(false);
    const [discountpercent, setdiscountpercent] = useState(0);
    const [subCat, setsubCat] = useState([]);
    const [conversionRates, setConversionRates] = useState({
        EUR: "",
        USD: "",
        CAD: "",
        GBP: "",
        JPY: "",
        AUD: ""
    });
    const [tags, setTags] = useState([]);
    const [fivetags, setfivetags] = useState([]);
    const [selectedCraftTypes, setSelectedCraftTypes] = useState([]);
    const [product, setProduct] = useState({
        title: '', description: '', discount: 0, priceINR: '', priceUSD: '', priceGBP: '', priceEUR: '', priceCAD: '', priceAUD: '', priceJPY: '', color: '', primaryColor: '', secondaryColor: '', craftType: [], yarnWeight: '', listingStatus: 'Active', qtyInStock: '', category: '', shopSection: '', subCategory: '', tags: [], secondaryMaterial: '', deliveryOption: '', itemWeight: '', itemSize: '', manufacturer: '', productDimensions: '', primaryMaterial: '', quantityInStock: '', makeContent: '', care: '', yardage: '', needleSize: '', hookSize: '', dateAdded: '', video: "", photos: [], length: '', width: '', variations: [], units: '', HSNCode: '', wrapsPerInch: '', yarnCounts: '', meterPer100g: '', otherNames: '', widthUnit: '', lengthUnit: '', personalization: 'false', handPainted: 'false', handDyed: 'false', handMade: 'false', organic: 'false', pleatedOrRuffled: 'false', wired: 'false', cutToSize: 'false', packageWeight: '', packageDimensions: '', bulletPoints: [], productLength: "", productWidth: "", productHeight: "", packageLength: "", packageWidth: "", packageHeight: "", lengthVariations: [], qtyVariations: [], colorVariations: [],
        sku: '', aPlusPhotos: [], heightUnit: '', height: '', packageDimensionsUnit: '',
        productDimensionsUnit: '',
    });
    const [shippingProfiles, setShippingProfiles] = useState([]);

    useEffect(() => {
        axios.get('https://api.digiblocks.tech/shipping-profiles')
            .then(response => {
                const shippingProfileNames = response.data.map(profile => profile.shippingProfileName);
                setShippingProfiles(shippingProfileNames);
            })
            .catch(error => {
                console.error('Error fetching shipping profiles:', error);
            });
    }, []);
    const [aplusarr, setaplusarr] = useState([]);

    const onPhotoDrop1 = async (acceptedFiles) => {
        if (acceptedFiles.length > 9) {
            alert("Please insert upto 9 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                let x = response.data.photos.map(x => x.url);
                setaplusarr([...aplusarr, ...x])
                setProduct({ ...product, aPlusPhotos: [...product.aPlusPhotos, ...x] });

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const { getRootProps: photoRootProps1, getInputProps: photoInputProps1 } = useDropzone({
        onDrop: onPhotoDrop1,
        accept: 'image/*',
    });

    const handleAddVariation = () => {
        if (selectedLength && inputValue) {
            setVariationsLength([...variationsLength, { length: selectedLength, value: inputValue }]);
            setselectedLength('');
            setInputValue('');
        }
    };
    let handleUnitInputChange = (e) => {
        setInputValue(e.target.value)
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/colors');
                const colorsData = response.data;
                setallColors(colorsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/units');
                const unitsData = response.data;
                setAllUnits(unitsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/categories');
                const categoriesData = response.data;
                setCat(categoriesData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/subcategories');
                const subcategoriesData = response.data;
                setsubCat(subcategoriesData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);

    const allFibres = [
        "kilograms",
        "Grams",
        "Packs of"
    ];
    let allLengths = [
        "Centimeters",
        "Feet",
        "Inches",
        "Meters",
        "Millimeters",
        "Yards"
    ];
    const handleColorSelection = (color) => {
        if (color === 'chooseall') {
            // Select all colors
            let x = allColors.map((p) => { return p.colors })
            setSelectedColors(x);
        } else if (color !== "chooseall" && color !== "") {
            // Check if the color is not already selected
            if (!selectedColors.includes(color)) {
                setSelectedColors([...selectedColors, color]);
            }
        }
    };

    const handleUnitChange = (e) => {
        setselectedLength(e.target.value)
    }

    const handleFibreSelection = (fibre) => {
        if (fibre === 'chooseall') {
            // Select all fibers
            setSelectedFibres(allFibres);
        } else if (fibre !== "chooseall" && fibre !== "") {
            setSelectedFibres([...selectedFibres, fibre]);
        }
    };

    const removeColor = (color) => {
        // Filter out the selected color to remove it
        const updatedColors = selectedColors.filter((c) => c !== color);
        setSelectedColors(updatedColors);
    };

    const removeFibre = (fibre) => {
        // Filter out the selected fiber to remove it
        const updatedFibres = selectedFibres.filter((f) => f !== fibre);
        setSelectedFibres(updatedFibres);
    };

    const saveVariationFibre = () => {
        // Save variation data for each selected qty
        const newVariationData = selectedFibres.map((fibre) => {
            return {
                QuantityType: fibre,
                price: fibreDetails[fibre]?.price || "",
                quantity: fibreDetails[fibre]?.quantity || "",
            };
        });
        setProduct({ ...product, qtyVariations: [...product.qtyVariations, ...newVariationData] })
        onClose();
        setVariationDataFibre(newVariationData);
    };

    const saveVariationLength = () => {
        setProduct({ ...product, lengthVariations: [...product.lengthVariations, ...variationsLength] })
        onClose();
    }

    const saveVariationColor = () => {
        // Save variation data for each selected color
        const newVariationData = selectedColors.map((color) => {
            return {
                color: color,
                price: colorDetails[color]?.price || "",
                quantity: colorDetails[color]?.quantity || "",
                linkedphoto: ""
            };
        });
        setVariationData(newVariationData);
        setProduct({ ...product, colorVariations: [...product.colorVariations, ...newVariationData] })
        onClose();
    };

    const cancelVariation = () => {
        setVariation("");
        setSelectedColors([]);
        setColorDetails({});
        setFibreDetails({});
    };

    const handlePriceChange = (color, price) => {
        setColorDetails((prevDetails) => ({
            ...prevDetails,
            [color]: {
                ...prevDetails[color],
                price: price,
            },
        }));
    };

    const handleQuantityChange = (color, quantity) => {
        setColorDetails((prevDetails) => ({
            ...prevDetails,
            [color]: {
                ...prevDetails[color],
                quantity: quantity,
            },
        }));
    };

    const handlePriceChangeFibre = (fibre, price) => {
        setFibreDetails((prevDetails) => ({
            ...prevDetails,
            [fibre]: {
                ...prevDetails[fibre],
                price: price,
            },
        }));
    };

    const handlePriceChangeLength = (e, index) => {
        const updatedVariations = [...variationsLength];
        updatedVariations[index] = {
            ...updatedVariations[index],
            price: e.target.value,
        };
        setVariationsLength(updatedVariations)

    };
    const handleQuantityChangeLength = (e, index) => {
        const updatedVariations = [...variationsLength];
        updatedVariations[index] = {
            ...updatedVariations[index],
            quantity: e.target.value,
        };
        setVariationsLength(updatedVariations)

    };

    const handleQuantityChangeFibre = (fibre, quantity) => {
        setFibreDetails((prevDetails) => ({
            ...prevDetails,
            [fibre]: {
                ...prevDetails[fibre],
                quantity: quantity,
            },
        }));
    };

    const handleCraftTypeChange = (craftType) => {
        const isSelected = selectedCraftTypes.includes(craftType);
        if (isSelected) {
            setSelectedCraftTypes(selectedCraftTypes.filter((type) => type !== craftType));
        } else {
            setSelectedCraftTypes([...selectedCraftTypes, craftType]);
        }
        setProduct({ ...product, craftType: selectedCraftTypes });
    };

    let [tagkeywords, settagkeywords] = useState("");
    let gettags = async (value) => {
        settagkeywords(value)
        try {
            let response = await axios.get("https://api.digiblocks.tech/adminsetting/tags");
            const lowercasedValue = value.toLowerCase();
            const filteredTags = response.data.filter((tag) => tag.tags.toLowerCase().includes(lowercasedValue)).slice(0, 5);
            setfivetags(filteredTags);  //may be less than 5 tags
        } catch (error) {
            console.error("Error fetching tags:", error);
        }

    };

    const [timeoutId, setTimeoutId] = useState(null);
    const [suggestionview, setsuggestionview] = useState(true);
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    let getrespectivesubcategory = async (category) => {
        try {
            let res = await axios.post("https://api.digiblocks.tech/getallsubcategory", { category });
            setrespectivesubcategories(res.data.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "title") {
            const updatedValue = value.slice(0, 140);
            setProduct({ ...product, [name]: updatedValue });
        }
        else if (name === 'tags') {
            setsuggestionview(true);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const newTimeoutId = setTimeout(() => {
                gettags(value);
            }, 1000);
            setTimeoutId(newTimeoutId);
        } else if (name === 'materials' && value) {
            const newMaterials = value.split(',').map((material) => material.trim());
            setMaterials(newMaterials);
            setProduct({ ...product, [name]: newMaterials });
        }
        else {
            const updatedValue = Array.isArray(product[name]) ? value.split(',') : value;
            setProduct({ ...product, [name]: updatedValue });
        }
        if (name === 'category') {
            setSelectedCategory(e.target.value);
            getrespectivesubcategory(e.target.value);
        }
        if (name === 'priceUSD') {
            setUsdPrice(value);
        }
        if (name === 'priceGBP') {
            setConversionRates({
                ...conversionRates,
                GBP: value,
            });
        }
        if (name === 'priceAUD') {
            setConversionRates({
                ...conversionRates,
                AUD: value,
            });
        }
        if (name === 'priceCAD') {
            setConversionRates({
                ...conversionRates,
                CAD: value,
            });
        }
        if (name === 'priceJPY') {
            setConversionRates({
                ...conversionRates,
                JPY: value,
            });
        }
        // Check if the selected yarn weight exists in YarnTypes
        if (name === 'yarnWeight' && YarnTypes[value]) {
            const yarnProperties = YarnTypes[value].properties;
            setProduct((prevProduct) => ({
                ...prevProduct,
                wrapsPerInch: yarnProperties.wrapsPerInch,
                yarnCounts: yarnProperties.yarnCounts,
                meterPer100g: yarnProperties.meterPer100g,
                otherNames: yarnProperties.otherNames,
                hookSize: yarnProperties.hookSize,
                needleSize: yarnProperties.needleSize,
            }));
        }
    };
    const handleColor = (e) => {
        if (e.target.value === "custom") {
            setshowinput1(true);
        } else {
            setCustomcolor1(e.target.value);
            setProduct({ ...product, color: e.target.value });
            setshowinput1(false);
        }
    }
    const handleColorprimary = (e) => {
        if (e.target.value === "custom") {
            setshowinput2(true);
        } else {
            setCustomcolor2(e.target.value);
            setProduct({ ...product, primaryColor: e.target.value });
            setshowinput2(false);
        }
    }
    const handleColorsecondary = (e) => {
        if (e.target.value === "custom") {
            setshowinput3(true);
        } else {
            setCustomcolor3(e.target.value);
            setProduct({ ...product, secondaryColor: e.target.value });
            setshowinput3(false);
        }
    }
    // color
    let [showinput1, setshowinput1] = useState(false)
    let [customcolor1, setCustomcolor1] = useState("")
    // primary
    let [showinput2, setshowinput2] = useState(false)
    let [customcolor2, setCustomcolor2] = useState("")
    // secondary
    let [showinput3, setshowinput3] = useState(false)
    let [customcolor3, setCustomcolor3] = useState("")


    let navigate = useNavigate();
    let setcustomcolorfunc = (e) => {
        setCustomcolor1(e.target.value)
        setProduct({ ...product, color: e.target.value })
    }
    let setcustomcolorprimaryfunc = (e) => {
        setCustomcolor2(e.target.value)
        setProduct({ ...product, primaryColor: e.target.value })
    }
    let setcustomcolorsecondaryfunc = (e) => {
        setCustomcolor3(e.target.value)
        setProduct({ ...product, secondaryColor: e.target.value })

    }

    let savetag = (newtag) => {
        if (tags.includes(newtag)) {
            alert("Already saved")
        } else {
            setTags([...tags, newtag]);
        }
    }

    const handleRemoveMaterial = (index) => {
        const updatedMaterials = [...materials];
        updatedMaterials.splice(index, 1);
        setMaterials(updatedMaterials);
        setProduct({ ...product, materials: updatedMaterials });
    };
    const handleRemoveCraftType = (index) => {
        const updatedCraftTypes = [...selectedCraftTypes];
        updatedCraftTypes.splice(index, 1);
        setSelectedCraftTypes(updatedCraftTypes);
    };

    const onPhotoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 9) {
            alert("Please insert up to 9 image files");
        } else {
            const validFiles = [];
            const invalidFiles = [];

            // Validate image dimensions
            const validateImages = (file) => {
                return new Promise((resolve) => {
                    const img = document.createElement('img');
                    const objectUrl = URL.createObjectURL(file);
                    img.onload = () => {
                        // console.log(img.width, img.height);
                        if (img.width <= 2000 && img.height <= 2000) {
                            validFiles.push(file);
                        } else {
                            invalidFiles.push(file);
                        }
                        URL.revokeObjectURL(objectUrl);
                        resolve();
                    };
                    img.onerror = () => {
                        invalidFiles.push(file);
                        URL.revokeObjectURL(objectUrl);
                        resolve();
                    };
                    img.src = objectUrl;
                });
            };

            await Promise.all(acceptedFiles.map(file => validateImages(file)));

            if (invalidFiles.length > 0) {
                toast({
                    description: "Some images have dimensions greater than 2000x2000 pixels and were not uploaded.",
                    title: 'Image Upload Failed',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                })
            }

            if (validFiles.length > 0) {
                const formData = new FormData();
                validFiles.forEach((file) => {
                    formData.append('photos', file);
                });

                try {
                    setimageloading(true);
                    const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    if (response.data.photos) {
                        const newUrls = response.data.photos.map(x => x.url);
                        setProduct((prevProduct) => ({ ...prevProduct, photos: [...prevProduct.photos, ...newUrls] }));
                        setUploadedPhotos((prevUploadedPhotos) => [...prevUploadedPhotos, ...newUrls]);
                    } else {
                        console.error('No photos returned in response');
                    }
                    setimageloading(false);
                } catch (error) {
                    console.error('Error uploading photos:', error);
                    setimageloading(false);
                }
            }
        }
    };

    let removeimage = (ind) => {

        const updatedPhotos = [...uploadedPhotos];
        updatedPhotos.splice(ind, 1);
        setUploadedPhotos(updatedPhotos);
    }

    let removeAplusImage = (ind) => {

        const updatedPhotos = [...aplusarr];
        updatedPhotos.splice(ind, 1);
        setaplusarr(updatedPhotos);
    }

    const onVideoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Maximum 1 video can be uploaded!")
        }
        else {
            setvideoloading(true)
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('videos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/videos', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({ ...product, video: response.data.videos[0].url });
                setUploadedVideos(response.data.videos);
                setvideoloading(false)
            } catch (error) {
                console.error('Error uploading videos:', error);
            }
        }

    };

    const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
        onDrop: onPhotoDrop,
        accept: 'image/*',
    });

    const { getRootProps: videoRootProps, getInputProps: videoInputProps } = useDropzone({
        onDrop: onVideoDrop,
        accept: 'video/*',
    });

    const fetchConversionRates = async () => {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (data && data.data) {
                const rates = data.data;
                setConversionRates({
                    EUR: (usdPrice * rates.EUR).toFixed(2),
                    USD: usdPrice,
                    CAD: (usdPrice * rates.CAD).toFixed(2),
                    GBP: (usdPrice * rates.GBP).toFixed(2),
                    JPY: (usdPrice * rates.JPY).toFixed(2),
                    AUD: (usdPrice * rates.AUD).toFixed(2)
                });
            }
        } catch (error) {
            console.error("Error fetching conversion rates:", error);
        }
    };
    const handleRemoveTag = (indexToRemove) => {
        const updatedTags = tags.filter((_, index) => index !== indexToRemove);
        setTags(updatedTags);
        setProduct({ ...product, tags: updatedTags });
    };


    const handleSubmit = async () => {
        try {
            if (
                uploadedPhotos.length > 0 &&
                product.priceINR &&
                product.priceUSD &&
                // product.priceGBP &&
                // product.priceEUR &&
                // product.priceCAD &&
                // product.priceAUD &&
                // product.priceJPY &&
                product.category &&
                product.title &&
                product.description &&
                product.bulletPoints &&
                product.deliveryOption &&
                product.color &&
                product.qtyInStock &&
                product.primaryMaterial
            ) {
                await fetchConversionRates();
                const updatedProduct = {
                    ...product,
                    priceGBP: conversionRates.GBP,
                    priceEUR: conversionRates.EUR,
                    priceCAD: conversionRates.CAD,
                    priceAUD: conversionRates.AUD,
                    priceJPY: conversionRates.JPY,
                };
                console.log(updatedProduct)
                await axios.post('https://api.digiblocks.tech/products', updatedProduct);
                toast({
                    title: 'Product Added',
                    description: 'The product has been added successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                navigate("/manager/listing");
            } else {
                const missingFields = [];
                if (!product.priceINR) missingFields.push('priceINR');
                if (!product.priceUSD) missingFields.push('priceUSD');
                // if (!product.priceGBP) missingFields.push('priceGBP');
                // if (!product.priceEUR) missingFields.push('priceEUR');
                // if (!product.priceCAD) missingFields.push('priceCAD');
                // if (!product.priceAUD) missingFields.push('priceAUD');
                // if (!product.priceJPY) missingFields.push('priceJPY');
                if (!product.category) missingFields.push('category');
                if (!product.title) missingFields.push('title');
                if (!product.description) missingFields.push('description');
                if (!product.bulletPoints) missingFields.push('bulletPoints');
                if (!product.deliveryOption) missingFields.push('deliveryOption');
                if (!product.color) missingFields.push('color');
                if (!product.qtyInStock) missingFields.push('qtyInStock');
                if (!product.primaryMaterial) missingFields.push('primaryMaterial');

                toast({
                    title: 'Missing Information',
                    description: `Please fill in all the required fields: ${missingFields.join(', ')}`,
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error adding product:', error);
            toast({
                title: 'Error',
                description: 'There was an error adding the product. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDiscardChanges = () => {
        window.location.reload()
    };

    const handleDraft = async () => {

        try {
            if (uploadedPhotos.length > 0 && product.category !== "") {
                await fetchConversionRates();
                const updatedProduct = {
                    ...product,
                    priceGBP: conversionRates.GBP,
                    priceEUR: conversionRates.EUR,
                    priceCAD: conversionRates.CAD,
                    priceAUD: conversionRates.AUD,
                    priceJPY: conversionRates.JPY,
                };
                await axios.post('https://api.digiblocks.tech/drafts/products/', updatedProduct);
                toast({
                    title: 'Product Added',
                    description: 'The product has been added to the drafts successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Missing Information',
                    description: 'Please fill in all the required fields.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error adding product:', error);
            toast({
                title: 'Error',
                description: 'There was an error adding the product. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

    }

    useEffect(() => {
        if (usdPrice !== "") {
            fetchConversionRates();
        }
    }, [usdPrice]);
    const dropzoneStyles = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };
    const photoStyles = {
        objectFit: 'fill',
        margin: '5px',
    };

    const imageTooltipText = `
        Photo requirements -->
        Use a JPG, GIF, or PNG.
        Recommended size: 2000px for the shortest side, and a resolution of 72PPI.
        Keep it under 1MB for faster uploading.
    `;

    const videoTooltipText = `
        Listing videos -->
        Most file types are accepted.
        Max file size: 10MB.
        Can be 5-30 seconds long.
        Keep in mind, your video won't include audio.
    `;
    let linkphotofunction = (e, i, index) => {
        let x = [...variationData];
        x[index].linkedphoto = e;
        setVariationData(x);
        alert("clicked Photo linked to variation")

    }
    const [bulletPoints, setBulletPoints] = useState([]);
    const [newBulletPoint, setNewBulletPoint] = useState('');
    const handleInputChangebullet = (e) => {
        setNewBulletPoint(e.target.value);
    };

    const handleAddBulletPoint = () => {
        if (newBulletPoint.trim() === '') {
            return;
        }

        if (bulletPoints.length < 5) {
            setBulletPoints((prevPoints) => [...prevPoints, newBulletPoint]);
            setNewBulletPoint('');
            setProduct(prevProduct => ({
                ...prevProduct,
                bulletPoints: [...bulletPoints, newBulletPoint]
            }));
        } else {
            alert('Maximum 5 bullet points allowed.');
        }
        setNewBulletPoint('');
    };

    const deleteBulletPoint = (index) => {
        const updatedBulletPoints = bulletPoints.filter((_, i) => i !== index);
        setBulletPoints(updatedBulletPoints);
    };

    let savediscount = () => {
        setProduct({
            ...product,
            discount: discountpercent
        });
    }
    let removediscount = () => {
        setdiscountpercent(0)
        setProduct({
            ...product,
            discount: 0
        });
    }

    return (
        <>
            <AdminNav />
            <Box ml={["", "", "250px"]} bg={"lightgrey"} p={4}>
                <Box border={"1px solid teal"} bg={"white"} mb={4} p={6} borderRadius={"30px 0 30px 0"}><Heading as={"h3"}>New Listing</Heading></Box>
                <VStack spacing={4} align="stretch">
                    {/* Basic Info */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}> Basic Information</Heading>
                        <FormControl>
                            <FormLabel>Title <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                *
                            </span> </FormLabel>
                            <Text fontSize={"14px"} m={0}>
                                Max of 140 letters limit: {product?.title ? product.title.length : 0}/140 letters
                            </Text>

                            <Input name="title" value={product?.title} onChange={handleInputChange} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                *
                            </span></FormLabel>
                            <Textarea name="description" onChange={handleInputChange} />
                        </FormControl>
                        {/* XXX */}
                        <FormControl>
                            <Box>
                                Bullet Points{' '}
                                <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span>
                            </Box>

                            <Flex>
                                <Input
                                    width={'90%'}
                                    name='bulletPoints'
                                    value={newBulletPoint}
                                    onChange={handleInputChangebullet}
                                />
                                <Button
                                    bg={'black'}
                                    size={'sm'}
                                    borderRadius={'none'}
                                    _hover={{ bg: 'black', color: 'white' }}
                                    color={'white'}
                                    m={'auto'}
                                    onClick={handleAddBulletPoint}
                                >
                                    Add
                                </Button>
                            </Flex>
                            {bulletPoints.length > 0 && <Table mt={4} gap={3}>
                                <Tbody>
                                    {bulletPoints.length > 0 && bulletPoints.map((point, index) => (
                                        <tr textAlign="center">

                                            <td>
                                                <Text mb={2} fontWeight={600}>
                                                    ●{"  "}{point}
                                                </Text>
                                            </td>
                                            <td>
                                                <Button color="red" bg="none" size={"sm"} onClick={() => deleteBulletPoint(index)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </td>

                                        </tr>
                                    ))}
                                </Tbody>

                            </Table>}
                        </FormControl>

                    </Box>
                    {/* Categorization */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Categorization</Heading>
                        <SimpleGrid columns={[1, 1, 3]} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Category <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Select name="category" onChange={handleInputChange}>
                                    <option value="">Select A Category </option>
                                    {cat.map((color) => (
                                        <option key={color} value={color.categories}>{color.categories.charAt(0).toUpperCase() + color.categories.slice(1)}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl flex={2}>
                                <FormLabel>Sub-Category <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Select name="subCategory" onChange={handleInputChange}>
                                    <option value="">Select A SubCategory </option>
                                    {respectivesubcategories.map((color) => (
                                        <option key={color} value={color.subcategories}>{color.subcategories.charAt(0).toUpperCase() + color.subcategories.slice(1)}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl flex={2}>
                                <FormLabel>Home Page Section</FormLabel>
                                <Select name="shopSection" onChange={handleInputChange}>
                                    <option value="">Select A Shop Section</option>
                                    <option value="BestSellers">Best Sellers - Loved by all </option>
                                    <option value="Featured">Featured - Crafted for you</option>
                                    <option value="NewArrivals">New Arrivals - New & now</option>
                                    <option value="MostViewed">Most Viewed - Seen by many</option>
                                    <option value="SpotlightDeals">Spotlight Deals
                                    </option>
                                </Select>

                            </FormControl>
                        </SimpleGrid>
                        <HStack mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Tags <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Box position={"relative"}>
                                    <Input
                                        name="tags"
                                        value={suggestionview === false ? "" : null}
                                        onChange={handleInputChange}
                                        placeholder="Add tags..."
                                    />


                                    {/* if text in tags then i will show else not */}
                                    {tagkeywords !== "" && suggestionview && fivetags.length > 0 && <Flex w={"20%"} p={2} zIndex={"999"} bg={"white"} direction={"column"} gap={2} top={50} left={0} border={"1px solid teal"} position={"absolute"} >
                                        <Button colorScheme='red' size={"sm"} borderRadius={"50%"} position={"absolute"} right={2} top={2} onClick={() => {
                                            setsuggestionview(false);
                                        }}> X</Button>
                                        {
                                            fivetags.map((e) => {
                                                return <>
                                                    <Box onClick={() => {
                                                        savetag(e.tags)
                                                        setsuggestionview(false);
                                                    }}>
                                                        <Text m={0} fontWeight={500}>
                                                            {e.tags}
                                                        </Text>
                                                    </Box>
                                                    <hr></hr>
                                                </>
                                            })
                                        }
                                    </Flex>}
                                </Box>
                                {tags.length > 0 && (
                                    <Box mt={2}>
                                        {tags.map((tag, index) => (
                                            <Tag key={index} fontSize={"16px"} borderRadius="full" variant="solid" p={2} bg="green" color={"white"} m={2}>
                                                <TagLabel>{tag}</TagLabel>
                                                <TagCloseButton onClick={() => handleRemoveTag(index)} />
                                            </Tag>
                                        ))}
                                    </Box>
                                )}
                            </FormControl>
                        </HStack>
                        <SimpleGrid columns={[1, 1, 2]} mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Delivery Option <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Select name="deliveryOption" onChange={handleInputChange}>
                                    <option value="">Select a Delivery Option</option>
                                    {shippingProfiles.map((profileName, index) => (
                                        <option key={index} value={profileName}>{profileName}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Manufacturer</FormLabel>
                                <Input name="manufacturer" value="M.Jiju Silk Mills" onChange={handleInputChange} />
                            </FormControl>
                        </SimpleGrid>
                        <HStack mt={2} spacing={4}>
                            <Flex direction={["column", "", "row"]} gap={2} alignItems={"center"} flex={2}>
                                <FormLabel>Offers Applicable</FormLabel>
                                <Flex gap={2} alignItems={"flex-start"} flexWrap={"wrap"} direction={"row"}>
                                    <Checkbox mt={2} w={"5%"} onChange={(e) => {
                                        setshowdiscount(e.target.checked);
                                    }} />
                                    <Text w={"90%"}>Check this box if you want to add any offer in this Item.</Text>
                                </Flex>

                                {
                                    showdiscount && <Box>

                                        <FormControl flex={2} gap={2}>
                                            <FormLabel>Percent Discount</FormLabel>
                                            <Input name="discountpercent" value={discountpercent} onChange={e => {
                                                setdiscountpercent(e.target.value);
                                            }} />

                                            <Button mt={2} onClick={savediscount}>Add Offer</Button>
                                        </FormControl>



                                    </Box>
                                }
                            </Flex>
                        </HStack>
                        {product.discount > 0 && <Flex gap={5}>
                            <Text fontWeight={"bold"} fontSize={"22px"}>Discount Applied : </Text>
                            <Text fontWeight={"bold"} fontSize={"22px"}>   {product.discount}%</Text>
                            <Button onClick={removediscount}>Remove</Button>
                        </Flex>}

                    </Box>
                    {/* Media */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Media <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                            *
                        </span></Heading>
                        <FormLabel>Upload up to 10 images & 1 video</FormLabel>
                        <>
                            <div>
                                <Flex fontWeight={"bold"} flexWrap={"wrap"} gap={3}>
                                    <Tooltip label={imageTooltipText}
                                        bg="green.500"
                                        color="white"
                                        placement="top"
                                        borderRadius="50px 0px 50px 0"
                                        p={4}
                                        fontSize="sm"
                                        boxShadow="md">
                                        <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
                                            <input {...photoInputProps()} />
                                            <Text>Drag & drop some photos here, or click to select photos</Text>
                                            <Text fontWeight={"light"} width="70%" m="auto">Use a JPG, GIF, or PNG.
                                                Recommended size: 2000px for the shortest side, and a resolution of 72PPI.
                                                Keep it under 1MB for faster uploading.</Text>
                                        </Box>
                                    </Tooltip>
                                    {imageloading && (
                                        <Spinner
                                            thickness='4px'
                                            speed='0.65s'
                                            emptyColor='gray.200'
                                            color='blue.500'
                                            size='xl'
                                        />
                                    )}
                                    <Tooltip label={videoTooltipText} bg="green.500"
                                        color="white"
                                        placement="top"
                                        borderRadius="50px 0px 50px 0"
                                        p={4}
                                        fontSize="sm"
                                        boxShadow="md">
                                        <div {...videoRootProps()} style={dropzoneStyles}>
                                            <input {...videoInputProps()} />
                                            <p>Drag & drop a video here, or click to select a video</p>
                                            <Text fontWeight={"light"} width="70%" m="auto">Most file types are accepted.
                                                Max file size: 10MB.
                                                Can be 5-30 seconds long.</Text>
                                        </div>
                                    </Tooltip>
                                </Flex>
                                {videoloading && (
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='xl'
                                    />
                                )}
                                {uploadedPhotos.length > 0 && (
                                    <div>
                                        <FormLabel>Uploaded Photos:</FormLabel>
                                        <SimpleGrid gap={10} columns={[1, 2, 3]} p={5}>
                                            {uploadedPhotos.map((e, ind) => (
                                                <Box width={"100px"} key={e._id}>
                                                    {ind === 0 && <Box textAlign={"center"}>Thumbnail</Box>}
                                                    <Image src={e} alt="Uploaded" style={photoStyles} />
                                                    <Center><DeleteIcon onClick={() => {
                                                        removeimage(ind)
                                                    }}></DeleteIcon></Center>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    </div>
                                )}
                                {uploadedVideos.length > 0 && (
                                    <div>
                                        <FormLabel>Uploaded Video:</FormLabel>
                                        <p>{uploadedVideos.length} Video Uploaded</p>
                                        <AspectRatio maxW='300px' ratio={1}>
                                            <iframe title='naruto' src={product.video} allowFullScreen />
                                        </AspectRatio>
                                    </div>
                                )}
                            </div>
                        </>
                    </Box>
                    {/* Pricing */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <>
                            <Heading fontSize={"24px"}>Pricing</Heading>
                        </>
                        <SimpleGrid columns={[2, 2, 4]} mt={2} spacing={4}>
                            <FormControl>
                                <FormLabel>Price in INR <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceINR" onChange={handleInputChange} />
                                    <InputRightElement children={<FaRupeeSign />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price in USD <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceUSD" onChange={handleInputChange} />
                                    <InputRightElement children={<FaDollarSign />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price in GBP</FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceGBP" placeholder={conversionRates.GBP} onChange={handleInputChange} />
                                    <InputRightElement children={<FaPoundSign />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price in EUR</FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceEUR" placeholder={conversionRates.EUR} onChange={handleInputChange} />
                                    <InputRightElement children={<FaEuroSign />} />
                                </InputGroup>
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid columns={[2, 2, 4]} mt={2} spacing={4}>
                            <FormControl>
                                <FormLabel>Price in CAD</FormLabel>
                                <InputGroup>
                                    <Input type="number" placeholder={conversionRates.CAD} name="priceCAD" onChange={handleInputChange} />
                                    <InputRightElement children={<FaDollarSign />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price in AUD</FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceAUD" placeholder={conversionRates.AUD} onChange={handleInputChange} />
                                    <InputRightElement children={<FaDollarSign />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price in JPY</FormLabel>
                                <InputGroup>
                                    <Input type="number" name="priceJPY" placeholder={conversionRates.JPY} onChange={handleInputChange} />
                                    <InputRightElement children={<FaYenSign />} />
                                </InputGroup>
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                    {/* Specifications */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Specifications</Heading>
                        <SimpleGrid columns={[2, 2, 3]} mt={2} spacing={4}>
                            <FormControl flex={2} pt={3}>
                                <FormLabel>Item Weight</FormLabel>
                                <Input name="itemWeight" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Item Dimensions (LxWxH)</FormLabel>
                                <HStack spacing={2}>
                                    <Input name="productLength" placeholder="Length" onChange={handleInputChange} />
                                    <Input name="productWidth" placeholder="Width" onChange={handleInputChange} />
                                    <Input name="productHeight" placeholder="Height" onChange={handleInputChange} />
                                </HStack>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Item Dimensions Unit</FormLabel>
                                <Select
                                    name="productDimensionsUnit"
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Unit </option>
                                    {allUnits.map((color) => (
                                        <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid columns={[2, 2, 3]} mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Package Weight</FormLabel>
                                <Input name="packageWeight" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Package Dimensions  (LxWxH)</FormLabel>
                                <HStack spacing={2}>
                                    <Input name="packageLength" placeholder="Length" onChange={handleInputChange} />
                                    <Input name="packageWidth" placeholder="Width" onChange={handleInputChange} />
                                    <Input name="packageHeight" placeholder="Height" onChange={handleInputChange} />
                                </HStack>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Package Dimensions Unit</FormLabel>
                                <Select
                                    name="packageDimensionsUnit"
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Unit </option>
                                    {allUnits.map((color) => (
                                        <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl flex={2} pt={3}>
                                <FormLabel>Item Size</FormLabel>
                                <Input name="itemSize" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Number Of Units</FormLabel>
                                <Input name="units" onChange={handleInputChange} />
                            </FormControl>
                        </SimpleGrid>

                    </Box>
                    {/* Inventory */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Inventory</Heading>
                        <SimpleGrid columns={[2, 2, 4]} m="auto" spacing={4}>
                            <FormControl>
                                <FormLabel>Color <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Box>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                            Select color
                                        </MenuButton>
                                        <MenuList maxH={"400px"} overflowY={"scroll"}>
                                            {allColors.map((color, i) => (
                                                <MenuItem keys={i} onClick={handleColor} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                            ))}
                                            <MenuItem value={"custom"} onClick={handleColor}>custom</MenuItem>

                                        </MenuList>

                                    </Menu>
                                    {showinput1 &&
                                        <>
                                            <Text>Enter Custom Color</Text>
                                            <Input onChange={setcustomcolorfunc}></Input>
                                        </>
                                    }
                                    {customcolor1 && <Text>Selected Color : {customcolor1}</Text>}
                                </Box>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Listing Status</FormLabel>
                                <Select name="listingStatus" onChange={handleInputChange}>
                                    <option value="Active">Active</option>
                                    <option value="Sold out">Sold out</option>
                                    <option value="Inactive">Inactive</option>
                                    <option value="Expired">Expired</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Quantity In Stock <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Input type="number" name="qtyInStock" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>SKU</FormLabel>
                                <Input type="text" name="sku" onChange={handleInputChange} />
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                    {/* ---------------- VARIATIONS ------------ */}
                    <Box display={"none"} border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Flex justifyContent={"space-between"} p={4}>
                            <Heading fontSize={"24px"}>Add Variations</Heading>
                            <Button onClick={onOpen} bg={"black"} color={"white"} border={"none"} w="120px" borderRadius={"100px"} _hover={{ boxShadow: "0 0 10px black" }} rightIcon={<PlusSquareIcon />} colorScheme='teal' variant='outline'>Add </Button>
                        </Flex>

                        {/* main modal (modal 0) */}
                        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent p={5}>
                                <ModalHeader>Add Variations</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6} >
                                    <>
                                        <Heading fontSize={"24px"}>Options</Heading>
                                        <Text>
                                            Buyers can choose from the following options. Use the options listed here for peak discoverability. Buyers won’t see custom options in filters.
                                        </Text>
                                        <Flex flexWrap={"wrap"} gap={5}>
                                            {/* Primary color */}
                                            <Button
                                                onClick={() => {
                                                    onOpen();
                                                    setVariation("primaryColor");
                                                }}
                                                bg="black"
                                                color="white"
                                                border="none"
                                                w="170px"
                                                borderRadius="100px"
                                                _hover={{ boxShadow: '0 0 10px black' }}
                                                rightIcon={<PlusSquareIcon />}
                                                colorScheme="teal"
                                                variant="outline"
                                            >

                                                Color
                                            </Button>
                                            {/* Primary color */}
                                            <Button
                                                onClick={() => {
                                                    onOpen();
                                                    setVariation("quantity");
                                                }}
                                                bg="black"
                                                color="white"
                                                border="none"
                                                w="170px"
                                                borderRadius="100px"
                                                _hover={{ boxShadow: '0 0 10px black' }}
                                                rightIcon={<PlusSquareIcon />}
                                                colorScheme="teal"
                                                variant="outline"
                                            >
                                                Quantity
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    onOpen();
                                                    setVariation("length");
                                                }}
                                                bg="black"
                                                color="white"
                                                border="none"
                                                w="170px"
                                                borderRadius="100px"
                                                _hover={{ boxShadow: '0 0 10px black' }}
                                                rightIcon={<PlusSquareIcon />}
                                                colorScheme="teal"
                                                variant="outline"
                                            >
                                                Length
                                            </Button>
                                        </Flex>
                                    </>
                                    {variation === "primaryColor" && (
                                        <>
                                            <Text>
                                                Add Color Variation of Product.
                                            </Text>
                                            <Flex direction={"column"} gap={5}>
                                                <Text marginLeft="2" marginRight="2">Click on Color to remove them or</Text>
                                                <Flex align="center" justifyContent={"space-between"}>
                                                    <Select width={"20%"} onChange={(e) => handleColorSelection(e.target.value)}>
                                                        <option value="">Select Color</option>
                                                        {allColors.map((color) => (
                                                            <option key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</option>
                                                        ))}
                                                        <option value="chooseall">Choose All</option>
                                                    </Select>
                                                    <Button
                                                        bg="black"
                                                        color="white"
                                                        border="none"
                                                        borderRadius="100px"
                                                        _hover={{ boxShadow: '0 0 10px black' }}
                                                        rightIcon={<DeleteIcon />}
                                                        colorScheme="teal"
                                                        variant="outline"
                                                        w={"100%"}
                                                        onClick={() => { setSelectedColors([]) }}
                                                    >
                                                        Remove all
                                                    </Button>
                                                </Flex>
                                                <SimpleGrid columns={3} gap={3}>
                                                    {selectedColors.length > 0 && selectedColors.map((color) => (
                                                        <>
                                                            <Button
                                                                key={color}
                                                                bg={"green"}
                                                                color={color === "white" ? "black" : 'white'}
                                                                border={'1px solid teal'}
                                                                w={'170px'}
                                                                borderRadius={'100px'}
                                                                _hover={{ boxShadow: '0 0 10px black' }}
                                                                colorScheme={'teal'}
                                                                variant={'outline'}
                                                            >
                                                                {color}&nbsp;&nbsp;&nbsp;
                                                                <DeleteIcon onClick={() => removeColor(color)}></DeleteIcon>
                                                            </Button>
                                                            <Input
                                                                placeholder={`Price of ${color}`}
                                                                value={colorDetails[color]?.price || ''}
                                                                onChange={(e) => handlePriceChange(color, e.target.value)}
                                                            />
                                                            <Input
                                                                placeholder={`Quantity of ${color}`}
                                                                value={colorDetails[color]?.quantity || ''}
                                                                onChange={(e) => handleQuantityChange(color, e.target.value)}
                                                            />

                                                        </>
                                                    ))}
                                                </SimpleGrid>
                                            </Flex>
                                            <Flex justifyContent="flex-end" w="100%" >
                                                <Flex gap={5} my={8} w={["100%", "100%", "100%"]}>
                                                    <Button w={["50%", "50%", "50%"]} bg={"green"} color={"white"} onClick={() => { saveVariationColor(); }}>
                                                        Save Variation
                                                    </Button>
                                                    <Button w={["50%", "50%", "50%"]} color={"white"} bg={"red"} onClick={() => { cancelVariation(); }}>
                                                        Cancel Variation
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                        </>
                                    )}
                                    {/* quantitybody */}

                                    {variation === "quantity" && (
                                        <>
                                            <Flex flexWrap={'wrap'} gap={5}>
                                                {/* Primary Fibre */}
                                            </Flex>
                                            <Flex direction={"column"} gap={5}>
                                                <Text>
                                                    Add Quantity Variation of Product.
                                                </Text>
                                                <Text marginLeft="2" marginRight="2">Click on Qty to remove them or</Text>
                                                <Flex align="center" justifyContent={"space-between"}>
                                                    <Select width={"20%"} onChange={(e) => handleFibreSelection(e.target.value)}>
                                                        <option value="">Select Quantity</option>
                                                        {allFibres.map((fibre) => (
                                                            <option key={fibre} value={fibre}>{fibre}</option>
                                                        ))}
                                                        <option value="chooseall">Choose All</option>
                                                    </Select>
                                                    <Button
                                                        bg="black"
                                                        color="white"
                                                        border="none"
                                                        borderRadius="100px"
                                                        _hover={{ boxShadow: '0 0 10px black' }}
                                                        rightIcon={<DeleteIcon />}
                                                        colorScheme="teal"
                                                        variant="outline"
                                                        onClick={() => { setSelectedFibres([]) }}
                                                    >
                                                        Remove all
                                                    </Button>
                                                </Flex>
                                                <SimpleGrid columns={3} gap={5}>
                                                    {selectedFibres.map((fibre) => (
                                                        <>
                                                            <Button
                                                                key={fibre}
                                                                bg={"teal"}
                                                                color={'white'}
                                                                border={'1px solid teal'}
                                                                w={'170px'}
                                                                borderRadius={'100px'}
                                                                _hover={{ boxShadow: '0 0 10px black' }}
                                                                colorScheme={'teal'}
                                                                variant={'outline'}
                                                            >
                                                                {fibre}&nbsp;&nbsp;&nbsp;
                                                                <DeleteIcon onClick={() => removeFibre(fibre)}></DeleteIcon>
                                                            </Button>
                                                            <Input
                                                                placeholder={`Price of ${fibre}`}
                                                                onChange={(e) => handlePriceChangeFibre(fibre, e.target.value)}
                                                            />
                                                            <Input
                                                                placeholder={`Quantity of ${fibre}`}
                                                                onChange={(e) => handleQuantityChangeFibre(fibre, e.target.value)}
                                                            />
                                                        </>
                                                    ))}
                                                </SimpleGrid>
                                            </Flex>
                                            <Flex justifyContent="flex-end" w="100%">
                                                <Flex gap={5} my={8} w={"100%"}>
                                                    <Button w={["50%", "50%", "50%"]} bg={"green"} color={"white"} onClick={() => { saveVariationFibre(); }}>
                                                        Save Variation
                                                    </Button>
                                                    <Button w={["50%", "50%", "50%"]} color={"white"} bg={"red"} onClick={() => { cancelVariation(); }}>
                                                        Cancel Variation
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </>
                                    )}
                                    {/* quantitybody */}
                                    {variation === "length" && (
                                        <>
                                            <Flex flexWrap={'wrap'} gap={5}>
                                                {/* Primary Fibre */}
                                            </Flex>
                                            <Flex direction={"column"} gap={5}>
                                                <Text>
                                                    Add Length Variation of Product.
                                                </Text>
                                                <Text>Select a Unit</Text>
                                                <Select onChange={handleUnitChange} value={selectedLength}>
                                                    <option value="" disabled>Select a unit</option>
                                                    {/* Assuming allLengths is an array of available length units */}
                                                    {allLengths.map((e) => (
                                                        <option key={e} value={e}>
                                                            {e}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <Text>Enter Value</Text>
                                                {selectedLength && <Input
                                                    type="number"
                                                    placeholder="Enter value"
                                                    onChange={handleUnitInputChange}

                                                />}
                                                <Button onClick={handleAddVariation} colorScheme="blue" mt={4}>
                                                    Add Variation
                                                </Button>
                                                <Flex direction={"column"} gap={5} alignItems={"center"}>
                                                    {variationsLength.map((e, i) => {
                                                        return <>
                                                            <Flex gap={10} justifyContent={"space-between"}>
                                                                <Flex gap={2} alignItems={"center"}>
                                                                    <Text m={0}>{e.length}</Text>
                                                                    <Text m={0}>{e.value}</Text>
                                                                </Flex>
                                                                <Input onChange={(e) => {
                                                                    handlePriceChangeLength(e, i)
                                                                }} placeholder={variationsLength[i].price || 'enter price '}></Input>
                                                                <Input placeholder={variationsLength[i].quantity || 'enter quantity'} onChange={(e) => {
                                                                    handleQuantityChangeLength(e, i)
                                                                }} ></Input>
                                                            </Flex>
                                                        </>
                                                    })}
                                                </Flex>
                                            </Flex>
                                            <Flex justifyContent="flex-end" ml="30%" w="100%">
                                                <Flex gap={5} my={8} w={"70%"}>
                                                    <Button w={"25%"} bg={"green"} color={"white"} onClick={saveVariationLength}>
                                                        Save Variation
                                                    </Button>
                                                    <Button w={"25%"} color={"white"} bg={"red"} onClick={cancelVariation}>
                                                        Cancel Variation
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </>
                                    )}
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                        <>
                            {variationData.length > 0 && <Text textDecoration={"underline"} fontWeight={"bold"}>Color Variation</Text>}
                            {variationData.length > 0 && <Table>
                                <Thead>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Link Photo</th>
                                    <th>Photo linked</th>
                                </Thead>
                                {
                                    variationData.map((e, index) => {
                                        return <tr>
                                            <td>{e.color}</td>
                                            <td>{e.price}</td>
                                            <td>{e.quantity}</td>
                                            <td>
                                                <Flex gap={3} alignItems={"center"} p={5} flexWrap={"wrap"}>
                                                    {
                                                        product.photos.map((ele, i) => {
                                                            return <Image alt="Uploaded" onClick={() => {
                                                                linkphotofunction(ele, i, index)
                                                            }} display={"block"} src={ele} w={"70px"} h={"70px"}></Image>
                                                        })
                                                    }
                                                </Flex>
                                            </td>
                                            <td>{
                                                variationData[index].linkedphoto !== "" && <Image w={"70px"} h={"70px"} alt="Uploaded" src={variationData[index].linkedphoto}></Image>
                                            }</td>
                                        </tr>
                                    })
                                }
                                <></>
                            </Table>}
                            {variationDataFibre.length > 0 && <Text textDecoration={"underline"} fontWeight={"bold"}>Quantity Variation</Text>}
                            {variationDataFibre.length > 0 && <Table>
                                <Thead>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </Thead>
                                {
                                    variationDataFibre.map((e) => {
                                        return <tr>
                                            <td>{e.QuantityType}</td>
                                            <td>{e.price}</td>
                                            <td>{e.quantity}</td>
                                        </tr>
                                    })
                                }
                                <></>
                            </Table>}
                            {variationsLength.length > 0 && <Text textDecoration={"underline"} fontWeight={"bold"}>Length Variation</Text>}
                            {variationsLength.length > 0 && <Table>
                                <Thead>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </Thead>
                                {
                                    variationsLength.map((e, i) => {
                                        return <tr>
                                            <td>{e.value} {e.length} </td>
                                            <td>{e.price}</td>
                                            <td>{e.quantity}</td>

                                        </tr>
                                    })
                                }
                                <></>
                            </Table>}
                        </>
                    </Box>
                    {/* Attributes */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Attributes</Heading>
                        <SimpleGrid columns={[1, 1, 2]} mt={2} spacing={4}>
                            <FormControl>
                                <FormLabel>Primary Color</FormLabel>

                                <Box>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                            Primary color
                                        </MenuButton>
                                        <MenuList maxH={"400px"} overflowY={"scroll"}>
                                            {allColors.map((color, i) => (
                                                <MenuItem keys={i} onClick={handleColorprimary} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                            ))}
                                            <MenuItem value={"custom"} onClick={handleColorprimary}>custom</MenuItem>

                                        </MenuList>

                                    </Menu>
                                    {showinput2 &&
                                        <>
                                            <Text>Enter Custom Color</Text>
                                            <Input onChange={setcustomcolorprimaryfunc}></Input>
                                        </>
                                    }
                                    {customcolor2 && <Text>Selected Color : {customcolor2}</Text>}
                                </Box>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Secondary Color</FormLabel>
                                <Box>
                                    {/*yyy */}
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                            Secondary color
                                        </MenuButton>
                                        <MenuList maxH={"400px"} overflowY={"scroll"}>
                                            {allColors.map((color, i) => (
                                                <MenuItem keys={i} onClick={handleColorsecondary} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                            ))}
                                            <MenuItem value={"custom"} onClick={handleColorsecondary}>custom</MenuItem>

                                        </MenuList>

                                    </Menu>
                                    {showinput3 &&
                                        <>
                                            <Text>Enter Custom Color</Text>
                                            <Input onChange={setcustomcolorsecondaryfunc}></Input>
                                        </>
                                    }
                                    {customcolor3 && <Text>Selected Color : {customcolor3}</Text>}
                                </Box>
                            </FormControl>
                        </SimpleGrid>
                        <HStack mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Materials</FormLabel>
                                <Text fontSize="md" color="gray.500">
                                    Separate Materials with commas. Example: silk, sari silk,
                                </Text>
                                <Input
                                    name="materials"
                                    onChange={handleInputChange}
                                    value={materials.join(", ")}
                                    placeholder="Add materials..."

                                />
                                {materials.length > 0 && (
                                    <Box mt={2}>
                                        {materials.map((material, index) => (
                                            <Tag key={index} fontSize={"16px"} borderRadius="full" variant="solid" p={2} bg="blue" color={"white"} m={2}>
                                                <TagLabel>{material}</TagLabel>
                                                <TagCloseButton onClick={() => handleRemoveMaterial(index)} />
                                            </Tag>
                                        ))}
                                    </Box>
                                )}
                            </FormControl>
                        </HStack>
                        <SimpleGrid columns={[1, 1, 2]} mt={2} spacing={4}>
                            <FormControl>
                                <FormLabel>Select Craft Types</FormLabel>
                                <Menu closeOnSelect={false}>
                                    <MenuButton as={Button} borderRadius={0} border={"1px solid lightgray"} bg={"none"} rightIcon={<Icon as={ChevronDownIcon} />}>
                                        Select Craft Types
                                    </MenuButton>
                                    <MenuList>
                                        <>
                                            {craftTypes.map((craftType, index) => (
                                                <MenuItem key={index} onClick={() => handleCraftTypeChange(craftType)}>
                                                    {craftType}
                                                </MenuItem>
                                            ))}
                                            <Button bg="none" onClick={() => setViewInput(true)}>Custom</Button>
                                            {
                                                viewInput &&
                                                <Flex px={3} gap={3} alignItems={"center"}>
                                                    <Input placeholder='Enter craft type' onChange={(e) => {

                                                        setViewInputValue(e.target.value);

                                                    }}></Input>
                                                    <Button onClick={() => handleCraftTypeChange(viewInputValue)} colorScheme='green' size={"sm"}>Save</Button>
                                                </Flex>

                                            }
                                        </>
                                    </MenuList>
                                </Menu>
                            </FormControl>
                            <Flex mt={2}>
                                {selectedCraftTypes.map((craftType, index) => (
                                    <Tag
                                        key={index}
                                        fontSize={"16px"}
                                        borderRadius="full"
                                        variant="solid"
                                        p={2}
                                        bg="green"
                                        color={"white"}
                                        m={2}
                                    >
                                        <TagLabel>{craftType}</TagLabel>
                                        <TagCloseButton onClick={() => handleRemoveCraftType(index)} />
                                    </Tag>
                                ))}
                            </Flex>
                        </SimpleGrid>
                        <SimpleGrid
                            columns={[1, 1, 3]}
                            mt={5}
                            spacing={{ base: 4, md: 8 }}
                            justify="center"
                            align="center"
                            w="100%"
                        >
                            <FormControl>
                                <FormLabel fontSize="md">Allow Personalization for buyers</FormLabel>
                                <Select
                                    colorScheme="green"
                                    value={product.personalization}
                                    onChange={(e) => handleInputChange(e)}
                                    name="personalization"
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>

                            {/* hand painted */}
                            <FormControl>
                                <FormLabel fontSize="md">Hand Painted</FormLabel>
                                <Select
                                    colorScheme="green"
                                    value={product.handPainted}
                                    onChange={(e) => handleInputChange(e)}
                                    name="handPainted"
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>
                            {/* hand dyed */}
                            <FormControl>
                                <FormLabel fontSize="md">Hand Dyed</FormLabel>
                                <Select
                                    colorScheme="green"
                                    value={product.handDyed}
                                    onChange={(e) => handleInputChange(e)}
                                    name="handDyed"
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>
                            {/* hand made */}
                            <FormControl>
                                <FormLabel fontSize="md">Hand Made</FormLabel>
                                <Select
                                    colorScheme="green"
                                    value={product.handMade}
                                    onChange={(e) => handleInputChange(e)}
                                    name="handMade"
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>
                            {/* Organic */}
                            <FormControl>
                                <FormLabel fontSize="md">Organic</FormLabel>
                                <Select
                                    colorScheme="green"
                                    onChange={(e) => handleInputChange(e)}
                                    name="organic"
                                    value={product.organic}
                                >
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel fontSize="md">Cut to Size</FormLabel>
                                <Select
                                    colorScheme="green"
                                    value={product.cutToSize}
                                    onChange={(e) => handleInputChange(e)}
                                    name="cutToSize"
                                >
                                    <option value="">None</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>

                    </Box>
                    {/* Technical Details */}
                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Technical Details</Heading>
                        <SimpleGrid columns={[2, 2, 3]} mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Primary Material <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                    *
                                </span></FormLabel>
                                <Input name="primaryMaterial" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Secondary Material</FormLabel>
                                <Input name="secondaryMaterial" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Yarn Weight</FormLabel>
                                <Select
                                    name="yarnWeight"
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select An Yarn Weight Option</option>
                                    {Object.keys(YarnTypes).map((key) => (
                                        <option key={key} value={key}>
                                            {YarnTypes[key].name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid columns={[2, 2, 4]} mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>m/100g</FormLabel>
                                <Input name="meterPer100g" value={product.meterPer100g} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Other Names</FormLabel>
                                <Input name="otherNames" value={product.otherNames} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Needle Size (mm)</FormLabel>
                                <Input name="needleSize" value={product.needleSize} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Hook Size (mm)</FormLabel>
                                <Input name="hookSize" value={product.hookSize} onChange={handleInputChange} />
                            </FormControl>
                        </SimpleGrid>
                        <SimpleGrid columns={[2, 2, 4]} spacing={4} mt={2}>

                        </SimpleGrid>
                        <SimpleGrid columns={[2, 2, 4]} mt={2} spacing={4}>
                            <FormControl flex={2}>
                                <FormLabel>Yardage</FormLabel>
                                <Input type="number" name="yardage" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Type (Make Content)</FormLabel>
                                <Input name="makeContent" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>HSN Code</FormLabel>
                                <Input name="HSNCode" onChange={handleInputChange} />
                            </FormControl>
                            <FormControl flex={2}>
                                <FormLabel>Wash care</FormLabel>
                                <Select name="care" onChange={handleInputChange}>
                                    <option value="">Select</option>
                                    <option value="machine-wash">Machine Wash</option>
                                    <option value="hand-wash">Hand Wash</option>
                                    <option value="dry-clean">Dry Clean</option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                    </Box>

                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                        <Heading fontSize={"24px"}>Add A+ Images</Heading>
                        <>
                            <Box mr={2} w={"100%"} {...photoRootProps1()} style={dropzoneStyles}>
                                <input {...photoInputProps1()} />
                                <Text>Drag & drop some photos here, or click to select photos</Text>
                                <Text fontWeight={"light"} width="70%" m="auto">Use a JPG, GIF, or PNG.
                                    Recommended size: 2000px for the shortest side, and a resolution of 72PPI.
                                    Keep it under 1MB for faster uploading.</Text>
                            </Box>
                            <SimpleGrid gap={10} columns={[1, 2, 3]} p={5}>
                                {aplusarr && aplusarr.map((photo, ii) => (
                                    <Box textAlign={"center"} width={"100px"} key={photo._id}>
                                        <Image src={photo} alt="Uploaded" style={photoStyles} />
                                        <Button onClick={() => {
                                            removeAplusImage(ii)
                                        }}><DeleteIcon></DeleteIcon></Button>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        </>
                    </Box>

                    <HStack width={"100%"} spacing={4} mt={4} justifyContent="space-between">
                        <VStack spacing={2} w={"50%"}>
                            <Button
                                width={["100%", "100%", "50%"]}
                                borderRadius={"none"}
                                bg={"black"}
                                color={"white"}
                                _hover={{ bg: "black", color: "white" }}
                                onClick={handleDiscardChanges}
                            >
                                Cancel
                            </Button>
                            <Button
                                width={["100%", "100%", "50%"]}
                                borderRadius={"none"}
                                colorScheme="green"
                                _hover={{ bg: "black", color: "white" }}
                                onClick={onOpen1}
                            >
                                Preview

                            </Button>
                        </VStack>

                        <VStack spacing={2} w={"50%"}>
                            <Button
                                width={["100%", "100%", "50%"]}
                                borderRadius={"none"}
                                colorScheme="green"
                                _hover={{ bg: "black", color: "white" }}
                                onClick={handleDraft}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                width={["100%", "100%", "50%"]}
                                borderRadius={"none"}
                                colorScheme="green"
                                onClick={handleSubmit}
                                _hover={{ bg: "black", color: "white" }}
                            >
                                Publish
                            </Button>
                        </VStack>
                    </HStack>
                </VStack >
            </Box >
            <Modal isOpen={isOpen1} onClose={onClose1} size="4xl" >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign="center">Product Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Image */}
                        <Flex flex={"5"} justifyContent={"space-between"} p={2}>
                            <Flex flex={"3"}>
                                <VStack p={4}>
                                    {product.photos.map((e, i) => {
                                        return <Image alt="Uploaded" onClick={(e) => {
                                            setcurrindex(i)
                                        }} w={"70px"} src={e}></Image>
                                    })}
                                </VStack>
                                <Image
                                    src={product.photos[currindex]}
                                    borderRadius="md"
                                    alt="Uploaded"
                                    mb={4}
                                    w={"70%"}
                                />
                            </Flex>
                            <Flex flex={"2"}>
                                <Box>
                                    {/* Title */}
                                    <Text fontSize="2xl" fontWeight="bold" mb={2} >
                                        {product.title}
                                    </Text>
                                    {/* Price */}
                                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                                        Price: ${product.priceUSD}
                                    </Text>
                                    {/* Description */}
                                    <Text fontSize="md" mb={4} >
                                        {product.description.length > 300 ? (
                                            <>
                                                {showMore ? product.description : `${product.description.slice(0, 100)}... `}
                                                <Text fontWeight={"semibold"} color={"green"} size="sm" onClick={() => setShowMore(!showMore)}>
                                                    {showMore ? 'Show Less' : 'Show More'}
                                                </Text>
                                            </>
                                        ) : (
                                            product.description
                                        )}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddNewListing;




