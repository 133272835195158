import React from 'react'
import AdminNav from './AdminNav'
import { Box, Flex, Text } from '@chakra-ui/react'
import CouponManagement from './CouponManagement';
import PromoCode from './PromoCode';
import EncourageBiggerOrders from './EncourageBiggerOrders';

const Marketing = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"} >
          <CouponManagement />
          <PromoCode/>
          <EncourageBiggerOrders/>
        </Box>
      </Flex>
    </>
  )
}

export default Marketing
