import React from 'react';
import { Helmet } from 'react-helmet';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import UserChatButton from '../components/Chat/ChatButton'
import EmbeddedWebsite from '../components/HomePageComponents/Reviews';

const ReviewsPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Customer Feedback" content="KnitSilk | Customer Reviews" />
                <title>KnitSilk | Customer Reviews</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <EmbeddedWebsite/>
            <UserChatButton/>
            <Footer />
        </>
    )
}

export default ReviewsPage;
