import { useState } from "react";

const Tooltip = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        width: '300px',
                        transform: 'translateX(-50%)',
                        backgroundColor: '#fff',
                        color: '#000',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        zIndex: 999,
                    }}
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;

