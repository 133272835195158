
import React, { useContext, useEffect, useState } from 'react';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import {
  Flex, Input, Heading, Button, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
  Box,
  ModalBody,
  ModalCloseButton,
  Image,

} from '@chakra-ui/react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { FaPaperPlane, FaRegImage } from 'react-icons/fa';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { AppContext } from "../Context/AppContext"

const ChatWithAdmin = () => {
  let { refresh, setRefresh } = useContext(AppContext)
  const [message, setMessage] = useState('');
  const [photo, setPhoto] = useState('');
  const [messages, setMessages] = useState([]);
  const { isOpen: isOpenChat, onOpen: onOpenChat, onClose: onCloseChat } = useDisclosure();
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const [userName, setUserName] =  useState("");


  const getUserID = async () => {
    try {
      const userToken = localStorage.getItem('userToken');
      const userDetailsResponse = await axios.get('https://api.digiblocks.tech/getuserdetails', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const userID = userDetailsResponse.data.userID;
      const userInfoResponse = await axios.get(`https://api.digiblocks.tech/getuser/${userID}`);
      const { name, email } = userInfoResponse.data;
      setUserName(name || email);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };


  const getallmessages = async () => {
    const userToken = localStorage.getItem('userToken');
    const response = await axios.get(`https://api.digiblocks.tech/messages`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    setMessages(response.data.messages);
  };

  useEffect(() => {
    getallmessages();
  }, [refresh]);

  useEffect(() => {
    scrollToLatest();
    getUserID();
  }, [])

  const handlesend = async () => {
    const userToken = localStorage.getItem('userToken');
    await axios.post(
      'https://api.digiblocks.tech/messages',
      { sender: userToken, message, reciever: 'Admin' },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    try {
      await axios.post(
        'https://api.digiblocks.tech/messageConfirmation/admin',
        { userName, message }
      );
    } catch (error) {
      console.error('Error sending confirmation email to admin:', error);
    }
    setMessages([...messages, { sender: userToken, message }]);
    scrollToLatest();
    setMessage('');
    setRefresh(!refresh)
  };

  const handlesendImage = async () => {
    if (photo) {
      const userToken = localStorage.getItem('userToken');
      await axios.post(
        'https://api.digiblocks.tech/messages',
        { sender: userToken, message: photo, reciever: 'Admin' },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setMessages([...messages, { sender: userToken, message }]);
      scrollToLatest();
      setMessage('');
      setPhoto('');
      setRefresh(!refresh)
      onCloseChat();
    } else {
      onCloseChat();
      alert("Please select a photo")
    }
  };

  const scrollToLatest = () => {
    const latestDiv = document.getElementById('latest');
    if (latestDiv) {
      latestDiv.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onPhotoDrop = async (acceptedFiles) => {
    if (acceptedFiles.length !== 1) {
      alert("Please insert 1 image file")
    } else {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('photos', file);
      });
      try {
        const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setPhoto(response?.data?.photos[0]?.url);

      } catch (error) {
        console.error('Error uploading photos:', error);
      }
    }
  };

  const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
    onDrop: onPhotoDrop,
    accept: 'image/*',
  });

  const dropzoneStyles = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };


  return (
    <>
      <Tooltip label="Chat with Us" bg="white" color={"black"} aria-label="Chat with Us">
        <Button
          onClick={onOpenChat}
          size="sm"
          borderRadius="none"
          colorScheme="green"
          bg="#6FA82F"
          width="150px"
          leftIcon={<IoChatbubbleEllipsesOutline />}
          _hover={{ bg: "black", color: "white" }}
        >Chat With Us
        </Button>
      </Tooltip>
      <Modal isOpen={isOpenChat} onClose={onCloseChat}>
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader zIndex={"999"} p={0}>
            <Flex pb={2} justifyContent={"center"} gap={2} alignItems={"center"}>
              <Heading m={0} color="black" size="md" textAlign="center">
                Talk To A Representative
              </Heading>
              <ModalCloseButton color="black" />
            </Flex>
          </ModalHeader>
          <ModalBody mx={2} p={0} >
            <Flex
              direction="column"
              maxW="600px"
              mx="0"
              borderRadius="lg"
              bg="#FFFFFF"
              height={"450px"}
            >
              <Flex sx={{
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-thumb": {
                  bg: "#6FA82F",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "white",
                },
              }} direction="column" flex="1" overflowY="auto" p="4" >

                {messages.map((e, ind) => (
                  <Flex
                    key={ind}
                    mb="2"
                    alignSelf={e.sender === 'Admin' ? 'flex-start' : 'flex-end'}
                    transition="background 0.3s ease"
                  >
                    {e.message.includes("https://res.cloudinary.com") ? (
                      <>
                        {e.sender === "Admin" ? (
                          <Flex id={messages.length - 1 === ind ? "latest" : ""} justifyContent={"flex-start"}>
                            <Image w={"50%"} src={e.message}></Image>
                          </Flex>
                        ) : (
                          <Flex id={messages.length - 1 === ind ? "latest" : ""} justifyContent={"flex-end"}>
                            <Image w={"50%"} src={e.message}></Image>
                          </Flex>
                        )}
                      </>
                    ) : (
                      <Flex key={ind} mb="2" alignSelf={e.sender === 'Admin' ? 'flex-start' : 'flex-end'} transition="background 0.3s ease">
                        <Flex flexWrap={"wrap"} direction="column" alignItems={e.sender === 'Admin' ? 'flex-start' : 'flex-end'}>
                          <Flex

                            flexWrap={"wrap"}
                            w={"100%"}
                            variant="solid"
                            color={"white"}
                            bg={e.sender === 'Admin' ? 'gray' : '#6FA82F'}
                            p="2"
                            borderRadius="30px"
                            id={messages.length - 1 === ind ? "latest" : ""}
                            mb="1"
                            style={{ overflowWrap: 'break-word' }}
                          >
                            {e.message}
                          </Flex>
                          <Text fontSize="xs" color="gray.500" textAlign="right">
                            {new Date(e.createdAt).toLocaleDateString()} {new Date(e.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>

              <Flex position={"relative"} h={"30px"}>

                <Button
                  position={"absolute"}
                  borderRadius={"50%"}
                  _hover={{ bg: "black", color: "white" }}
                  right={5}
                  bg="none"
                  w={"10%"}
                  onClick={() => {
                    scrollToLatest();
                  }}
                  colorScheme="green"
                  color="#6FA82F"
                >
                  ⇩
                </Button>
              </Flex>
              <Flex align="center" gap={2} flex={"8"} maxH={"70px"}>
                <IconButton flex={"1"}
                  mr="2"
                  onClick={() => {
                    onOpen1();
                  }}
                  _hover={{ bg: "black", color: "white" }}
                  colorScheme="green"
                  bg="#6FA82F"
                  borderRadius="full"
                  position={"absoulute"}
                  right={0}
                  bottom={0}
                  icon={<FaRegImage />}
                >

                </IconButton>
                <Input
                  placeholder="Type here..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  mr="2"
                  flex={"5"}
                  borderRadius="full"
                  border="2px solid #6FA82F"
                  focusBorderColor="#6FA82F"
                  _hover={{ borderColor: '#6FA82F' }}
                />
                <IconButton
                  flex={"1"}
                  onClick={() => {
                    handlesend();
                    setTimeout(() => {
                      scrollToLatest();
                    }, 500)
                  }}
                  colorScheme="green"
                  _hover={{ bg: "black", color: "white" }}
                  borderRadius="full"
                  bg="#6FA82F"
                  icon={<FaPaperPlane />}
                >
                </IconButton>
              </Flex>
              <Modal isOpen={isOpen1} onClose={onClose1}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Image Upload</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
                      <input {...photoInputProps()} />
                      <Text>Drag & drop some photos here, or click to select photos</Text>
                    </Box>
                    {photo && (
                      <>
                        <Text>Preview</Text>
                        <Image w={"50%"} src={photo}></Image>
                      </>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose1}>
                      Close
                    </Button>
                    <Button variant='ghost' onClick={() => {
                      handlesendImage();
                    }}>Send</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChatWithAdmin;