// src/App.js
import React, { useState, useEffect } from 'react';
import {
    ChakraProvider,
    Flex,
    Text,
    Box,
    Heading,
    Input,
    extendTheme,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';

const theme = extendTheme({});

const PromoCode = () => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [promoCodes, setPromoCodes] = useState([]);
    const [selectedPromoCode, setSelectedPromoCode] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchPromoCodes = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://api.digiblocks.tech/promo-codes');
            setPromoCodes(response.data);
        } catch (error) {
            console.error('Error fetching promo codes:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPromoCodes();
    }, []);

    const handleFormSubmit = async (formData) => {
        try {
            setLoading(true);
            if (selectedPromoCode) {
                await axios.put(`https://api.digiblocks.tech/promo-codes/update/${selectedPromoCode.code}`, formData);
            } else {
                await axios.post('https://api.digiblocks.tech/promo-codes/create', formData);
            }
            fetchPromoCodes();
            setSelectedPromoCode(null);
            onClose();
            showToast(selectedPromoCode ? 'Promo Code Updated' : 'Promo Code Created', 'success');
        } catch (error) {
            console.error('Error submitting form:', error);
            showToast('Error Submitting Form', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (promoCode) => {
        try {
            setLoading(true);
            await axios.delete(`https://api.digiblocks.tech/promo-codes/delete/${promoCode.code}`);
            fetchPromoCodes();
            showToast('Promo Code Deleted', 'success');
        } catch (error) {
            console.error('Error deleting promo code:', error);
            showToast('Error Deleting Promo Code', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (promoCode) => {
        setSelectedPromoCode(promoCode);
        onOpen();
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status: status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <ChakraProvider theme={theme}>
            <Box maxW="100%" p={8}>

                <Flex justifyContent={"space-between"}>
                    <Heading mb={4} size={["sm", "md", "xl"]}>PromoCode Management</Heading>
                    <Button bg={"black"}
                        borderRadius={"none"}
                        size={"sm"}
                        colorScheme="green"
                        mb={4} onClick={onOpen}>
                        {selectedPromoCode ? 'Update Promo Code' : 'Create Promo Code'}
                    </Button>
                </Flex>
                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay />
                    <ModalContent borderRadius="md">
                        <ModalHeader textAlign="center" fontWeight="bold" fontSize="2xl">
                            {selectedPromoCode ? 'Update Promo Code' : 'Create Promo Code'}
                        </ModalHeader>
                        <ModalCloseButton color={"black"}/>
                        <ModalBody>
                            <Stack spacing={4}>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleFormSubmit({
                                            code: e.target.code.value,
                                            discountPercentage: e.target.discountPercentage.value,
                                            minOrderAmount: e.target.minOrderAmount.value,
                                            startDate: e.target.startDate.value,
                                            endDate: e.target.endDate.value,
                                        });
                                    }}
                                >
                                    <Stack spacing={4}>
                                        <Text mx="2" fontWeight={"bold"}>Promo Code</Text>
                                        <Input
                                            type="text"
                                            name="code"
                                            placeholder="Promo Code"
                                            defaultValue={selectedPromoCode ? selectedPromoCode.code : ''}
                                            isRequired
                                        />
                                        <Text mx="2" fontWeight={"bold"}>Discount Percentage</Text>
                                        <Input
                                            type="number"
                                            name="discountPercentage"
                                            placeholder="Discount Percentage"
                                            defaultValue={selectedPromoCode ? selectedPromoCode.discountPercentage : ''}
                                            isRequired
                                        />
                                        <Text mx="2" fontWeight={"bold"}>Order minimum</Text>
                                        <Text mx="2">
                                            You can require a minimum spend for buyers to qualify for your offer.
                                        </Text>
                                        <Input
                                            type="number"
                                            name="minOrderAmount"
                                            placeholder="Minimum Order Amount (in USD)"
                                            defaultValue={selectedPromoCode ? selectedPromoCode.minOrderAmount : ''}
                                            isRequired
                                        />
                                        <Text mx="2" fontWeight={"bold"}>Valid From</Text>
                                        <Input
                                            type="date"
                                            name="startDate"
                                            placeholder="Start Date"
                                            defaultValue={selectedPromoCode ? selectedPromoCode.startDate : ''}
                                            isRequired
                                        />
                                        <Text mx="2" fontWeight={"bold"}>Valid To</Text>
                                        <Input
                                            type="date"
                                            name="endDate"
                                            placeholder="End Date"
                                            defaultValue={selectedPromoCode ? selectedPromoCode.endDate : ''}
                                            isRequired
                                        />
                                        <Button
                                            type="submit"
                                            colorScheme="teal"
                                            leftIcon={loading && <Spinner size="sm" />}
                                            isLoading={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Submit'}
                                        </Button>
                                    </Stack>
                                </form>
                            </Stack>
                        </ModalBody>
                        <ModalFooter />
                    </ModalContent>
                </Modal>
                <Flex
                    direction="column"
                    alignItems="center"
                    display={{ base: 'flex', md: 'none' }}
                    mb={4}
                >
                    {promoCodes.map((promoCode) => (
                        <Box
                            key={promoCode._id}
                            p={4}
                            borderWidth="1px"
                            borderRadius="md"
                            boxShadow="md"
                            width="100%"
                            mb={4}
                        >
                            <Heading size="md" mb={2}>
                                {promoCode.code}
                            </Heading>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Box>
                                    <strong>Discount Percentage: </strong>
                                    {promoCode.discountPercentage}%
                                </Box>
                                <Box>
                                    <strong>Min Order Amount: </strong>
                                    {promoCode.minOrderAmount}
                                </Box>
                            </Flex>
                            <Box>
                                <strong>Valid From: </strong>
                                {formatDateTime(promoCode.startDate)}
                            </Box>
                            <Box>
                                <strong>Valid To: </strong>
                                {formatDateTime(promoCode.endDate)}
                            </Box>
                            <Flex mt={2}>
                                <Button
                                    colorScheme="teal"
                                    size="sm"
                                    onClick={() => handleUpdate(promoCode)}
                                    leftIcon={<FaEdit />}
                                >
                                    Update
                                </Button>
                                <Button
                                    colorScheme="red"
                                    size="sm"
                                    ml={2}
                                    onClick={() => handleDelete(promoCode)}
                                    leftIcon={<FaTrash />}
                                >
                                    Delete
                                </Button>
                            </Flex>
                        </Box>
                    ))}
                </Flex>

                <Stack spacing={4}>

                    <Table variant="simple" display={{ base: 'none', md: 'table' }}>
                        <Thead>
                            <Tr>
                                <Th>Code</Th>
                                <Th>Discount %</Th>
                                <Th>Min Order Amount (in USD)</Th>
                                <Th>Valid From</Th>
                                <Th>Valid To</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {promoCodes.map((promoCode) => (
                                <Tr key={promoCode._id}>
                                    <Td>{promoCode.code}</Td>
                                    <Td>{promoCode.discountPercentage} %</Td>
                                    <Td>{promoCode.minOrderAmount}</Td>
                                    <Td>{formatDateTime(promoCode.startDate)}</Td>
                                    <Td>{formatDateTime(promoCode.endDate)}</Td>
                                    <Td>
                                        <Button colorScheme="teal" size="sm" onClick={() => handleUpdate(promoCode)} leftIcon={<FaEdit />}>
                                            Update
                                        </Button>
                                        <Button
                                            colorScheme="red"
                                            size="sm"
                                            ml={2}
                                            onClick={() => handleDelete(promoCode)}
                                            leftIcon={<FaTrash />}
                                        >
                                            Delete
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
            </Box>
        </ChakraProvider>
    );
};

export default PromoCode;


function formatDateTime(dateTimeString) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return new Date(dateTimeString).toLocaleDateString(undefined, options);
}