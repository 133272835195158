import React, { createContext, useEffect, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);
  const [auth, setAuth] = useState(localStorage.getItem("userToken") ? true : false);
  const [adminAuth, setAdminAuth] = useState(sessionStorage.getItem("adminstatus") === true ? true : false);
  const [cartLength, setCartLength] = useState(0);
  let [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [wishlistLength, setWishlistLength] = useState(0);
  useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      setAuth(true);
    }
  }, [refresh]);

  const contextValue = {
    refresh,
    setRefresh,
    cartLength,
    setCartLength,
    wishlistLength,
    setWishlistLength,
    auth,
    setAuth,
    adminAuth,
    setAdminAuth,
    cartTotalPrice,
    setCartTotalPrice,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };