const countriesData = {
    India: {
        States: [
            'Andhra Pradesh',
            'Bihar',
            'Chhattisgarh',
            'Goa',
            'Gujarat',
            'Haryana',
            'Himachal Pradesh',
            'Jharkhand',
            'Karnataka',
            'Kerala',
            'Madhya Pradesh',
            'Maharashtra',
            'Odisha',
            'Punjab',
            'Rajasthan',
            'Sikkim',
            'Tamil Nadu',
            'Telangana',
            'Uttarakhand',
            'Uttar Pradesh',
            'West Bengal',
        ],
        NorthEast: [
            'Arunachal Pradesh',
            'Assam',
            'Manipur',
            'Meghalaya',
            'Mizoram',
            'Nagaland',
            'Tripura',

        ],
        UnionTerritories: [
            'Chandigarh',
            'Dadra and Nagar Haveli and Daman & Diu',
            'Delhi',
            'Jammu & Kashmir',
            'Puducherry',
        ],
        FarOfTerritories:[
            'Andaman and Nicobar Islands',
            'Ladakh',
            'Lakshadweep',
        ]
    },

    USA: {
        AllStates: [
            'Maine',
            'Vermont',
            'New Hampshire',
            'Massachusetts',
            'Connecticut',
            'Rhode Island',
            'New York',
            'Pennsylvania',
            'District of Columbia',
            'New Jersey',
            'North Dakota',
            'South Dakota',
            'Nebraska',
            'Kansas',
            'Minnesota',
            'Iowa',
            'Wisconsin',
            'Illinois',
            'Missouri',
            'Indiana',
            'Ohio',
            'Michigan',
            'Oklahoma',
            'Texas',
            'West Virginia',
            'Virginia',
            'Delaware',
            'Kentucky',
            'Tennessee',
            'North Carolina',
            'South Carolina',
            'Georgia',
            'Alabama',
            'Mississippi',
            'Florida',
            'Louisiana',
            'Arkansas',
            'Maryland',
            'Arizona',
            'Montana',
            'Idaho',
            'Wyoming',
            'Colorado',
            'Utah',
            'Nevada',
            'California',
            'Oregon',
            'Washington',
            'New Mexico',
        ],
        Hawaii_Alaska: [
            'Hawaii',
            'Alaska',
        ],
        US_Protectorates: [
            'Puerto Rico',
            'Guam',
            'Virgin Islands',
            'American Samoa',
            'Northern Mariana Islands',
            'Palau',
            'Federated States of Micronesia',
            'Marshall Islands',
        ],
        APO_FPO: [
            'AA',
            'AE',
            'AP',
        ],
    },
    Europe: {
        EUCountries: [
            'Austria',
            'Belgium',
            'Bulgaria',
            'Croatia',
            'Czech Republic',
            'Denmark',
            'Estonia',
            'Finland',
            'Greece',
            'Hungary',
            'Ireland',
            'Latvia',
            'Lithuania',
            'Luxembourg',
            'Malta',
            'Netherlands',
            'Poland',
            'Portugal',
            'Romania',
            'Slovakia',
            'Slovenia',
            'Sweden',
            'United Kingdom',
            'France',
            'Germany',
            'Italy',
            'Spain',
            'Cyprus',
        ],
        RestofEuropeCountries: [
            'Liechtenstein',
            'Monaco',
            'Switzerland',
            'Iceland',
            'Norway',
            'Albania',
            'Andorra',
            'Bosnia and Herzegovina',
            'Macedonia',
            'Montenegro',
            'San Marino',
            'Serbia',
            'Vatican City State (Holy See)',
            'Belarus',
            'Ukraine',
            'Georgia',
            'Gibraltar',
            'Moldova Republic of',
            'Greenland',
            'Faroe Islands',
            'Russia',
            'Svalbard and Jan Mayen Islands',
        ],
    },
    TheAmericas: {
        "North America": [
            'Canada',
        ],
        "Mexico" :[
            'Mexico',
        ],
        SouthAmerica: [
            'Argentina',
            'Bolivia',
            'Brazil',
            'Chile',
            'Colombia',
            'Ecuador',
            'French Guiana',
            'Guyana',
            'Paraguay',
            'Peru',
            'Suriname',
            'Uruguay',
            'Venezuela',
            'Falkland Islands (Malvinas)',
        ],
        CentralAmerica: [
            'Belize',
            'Costa Rica',
            'El Salvador',
            'Honduras',
            'Guatemala',
            'Nicaragua',
            'Panama',
        ],
        Caribbean: [
            'Bahamas',
            'Haiti',
            'Dominican Republic',
            'Barbados',
            'Trinidad and Tobago',
            'Saint Vincent and the Grenadines',
            'Cayman Islands',
            'Anguilla',
            'Antigua and Barbuda',
            'Aruba',
            'Bermuda',
            'Dominica',
            'Grenada',
            'Guadeloupe',
            'Jamaica',
            'Martinique',
            'Montserrat',
            'Netherlands Antilles',
            'Saint Kitts and Nevis',
            'Saint Lucia',
            'Turks and Caicos Islands',
            'Virgin Islands (British)',
        ],
    },
    Africa: {
        EastAfrica: [
            'Tanzania',
            'Kenya',
            'Uganda',
            'Rwanda',
            'Burundi',
            'Djibouti',
            'Eritrea',
            'Ethiopia',
            'Comoros',
            'Mauritius',
            'Seychelles',
            'Malawi',
            'Zambia',
            'Zimbabwe',
            'Madagascar',
            'Mayotte',
            'Mozambique',
            'Reunion',
        ],
        CentralAfrica: [
            'Angola',
            'Cameroon',
            'Central African Republic',
            'Chad',
            'Congo Democratic Republic of',
            'Equatorial Guinea',
            'Gabon',
            'Sao Tome and Principe',
        ],
        NorthAfrica: [
            'Algeria',
            'Egypt',
            'Libya',
            'Morocco',
            'Tunisia',
            'Western Sahara',
        ],
        SouthernAfrica: [
            'Botswana',
            'Lesotho',
            'Namibia',
            'South Africa',
            'Swaziland',
        ],
        WestAfrica: [
            'Benin',
            'Burkina Faso',
            'Cape Verde',
            'Ivory Coast',
            'Gambia',
            'Ghana',
            'Guinea',
            'Guinea-Bissau',
            'Liberia',
            'Mali',
            'Niger',
            'Nigeria',
            'Senegal',
            'Sierra Leone',
            'Togo',
            'Mauritania',
            'Saint Helena',
        ],
    },
    Oceania: {
        Melanesia: [
            'Fiji',
            'New Caledonia',
            'Papua New Guinea',
            'Vanuatu',
            'Solomon Islands',
        ],
        Micronesia: ['Kiribati', 'Nauru'],
        Polynesia: [
            'Cook Islands',
            'French Polynesia',
            'Niue',
            'Pitcairn',
            'Samoa',
            'Tokelau',
            'Tonga',
            'Tuvalu',
            'Wallis and Futuna Islands',
        ],
        "Australia and New Zealand": [
            'Australia',
            'New Zealand',
            'Norfolk Island',
            'Cocos (Keeling) Islands',
            'Christmas Island',
        ],
    },
    Asia: {
        "East Asia": [
            'China',
            'Hong Kong',
            'Macau',
            'Japan',
            'Mongolia',
            'South Korea',
            'Taiwan',
        ],
        "West Asia": [
            'Armenia',
            'Azerbaijan',
            'Bahrain',
            'Iraq',
            'Israel',
            'Jordan',
            'Kuwait',
            'Lebanon',
            'Oman',
            'Qatar',
            'Saudi Arabia',
            'Turkey',
            'United Arab Emirates',
            'Yemen',
            'Afghanistan',
        ],
        "Southeast Asia": [
            'Brunei',
            'Cambodia',
            'East Timor',
            'Indonesia',
            'Laos',
            'Malaysia',
            'Philippines',
            'Singapore',
            'Thailand',
            'Vietnam',
            'Myanmar',
        ],
        "Central Asia": [
            'Kazakhstan',
            'Kyrgyzstan',
            'Tajikistan',
            'Turkmenistan',
            'Uzbekistan',
        ],
        "South Asia": [
            'Bangladesh',
            'Bhutan',
            'Maldives',
            'Nepal',
            'Pakistan',
            'Sri Lanka',
            'British Indian Ocean Territory',
        ],
    },
}

export default countriesData;