import React, { useState } from 'react';
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { FaComment } from 'react-icons/fa';

const UserChatButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleChatClick = () => {
    onOpen();
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <Box>
     <style>
        {`
          @keyframes zoomInOut {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(1.1);
            }
          }
        `}
      </style>
      <IconButton
        icon={<FaComment  size={"25px"}/>}
        bg="#6FA82F"
        color="white"
        position="fixed"
        zIndex={"999"}
        bottom="4"
        right="4"
        borderRadius="full"
        boxShadow="0 0 7px green"
        w={"70px"}
        h={"70px"}
        _hover={{ transform: "scale(1.2)" }}
        animation="zoomInOut 1s infinite alternate"
        onClick={handleChatClick}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="md" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent
          position="fixed"
          bottom="4"
          right="4"
          borderRadius="md"
          border="2px"
          borderColor="whiteAlpha.300"
          width="350px"
          maxHeight="600px"
        >
          <ModalBody overflowY="auto">
            {!iframeLoaded && (
              <Box display="flex" width="350px"
                height="500px" justifyContent="center" alignItems="center">
                <Spinner size="xl" color="green.500" />
              </Box>
            )}
            <Box style={{ display: iframeLoaded ? 'block' : 'none' }}>
              <iframe
                style={{ height: '500px', width: '320px' }}
                frameBorder="0"
                src="https://widget.writesonic.com/CDN/index.html?service-base-url=https://api.botsonic.ai&token=9bcb8572-7ae8-43eb-b319-990dc02ad9bc&base-origin=https://bot.writesonic.com&instance-name=Botsonic&standalone=true&page-url=https://bot.writesonic.com/389a793d-7158-464c-bc7a-e0d451b9da02?t=connect&workspace_id=86650e74-c099-4d72-b695-afd93893e9fa"
                onLoad={handleIframeLoad}
              ></iframe>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserChatButton;
