import React from 'react';
import { Box, Button, Center, Heading, VStack, Text, Icon } from '@chakra-ui/react';
import { WarningIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const Failure = () => {
    return (
        <Center minHeight="100vh" bg="red.50">
            <VStack spacing={6} p={5} boxShadow="lg" borderRadius="md" bg="white">
                <MotionBox
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <WarningIcon w={10} h={10} color="red.500" />
                </MotionBox>
                <Heading as="h4" size="md" color="red.500" textAlign="center">
                    Oops, something went wrong!
                </Heading>
                <Text fontSize="lg" color="gray.600" textAlign="center">
                    😢 We encountered an error. Please try again later.
                </Text>
                <Button
                    as="a"
                    href="/"
                    leftIcon={<ArrowBackIcon />}
                    colorScheme="red"
                    variant="solid"
                    size="md"
                >
                    Back to Home
                </Button>
            </VStack>
        </Center>
    );
};

export default Failure;
