import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Nav from '../components/HomePageComponents/Nav'
import Footer from '../components/HomePageComponents/Footer';
import AddressesPage from '../components/MyAccount/Addresses';
import { Helmet } from 'react-helmet';
import UserChatButton from '../components/Chat/ChatButton'

const UserAddress = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | My Addresses" content="KnitSilk | My Addresses" />
                <title>KnitSilk | My Addresses</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <AddressesPage />
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    )
}

export default UserAddress;
