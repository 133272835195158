import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Heading,
    Flex,
    Button,
    ChakraProvider,
    Stack,
    Spinner,
    IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useBreakpointValue
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import AdminNav from './AdminNav';
import { useNavigate } from 'react-router-dom';


const SubregionTable = ({ subregions }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box>
            {isMobile ? (
                <Stack spacing={4}>
                    {subregions.map(([subregion, data]) => (
                        <Box key={subregion} borderWidth="1px" borderRadius="md" p={4}>
                            <Text fontSize="lg" fontWeight="bold">
                                Subregion: {subregion}
                            </Text>
                            <Text>
                                <Text fontWeight="bold">Countries:</Text> {data.countries.join(', ')}
                            </Text>
                            <Text>
                                <Text fontWeight="bold">Standard Shipping Fee:</Text>{' '}
                                {data.shippingFees.standard?.perOrder} per Order + {data.shippingFees.standard?.perItem} per
                                Item
                            </Text>
                            <Text>
                                <Text fontWeight="bold">Expedited Shipping Fee:</Text>{' '}
                                {data.shippingFees.expedited?.perOrder} per Order + {data.shippingFees.expedited?.perItem} per
                                Item
                            </Text>
                            <Text>
                                <Text fontWeight="bold">Standard Transit Time:</Text> {data.transitTimesStandard} days
                            </Text>
                            <Text>
                                <Text fontWeight="bold">Expedited Transit Time:</Text> {data.transitTimesExpedited} days
                            </Text>
                        </Box>
                    ))}
                </Stack>

            ) : (
                <Table colorScheme="green" size="sm">
                    <Thead>
                        <Tr>
                            <Th width="10%">Subregion</Th>
                            <Th width="40%">Countries</Th>
                            <Th width="15%">Standard Shipping Fee</Th>
                            <Th width="15%">Expedited Shipping Fee</Th>
                            <Th width="10%">Standard Transit Time</Th>
                            <Th width="10%">Expedited Transit Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subregions.map(([subregion, data]) => (
                            <Tr key={subregion}>
                                <Td>{subregion}</Td>
                                <Td>{data.countries.join(', ')}</Td>
                                <Td>
                                    {data.shippingFees.standard?.perOrder} per Order +{' '}
                                    {data.shippingFees.standard?.perItem} per Item
                                </Td>
                                <Td>
                                    {data.shippingFees.expedited?.perOrder} per Order +{' '}
                                    {data.shippingFees.expedited?.perItem} per Item
                                </Td>
                                <Td>{data.transitTimesStandard} days</Td>
                                <Td>{data.transitTimesExpedited} days</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};


const ShippingProfile = ({ profile, onDelete }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const navigate = useNavigate();

    const handleDelete = () => {
        onDelete(profile._id);
        onClose();
    };
    const handleEditProfile = () => {
        const profileId = profile._id;
        navigate(`/manager/shippingprofiles/edit/${profileId}`);
    };

    return (
        <>
            <Box p={4} borderWidth="1px" borderRadius="md">
                <Text fontSize="lg" fontWeight="bold">
                    Shipping Profile Instructions for Admins:
                </Text>
                <Text>
                    All Shipping Charges are added in the local currency.
                </Text>
                <Text>
                    USA: USD, India: INR, Japan: JPY, Australia: AUD, Canada: CAD, UK: GBP, EUR: EUR.
                </Text>
            </Box>
            <Box key={profile._id} p={6} mb={6} border="1px" borderColor="green.500" borderRadius="xl" boxShadow="md">
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    borderBottom={{ base: "1px solid green", md: "none" }}
                    justify="space-between"
                    mb={2}
                    wrap="wrap"
                >
                    <Heading as="h3" size="lg" color="green.500" mb={{ base: 2, md: 0 }}>
                        {profile.shippingProfileName}
                    </Heading>
                    <Flex
                        fontWeight="semibold"
                        flex={{ base: 'none', md: '1' }}
                        textAlign={{ base: 'center', md: 'left' }}
                        mt={{ base: 2, md: 0 }}
                        mx={["","","6"]}
                        mb={{ base: 2, md: 0 }}
                        justifyContent={"space-between"}
                    >
                        <Text mr={10} display={["none", "none", "block"]} fontSize={{ base: 'sm', md: 'md' }}>
                            Country of Origin: {profile.countryOfOrigin}
                        </Text>
                        <Text mr={10} display={["none", "none", "block"]} fontSize={{ base: 'sm', md: 'md' }}>
                            Origin PIN Code: {profile.PINcode}
                        </Text>
                        <Text mr={10} fontSize={{ base: 'sm', md: 'md' }}>
                            Processing Time: {profile.processingTime}
                        </Text>
                    </Flex>
                    <Stack
                        direction={{ base: 'row', md: 'column' }}
                        spacing={2}
                        m={{ base: 2, md: 0 }}
                        textAlign={{ base: 'center', md: 'right' }}
                    >
                        <IconButton
                            icon={<EditIcon />}
                            colorScheme="green"
                            size="sm"
                            aria-label="Edit Shipping Profile"
                            onClick={handleEditProfile}
                        />
                        <IconButton
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            size="sm"
                            aria-label="Delete Shipping Profile"
                            onClick={onOpen}
                        />
                    </Stack>
                </Flex>

                <Flex>
                    <Box flex={2}>
                        {Object.entries(profile.data).map(([region, subregions]) => (
                            <Box key={region} mb={4}>
                                <Heading as="h4" textAlign={"left"} size="md" m={"auto"} mb={2}>
                                    {region}
                                </Heading>
                                <SubregionTable subregions={Object.entries(subregions)} />
                            </Box>
                        ))}
                    </Box>
                </Flex>
                <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Delete Shipping Profile
                            </AlertDialogHeader>
                            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>
        </>
    );
};

const ShippingProfilesList = () => {
    const [shippingProfiles, setShippingProfiles] = useState([]);
    const [loading, setLoading] = useState(true); // New state for loading indicator
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://api.digiblocks.tech/shipping-profiles')
            .then(response => {
                setShippingProfiles(response.data);
                setLoading(false); // Set loading to false once data is loaded
            })
            .catch(error => {
                console.error('Error fetching shipping profiles:', error);
                setLoading(false); // Set loading to false in case of an error
            });
    }, []);

    const handleDeleteProfile = (profileId) => {
        axios.delete(`https://api.digiblocks.tech/shipping-profiles/${profileId}`)
            .then(response => {
                if (response.status === 200) {
                    setShippingProfiles(prevProfiles => prevProfiles.filter(profile => profile._id !== profileId));
                    toast({
                        title: 'Profile Deleted',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Error Deleting Profile',
                        description: 'An error occurred while deleting the profile.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .catch(error => {
                console.error('Error deleting shipping profile:', error);
                toast({
                    title: 'Error Deleting Profile',
                    description: 'An error occurred while deleting the profile.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <ChakraProvider>
            <AdminNav />
            <Box ml={['', '', '15%']} p={3} textAlign="center">
                <Flex justifyContent={"space-between"} direction={["column", "column", "row"]}>
                    <Heading as="h2" size={["md", "md", "xl"]} mb={6}>
                        All Shipping Profiles List
                    </Heading>
                    <Button size={"md"} bg={"black"} colorScheme='black' color={"white"} borderRadius={0} onClick={() => navigate("/manager/shippingprofiles/create/")}>
                        Create A Shipping Profile
                    </Button>
                </Flex>
                {loading ? (
                    <Spinner m={"auto"} size="xl" color="green.500" />
                ) : (
                    shippingProfiles.map(profile => (
                        <ShippingProfile key={profile._id} profile={profile} onDelete={handleDeleteProfile} />
                    ))
                )}
            </Box>
        </ChakraProvider>
    );
};

export default ShippingProfilesList;
