import { Box, Flex, Text, Table, Thead, Heading, Button, Tbody, Tr, Th, Td, Image, Stack } from '@chakra-ui/react';
import { FaTruck, FaMoneyBillAlt, FaClock, FaTag } from 'react-icons/fa';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/knitsilk black logo.png";
import html2pdf from 'html2pdf.js';
import { format } from "date-fns";
import { FaRupeeSign, FaPoundSign, FaEuroSign, FaYenSign } from 'react-icons/fa';
import { IoLogoUsd } from 'react-icons/io';
import { TbCurrencyDollarAustralian } from 'react-icons/tb';


const currencyIcons = {
  INR: <FaRupeeSign />,
  USD: <IoLogoUsd />,
  EUR: <FaEuroSign />,
  GBP: <FaPoundSign />,
  JPY: <FaYenSign />,
  CAD: <IoLogoUsd />,
  AUD: <TbCurrencyDollarAustralian />,
};

const OrdersSingle = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const contentRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getSingleOrder = async () => {
      try {
        const response = await axios.post("https://api.digiblocks.tech/allOrders", { orderID: id });
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching single order:', error);
      }
    };

    getSingleOrder();
  }, [id]);

  const handleDownloadPDF = () => {
    const content = contentRef.current;

    if (content) {
      html2pdf(content);
    }
  };

  return (
    <>
      <Flex width={["100%", "100%", "74%"]} direction={["column", "column", "row"]} m="auto" display={"flex"} justifyContent={"space-between"}>
        <Button onClick={handleDownloadPDF} colorScheme='green' borderRadius={"none"}>Download Invoice</Button>
        <Button onClick={() => navigate("/manager/orders")} colorScheme='green' borderRadius={"none"}>Back To Orders</Button>
      </Flex>
      <Box p={10} maxWidth={"1000px"} m="auto" boxShadow="lg" borderRadius="md" >
        {order ? (
          <>
            <Stack px="20px" ref={contentRef}>
              <Box>
                <Flex flexWrap="wrap" justifyContent="space-between">
                  <Box w={["100%", "100%", "40%"]}>
                    <Box w={["100%", "", "48%"]} >
                      <Box flexBasis={['100%']}  >
                        <Image src={Logo} alt="Uploaded" py={3} />
                      </Box>
                      <Box ml={"4"} flexBasis={['100%']} mt={[3, 2]} >
                        <Text w={"100%"} >No. 71, Jumma Masjid Road, Ashira Avenue
                          Bangalore (560002), Karnataka, India</Text>
                      </Box>
                    </Box>
                  </Box>
                  <Flex w={["100%", "", "60%"]} justifyContent={"space-between"} flexWrap={"wrap"} textAlign={["right"]}>
                    <Box>
                      <Text fontWeight={"bold"} textDecor="underline" mb={2}>
                        Billing Info
                      </Text>
                      {Object.entries(order?.customerInfo?.billing || {}).map(([key, value], i) => (
                        <Flex key={i} justifyContent={"flex-end"}>
                          <Text m={0}>{value}</Text>
                        </Flex>
                      ))}
                    </Box>
                    {/* shipping info */}
                    <Box>
                      <Text fontWeight="bold" textDecor="underline" mb={2}>
                        Shipping Info
                      </Text>
                      {Object.entries(order?.customerInfo?.shipping || {}).map(([key, value], i) => (
                        <Flex key={i} justifyContent={"flex-end"}>
                          <Text m={0}>{value}</Text>
                        </Flex>
                      ))}
                    </Box>
                  </Flex>
                </Flex>
              </Box>
              {/* order items for large screen */}
              <Box w={"100%"} display={["none", "none", "block"]}>
                <Text fontSize="xl" fontWeight="bold" mb={2} color="black.500">
                  Order Item Details :
                </Text>
                <Table variant="simple" colorScheme="black">
                  <Thead>
                    <Tr>
                      <Th>Product</Th>
                      <Th>Price</Th>
                      <Th>Quantity</Th>
                      <Th>Total Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {order?.cartProducts?.map((product, i) => (
                      <Tr key={i}>
                        <Td>
                          <Flex align="center">
                            <Image src={product.photos[0]} alt={product.title} w="40px" h="40px" borderRadius="md" mr={2} />
                            <Text fontWeight="bold">{product.title}</Text>
                          </Flex>
                        </Td>
                        <Td>
                        <Flex alignItems={"center"} direction={"row"} gap={1}>
                        <Text m={0}>{currencyIcons[order?.userCurrency]}</Text>
                        <Text m={0}> {product[`price${order?.userCurrency}`]}</Text>
                        </Flex>
                        </Td>
                        <Td textAlign={"center"}>{product.quantity}</Td>
                        <Td>
                        <Flex alignItems={"center"} direction={"row"} gap={1}>
                        <Text m={0}>{currencyIcons[order?.userCurrency]}</Text>
                        <Text m={0}> {(Number(product.quantity) * Number(product[`price${order?.userCurrency}`])).toFixed(2)}</Text>
                        </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              {/* order items for small screens and md screens */}
              <Box display={["block", "block", "none"]}>
                <Text fontSize="3xl" fontWeight="bold" mb={2} color="black.500">
                  Order Item Details
                </Text>
                <Flex direction={"column"} gap={5}>
                  {order?.cartProducts?.map((product, i) => (
                    <Flex direction={"column"} gap={3} key={i} w={"100%"} >
                      <Flex flex={"5"} justifyContent={"flex-start"} alignItems={"center"} w={["100%"]} gap={3}>
                        <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Image</Text>
                        <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Title</Text>
                        <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">Quantity </Text>
                        {/* <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">${product.priceUSD}</Text> */}
                      </Flex>
                      <Flex flex={"5"} justifyContent={"flex-start"} alignItems={"center"} w={["100%"]} gap={3}>
                        <Image flex={"1"} src={product.photos[0]} alt={product.title} m={0} w="40px" h="40px" borderRadius="md" mr={2} />
                        <Text flex={"3"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">{product.title}</Text>
                        <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold" textAlign={"center"}>{product.quantity}</Text>
                        {/* <Text flex={"1"} m={0} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontWeight="bold">${product.priceUSD}</Text> */}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Box>

              <Flex direction={"column"} justifyContent={"flex-start"} gap={3} >
                <Text fontSize="xl" fontWeight="bold" mb={2} color="black.500">
                  Shipping Details :
                </Text>
                <Flex flexWrap="wrap" direction={"row"} gap={2}  justifyContent={"flex-start"} w={"100%"}>
                  <Flex align="center" alignItems={"center"} w={"40%"}>
                    <FaTruck />
                    <Text pl={4} m={0}>Shipping Method: {order?.shippingMethod}</Text>
                  </Flex>
                  <Flex align="center" alignItems={"center"} w={"40%"} justifyContent={"center"} gap={3}>
                  <FaMoneyBillAlt />
                  <Text m={0}>
                  Shipping Charges: 
                  </Text>
                  <Text m={0}>
                  {currencyIcons[order?.userCurrency]}
                  </Text>
                  <Text m={0}>   {order?.shippingCharges}</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex w={"100%"} direction={["column", "", "column"]}>
                <Text w={["100%", "", "40%"]} fontSize="xl" fontWeight="bold" mb={2} color="black.500">
                  Order Summary
                </Text>
                <Flex w={["100%", "", "60%"]} gap={3} flexWrap="wrap">
                  <Flex align="center" mr={4}>
                    <FaTag />
                    <Text m={0} pl={2}>Total Discounts: </Text>
                    <Text m={0} pl={2}>{currencyIcons[order?.userCurrency]}</Text>
                    <Text m={0} pl={2}>{order?.discount}</Text>
                  </Flex>
                  <Flex align="center" mr={4}>
                    <FaMoneyBillAlt />
                    <Text m={0} pl={2}>Total Amount Paid: </Text>
                    <Text m={0} pl={2}> {currencyIcons[order?.userCurrency]}</Text>
                    <Text m={0} pl={2}>{order.finalAmount}</Text>
                  </Flex>
                  <Flex align="center" mr={4}>
                    <FaMoneyBillAlt />
                    <Text m={0} pl={2}>Payment Method: {order?.paymentMethod}</Text>
                  </Flex>
                  <Flex align="center">
                    <FaClock />
                    <Text m={0} pl={2}>Time of Payment: {order.timeOfPayment ? new Date(order.timeOfPayment).toLocaleString() : ''}</Text>

                  </Flex>
                </Flex>
              </Flex>
            </Stack>
          </>
        ) : (
          <Text>Loading...</Text>
        )}
      </Box>
    </>
  );
};

export default OrdersSingle;
