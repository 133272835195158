import React from "react";
import {ChakraProvider, } from "@chakra-ui/react";
import ShippingProfilesList from "./AllShippings";

const AllShippingProfiles = () => {

    return (
        <ChakraProvider>
                <ShippingProfilesList/>
        </ChakraProvider>
    );
};

export default AllShippingProfiles;
