import React, { useMemo } from 'react';
import { Box, Grid, GridItem, Heading, Text, Center, Image, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ImageGrid = () => {
    const imageUrls = useMemo(() => [
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186638/3_zw8jpj.png", 
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186637/1_mjnxme.png", 
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186637/4_xj9iia.png", 
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186524/2_x9jgzm.png", 
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186642/5_mmd6hg.png", 
        "https://res.cloudinary.com/djq1crfqa/image/upload/v1721186638/6_lrauay.png"
    ], []);

    const headingStyle = useMemo(() => ({
        color: '#6FA82F',
        fontFamily: 'DM Serif Display',
        fontSize: { base: '24px', md: '46px' },
        lineHeight: { base: '32px', md: '58px' },
        fontWeight: 400,
    }), []);

    const textStyle = useMemo(() => ({
        fontSize: { base: '14px', md: '18px' },
        lineHeight: { base: '24px', md: '28px' },
        textAlign: 'center',
    }), []);

    const gridItemStyle = useMemo(() => ({
        transition: 'transform 0.3s, box-shadow 0.3s',
        _hover: {
            transform: 'scale(1.05)',
            boxShadow: '0 0 10px rgba(111, 168, 47, 0.5)',
            borderRadius:"xl"
        },
    }), []);

    const navigate = useNavigate();

    const handleImageClick = (index) => {
        switch (index) {
            case 0:
                navigate("/products?category=Yarns&subcategories=All");
                break;
            case 1:
                navigate('/products?category=Ribbons&subcategories=All');
                break;
            case 2:
                navigate('/products?category=Fibres&subcategories=All');
                break;
            case 3:
                navigate('/products?category=Home Decor&subcategories=All');
                break;
            case 4:
                navigate('/products?category=Clothing&subcategories=All');
                break;
            case 5:
                navigate('/products?category=Scarves&subcategories=All');
                break;
            default:
                break;
        }
    };

    return (
        <Box p={"10px"}>
            <Center textAlign={"center"}>
                <Heading sx={headingStyle}>Explore Our Diverse Selection of Categories</Heading>
            </Center>
            <Center px="2">
                <Text textAlign={"center"} mt={4} sx={textStyle}>
                    Bring decadence and sophistication to apparels, accessories and your home with our range of gorgeous silk yarns and blends.
                </Text>
            </Center>

            <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                gap={8}
                px={["20px", "", ""]}
                maxWidth={"1200px"}
                margin={"auto"}
                mt={"2%"}
            >
                {imageUrls.map((imageUrl, index) => (
                    <GridItem key={index} colSpan={1} sx={gridItemStyle}>
                        <Image
                            objectFit={"fill"}
                            alt="Uploaded"
                            src={imageUrl}
                            w={"100%"}
                            loading="lazy"
                            fallback={<Skeleton height="350px" />}
                            _hover={{ cursor: "pointer" }}
                            border={"1px dotted #6FA82F"}
                            borderRadius={"xl"}
                            onClick={() => handleImageClick(index)}
                        />
                    </GridItem>
                ))}
            </Grid>
        </Box>
    );
};

export default React.memo(ImageGrid);
