import { Box, Flex, Select, Checkbox, Text, Radio } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { CiBoxList } from 'react-icons/ci';
import { IoGridOutline } from 'react-icons/io5';

const ProductsSidebar = ({ products, setProducts, setshowStyle,setCurrentPage }) => {
  const [currCategory, setCurrCategory] = useState('All');
  const [selectedSubcategories, setSelectedSubcategories] = useState("All");

  const handlePriceSortChange = (e) => {
    const value = e.target.value;
    let x = [...products];
    if (value === 'LowToHigh') {
      setProducts([...x].sort((a, b) => a.price - b.price));
    } else if (value === 'HighToLow') {
      setProducts([...x].sort((a, b) => b.price - a.price));
    }
  };

  const handleCategoryChange = (category) => {
    setCurrentPage(1);
    setCurrCategory(category);
    setSelectedSubcategories([]);
    if (category === 'All') {
      getdata();
    } else {
      fetchDataByCategory(category);
    }
  };


  let getdata = async (e) => {
    fetch('https://api.digiblocks.tech/productsWithDiscount')
      .then((response) => response.json())
      .then((data) => {
        const filteredProducts = data.filter(product => product.discount > 0);
        console.log(filteredProducts)
        setProducts(filteredProducts);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  };


  const fetchDataByCategory = async (category) => {
    try {
      const response = await axios.post(`https://api.digiblocks.tech/products/offers/filterBycategories`, {
        category: category
      });
      setProducts([...response.data]);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchDataBySubcategories = async (subcategory, category) => {
    if (!subcategory || subcategory == "All") {
      // No subcategories selected, fetch data for the entire category
      fetchDataByCategory(category);
      return;
    } else {
      try {
        const response = await axios.post(
          `https://api.digiblocks.tech/products/offers/filterBySubcategories`, {
          subcategory: subcategory, category: category
        }
        );
        setProducts([...response.data]);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }


  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategories(subcategory);
    fetchDataBySubcategories(subcategory, currCategory);
  };

  useEffect(() => {
    getdata();
  }, []);

  let [yarns, setyarns] = useState([]);
  let [blends, setblends] = useState([]);
  let [fibres, setfibres] = useState([]);
  let [ribbons, setribbons] = useState([]);
  let [scarves, setscarves] = useState([]);
  let [clothing, setclothing] = useState([]);
  let [home, sethome] = useState([]);
  let [accessories, setAccessories] = useState([]);
  let [colors, setcolors] = useState([]);

  let getsubcategoryyarns = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Yarns',
    });
    setyarns(data.data.data);
  };
  let getsubcategoraccessories = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Accessories',
    });
    setAccessories(data.data.data);
  };
  let getsubcategoryblends = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Blends',
    });
    setblends(data.data.data);
  };
  let getsubcategoryribbons = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Ribbons',
    });
    setribbons(data.data.data);
  };
  let getsubcategoryclothing = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Clothing',
    });
    setclothing(data.data.data);
  };
  let getsubcategoryscarves = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Scarves',
    });
    setscarves(data.data.data);
  };
  let getsubcategoryhome = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Home Decor',
    });
    sethome(data.data.data);
  };
  let getsubcategoryfibre = async () => {
    let data = await axios.post('https://api.digiblocks.tech/getallsubcategory', {
      category: 'Fibre',
    });
    setfibres(data.data.data);
  };

  let getallcolors = async () => {
    let res = await axios.get("https://api.digiblocks.tech/adminsetting/colors");
    setcolors(res.data)
  }


  const fetchDataByColor = async (color, category) => {
    try {
      let response;

      response = await axios.post(`https://api.digiblocks.tech/products/colors?color=${color}&category=${category}`);
      setProducts([...response.data]);
    } catch (error) {
      console.error('Error fetching products by color:', error);
    }
  };
  const handlecolorfilter = (selectedColor) => {
    // Filter products based on selected color
    if (selectedColor === 'All') {
      getdata();
    } else {
      // Call the backend route with the selected color
      fetchDataByColor(selectedColor, currCategory);
    }
  };
  useEffect(() => {
    getsubcategoryyarns();
    getsubcategoryblends();
    getsubcategoryclothing();
    getsubcategoryhome();
    getsubcategoryribbons();
    getsubcategoryfibre();
    getsubcategoryscarves();
    getsubcategoraccessories();
    getallcolors();
  }, []);
  const YarnTypes =
  {
    "Single, Cobweb, Thread, Zephyr": {
      "name": "Single, Cobweb, Thread, Zephyr",
      "properties": {
        "needleSize": "1.5 - 2.5",
        "hookSize": "1.5 - 2.5",
        "wrapsPerInch": "40+ wpi",
        "yarnCounts": "8+",
        "meterPer100g": "More than 800",
        "otherNames": "0 or Lace"
      }
    },
    "Light Fingering, Sock, Baby": {
      "name": "Light Fingering, Sock, Baby",
      "properties": {
        "needleSize": "1.5 - 2.5",
        "hookSize": "1.5 - 2.5",
        "wrapsPerInch": "30 - 40 wpi",
        "yarnCounts": "6-8",
        "meterPer100g": "600 - 800",
        "otherNames": "0 or Lace"
      }
    },
    "Fingering Sock, Baby, ": {
      "name": "Fingering Sock, Baby",
      "properties": {
        "hookSize": "2.25 - 3.5",
        "needleSize": "2.0 - 3.0",
        "wrapsPerInch": "20 - 30 wpi",
        "yarnCounts": "5-6",
        "meterPer100g": "500 - 600",
        "otherNames": "1 or Super Fine"
      }
    },
    "Light Fingering, Sock, Baby": {
      "name": "Light Fingering, Sock, Baby",
      "properties": {
        "needleSize": "2.0 - 3.0",
        "hookSize": "2.25 - 3.5",
        "wrapsPerInch": "20 - 30 wpi",
        "yarnCounts": "5-6",
        "meterPer100g": "500 - 600",
        "otherNames": "1 or Super Fine"
      }
    },
    "Sport, Baby, ": {
      "name": "Sport, Baby",
      "properties": {
        "hookSize": "3.5 - 4.5",
        "needleSize": "3.0 - 4.0",
        "wrapsPerInch": "12 - 18 wpi",
        "yarnCounts": "2.5 - 3.5",
        "meterPer100g": "250 - 350",
        "otherNames": "2 or Fine"
      }
    },
    "Light Worsted, DK": {
      "name": "Light Worsted, DK",
      "properties": {
        "hookSize": "4.5 - 5.5",
        "needleSize": "4.0 - 4.5",
        "wrapsPerInch": "11 - 15 wpi",
        "yarnCounts": "2.0 - 2.5",
        "meterPer100g": "200 - 250",
        "otherNames": "3 or Light"
      }
    },
    "Worsted, Afghan,  4-ply ": {
      "name": "Worsted, Afghan,  4-ply ",
      "properties": {
        "hookSize": "5.5 - 6.5",
        "needleSize": "4.5 - 5.5",
        "wrapsPerInch": "9 - 12 wpi",
        "yarnCounts": "1.5 - 2.0",
        "meterPer100g": "120 - 200",
        "otherNames": "4 or Medium"
      }
    },
    "Craft, Rug": {
      "name": "Craft, Rug",
      "properties": {
        "hookSize": "6.5 - 9.0",
        "needleSize": "5.5 - 8.0",
        "wrapsPerInch": "6 - 8 wpi",
        "yarnCounts": "1.0 - 1.3",
        "meterPer100g": "100 - 130",
        "otherNames": "5 or Bulky"
      }
    },
    "Roving or Super Bulky": {
      "name": "Roving or Super Bulky",
      "properties": {
        "hookSize": ">9.0",
        "needleSize": ">8.0",
        "wrapsPerInch": "5 - 6 wpi",
        "yarnCounts": "below 1.0",
        "meterPer100g": "Less than 100",
        "otherNames": "6 or Super Bulky"
      }
    },
    "Roving or Jumbo": {
      "name": "Roving or Jumbo",
      "properties": {
        "hookSize": "15 mm and larger",
        "needleSize": "12.75 mm and larger",
        "wrapsPerInch": "N/A",
        "yarnCounts": "below 1.0",
        "meterPer100g": "Less than 100",
        "otherNames": "6 or Super Bulky"
      }
    }
  }


  const handleYarnWeightFilter = (selectedYarnWeight) => {
    // Filter products based on selected yarn weight
    if (selectedYarnWeight === 'All') {
      getdata();
    } else {
      // Call the backend route with the selected yarn weight
      fetchDataByYarnWeight(selectedYarnWeight, currCategory);
    }
  };

  const fetchDataByYarnWeight = async (yarnWeight, category) => {
    try {
      const response = await axios.get(`https://api.digiblocks.tech/filterbyyarn?yarnWeight=${yarnWeight}&category=${category}`);
      setProducts([...response.data]);
    } catch (error) {
      console.error('Error fetching products by yarn weight:', error);
    }
  };
  return (
    <Box display={['none', 'none', 'block']}>
      <Flex direction="column" w={'100%'} justifyContent={'flex-start'} gap={2}>
        <Text fontSize={'22px'} fontWeight={'bolder'}>
          Apply Filters
        </Text>
        <Flex justifyContent={'space-between'} alignItems={'center'} border={'1px solid gray'} p={2} gap={4}>
          <Text m={0} fontWeight={500}>
            View in
          </Text>
          <Box display={'flex'}>
            <Box ml={4} cursor={'pointer'} onClick={() => setshowStyle('list')}>
              <CiBoxList size={'30px'} />
            </Box>
            <Box ml={4} cursor={'pointer'} onClick={() => setshowStyle('grid')}>
              <IoGridOutline size={'30px'} />
            </Box>
          </Box>
        </Flex>

        <Flex direction={'column'} w={'100%'}>
          <Text fontWeight={500}  mt={2}>
            Sort By Price :
          </Text>
          <Select onChange={handlePriceSortChange}>
            <option value="LowToHigh">Low To High</option>
            <option value="HighToLow">High To Low</option>
          </Select>
        </Flex>
        <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
          <Text fontWeight={500}  m={0}>
            Filter By Category :
          </Text>
          {['All', 'Yarns', 'Fibres', 'Ribbons', 'Clothing', 'Scarves', 'Home Decor', 'Accessories'].map((category) => (
            <Checkbox
              key={category}
              isChecked={currCategory === category}
              onChange={() => handleCategoryChange(category)}
              colorScheme="green"
            >
              {category}
            </Checkbox>
          ))}
        </Flex>
        {/* Yarns */}
        {currCategory === 'Yarns' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Yarn Category
            </Text>
            {yarns.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Fibres */}
        {currCategory === 'Fibres' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Fibres Category
            </Text>
            {fibres.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Ribbons */}
        {currCategory === 'Ribbons' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Ribbons Category
            </Text>
            {ribbons.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Clothing */}
        {currCategory === 'Clothing' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Clothing Category
            </Text>
            {clothing.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Scarves */}
        {currCategory === 'Scarves' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text  fontWeight={500} m={0}>
              Scarves Category
            </Text>
            {scarves.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Home Decor */}
        {currCategory === 'Home Decor' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Home Decor Category
            </Text>
            {home.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}
        {/* Accessories */}
        {currCategory === 'Accessories' && (
          <Flex direction={'column'} gap={2} w={'100%'} p={5} pl={0}>
            <Text fontWeight={500} m={0}>
              Accessories Category
            </Text>
            {accessories.map((e, i) => (
              <Radio
                key={e.subcategories}
                isChecked={selectedSubcategories === e.subcategories}
                onChange={() => handleSubcategoryChange(e.subcategories)}
                colorScheme="green"
              >
                {e.subcategories}
              </Radio>
            ))}
          </Flex>
        )}

        <>
          <Text fontWeight={500} m={0}>
            Filter By Color :
          </Text>
          <Select onChange={(e) => handlecolorfilter(e.target.value)}>
            <option value={"All"}>All</option>
            {
              colors.map((e) => {
                return <>
                  <option value={e.colors}>{e.colors}</option>
                </>
              })
            }
          </Select>
        </>


        <>
          <Text fontWeight={500} m={0}>
            Filter By Yarn Weight:
          </Text>
          <Select onChange={(e) => handleYarnWeightFilter(e.target.value)}>
            <option value="All">All</option>
            {Object.keys(YarnTypes).map((yarnWeight) => (
              <option key={yarnWeight} value={yarnWeight}>
                {yarnWeight}
              </option>
            ))}
          </Select>
        </>
      </Flex>
    </Box>
  );
};

export default ProductsSidebar;
