import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Heading,
    VStack,
    HStack,
    Input,
    Textarea,
    Select,
    Button,
    useToast,
    FormControl,
    FormLabel,
    Spinner,
    InputGroup,
    Flex,
    InputRightElement,
    Text,
    SimpleGrid,
} from '@chakra-ui/react';
import { FaLongArrowAltLeft } from "react-icons/fa";
import {
    FaRupeeSign,
    FaDollarSign,
    FaPoundSign,
    FaEuroSign,
    FaYenSign,
} from 'react-icons/fa';
import YarnTypes from './YarnTypes.js';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminNav from './AdminNav';
import { DeleteIcon } from '@chakra-ui/icons';

const EditDraft = () => {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [originalProduct, setOriginalProduct] = useState({});
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();
    const [cat, setCat] = useState([]);
    const [subCat, setsubCat] = useState([]);
    const [allColors, setallColors] = useState([]);
    const [allUnits, setAllUnits] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/colors');
                const colorsData = response.data;

                setallColors(colorsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/categories');
                const categoriesData = response.data;
                setCat(categoriesData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/subcategories');
                const subcategoriesData = response.data;
                setsubCat(subcategoriesData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/units');
                const unitsData = response.data;
                setAllUnits(unitsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get(`https://api.digiblocks.tech/drafts/products/${id}`);
                setPhotos([...response.data.photos]);
                setProduct(response.data);
                setOriginalProduct(response.data);
            } catch (error) {
                console.error('Error fetching product details:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch product details',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductDetails();
    }, [id, toast]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleCancel = () => {
        setProduct(originalProduct);
    };

    const handlePublish = async () => {
        try {
            await axios.post(`https://api.digiblocks.tech/products/`, product);
            const productId = id;
            // Make a DELETE request to remove the product from drafts
            await axios.delete(`https://api.digiblocks.tech/drafts/products/${productId}`);
            toast({
                title: 'Success',
                description: 'Product updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/manager/listing')
        } catch (error) {
            console.error('Error updating product:', error);
            toast({
                title: 'Error',
                description: 'Failed to update product',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSaveChanges = async () => {
        try {
            const productId = id;
            await axios.put(`https://api.digiblocks.tech/drafts/products/${productId}`, product);
            toast({
                title: 'Success',
                description: 'Product updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/manager/alldrafts');
        } catch (error) {
            console.error('Error updating product:', error);
            toast({
                title: 'Error',
                description: 'Failed to update product',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }

    }

    const removeImage = (i, photo) => {
        // Create a copy of the current photos array
        const updatedPhotos = [...photos];
        updatedPhotos.splice(i, 1);
        setPhotos(updatedPhotos);
        setProduct({ ...product, photos: updatedPhotos });
    };


    // Primary (Main) Image image
    const onPhotoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [response.data.photos[0].url, ...product.photos.slice(1)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop1 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], response.data.photos[0].url, ...product.photos.slice(2)]
                });

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop2 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], response.data.photos[0].url, ...product.photos.slice(3)]
                });

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop3 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], response.data.photos[0].url, ...product.photos.slice(4)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop4 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], response.data.photos[0].url, ...product.photos.slice(5)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop5 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], product.photos[4], response.data.photos[0].url, ...product.photos.slice(6)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop6 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], product.photos[4], product.photos[5], response.data.photos[0].url, ...product.photos.slice(7)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop7 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], product.photos[4], product.photos[5], product.photos[6], response.data.photos[0].url, ...product.photos.slice(8)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop8 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], product.photos[4], product.photos[5], product.photos[6], product.photos[7], response.data.photos[0].url, ...product.photos.slice(9)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const onPhotoDrop9 = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Please insert upto 1 image files")
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({
                    ...product,
                    photos: [product.photos[0], product.photos[1], product.photos[2], product.photos[3], product.photos[4], product.photos[5], product.photos[6], product.photos[7], product.photos[8], response.data.photos[0].url, ...product.photos.slice(10)]
                });
            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };

    const onVideoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Maximum 1 video can be uploaded!")
        }
        else {

            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('videos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/videos', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({ ...product, video: response.data.videos[0].url });
            } catch (error) {
                console.error('Error uploading videos:', error);
            }
        }

    };

    const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
        onDrop: onPhotoDrop,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps1, getInputProps: photoInputProps1 } = useDropzone({
        onDrop: onPhotoDrop1,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps2, getInputProps: photoInputProps2 } = useDropzone({
        onDrop: onPhotoDrop2,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps3, getInputProps: photoInputProps3 } = useDropzone({
        onDrop: onPhotoDrop3,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps4, getInputProps: photoInputProps4 } = useDropzone({
        onDrop: onPhotoDrop4,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps5, getInputProps: photoInputProps5 } = useDropzone({
        onDrop: onPhotoDrop5,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps6, getInputProps: photoInputProps6 } = useDropzone({
        onDrop: onPhotoDrop6,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps7, getInputProps: photoInputProps7 } = useDropzone({
        onDrop: onPhotoDrop7,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps8, getInputProps: photoInputProps8 } = useDropzone({
        onDrop: onPhotoDrop8,
        accept: 'image/*',
    });
    const { getRootProps: photoRootProps9, getInputProps: photoInputProps9 } = useDropzone({
        onDrop: onPhotoDrop9,
        accept: 'image/*',
    });

    const { getRootProps: videoRootProps, getInputProps: videoInputProps } = useDropzone({
        onDrop: onVideoDrop,
        accept: 'video/*',
    });
    const dropzoneStyles = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    return (
        <>
            <AdminNav />
            <Box ml={["", "", "250px"]} p={4}>
                <Flex justifyContent={"space-between"}> <Heading as={"h3"}>Edit Draft</Heading>
                    <HStack spacing={2}>
                        <Button
                            color="white"
                            bg="black"
                            borderRadius={"none"}
                            _hover={{ bg: "black", color: "white" }}
                            leftIcon={<FaLongArrowAltLeft />}
                            onClick={() => navigate(`/manager/alldrafts`)}
                        >
                            Back To Drafts
                        </Button>
                    </HStack>
                </Flex>
                {isLoading ? (
                    <Spinner color='green' size="xl" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : (
                    <VStack spacing={4} mt={4} align="stretch">
                        {/* Basic Info */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}> Basic Information</Heading>
                            <FormControl>
                                <FormLabel>Title</FormLabel>
                                <Input name="title" value={product.title} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea value={product.description} name="description" onChange={handleInputChange} />
                            </FormControl>
                        </Box>

                        {/* Categorization */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Categorization</Heading>
                            <HStack spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Category</FormLabel>
                                    <Select name="category" value={product.category} onChange={handleInputChange}>
                                        <option value="">Select A Category </option>
                                        {cat.map((color) => (
                                            <option key={color} value={color.categories}>{color.categories.charAt(0).toUpperCase() + color.categories.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Sub-Category</FormLabel>
                                    <Select name="subCategory" value={product.subCategory} onChange={handleInputChange}>
                                        <option value="">Select A SubCategory </option>
                                        {subCat.map((color) => (
                                            <option key={color} value={color.subcategories}>{color.subcategories.charAt(0).toUpperCase() + color.subcategories.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl flex={2}>
                                    <FormLabel>Home Page Section</FormLabel>
                                    <Select name="shopSection" value={product.shopSection} onChange={handleInputChange}>
                                        <option value="">Select A Shop Section</option>
                                        <option value="BestSellers">Best Sellers - Loved by all </option>
                                        <option value="Featured">Featured - Crafted for you</option>
                                        <option value="NewArrivals">New Arrivals - New & now</option>
                                        <option value="MostViewed">Most Viewed - Seen by many</option>
                                        <option value="SpotlightDeals">Spotlight Deals
                                        </option>
                                    </Select>
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Delivery Option</FormLabel>
                                    <Select name="deliveryOption" value={product.deliveryOption} onChange={handleInputChange}>
                                        <option value="Option1">Option1</option>
                                        <option value="Option2">Option2</option>
                                        <option value="Option3">Option3</option>
                                        <option value="Option4">Option4</option>
                                        <option value="Option5">Option5</option>
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Item Weight</FormLabel>
                                    <Input name="itemWeight" value={product.itemWeight} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Item Size</FormLabel>
                                    <Input name="itemSize" value={product.itemSize} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <FormControl flex={2}>
                                <FormLabel>Product Dimensions (LxWxH in mm)</FormLabel>
                                <Input name="productDimensions" value={product.productDimensions} onChange={handleInputChange} />
                            </FormControl>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Manufacturer</FormLabel>
                                    <Input name="manufacturer" value="M.Jiju Silk Mills" onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Package Weight</FormLabel>
                                    <Input name="packageWeight" value={product.packageWeight} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Package Dimensions  (LxWxH in mm)</FormLabel>
                                    <Input name="packageDimensions" value={product.packageDimensions} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                        </Box>


                        {/* Pricing */}
                        <>
                            <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                                <Heading fontSize={"24px"}>Pricing</Heading>

                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Price in INR</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceINR" value={product.priceINR} onChange={handleInputChange} />
                                            <InputRightElement children={<FaRupeeSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in USD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" value={product.priceUSD} name="priceUSD" onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in GBP</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceGBP" value={product.priceGBP} onChange={handleInputChange} />
                                            <InputRightElement children={<FaPoundSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in EUR</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceEUR" value={product.priceEUR} onChange={handleInputChange} />
                                            <InputRightElement children={<FaEuroSign />} />
                                        </InputGroup>
                                    </FormControl>
                                </HStack>
                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Price in CAD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" value={product.priceCAD} name="priceCAD" onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in AUD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceAUD" value={product.priceAUD} onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in JPY</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceJPY" value={product.priceJPY} onChange={handleInputChange} />
                                            <InputRightElement children={<FaYenSign />} />
                                        </InputGroup>
                                    </FormControl>
                                </HStack>
                            </Box>
                        </>
                        {/* Inventory */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Inventory</Heading>
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Color</FormLabel>
                                    <Select
                                        name="color"
                                        value={product.color}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Color</option>
                                        {allColors.map((color) => (
                                            <option key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Listing Status</FormLabel>
                                    <Select name="listingStatus" value={product.listingStatus} onChange={handleInputChange}>
                                        <option value="Active">Active</option>
                                        <option value="Sold out">Sold out</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Expired">Expired</option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Quantity In Stock</FormLabel>
                                    <Input type="number" name="qtyInStock" value={product.qtyInStock} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                        </Box>
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"20px"}>
                            <FormControl>
                                <Heading fontSize={"24px"}>Media</Heading>
                                <SimpleGrid gap={5} columns={3} spacing={4}>
                                    {product.photos && product.photos.map((photo, index) => (
                                        <Box key={index}>

                                            {index === 0 ? <Text fontSize={"22px"} fontWeight={500}>Primary (Main) Image Photo</Text> : <></>}

                                            <Text fontWeight={"bold"}>{index + 1}. Image</Text>
                                            <img src={photo} alt={`Product Photo ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                            <Button onClick={() => {
                                                removeImage(index, photo)
                                            }}><DeleteIcon></DeleteIcon></Button>
                                            <>

                                            </>
                                        </Box>
                                    ))}

                                </SimpleGrid>

                                <FormLabel>Upload upto 10 images & 1 video</FormLabel>
                                <>
                                    <div>
                                        <SimpleGrid gap={5} columns={3}>
                                            {/* 0 */}
                                            <Box border={"1px dashed red"}>
                                                <Text m={0}> Primary (Main) Image</Text>
                                                <Text m={0}> 1. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps()} />
                                                    <Text>Drag & drop some photos here, or click to select photos </Text>
                                                </Box>
                                            </Box>

                                            {/* 1 */}
                                            <Box>
                                                <Text m={0}> 2. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps1()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps1()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>

                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>3. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps2()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps2()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>

                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>4. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps3()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps3()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>5. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps4()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps4()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>6. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps5()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps5()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>7. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps6()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps6()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>8. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps7()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps7()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>9. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps8()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps8()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>
                                            {/* 2 */}
                                            <Box>
                                                <Text m={0}>10. Image</Text>
                                                <Box mr={2} w={"100%"} {...photoRootProps9()} style={dropzoneStyles}>
                                                    <input  {...photoInputProps9()} />
                                                    <Text>Drag & drop some photos here, or click to select photos</Text>
                                                </Box>
                                            </Box>

                                        </SimpleGrid>
                                        <>

                                        </>

                                    </div>
                                </>
                            </FormControl>
                            {/* Display Video */}
                            <FormControl>
                                <FormLabel>Video</FormLabel>
                                <InputGroup width="100%" as={VStack}>
                                    <Input
                                        name="video"
                                        value={product.video}
                                        onChange={handleInputChange}
                                        placeholder="Enter Video URL"
                                    />
                                    <div {...videoRootProps()} style={dropzoneStyles}>
                                        <input {...videoInputProps()} />
                                        <p>To Replace/Upload video Drag & drop a video here, or click to select a video</p>
                                    </div>
                                    <InputRightElement width="4.5rem">
                                        <Button
                                            as="a"
                                            href={product.video}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            size="md"
                                            fontSize="sm"
                                            colorScheme="blue"
                                            variant="outline"
                                        >
                                            View
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </Box>


                        {/* Attributes */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Attributes</Heading>
                            <HStack mt={2} spacing={4}>
                                <FormControl>
                                    <FormLabel>Primary Color</FormLabel>
                                    <Select
                                        name="primaryColor"
                                        value={product.primaryColor}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select a Color</option>
                                        {allColors.map((color) => (
                                            <option key={color._id} value={color.colors}>
                                                {color.colors}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Secondary Color</FormLabel>
                                    <Select
                                        name="secondaryColor"
                                        onChange={handleInputChange}
                                        value={product.secondaryColor}
                                    >
                                        <option value="">Select a Color</option>
                                        {allColors.map((color) => (
                                            <option key={color._id} value={color.colors}>
                                                {color.colors}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Number Of Skeins</FormLabel>
                                    <Input name="skeins" value={product.skeins} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl>
                                    <FormLabel>Length</FormLabel>
                                    <Input
                                        type="number"
                                        name="length"
                                        value={product.length}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Length Unit</FormLabel>
                                    <Select
                                        name="lengthUnit"
                                        value={product.lengthUnit}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Unit </option>
                                        {allUnits.map((color) => (
                                            <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Width</FormLabel>
                                    <Input
                                        type="number"
                                        name="width"
                                        value={product.width}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Width Unit</FormLabel>
                                    <Select
                                        name="widthUnit"
                                        onChange={handleInputChange}
                                        value={product.widthUnit}
                                    >
                                        <option value="">Select Unit </option>
                                        {allUnits.map((color) => (
                                            <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </HStack>

                            <HStack
                                mt={5}
                                spacing={{ base: 4, md: 8 }}
                                justify="center"
                                align="center"
                                w="100%"
                            >
                                <FormControl>
                                    <FormLabel fontSize="md">Allow Personalization for buyers</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.personalization}
                                        onChange={(e) => handleInputChange(e)}
                                        name="personalization"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="md">Hand Painted or Dyed</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.handPaintedOrDyed}
                                        onChange={(e) => handleInputChange(e)}
                                        name="handPaintedOrDyed"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>
                            </HStack>

                            <HStack mt={3} spacing={4}>
                                <FormControl>
                                    <FormLabel fontSize="md">Pleated or Ruffled</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.pleatedOrRuffled}
                                        onChange={(e) => handleInputChange(e)}
                                        name="pleatedOrRuffled"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="md">Wired</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.wired}
                                        onChange={(e) => handleInputChange(e)}
                                        name="wired"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontSize="md">Cut to Size</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.cutToSize}
                                        onChange={(e) => handleInputChange(e)}
                                        name="cutToSize"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>

                            </HStack>

                        </Box>

                        {/* Technical Details */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Technical Details</Heading>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Primary Material</FormLabel>
                                    <Input name="primaryMaterial" value={product.primaryMaterial} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Secondary Material</FormLabel>
                                    <Input name="secondaryMaterial" value={product.secondaryMaterial} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Yarn Weight</FormLabel>
                                    <Select
                                        name="yarnWeight"
                                        value={product.yarnWeight}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select An Yarn Weight Option</option>
                                        {Object.keys(YarnTypes).map((key) => (
                                            <option key={key} value={key}>
                                                {YarnTypes[key].name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Wraps Per Inch</FormLabel>
                                    <Input name="wrapsPerInch" value={product.wrapsPerInch} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Yarn Counts (NM)</FormLabel>
                                    <Input name="yarnCounts" value={product.yarnCounts} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>m/100g</FormLabel>
                                    <Input name="meterPer100g" value={product.meterPer100g} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack spacing={4} mt={2}>
                                <FormControl flex={2}>
                                    <FormLabel>Other Names</FormLabel>
                                    <Input name="otherNames" value={product.otherNames} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Wash care</FormLabel>
                                    <Select name="care" value={product.care} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="machine-wash">Machine Wash</option>
                                        <option value="hand-wash">Hand Wash</option>
                                        <option value="dry-clean">Dry Clean</option>
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Needle Size (mm)</FormLabel>
                                    <Input name="needleSize" value={product.needleSize} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Hook Size (mm)</FormLabel>
                                    <Input name="hookSize" value={product.hookSize} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Yardage</FormLabel>
                                    <Input type="number" name="yardage" value={product.yardage} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Type (Make Content)</FormLabel>
                                    <Input name="makeContent" value={product.makeContent} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>HSN Code</FormLabel>
                                    <Input name="HSNCode" value={product.HSNCode} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                        </Box>



                        {/* Submit button */}
                        <HStack spacing={4} mt={4} justifyContent="space-between">
                            <Button
                                borderRadius={"none"}
                                bg={"black"}
                                onClick={handleCancel}
                                color={"white"}
                                _hover={{ bg: "black", color: "white" }}
                            >
                                Cancel
                            </Button>

                            <Button
                                borderRadius={"none"}
                                onClick={handleSaveChanges}
                                colorScheme={"orange"}
                                _hover={{ bg: "black", color: "white" }}
                            >
                                Save Changes
                            </Button>

                            <HStack spacing={4}>
                                <Button
                                    // width={"15%"}
                                    borderRadius={"none"}
                                    colorScheme="green"
                                    onClick={handlePublish}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Publish
                                </Button>
                            </HStack>
                        </HStack>
                    </VStack>
                )}
            </Box>
        </>
    );
};

export default EditDraft;