import { Box, Center, Heading, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StripeSuccess = () => {
  const [totalPrice, setTotalPrice] = useState(0);

  let getDraft = async () => {
    let userToken = localStorage.getItem('userToken');
    try {
      let response = await axios.get('https://api.digiblocks.tech/order/draft', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      let em = response.data[0]?.orderdetails?.orderDetails?.customerInfo?.shipping?.email;
      let nm = response.data[0]?.orderdetails?.orderDetails?.customerInfo?.shipping?.customerName;


      let arr = response.data[0]?.orderdetails?.orderDetails?.customerInfo?.checkoutDetails?.cartProducts;

      let ttotalPrice = arr.reduce((acc, product) => acc + (product.priceUSD*product.quantity), 0);
      // console.log(response.data[0]?.orderdetails?.orderDetails?.shippingCharges)
      ttotalPrice+=response.data[0]?.orderdetails?.orderDetails?.shippingCharges;
      // ttotalPrice-=response.data[0]?.orderdetails?.orderDetails?.
      // console.log(response.data[0]?.orderdetails?.orderDetails)
      setTotalPrice(ttotalPrice);

      sendEmailOnPaymentSuccess(em, nm, arr, ttotalPrice);

      storeInDB(response.data[0]?.orderdetails, ttotalPrice);

    } catch (error) {
      console.error('Error fetching drafts:', error);
    }
  };

  let storeInDB = async (draft, totalPrice) => {
    // console.log(totalPrice)
    let time = new Date().toLocaleString();
    let data = { ...draft, paymentMethod: 'Stripe', timeOfPayment: time, userCurrency:"USD", finalAmount: totalPrice };

    let userToken = localStorage.getItem('userToken');
    await axios.post('https://api.digiblocks.tech/order', { data }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

  };

  const sendEmailOnPaymentSuccess = async (em, nm, arr, ttotalPrice) => {
    let mailEndpoint = 'https://api.digiblocks.tech/sendOrderMail';
    let mailData = {
      userName: nm,
      userEmail: em,
      orderedProducts: arr,
      totalPrice: ttotalPrice
    };

    axios.post(mailEndpoint, mailData)
      .then((response) => {
        // console.log('Mail sent successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error sending mail:', error);
      });
  }
  useEffect(() => {
    getDraft();
  }, []);

  return (
    <Center h="100vh">
      <Box textAlign="center">
        <Heading as="h4" size="lg" mb="4">Payment Successful</Heading>
        <Link href="/" color="blue.500" fontSize="lg" mb="4">
          Back to Home
        </Link>
      </Box>
    </Center>
  );
};

export default StripeSuccess;