import React from 'react';
import {
    Box,
    Container,
    Text,
    VStack,
    Link as ChakraLink,
    Heading,
    ChakraProvider,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Footer from '../components/HomePageComponents/Footer';
import Nav from '../components/HomePageComponents/Nav';
import TopBanner from '../components/HomePageComponents/TopBanner';
import { Link } from 'react-router-dom';
import UserChatButton from '../components/Chat/ChatButton'

const Disclaimer = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Disclaimer" content="KnitSilk | Disclaimer" />
                <title>KnitSilk | Disclaimer</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Box py="8" backgroundColor="#f6faf2">
                <Container maxW="1200px">
                    <VStack spacing="4">
                        <Heading
                            as="h2"
                            fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                        >
                            Disclaimer
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Though all efforts have been made to ensure the accuracy and currency of the content
                            on this Portal, the same should not be construed as a statement of law or used for
                            any legal purposes. Knitsilk accepts no responsibility in relation to the accuracy,
                            completeness, usefulness or otherwise, of the contents. Users are advised to verify/check
                            any information with the relevant source(s), and to obtain any appropriate professional
                            advice before acting on the information provided in the Portal.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            In no event will Knitsilk be liable for any expense, loss or damage including, without
                            limitation, indirect or consequential loss or damage, or any expense, loss or damage
                            whatsoever arising from use, or loss of use, of data, arising out of or in connection
                            with the use of this Portal.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Links to other websites that have been included on this Portal are provided for public
                            convenience only. Knitsilk is not responsible for the contents or reliability of linked
                            websites and does not necessarily endorse the view expressed within them. We cannot
                            guarantee the availability of such linked pages at all times.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Material featured on this Portal may be reproduced free of charge after taking proper
                            permission by sending a mail to us. However, the material has to be reproduced accurately
                            and not to be used in a derogatory manner or in a misleading context. Wherever the material
                            is being published or issued to others, the source must be prominently acknowledged.
                            However, the permission to reproduce this material shall not extend to any material which is
                            identified as being copyright of a third party. Authorization to reproduce such material must
                            be obtained from the departments/copyright holders concerned.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            These terms and conditions shall be governed by and construed in accordance with the
                            Indian Laws. Any dispute arising under these terms and conditions shall be subject to the
                            exclusive jurisdiction of the courts of India.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If you have any questions or concerns, please{' '}
                            <ChakraLink as={Link} to="/contact" color="#6FA82F" textDecoration="underline">
                                contact us
                            </ChakraLink>
                            .
                        </Text>
                    </VStack>
                </Container>
            </Box>
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    );
};

export default Disclaimer;
