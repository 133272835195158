import { Box, Flex, Heading, Text, Link, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import axios from 'axios';

const CashfreeSuccess = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [orderPaid, setOrderPaid] = useState(false);
  const [error, setError] = useState('');
  const [orderStored, setOrderStored] = useState(false);

  const getOrderStatus = async () => {
    let cashfreeStatusID = localStorage.getItem('cashfreeStatus');
    try {
      let response = await axios.get(`https://api.digiblocks.tech/api/cashfree/order/${cashfreeStatusID}`);
      return response?.data?.order_status;
    } catch (error) {
      console.error('Error fetching order status:', error);
      setError('Unable to verify order status. Please try again.');
      setLoading(false);
      return null;
    }
  };

  const getDraftAndStoreInDB = async () => {
    let userToken = localStorage.getItem('userToken');
    let currentOrderID = localStorage.getItem('currentOrderID');

    try {
      let response = await axios.get('https://api.digiblocks.tech/order/draft', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.data && response.data.length > 0) {
        let draftData = response.data[0];
        let ttotalPrice = draftData?.finalAmount;
        setTotalPrice(ttotalPrice);

        draftData.paymentMethod = "Cashfree";

        if (currentOrderID && currentOrderID === draftData._id) {
          toast({
            title: "Order Already Processed",
            description: "This order has already been processed. Please check your email for confirmation.",
            status: "info",
            duration: 6000,
            isClosable: true,
            position: "bottom"
          });
          setLoading(false);
          return;
        }

        let storeResponse = await axios.post('https://api.digiblocks.tech/order', draftData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        const { orderID } = storeResponse.data;

        // Mark the order as processed
        localStorage.setItem('currentOrderID', draftData._id);

        await axios.post('https://api.digiblocks.tech/sendOrderMail', { orderID });

        await axios.post('https://api.digiblocks.tech/removeitems', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        setOrderStored(true);
        setLoading(false);
      } else {
        console.error('No draft data available.');
        setError('No draft data available.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error performing operations:', error);
      setError('Error performing operations. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAndProcessOrder = async () => {
      setLoading(true);
      const orderStatus = await getOrderStatus();
      console.log("orderStatus" + orderStatus)
      if (orderStatus === 'PAID') {
        setOrderPaid(true);
        await getDraftAndStoreInDB();
      } else {
        setOrderPaid(false);
        setError('Payment not confirmed. Please try again.');
        setLoading(false);
      }
    };

    checkAndProcessOrder();

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Flex align="center" justify="center" h="100vh">
      <Box textAlign="center" p={8} bg="white" rounded="md" shadow="md">
        {orderPaid ? (
          <>
            <Heading as="h4" size="lg" mb={4}>
              <CheckCircleIcon color="green.500" mr={2} />
              Payment Successful 🎉
            </Heading>
            <Text fontSize="lg" mb={4}>Thank you for your order! Please check your email for confirmation.</Text>
            <Text fontSize="lg" mb={4}>Total Price: {totalPrice} 💰</Text>
            <Link
              href="/"
              color="blue.500"
              fontSize="lg"
              pointerEvents={orderStored ? 'auto' : 'none'}
              opacity={orderStored ? 1 : 0.5}
            >
              Back to Home 🏠
            </Link>
          </>
        ) : (
          <>
            <WarningIcon color="red.500" w={6} h={6} mb={4} />
            <Text fontSize="lg" color="red.500" mb={4}>{error}</Text>
            <Link href="/" color="blue.500" fontSize="lg">
              Back to Home 🏠
            </Link>
          </>
        )}
        {loading && (
          <Flex align="center" justify="center" mt={4}>
            <Spinner size="lg" />
            <Text ml={2}>Loading...</Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default CashfreeSuccess;
