import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Icon, Avatar, useDisclosure } from '@chakra-ui/react';
import { FaLock, FaShoppingBag, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ChatWithAdmin from '../../pages/ChatWithAdmin';
import DogsComponent from '../DogsComponent';
import axios from 'axios'

const Dashboard = () => {
  const MotionBox = motion(Box);
  const userToken = localStorage.getItem('userToken');
  const navigate = useNavigate();
  const { isOpen: customIsOpen, onOpen: customOpenChat, onClose: customCloseChat } = useDisclosure();
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");


  let getUserDetails = async () => {
    try {
      let token = localStorage.getItem('userToken');
      const response = await axios.get('https://api.digiblocks.tech/getuserdetails', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      getUserData(response.data.userID);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  let getUserData = async (id) => {
    let response = await axios.get(`https://api.digiblocks.tech/getuser/${id}`);
    // console.log(response.data);
    if (response.data.name) {
      setName(response.data?.name);
      setEmail(response.data?.email);
    } else {
      let x = JSON.parse(localStorage.getItem("googleuser"))
      let usernamee = x?.name;
      let userEmaill = x?.email;
      setName(usernamee);
      setEmail(userEmaill);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, [])
  return (
    <>
      {
        userToken ? (
          <>
            {/* Feature Boxes */}

            <Box
              border="1px solid #E2E8F0"
              borderRadius="md"
              boxShadow="md"
              p="20px"
              width={["100%", "80%", "65%"]}
              m="auto"
              mt="6"
            >
              <Flex align="center" gap="20px">
                <Avatar size="md" name={name} />
                <Flex direction="column" gap="10px">
                  <Flex align="center" gap="5px">
                    <Text fontSize="md">{name}</Text>
                  </Flex>
                  <Flex align="center" gap="5px">
                    <Text fontSize="md">{email}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify="space-around"
              align="center"
              flexWrap="wrap"
              p={["", "", "4"]}
              margin={"auto"}
              w={{ base: '100%', md: '55%', lg: '70%' }}
            >
              {/* Your Orders */}
              <MotionBox
                p={6}
                m={4}
                w={{ base: '80%', md: '45%', lg: '20%' }}
                borderRadius="lg"
                boxShadow="md"
                bg="orange.100"
                onClick={() => navigate('/my-account/my-orders')}
                whileHover={{ scale: 1.05, boxShadow: 'lg' }}
                transition={{ duration: 0.3 }}
              >
                <Icon as={FaShoppingBag} boxSize={8} color="orange.500" mb={4} />
                <Text fontFamily='DM Serif Display' fontSize="lg" fontWeight="bold">
                  Your Orders
                </Text>
              </MotionBox>

              {/* Your Addresses */}
              <MotionBox
                p={6}
                m={4}
                w={{ base: '80%', md: '45%', lg: '20%' }}
                borderRadius="lg"
                boxShadow="md"
                bg="purple.100"
                onClick={() => navigate('/my-account/my-addresses')}
                whileHover={{ scale: 1.05, boxShadow: 'lg' }}
                transition={{ duration: 0.3 }}
              >
                <Icon as={FaMapMarkerAlt} boxSize={8} color="purple.500" mb={4} />
                <Text fontFamily='DM Serif Display' fontSize="lg" fontWeight="bold">
                  Your Addresses
                </Text>
              </MotionBox>

              {/* Login & Security */}
              <MotionBox
                p={6}
                m={4}
                w={{ base: '80%', md: '45%', lg: '20%' }}
                borderRadius="lg"
                boxShadow="md"
                onClick={customOpenChat}
                bg="teal.100"
                whileHover={{ scale: 1.05, boxShadow: 'lg' }}
                transition={{ duration: 0.3 }}
              >
                <Icon as={FaLock} boxSize={8} color="teal.500" mb={4} />
                <ChatWithAdmin customIsOpen={customIsOpen} customOnClose={customCloseChat} />
              </MotionBox>

              {/* Contact Us */}
              <MotionBox
                p={6}
                m={4}
                w={{ base: '80%', md: '45%', lg: '20%' }}
                borderRadius="lg"
                boxShadow="md"
                bg="blue.100"
                onClick={() => navigate('/contact')}
                whileHover={{ scale: 1.05, boxShadow: 'lg' }}
                transition={{ duration: 0.3 }}
              >
                <Icon as={FaPhone} boxSize={8} color="blue.500" mb={4} />
                <Text fontFamily='DM Serif Display' fontSize="lg" fontWeight="bold">
                  Contact Us
                </Text>
              </MotionBox>
            </Flex>
          </>
        ) : (
          <>
            <Text textAlign="center" mt="4" onClick={() => navigate("/login")} cursor={"pointer"} fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
              Please Login First !!!
            </Text>
            <DogsComponent />
          </>
        )}
      );
    </>
  );
};

export default Dashboard;
