import React from 'react';
import { Helmet } from 'react-helmet';
import ContactusComponent from '../components/ContactPageComponents/ContactUs';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import UserChatButton from '../components/Chat/ChatButton'

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Contact Us" content="KnitSilk | Contact Us" />
                <title>KnitSilk | Contact Us</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <ContactusComponent />
            <UserChatButton/>
            <Footer />
        </>
    );
}

export default ContactPage;
