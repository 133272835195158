import { Flex, Button, Text, VStack, HStack, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function WithBackgroundImage() {
   const navigate = useNavigate();
    return (
        <Flex
            w="full"
            h="100vh"
            backgroundImage="url(https://img.freepik.com/free-photo/sewing-threads-closeup-multicolored_53876-14697.jpg?w=2000&t=st=1698122551~exp=1698123151~hmac=9a251b7aee6f9c89eeb77cd723b0e7b542c1796a177f0d9bd1ad1ef33922108a)"
            backgroundSize="cover"
            backgroundPosition="center center"
        >
            <VStack
                w="full"
                justify="center"
                px={useBreakpointValue({ base: 4, md: 8 })}
                bgGradient="linear(to-r, blackAlpha.600, transparent)"
                color="white"
            >
                <VStack maxW="2xl" align="flex-start" spacing={6}>
                    <Text
                        lineHeight={1.2}
                        fontFamily="DM Serif Display"
                        fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}
                    >
                        Knitsilk is a distinguished brand under the ownership of M. Jiju Silk Mills, a renowned manufacturer with an unwavering legacy in the silk industry.
                    </Text>
                    <HStack spacing={4}>
                        <Button
                            bg="#232424"
                            borderRadius={0}
                            color="white"
                            _hover={{ bg: '#6FA82F' }}
                            onClick={() => navigate('/contact')}
                        >
                            Contact Us
                        </Button>
                        <Button
                            bg="whiteAlpha.300"
                            borderRadius={0}
                            color="white"
                            _hover={{ bg: 'whiteAlpha.500' }}
                            onClick={() => navigate('/products')}
                        >
                            Explore our Products
                        </Button>
                    </HStack>
                </VStack>
            </VStack>
        </Flex>
    );
}
