import React, { useContext, useEffect, useState } from 'react';
import { Box, Center, Heading, SimpleGrid, Text, Image, Button, Flex, useToast, Skeleton } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { FaHeart, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import axios from 'axios';
import { AppContext } from '../../Context/AppContext';
import ShareButton from '../ProductsPageComponents/ShareButton';
import { useQuery, useQueryClient } from 'react-query';
import Tooltip from '../ProductsPageComponents/ToolTip';

const userCurrency = localStorage.getItem('userCurrency');

const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const NewArrivals = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);

  const queryClient = useQueryClient();
  
  const getdata = async () => {
    try {
      const data = await axios.get('https://api.digiblocks.tech/products/newarrivals');
      const slicedData = data.data.slice(0, 4);
      return slicedData;
    } catch (error) {
      console.error('Error fetching new arrivals:', error);
    }
  };

  const { data: products, isLoading: loading } = useQuery('newArrivals', getdata);

  

  const getcartLength = async () => {
    const userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        const response = await axios.get('https://api.digiblocks.tech/getcartitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setCartLength(response.data.data.length);
      } catch (error) {
        console.error('Error getting cart length:', error);
      }
    }
  };

  const addtocartfunctionGrid = async (id, e) => {
    setRefresh(!refresh);
    const userToken = localStorage.getItem('userToken');
    e.stopPropagation();
    if (userToken) {
        try {
            const data = await axios.post(
                'https://api.digiblocks.tech/addtocart',
                { productId: id },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (data.data.message === 'Item added to cart successfully.') {
                setRefresh(!refresh);
                toast({
                    title: 'Added to Cart.',
                    description: 'Item added to cart successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else if (data.data.msg === 'Product already in cart') {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in cart.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
            getcartLength();
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    } else {
        // Get current cart from cookies
        const cart = JSON.parse(Cookies.get('guestCart') || '[]');

        // Add product to cart
        if (!cart.includes(id)) {
            cart.push(id);
            Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
            toast({
                title: "Product added to the cart.",
                description: 'Please login to proceed to checkout.',
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Already in Cart.',
                description: 'Product already in the cart.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        }
    }
};


  const addToWishlistFunction = async (id) => {
    let userToken = localStorage.getItem('userToken');
    if (!userToken) {
      toast({
        title: 'Please login first to add this item to your wishlist.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      let wishlistdata = await axios.post(
        'https://api.digiblocks.tech/addtowishlist',
        {
          productId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      toast({
        title: 'Item added to wishlist successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      getwishlistlength();
    } catch (error) {
      console.error('Error adding to wishlist:', error);
  
      toast({
        title: 'Failed to add item to wishlist. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  const getwishlistlength = async () => {
    const userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        const response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setWishlistLength(response.data.data.length);
      } catch (error) {
        console.error('Error getting wishlist length:', error);
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries('newArrivals');
  }, [refresh]);

  const textStyle = {
    fontSize: { base: '14px', md: '18px' },
    lineHeight: { base: '24px', md: '28px' },
    textAlign: 'center',
    display: { base: 'block', md: 'inline-block' },
  };

  return (
    <Box mt={'3%'} p={4} bg="white" maxW="1200px" mx="auto">
      <Center>
        <Heading
        as={"h1"}
          cursor={'pointer'}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
          style={{
            color: '#6FA82F',
            fontFamily: 'DM Serif Display',
            fontSize: { base: '24px', md: '46px' },
            lineHeight: { base: '32px', md: '58px' },
            fontWeight: 400,
          }}
        >
          {isHovered ? 'New Arrivals' : 'New & Now'}
        </Heading>
      </Center>
      <Center>
        <Text mt={4} fontSize={'16px'} style={textStyle}>
          Indulge in the luxurious allure of our latest silk arrivals. Experience the sheer elegance and unmatched comfort of our new silk products, crafted to elevate your style.
        </Text>
      </Center>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 4 }} spacing={8} mt={8}>
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Box key={index} w={['80%', '90%', '100%']} m={'auto'} bg="#f9f9f9" shadow="md" p={4} position="relative">
              <Skeleton height="200px" />
              <Skeleton height="20px" mt={4} />
              <Skeleton height="18px" mt={2} />
              <Skeleton height="18px" mt={2} />
              <Skeleton height="18px" mt={2} />
              <Skeleton height="18px" mt={2} />
            </Box>
          ))
        ) : (
          products.map((product) => (
            <Box w={['80%', '90%', '100%']} m={'auto'} key={product._id} bg="#f9f9f9" shadow="md" p={4} position="relative">
              <Flex p={2} direction={'column'} alignItems={'center'} gap={5} position={'absolute'} top={0} right={0}>
                <Button
                  zIndex={'999'}
                  _hover={{ transform: 'scale(1.3)', transition: 'transform 0.3s ease-in-out' }}
                  size={'md'}
                  borderRadius={'50%'}
                  onClick={(e) => {
                    e.stopPropagation();
                    addToWishlistFunction(product._id);
                  }}
                >
                  <FaHeart color="rgb(111,168,47)" />
                </Button>
                <ShareButton shareUrl={`https://knitsilk.com/products/${product._id}`} shareTitle={product.title}/>
              </Flex>
              <Center>
                <Image
                  src={product.photos[0]}
                  alt={product.title}
                  transition="transform 0.2s"
                  _hover={{ transform: 'scale(1.1)',cursor:"pointer" }}
                  height="200px"
                  onClick={() => {
                    // Get the existing recently viewed products from localStorage
                    const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
                
                    // Add the current product ID to the recently viewed products array
                    const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];
                
                    // Ensure that only unique product IDs are stored
                    const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];
                
                    // Store the updated recently viewed products array back into localStorage
                    localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));
                
                    // Redirect to the single product page
                    navigate(`/products/${product._id}`);
                  }}
                />
              </Center>
              <Tooltip content={product.title}>
              <Text mt={4} fontFamily='DM Serif Display' fontSize="lg"  textAlign="center">
                {product.title.split(' ').slice(0, 8).join(' ')}...
              </Text>
              </Tooltip>
              <Center>
                <Text display={'flex'} gap={"5px"} alignItems={'center'} fontSize={'18px'} fontWeight={500}>
                  {currencyIcons[userCurrency]} {product[`price${userCurrency}`]}
                </Text>
              </Center>
              <Text m={0} textAlign={"center"} fontSize={'16px'}>
              {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
            </Text>
              <Center>
                <Button
                  colorScheme="green"
                  bg="#e8eee4"
                  color="#6FA82F"
                  w="70%"
                  mt={4}
                  borderRadius={0}
                  mb={4}
                  _hover={{ bg: '#6FA82F', color: 'white' }}
                  rightIcon={<FaShoppingCart />}
                  isFullWidth
                  p={'0 70px'}
                  onClick={(e) => {
                    addtocartfunctionGrid(product._id, e);
                  }}
                >
                  Add to Cart
                </Button>
              </Center>
            </Box>
          ))
        )}
      </SimpleGrid>

      <Center mt={12}>
        <Button
          _hover={{ bg: '#232424' }}
          bg={'#6FA82F'}
          fontSize={['14px', '14px', '18px']}
          p={['25px', '30px']}
          color={'white'}
          borderRadius={'0'}
          m={'auto'}
          mt={'30px'}
        >
          <Flex gap={'10px'} onClick={() => navigate('/products')} alignItems={'center'}>
            <Text mt={3}>Find More</Text>
            <BsArrowRight />
          </Flex>
        </Button>
      </Center>
    </Box>
  );
};

export default NewArrivals;
