import React, { useState, useEffect, useContext } from "react";
import {
  ChakraProvider,
  Box,
  Heading,
  Input,
  Button,
  Switch,
  useToast,
  Image,
  HStack,
  VStack,
  Text,
  Stack,
  Select,
  Flex,
  Divider,
  SimpleGrid,
  useDisclosure,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Checkbox,
  IconButton,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { AppContext } from "../../Context/AppContext";
import {
  FaPlus,
  FaUser,
  FaPhone,
  FaEnvelope,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ChatWithAdmin from "../../pages/ChatWithAdmin";
import { FaLocationDot } from "react-icons/fa6";
import Cookies from "js-cookie";
import BigOrderUi from "./BigOrderUi";
const currencyIcons = {
  INR: "₹",
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  CAD: "$",
  AUD: "$",
};

let xyz = 0;
const Address = () => {
  let navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [locationBlocked, setLocationBlocked] = useState(false);
  const [shippingcharge, setShippingcharge] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [loadingUserAddresses, setLoadingUserAddresses] = useState(true);
  const [priceupdate, setpriceupdate] = useState(false);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  let {
    cartTotalPrice,
    setCartTotalPrice,
    cartLength,
    setCartLength,
    refresh,
    setRefresh,
  } = useContext(AppContext);
  let userCurrency = localStorage.getItem("userCurrency");
  const [shippingFee, setShippingFee] = useState(
    JSON.parse(localStorage.getItem("shippingFee")) || 0
  );
  const [selectedStandard, setSelectedStandard] = useState(true);
  const [selectedExpedited, setSelectedExpedited] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [gotDiscount, setGotDiscount] = useState(0);
  const [userFirstOrder, setUserFirstOrder] = useState(false);
  const [usdTotal, setusdtotal] = useState(0);
  const [countries, setCountries] = useState([]);
  const [callCode, setCallCode] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(
    userAddresses.length > 0 ? userAddresses[0] : null
  );
  const [discountData, setDiscountData] = useState(null);
  const [discountMessage, setDiscountMessage] = useState("");
  const [bigOrderDiscount, setBigOrderDiscount] = useState(0);

  //discount part
  const [cartINR, setCartINR] = useState(0);
  const [cartUSD, setCartUSD] = useState(0);
  const [cartEUR, setCartEUR] = useState(0);
  const [cartJPY, setCartJPY] = useState(0);
  const [cartCAD, setCartCAD] = useState(0);
  const [cartAUD, setCartAUD] = useState(0);
  const [cartGBP, setCartGBP] = useState(0);

  const fetchDiscountData = async (
    totINR,
    totUSD,
    totEUR,
    totJPY,
    totCAD,
    totAUD,
    totGBP
  ) => {
    let userCurrency = localStorage.getItem("userCurrency");

    try {
      const response = await axios.get(
        "https://api.digiblocks.tech/api/discounts"
      );
      // console.log(response.data[0]);
      setDiscountData(response.data[0]);

      if (userCurrency === "USD") {
        setDiscountMessage(
          `Buy a total of ${currencyIcons[userCurrency]} ${response?.data[0]?.priceUSD} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totUSD >= response?.data[0]?.priceUSD && response?.data[0]?.priceUSD) {
          let x = totUSD * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "INR") {
        setDiscountMessage(
          `Buy a total of  ${currencyIcons[userCurrency]} ${response?.data[0]?.priceINR} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totINR >= response?.data[0]?.priceINR && response?.data[0]?.priceINR) {
          let x = totINR * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "EUR") {
        setDiscountMessage(
          `Buy a total of  ${currencyIcons[userCurrency]} ${response?.data[0]?.priceEUR} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totEUR >= response?.data[0]?.priceEUR && response?.data[0]?.priceEUR) {
          let x = totEUR * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "GBP") {
        setDiscountMessage(
          `Buy a total of ${currencyIcons[userCurrency]} ${response?.data[0]?.priceGBP} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totGBP >= response?.data[0]?.priceGBP && response?.data[0]?.priceGBP) {
          let x = totGBP * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "AUD") {
        setDiscountMessage(
          `Buy a total of  ${currencyIcons[userCurrency]} ${response?.data[0]?.priceAUD} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totAUD >= response?.data[0]?.priceAUD && response?.data[0]?.priceAUD) {
          let x = totAUD * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "CAD") {
        setDiscountMessage(
          `Buy a total of  ${currencyIcons[userCurrency]} ${response?.data[0]?.priceCAD} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totCAD >= response?.data[0]?.priceCAD && response?.data[0]?.priceCAD) {
          let x = totCAD * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      } else if (userCurrency === "JPY") {
        setDiscountMessage(
          `Buy a total of  ${currencyIcons[userCurrency]} ${response?.data[0]?.priceJPY} to get a discount of ${response?.data[0]?.discount}%`
        );
        if (totJPY >= response?.data[0]?.priceJPY && response?.data[0]?.priceJPY) {
          let x = totJPY * (response?.data[0]?.discount / 100).toFixed(2);
          setBigOrderDiscount(x);
          setDiscountMessage(
            `${response?.data[0]?.discount}% discount is applied`
          );
        } else {
          setBigOrderDiscount(0);
        }
      }
    } catch (error) {
      console.error("Error fetching discount data:", error);
    }
  };

  useEffect(() => {
    fetchIPDetails();
    getAllProducts();
    getCountries();
    getUserAddresses();
    calculateOrderTotal();
    fetchCoupons();
    isUserOrderingForFirstTime();
    getAllvouchers();
  }, [priceupdate, refresh]);

  let getAllvouchers = async () => {
    let data = await axios.get("https://api.digiblocks.tech/promo-codes");
    setVouchers(data.data);
  };
  const fetchIPDetails = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    setCallCode(response.data.country_calling_code);
    fetchData(response.data);
  };
  const getAllProducts = async () => {
    try {
      const response = await axios.get("https://api.digiblocks.tech/products");
      setProducts(response.data);
      getCartProducts(response.data);
    } catch (error) {
      console.error("Error getting all products:", error);
    }
  };

  const getCartProducts = async (products) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("userToken");
      const response = await axios.get(
        "https://api.digiblocks.tech/getcartitems",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const cartItems = response.data.data;

      const cartProductDetails = products
        .map((product) => {
          const cartItem = cartItems.find(
            (item) => item.productId === product._id
          );
          return cartItem ? { ...product, quantity: cartItem.quantity } : null;
        })
        .filter(Boolean);

      // console.log(cartProductDetails);
      // Initialize cart totals for each currency
      let totINR = 0;
      let totUSD = 0;
      let totEUR = 0;
      let totJPY = 0;
      let totCAD = 0;
      let totAUD = 0;
      let totGBP = 0;

      // Calculate the total for each currency based on product prices
      cartProductDetails &&
        cartProductDetails.forEach((product) => {
          totINR += product.priceINR * product.quantity || 0;
          totUSD += product.priceUSD * product.quantity || 0;
          totEUR += product.priceEUR * product.quantity || 0;
          totJPY += product.priceJPY * product.quantity || 0;
          totCAD += product.priceCAD * product.quantity || 0;
          totAUD += product.priceAUD * product.quantity || 0;
          totGBP += product.priceGBP * product.quantity || 0;
        });

      fetchDiscountData(totINR, totUSD, totEUR, totJPY, totCAD, totAUD, totGBP);

      // Set the state for each currency
      setCartINR(totINR);
      setCartUSD(totUSD);
      setCartEUR(totEUR);
      setCartJPY(totJPY);
      setCartCAD(totCAD);
      setCartAUD(totAUD);
      setCartGBP(totGBP);
      // discount logic

      setCartProducts(cartProductDetails);
      setLoading(false);

      let totalUSD = cartProductDetails.reduce(
        (acc, curr) => acc + curr.priceUSD * curr.quantity,
        0
      );
      setusdtotal(totalUSD);

      let totalQuantity = cartProductDetails.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      // console.log('Total quantity of whole cart:', totalQuantity);

      let shippingFee = 0;

      if (selectedStandard || selectedExpedited) {
        const shippingType = selectedStandard ? "standard" : "expedited";
        const perOrderFee =
          Number(xyz?.shippingFees?.[shippingType]?.perOrder) || 0;
        const perItemFee =
          Number(xyz?.shippingFees?.[shippingType]?.perItem) || 0;

        shippingFee = perOrderFee + (totalQuantity - 1) * perItemFee;
      }

      setShippingcharge(shippingFee);
      if (cartProductDetails.length == 0) {
        setCartTotalPrice(0);
      }
    } catch (error) {
      console.error("Error getting cart products:", error);
    }
  };

  const getUserAddresses = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const response = await axios.get(
        "https://api.digiblocks.tech/user/address",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      // console.log(response.data)
      setSelectedAddress(response.data.addresses[0]._id);
      setCustomerInfo((prevInfo) => ({
        ...prevInfo,
        shipping: {
          ...prevInfo.shipping,
          ...response.data.addresses[0].shipping,
        },
        billing: {
          ...prevInfo.shipping,
          ...response.data.addresses[0].shipping,
        },
        isBillingSameAsShipping: true,
      }));
      setUserAddresses(response.data.addresses);
    } catch (error) {
      console.error("Error getting user addresses:", error);
    } finally {
      setLoadingUserAddresses(false);
    }
  };

  let isUserOrderingForFirstTime = async () => {
    let userToken = localStorage.getItem("userToken");
    let response = await axios.get("https://api.digiblocks.tech/order", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    const paidCount = response.data.filter(obj => obj.isPaid === true).length;

    if (response.data.length > 0) {
     
      setUserFirstOrder(false);
    } else {
      setUserFirstOrder(true);
      
    }
    return paidCount;
  };
  const [customerInfo, setCustomerInfo] = useState({
    shipping: {
      customerName: "",
      email: "",
      phone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
    },
    billing: {
      customerName: "",
      email: "",
      phone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
    },
    isBillingSameAsShipping: true,
  });
  // console.log(customerInfo);
  const updateQuantity = async (productId, newQuantity) => {
    let userToken = localStorage.getItem("userToken");
    try {
      const response = await fetch(
        "https://api.digiblocks.tech/updatequantity",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            productId: productId,
            quantity: newQuantity,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error updating quantity: ${response.statusText}`);
      }

      const responseData = await response.json();
      setRefresh(!refresh);
      getCartProducts();
      setpriceupdate(!priceupdate);
      if (responseData.msg === "Quantity updated successfully.") {
        const updatedCartProducts = cartProducts.map((item) =>
          item._id === productId ? { ...item, quantity: newQuantity } : item
        );

        setCartProducts(updatedCartProducts);

        setSelectedQuantities((prevState) => ({
          ...prevState,
          [productId]: newQuantity,
        }));

        toast({
          title: "Quantity Updated",
          description: "Quantity updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Error updating quantity:", responseData.msg);
        toast({
          title: "Error",
          description: responseData.message || "Error updating quantity.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating quantity:");
      toast({
        title: "Error",
        description: "Error updating quantity.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      getCartProducts();
    }
  };

  const removeItem = async (productId) => {
    let userToken = localStorage.getItem("userToken");
    try {
      const response = await axios.delete(
        `https://api.digiblocks.tech/removeitem/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.msg.includes("deleted")) {
        getAllProducts();
        const updatedCartProducts = cartProducts.filter(
          (item) => item._id !== productId
        );
        setCartProducts(updatedCartProducts);
        toast({
          title: "Item Removed",
          description: "Item removed from the cart.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Error removing item:", response.data.msg);
        toast({
          title: "Error",
          description: response.data.msg || "Error removing item.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error removing item:", error);
      toast({
        title: "Error",
        description: "Error removing item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      getcartLength();
    }
  };
  let getcartLength = async () => {
    let userToken = localStorage.getItem("userToken") || "";
    if (userToken) {
      let response = await axios.get(
        "https://api.digiblocks.tech/getcartitems",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setCartLength(response.data.data.length);
    }
  };
  const placeOrder = async () => {
    if (locationBlocked) {
      toast({
        title: "Shipping Not Available",
        description:
          "We currently do not ship to your location. Please check back later or contact our support team for more information.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit the function early if customerInfo is missing
    }

    if (!customerInfo || !customerInfo.shipping.customerName) {
      toast({
        title: "Shipping Address Required",
        description: "Please add the shipping address.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit the function early if customerInfo is missing
    }

    let paymentMethod = "PayPal";
    if (localStorage.getItem("userCurrency") === "INR") {
      paymentMethod = "RazorPay";
    }
    try {
      const orderData = {
        customerInfo: customerInfo,
        cartProducts: cartProducts,
        shippingMethod: selectedExpedited ? "Expedited" : "Standard",
        shippingCharges: shippingcharge,
        discount: gotDiscount ? gotDiscount : 0,
        finalAmount: Number(
          Number(
            cartTotalPrice -
              gotDiscount -
              Number(bigOrderDiscount.toFixed(2)) +
              shippingcharge
          ).toFixed(2)
        ),
        paymentMethod: paymentMethod,
        userCurrency: localStorage.getItem("userCurrency"),
      };

      let userToken = localStorage.getItem("userToken");

      const response = await fetch("https://api.digiblocks.tech/order", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      });

      const responseData = await response.json();
      localStorage.setItem("newOrderId", responseData.orderID);
      Cookies.set("newOrderId", responseData.orderID);
      // console.log(responseData.orderID)

      navigate("/choosepayment");
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: `An error occurred while placing the order: ${error.message}. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(
        "https://api.digiblocks.tech/discount/coupons"
      );
      setCoupons(response.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };
  const applyCoupon = async() => {
    const enteredCouponCode = couponCode.trim();
    if (!enteredCouponCode) {
      toast({
        title: "Invalid Coupon",
        description: "Please enter a valid coupon code.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const coupon = coupons.find(
      (coupon) =>
        coupon.code === enteredCouponCode && coupon.status === "Active"
    );
    // && time range conditions
    const voucher = vouchers.find(
      (coupon) => coupon.code === enteredCouponCode
    );
    let count =await isUserOrderingForFirstTime();
  
    if (coupon ) {
      if (count==0) {
        toast({
          title: "Coupon Applied",
          description: `Discount of ${coupon.percentageDiscount}% applied`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        calculateOrderTotal(coupon);
      } else if (count>=1) {
        toast({
          title: "Not Applicable !!!",
          description: `Coupon for First Order`,
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        setCouponCode("");
      }
    } else if (voucher) {
      calculateOrderTotal(voucher);
    } else {
      toast({
        title: "Invalid Coupon",
        description: "The entered coupon code is invalid or expired.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setCouponCode("");
    }
  };
  const calculateOrderTotal = (coupon) => {
    let subtotal = cartTotalPrice;
    let discount = 0;
    const currentDate = new Date();
    const startDate = new Date(coupon?.validFrom);
    const endDate = new Date(coupon?.validTo);
    if (currentDate > endDate) {
      toast({
        title: "Offer Expired",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (
      coupon?.status === "Active" &&
      currentDate >= startDate &&
      currentDate <= endDate
    ) {
      const discountPercentage = coupon.percentageDiscount;
      discount = (subtotal * discountPercentage) / 100;
      setGotDiscount(discount);
    } else if (coupon?.minOrderAmount !== null) {
      const currentDate = new Date();
      const startDate = new Date(coupon?.startDate);
      const endDate = new Date(coupon?.endDate);
      if (currentDate > endDate) {
        toast({
          title: "Offer Expired",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (
        usdTotal > coupon?.minOrderAmount &&
        currentDate >= startDate &&
        currentDate <= endDate
      ) {
        const discountPercentage = coupon.discountPercentage;
        discount = (subtotal * discountPercentage) / 100;
        setGotDiscount(discount);
        toast({
          title: "Coupon Applied",
          description: `Discount of ${coupon?.discountPercentage}% applied`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setGotDiscount(0);
        if (coupon?.minOrderAmount) {
          toast({
            title: "Buy More to get More",
            description: `A minimum of ${coupon?.minOrderAmount} should be purchased`,
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }
  };
  const fetchData = async (d) => {
    try {
      if (d.country === "IN" || d.country === "US") {
        const response = await axios.get(
          `https://api.digiblocks.tech/shipping-profiles/country/${d.region}`
        );
        localStorage.setItem("shippingFee", JSON.stringify(response.data));
        xyz = response.data;
        setShippingFee(response.data);
      } else {
        const response = await axios.get(
          `https://api.digiblocks.tech/shipping-profiles/country/${d.country}`
        );
        localStorage.setItem("shippingFee", JSON.stringify(response.data));
        setShippingFee(response.data);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Country not found in shipping profiles"
      ) {
        setLocationBlocked(true);
      }
      setShippingFee(0);
    }
  };
  const handleCheckboxChange = (value) => {
    setRefresh(!refresh);
    if (value === "standard") {
      setSelectedStandard(true);
      setSelectedExpedited(false);
    } else if (value === "expedited") {
      setSelectedStandard(false);
      setSelectedExpedited(true);
    }
  };
  const handleDelete = async (address) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const apiUrl = `https://api.digiblocks.tech/user/address/${address._id}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        getUserAddresses();
        toast({
          title: "Address Removed",
          description: "Your address was removed successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error("Failed to remove address:", response.data);
        toast({
          title: "Error",
          description: "Failed to remove address. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error removing address:", error);
      toast({
        title: "Error",
        description:
          "An error occurred while removing the address. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleUseAddress = (address) => {
    setSelectedAddress(address._id);
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      shipping: {
        ...prevInfo.shipping,
        ...address.shipping,
      },
      billing: {
        ...prevInfo.shipping,
        ...address.shipping,
      },
      isBillingSameAsShipping: true,
    }));
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [editAddress, setEditedAddress] = useState(null);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    customerName: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    isBillingSameAsShipping: true,
  });
  const [billingformData, setBillingFormData] = useState({
    customerName: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const handleOpenModal = (address) => {
    onOpen();
    if (address) {
      setEditedAddress(address);
      setFormData({
        customerName: address.shipping.customerName,
        email: address.shipping.email,
        phone: address.shipping.phone,
        street: address.shipping.street,
        city: address.shipping.city,
        state: address.shipping.state,
        zipCode: address.shipping.zipCode,
        country: address.shipping.country,
        isBillingSameAsShipping: true,
      });
    } else {
      setEditedAddress(null);
      setFormData({
        customerName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        isBillingSameAsShipping: true,
      });
    }
  };
  const handleAddOrUpdateAddress = async () => {
    if (!isChecked) {
      try {
        const userToken = localStorage.getItem("userToken");
        const apiUrl = editAddress
          ? `https://api.digiblocks.tech/user/address/${editAddress._id}`
          : "https://api.digiblocks.tech/user/address";
        const method = editAddress ? "put" : "post";

        // Check if any required field is missing
        const requiredFields = [
          "customerName",
          "email",
          "phone",
          "street",
          "city",
          "state",
          "zipCode",
          "country",
        ];
        const missingFields = requiredFields.filter(
          (field) => !formData[field]
        );
        if (missingFields.length > 0) {
          const errorMessage = `Please fill in the following fields: ${missingFields.join(
            ", "
          )}`;
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const response = await axios[method](
          apiUrl,
          {
            shipping: {
              customerName: formData.customerName,
              email: formData.email,
              phone: formData.phone,
              street: formData.street,
              city: formData.city,
              state: formData.state,
              zipCode: formData.zipCode,
              country: formData.country,
            },
            callCode: callCode,
            is_billing_same_as_shipping: false,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        // console.log(response)
        if (response.status === 200 || response.status === 201) {
          onClose();
          onOpen1();
          getUserAddresses();
          setStep(2);
          toast({
            title: editAddress ? "Address Updated" : "Address Added",
            description: `Your address was ${
              editAddress ? "updated" : "added"
            } successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error("Failed to add/update address:", response.data);
          toast({
            title: "Error",
            description: "Failed to add/update address. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error adding/updating address:", error);
        toast({
          title: "Error",
          description:
            "An error occurred while adding/updating the address. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        const userToken = localStorage.getItem("userToken");
        const apiUrl = editAddress
          ? `https://api.digiblocks.tech/user/address/${editAddress._id}`
          : "https://api.digiblocks.tech/user/address";
        const method = editAddress ? "put" : "post";

        // Check if any required field is missing
        const requiredFields = [
          "customerName",
          "email",
          "phone",
          "street",
          "city",
          "state",
          "zipCode",
          "country",
        ];
        const missingFields = requiredFields.filter(
          (field) => !formData[field]
        );
        if (missingFields.length > 0) {
          const errorMessage = `Please fill in the following fields: ${missingFields.join(
            ", "
          )}`;
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const response = await axios[method](
          apiUrl,
          {
            shipping: {
              customerName: formData.customerName,
              email: formData.email,
              phone: formData.phone,
              street: formData.street,
              city: formData.city,
              state: formData.state,
              zipCode: formData.zipCode,
              country: formData.country,
            },
            billing: {
              customerName: formData.customerName,
              email: formData.email,
              phone: formData.phone,
              street: formData.street,
              city: formData.city,
              state: formData.state,
              zipCode: formData.zipCode,
              country: formData.country,
            },
            callCode: callCode,
            is_billing_same_as_shipping: true,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        // console.log(response)
        if (response.status === 200 || response.status === 201) {
          onClose();
          // onOpen1();
          getUserAddresses();
          // setStep(2);
          toast({
            title: editAddress ? "Address Updated" : "Address Added",
            description: `Your address was ${
              editAddress ? "updated" : "added"
            } successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error("Failed to add/update address:", response.data);
          toast({
            title: "Error",
            description: "Failed to add/update address. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error adding/updating address:", error);
        toast({
          title: "Error",
          description:
            "An error occurred while adding/updating the address. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  const handleBillingAddress = () => {
    setCustomerInfo((prevCustomerInfo) => ({
      ...prevCustomerInfo,
      billing: {
        customerName: billingformData.customerName,
        email: billingformData.email,
        phone: billingformData.phone,
        street: billingformData.street,
        city: billingformData.city,
        state: billingformData.state,
        zipCode: billingformData.zipCode,
      },
    }));
    onClose1();
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    // console.log(e.target.checked)
    setIsChecked(e.target.checked);
    if (e.target.checked) {
    } else {
    }
  };

  const getCountries = async () => {
    try {
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "Y21vYlNQV0VQQzY2ajBkVDJ2cndBZjJpMGhNY2JhbEJjWVBpelFwWQ==",
          },
        }
      );
      if (response.data && Array.isArray(response.data)) {
        setCountries(response.data);
      } else {
        console.error(
          "Invalid response structure for countries:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error getting countries:", error);
    }
  };

  return (
    <ChakraProvider>
      <Box p={4} maxW={["100%", "90%", "90%"]} margin={"auto"}>
        <Heading as="h1" size="lg" mb={4} textAlign="center">
          Checkout
        </Heading>
        <BigOrderUi/>
        <Flex direction={["column", "column", "row"]} w={"100%"} gap={8}>
          <Box borderRadius="md" width={["100%", "100%", "70%"]}>
            <VStack spacing={6} mt={8} align="stretch">
              {userAddresses.length > 0 && (
                <Heading fontSize={"2xl"} textAlign="left">
                  Select A Delivery Address :
                </Heading>
              )}
              {userAddresses.length > 0 && (
                <VStack spacing={4} align="stretch">
                  {Array.isArray(userAddresses) ? (
                    userAddresses.map((address, index) => (
                      <Box
                        key={address._id}
                        borderWidth="1px"
                        borderRadius="30px"
                        overflow="hidden"
                        py={["4", "4", "4"]}
                        px={["4", "4", "6"]}
                        shadow="md"
                        bg="white"
                        width="100%"
                      >
                        <VStack width="100%" align="start" spacing={2}>
                          <HStack>
                            <Box as={FaUser} boxSize={5} color="blue.500" />
                            <Text m="auto" fontSize="lg" fontWeight="bold">
                              {address.shipping.customerName}
                            </Text>
                          </HStack>
                          <HStack>
                            <Box as={FaPhone} boxSize={4} color="teal.500" />
                            <Text m="auto">
                              {address.callCode} {address.shipping.phone}
                            </Text>
                          </HStack>
                          <HStack>
                            <Box
                              as={FaEnvelope}
                              boxSize={4}
                              color="orange.500"
                            />
                            <Text m="auto">{address.shipping.email}</Text>
                          </HStack>
                          <HStack>
                            <Box
                              as={FaLocationDot}
                              boxSize={4}
                              color="blue.500"
                            />
                            <Text m="auto">{`${address.shipping.street},${address.shipping.city},  ${address.shipping.state}, ${address.shipping.zipCode}, ${address.shipping.country}`}</Text>
                          </HStack>
                        </VStack>
                        <Spacer />
                        <HStack alignItems="center" mt="2" spacing={4}>
                          <Tooltip
                            label="Edit Address"
                            bg="white"
                            color="green"
                            aria-label="edit-tooltip"
                          >
                            <IconButton
                              icon={<FaEdit />}
                              size="sm"
                              borderRadius="full"
                              bg="green.200"
                              color="green.800"
                              onClick={() => handleOpenModal(address)}
                            />
                          </Tooltip>
                          <Tooltip
                            label="Delete Address"
                            bg="white"
                            color="red"
                            aria-label="delete-tooltip"
                          >
                            <IconButton
                              icon={<FaTrash />}
                              size="sm"
                              borderRadius="full"
                              bg="red.200"
                              color="red.800"
                              onClick={() => handleDelete(address)}
                            />
                          </Tooltip>
                          <Button
                            mt={1}
                            borderRadius="16"
                            colorScheme="green"
                            _hover={{ bg: "black", color: "white" }}
                            width="150px"
                            bg="#6FA82F"
                            size="sm"
                            onClick={() => handleUseAddress(address)}
                            isDisabled={selectedAddress === address._id}
                          >
                            {selectedAddress === address._id
                              ? "Address Used"
                              : "Use This Address"}
                          </Button>
                        </HStack>
                      </Box>
                    ))
                  ) : (
                    <p>Loading user addresses...</p>
                  )}
                </VStack>
              )}
              <Box
                borderWidth="1px"
                borderRadius="30px"
                overflow="hidden"
                py={["2", "2", "3"]}
                px={["4", "4", "6"]}
                shadow="md"
                bg="white"
                width="100%"
                _hover={{ cursor: "pointer" }}
                onClick={() => handleOpenModal()}
              >
                <HStack spacing={2} alignItems="center">
                  <Box as={FaPlus} boxSize={4} color="gray.500" />
                  <Text fontWeight="bold" mt="3">
                    Add A New Shipping Address
                  </Text>
                </HStack>
              </Box>

              {step == 1 && (
                <Modal isOpen={isOpen} onClose={onClose} size="lg">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      {editAddress
                        ? "Edit Shipping Address"
                        : "Add New Shipping Address"}
                    </ModalHeader>
                    <ModalCloseButton color="black" />
                    <ModalBody>
                      <VStack spacing={4}>
                        <HStack spacing={4} w="100%">
                          <FormControl isRequired>
                            <FormLabel>Full Name</FormLabel>
                            <Input
                              type="text"
                              border={"1px solid #6FA82F"}
                              value={formData.customerName}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  customerName: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </HStack>
                        <FormControl isRequired>
                          <FormLabel>Mobile Number</FormLabel>
                          <Flex>
                            <Input
                              value={callCode}
                              border={"1px solid #6FA82F"}
                              mr="2"
                              width="100px"
                            />
                            <Input
                              border={"1px solid #6FA82F"}
                              type="tel"
                              value={formData.phone}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl isRequired width={"100%"}>
                          <FormLabel>Email</FormLabel>
                          <Input
                            border={"1px solid #6FA82F"}
                            type="email"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl isRequired w="100%">
                          <FormLabel>Street</FormLabel>
                          <Input
                            border={"1px solid #6FA82F"}
                            type="text"
                            value={formData.street}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                street: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <HStack spacing={4} width={"100%"}>
                          <FormControl isRequired flex={"1"}>
                            <FormLabel>Country</FormLabel>
                            <Select
                              border={"1px solid #6FA82F"}
                              placeholder="Select Your Country"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  country: e.target.value,
                                });
                              }}
                              value={formData.country}
                            >
                              {countries.map((country) => (
                                <option key={country.iso2} value={country.iso2}>
                                  {country.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl isRequired flex="1">
                            <FormLabel>State</FormLabel>
                            <Input
                              borderRadius="md"
                              border={"1px solid #6FA82F"}
                              type="text"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  state: e.target.value,
                                });
                              }}
                              value={formData.state}
                            />
                          </FormControl>
                        </HStack>
                        <HStack spacing={4} w="100%">
                          <FormControl isRequired flex={"1"}>
                            <FormLabel>City</FormLabel>
                            <Input
                              border={"1px solid #6FA82F"}
                              type="text"
                              value={formData.city}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  city: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                          <FormControl isRequired flex="1">
                            <FormLabel>Zip Code</FormLabel>
                            <Input
                              border={"1px solid #6FA82F"}
                              type="text"
                              value={formData.zipCode}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  zipCode: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </HStack>
                        <Stack direction="row" align="left">
                          <Switch
                            colorScheme="green"
                            size="lg"
                            // isChecked={isChecked}
                            onChange={handleChange}
                          />
                          <Text fontWeight={"semibold"}>
                            Is Billing address same as shipping address.
                          </Text>
                        </Stack>
                      </VStack>
                    </ModalBody>

                    <ModalFooter
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Button
                        colorScheme="red"
                        p={"0 70px"}
                        borderRadius={"none"}
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        colorScheme="green"
                        color="white"
                        bg="#6FA82F"
                        borderRadius={0}
                        _hover={{ bg: "black", color: "white" }}
                        p={"0 50px"}
                        ml={3}
                        onClick={handleAddOrUpdateAddress}
                      >
                        {editAddress ? "Save Changes" : "Save Shipping Address"}
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              )}

              {step == 2 && (
                <Modal isOpen={isOpen1} onClose={onClose1} size="lg">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Add New Billing Address</ModalHeader>
                    <ModalCloseButton color="black" />
                    <ModalBody>
                      <VStack spacing={4}>
                        <HStack spacing={4} w="100%">
                          <FormControl isRequired>
                            <FormLabel>Full Name</FormLabel>
                            <Input
                              type="text"
                              border={"1px solid #6FA82F"}
                              value={billingformData.customerName}
                              onChange={(e) =>
                                setBillingFormData({
                                  ...billingformData,
                                  customerName: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </HStack>
                        <FormControl isRequired>
                          <FormLabel>Mobile Number</FormLabel>
                          <Flex>
                            <Input
                              value={callCode}
                              border={"1px solid #6FA82F"}
                              mr="2"
                              width="100px"
                            />
                            <Input
                              border={"1px solid #6FA82F"}
                              type="tel"
                              value={billingformData.phone}
                              onChange={(e) =>
                                setBillingFormData({
                                  ...billingformData,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl isRequired width={"100%"}>
                          <FormLabel>Email</FormLabel>
                          <Input
                            border={"1px solid #6FA82F"}
                            type="email"
                            value={billingformData.email}
                            onChange={(e) =>
                              setBillingFormData({
                                ...billingformData,
                                email: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl isRequired w="100%">
                          <FormLabel>Street</FormLabel>
                          <Input
                            border={"1px solid #6FA82F"}
                            type="text"
                            value={billingformData.street}
                            onChange={(e) =>
                              setBillingFormData({
                                ...billingformData,
                                street: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <HStack spacing={4} width={"100%"}>
                          <FormControl isRequired flex={"1"}>
                            <FormLabel>Country</FormLabel>
                            <Select
                              border={"1px solid #6FA82F"}
                              placeholder="Select Your Country"
                              onChange={(e) => {
                                setBillingFormData({
                                  ...billingformData,
                                  country: e.target.value,
                                });
                              }}
                              value={billingformData.country}
                            >
                              {countries.map((country) => (
                                <option key={country.iso2} value={country.iso2}>
                                  {country.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl isRequired flex="1">
                            <FormLabel>State</FormLabel>
                            <Input
                              borderRadius="md"
                              border={"1px solid #6FA82F"}
                              type="text"
                              onChange={(e) => {
                                setBillingFormData({
                                  ...billingformData,
                                  state: e.target.value,
                                });
                              }}
                              value={billingformData.state}
                            />
                          </FormControl>
                        </HStack>
                        <HStack spacing={4} w="100%">
                          <FormControl isRequired flex={"1"}>
                            <FormLabel>City</FormLabel>
                            <Input
                              border={"1px solid #6FA82F"}
                              type="text"
                              value={billingformData.city}
                              onChange={(e) =>
                                setBillingFormData({
                                  ...billingformData,
                                  city: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                          <FormControl isRequired flex="1">
                            <FormLabel>Zip Code</FormLabel>
                            <Input
                              border={"1px solid #6FA82F"}
                              type="text"
                              value={billingformData.zipCode}
                              onChange={(e) =>
                                setBillingFormData({
                                  ...billingformData,
                                  zipCode: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </HStack>
                      </VStack>
                    </ModalBody>

                    <ModalFooter
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Button
                        colorScheme="red"
                        p={"0 70px"}
                        borderRadius={"none"}
                        onClick={onClose1}
                      >
                        Cancel
                      </Button>
                      <Button
                        colorScheme="green"
                        color="white"
                        bg="#6FA82F"
                        borderRadius={0}
                        _hover={{ bg: "black", color: "white" }}
                        p={"0 50px"}
                        ml={3}
                        onClick={handleBillingAddress}
                      >
                        Save Billing Address
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              )}

              <SimpleGrid>
                {shippingFee && (
                  <Box mt={4}>
                    <Heading fontSize={"2xl"} textAlign="left">
                      Select A Shipping Method :
                    </Heading>
                    <Flex
                      mt={5}
                      direction={"column"}
                      justifyContent="flex-start"
                      gap={7}
                    >
                      <Box
                        borderWidth="1px"
                        borderRadius="30px"
                        overflow="hidden"
                        py={["2", "2", "3"]}
                        px={["4", "4", "6"]}
                        shadow="md"
                        bg="white"
                        width="100%"
                      >
                        <Flex alignItems={"center"} gap={3}>
                          <Checkbox
                            colorScheme="green"
                            isChecked={selectedStandard}
                            onChange={() => handleCheckboxChange("standard")}
                          ></Checkbox>
                          <Text fontWeight="bold" m={0}>
                            Standard:
                          </Text>
                        </Flex>
                        <Flex gap={3} alignItems={"center"}>
                          <Flex gap={1}>
                            <Text m={0}> {currencyIcons[userCurrency]}</Text>
                            <Text m={0}>
                              {shippingFee?.shippingFees?.standard?.perOrder
                                ? shippingFee.shippingFees.standard.perOrder
                                : "Free"}{" "}
                              for first piece
                            </Text>
                          </Flex>
                          <Text m={0}>+</Text>
                          <Flex gap={1}>
                            <Text m={0}> {currencyIcons[userCurrency]}</Text>
                            <Text m={0}>
                              {shippingFee?.shippingFees?.standard?.perItem
                                ? shippingFee.shippingFees.standard.perItem
                                : "Free"}{" "}
                              for additional piece
                            </Text>
                          </Flex>
                        </Flex>
                        <Text mt="2" color="gray" ml={2}>
                          {shippingFee.transitTimesStandard} days
                        </Text>
                      </Box>
                      <Box
                        borderWidth="1px"
                        borderRadius="30px"
                        overflow="hidden"
                        py={["2", "2", "3"]}
                        px={["4", "4", "6"]}
                        shadow="md"
                        bg="white"
                        width="100%"
                      >
                        <Flex gap={3} alignItems={"center"}>
                          <Checkbox
                            colorScheme="green"
                            isChecked={selectedExpedited}
                            onChange={() => handleCheckboxChange("expedited")}
                          />
                          <Text m={0} fontWeight="bold">
                            Expedited:
                          </Text>
                        </Flex>
                        <Flex gap={3} alignItems={"center"}>
                          <Flex gap={1}>
                            <Text m={0}>{currencyIcons[userCurrency]}</Text>
                            <Text m={0}>
                              {shippingFee.shippingFees.expedited.perOrder} for
                              first piece
                            </Text>
                          </Flex>
                          <Text m={0}>+</Text>
                          <Flex gap={1}>
                            <Text m={0}>{currencyIcons[userCurrency]}</Text>
                            <Text m={0}>
                              {shippingFee.shippingFees.expedited.perItem} for
                              additional piece
                            </Text>
                          </Flex>
                        </Flex>
                        <Text ml={2} color="gray" mt="2">
                          {shippingFee.transitTimesExpedited} days
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                )}
              </SimpleGrid>
            </VStack>
          </Box>
          {/* Order Summary */}
          <VStack
            bg={"rgb(246,250,242)"}
            p={3}
            borderRadius={"15px"}
            width={["100%", "100%", "30%"]}
            align="stretch"
            m={"auto"}
          >
            <Heading fontSize={"2xl"} textAlign="center">
              Order Summary
            </Heading>
            <VStack
              h={"300px"}
              p={2}
              overflowY={"scroll"}
              align="stretch"
              css={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(111,168,47)",
                  borderRadius: "8px",
                  cursor: "pointer"
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "rgb(246,250,242)",
                },
              }}
              boxShadow={"0 0 2px rgb(111,168,47)"}
              borderRadius={2}
            >
              {loading ? (
                <p>Loading Order Summary</p>
              ) : (
                <>
                  {cartProducts.length > 0 ? (
                    cartProducts.map((item, i) => (
                      <Box
                        border="1px solid rgb(111,168,47)"
                        mb={i == cartProducts.length - 1 ? "200px" : ""}
                        borderRadius={"10px"}
                        key={item._id}
                        p={2}
                        borderBottom="1px solid #ccc"
                      >
                        <Flex direction={"column"}>
                          <Flex
                          alignItems={"center"}
                            direction={"row"}
                            gap={2}
                            justifyContent={"space-evenly"}
                          >
                      
                            <Image
                              src={item.photos[0]}
                              alt={item.title}
                              boxSize="50px"
                              objectFit="cover"
                            />
                         

                            <Text fontSize={["12px", "16px"]} lineHeight={1.5}>
                              {item.title.split(" ").slice(0, 15).join(" ")}...
                            </Text>
                          </Flex>
                          <Flex   direction={"column"} gap={"1"}>
                              <Flex   justifyContent={"space-evenly"} flexWrap={"wrap"} gap={1}>
                                <Flex gap={1} alignItems={"center"}>
                                <Text m={0} mt={2}>
                                  {currencyIcons[userCurrency]}
                                </Text>
                                <Text
                                  m={0}
                                  display={"flex"}
                                  mt={2}
                                  textAlign={"center"}
                                  fontSize={["12px", "14px"]}
                                >
                                  {(
                                    item[`price${userCurrency}`] -
                                    (
                                      item[`price${userCurrency}`] *
                                      item.discount
                                    ).toFixed(2) /
                                      100
                                  ).toFixed(2)}
                                </Text>
                                </Flex>
                                <Flex direction={"row"} justifyContent={"center"} gap={2}>
                                <Select
                                  value={item.quantity}
                                  w={16}
                                  size={"sm"}
                                  border="1px solid rgb(111,168,47)"
                                  borderRadius={"md"}
                                  onChange={(e) =>
                                    updateQuantity(
                                      item._id,
                                      parseInt(e.target.value)
                                    )
                                  }
                                >
                                  {Array.from(
                                    { length: item.qtyInStock },
                                    (_, index) => index + 1
                                  ).map((qty) => (
                                    <option key={qty} value={qty}>
                                      {qty}
                                    </option>
                                  ))}
                                </Select>
                                <IconButton
                                  size={"sm"}
                                  color="red"
                                  border={"1px solid red"}
                                  bg={"none"}
                                  aria-label="Remove item"
                                  icon={<FaTrash />}
                                  variant="ghost"
                                  onClick={() => removeItem(item._id)}
                                />
                              </Flex>
                              </Flex>
                           
                            </Flex>
                        </Flex>
                      </Box>
                    ))
                  ) : (
                    <p>No items in cart</p>
                  )}
                </>
              )}
            </VStack>
            <Divider/>
        {   bigOrderDiscount==0 ? <Flex gap={3} mt={3}>
              <Input
                id="couponCodeInput"
                placeholder="Enter Coupon Code"
                bg={"white"}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Button
                color={"white"}
                bg={"rgb(111,168,47)"}
                _hover={{ bg: "black", color: "white" }}
                size="sm"
                mt="1"
                borderRadius={"none"}
                onClick={() => applyCoupon()}
              >
                Apply
              </Button>
            </Flex>:<Box fontSize={"18px"}> 🥳 Hurray! Discount Applied 😍</Box>}
            <Box mt={3}>
              <Flex justifyContent={"space-between"}>
                <Text fontSize={"18px"} m={0}>
                  Total Items :{" "}
                </Text>
                <Text>{cartLength}</Text>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text fontSize={"18px"} m={0}>
                  Subtotal :{" "}
                </Text>
                <Text>
                  {currencyIcons[userCurrency]} {cartTotalPrice.toFixed(2)}
                </Text>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text fontSize={"18px"} m={0}>
                  Shipping Cost :{" "}
                </Text>
                {/* xxx */}
                <Text>{Number(shippingcharge)}</Text>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text fontSize={"18px"} m={0}>
                  Discount :{" "}
                </Text>
                <Text>
                  {currencyIcons[userCurrency]}{" "}
                  {gotDiscount
                    ? gotDiscount.toFixed(2)
                    : 0 + bigOrderDiscount
                    ? bigOrderDiscount.toFixed(2)
                    : 0}
                </Text>
              </Flex>
              {discountMessage && <Text color={"red"}>{discountMessage}</Text>}
              <Divider></Divider>
              <Flex
                fontWeight={"bold"}
                fontSize={"24px"}
                justifyContent={"space-between"}
              >
                <Text m={0}>Order Total : </Text>
                <Text m={0}>
                  {currencyIcons[userCurrency]}{" "}
                  {Number(
                    (
                      cartTotalPrice -
                      gotDiscount -
                      Number(bigOrderDiscount).toFixed(2) +
                      Number(shippingcharge)
                    ).toFixed(2)
                  )}
                </Text>
              </Flex>
            </Box>
            {cartProducts.length > 0 && (
              <Button
                h={"50px"}
                _hover={{ bg: "black", color: "white" }}
                colorScheme={cartProducts.length > 0 ? "blue" : "red"}
                fontSize={"22px"}
                m={"auto"}
                w={"100%"}
                bg={"rgb(111,168,47)"}
                borderRadius={0}
                color={"white"}
                onClick={() => {
                  placeOrder();
                }}
              >
                Place Order
              </Button>
            )}
            <Flex justifyContent={"space-between"}>
              <ChatWithAdmin />
              <Button
                size="sm"
                borderRadius="none"
                colorScheme="green"
                _hover={{ bg: "black", color: "white" }}
                width="150px"
                bg="#6FA82F"
                onClick={() => window.open("/contact", "_blank")}
              >
                Contact Us
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};
export default Address;
