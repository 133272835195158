import React, { useEffect, useState } from 'react';
import { Center, Heading, Image, Text, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Success = () => {
  const [paymentDetails, setPaymentDetails] = useState("");

  const getDraftAndStoreInDB = async () => {
    let userToken = localStorage.getItem('userToken');
    try {
      let response = await axios.get('https://api.digiblocks.tech/order/draft', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.data && response.data.length > 0) {
        let draftData = response.data[0];
        console.log(draftData);
        let storeResponse = await axios.post('https://api.digiblocks.tech/order', draftData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });


        let totalPriceInUSD = response.data[0].finalAmount;
        getPaymentData(Number((totalPriceInUSD).toFixed(2)));


        const { orderID } = storeResponse.data;

        await axios.post('https://api.digiblocks.tech/sendOrderMail', { orderID });

        await axios.post('https://api.digiblocks.tech/removeitems', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });
      } else {
        console.error('No draft data available.');
      }
    } catch (error) {
      console.error('Error performing operations:', error);
    }
  };
  let storetodbfunction = async () => {
    let x = localStorage.getItem("paymentDetails");
    let userToken = localStorage.getItem("userToken");
    try {
      if (x !== "") {
        await axios.post("https://api.digiblocks.tech/payment/store", { datastring: x }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
      }
    } catch (error) {
      console.error('Error storing data to the server:', error.message);
    }
  };

  const getPaymentData = async (totalPrice) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentId = urlParams.get('paymentId');
      const PayerID = urlParams.get('PayerID');

      // Check if paymentId and PayerID are present before making the API call
      if (!paymentId || !PayerID) {
        alert('PaymentId or PayerID is missing.');
        return;
      }
      const response = await axios.post(`https://api.digiblocks.tech/pay/success?paymentId=${paymentId}&PayerID=${PayerID}`, {
        totalPrice: totalPrice
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      setPaymentDetails(response.data.paymentDetails);
      localStorage.setItem("paymentDetails", JSON.stringify(response.data?.paymentDetails));
    } catch (error) {
      console.error('Error fetching payment details:', error.message);
    }
  };

  useEffect(() => {
    getDraftAndStoreInDB();
    storetodbfunction();
  });
  let navigate = useNavigate();


  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Center>
        <Flex
          direction="column"
          gap="10px"
          border="2px solid #4CAF50"
          p={3}
          textAlign="center"
          borderRadius="lg"
          boxShadow="lg"
          maxW="400px"
          w="100%"
          m="auto"
        >
          <Helmet>
            <meta name="KnitSilk | Payment Successful" content="KnitSilk | Payment Successful" />
            <title>KnitSilk | Payment Successful</title>
          </Helmet>
          {paymentDetails != null ? (
            <>
              <Heading as="h1" fontSize="2xl" color="#4CAF50">
                Payment Successful
              </Heading>
              <Image
                alt="Payment Successful"
                src="https://i.gifer.com/origin/11/1184b4c0aa977f925dde58d2075772dd.gif"
                borderRadius="md"
              />         
              <Text fontSize="md">
                For any further queries, reach out to us at{" "}
                <b>enquiry@knitsilk.com</b>
              </Text>
              <Button
                mt={5}
                variant="solid"
                bg="#4CAF50"
                color="white"
                _hover={{ bg: "#43A047" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue Shopping
              </Button>
            </>
          ) : (
            <Text>Loading...</Text>
          )}
        </Flex>
      </Center>
    </Flex>


  );
};

export default Success;