import React, { useEffect, useState } from 'react';
import axios from 'axios';
import razorpay from '../../image/razorpay.svg'
import code_sense from '../../image/code_sense.jpg'
import "./Stylepayment.css"
import { Box } from '@chakra-ui/react';


const Razorpay = () => {
  const key_secret = 'rzp_live_qOQd13kCpjIKlj';
  const [orderid, setOrderId] = useState(null);
  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const [phone, setphone] = useState(null);
  const [finalAmount, setFinalAmount] = useState(0);
  const [loading2, setLoading2] = useState(false);
  let [data, setdata] = useState({
    key: 'rzp_live_qOQd13kCpjIKlj',
    amount: 0,
    name: '',
    currency: "INR",
    profile_name: '',
    email: '',
    number: '',
    callback_url: "https://api.digiblocks.tech/api/payment-callback",
    cancel: "https://api.digiblocks.tech/api/payment-cancel"
  })

  const getOrderId = async (amount, name, email, phone) => {
    amount = Math.round(Number(amount));
    let x = { ...data, amount: amount, name, email, number: phone };
    console.log(x)
    setLoading2(true);
    try {
        const res = await axios.post('https://api.digiblocks.tech/api/orders', { ...x });
        console.log(res);
        setOrderId(res.data);
    } catch (error) {
        console.error(error);
    } finally {
        setLoading2(false);
    }
};


  let getDraft = async () => {
    let userToken = localStorage.getItem("userToken");
    try {
      let response = await axios.get("https://api.digiblocks.tech/order", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // getting the last( or latest ) element of the array of orders
      let l=response.data.length;
      // console.log(l)
      response=response.data[l-1];
      console.log(response)
      // Process the response or return data as needed
      setname(response?.customerInfo?.billing?.customerName);
      setemail(response?.customerInfo?.billing?.email);
      setphone(response?.customerInfo?.billing?.phone);
      setFinalAmount(response?.finalAmount)
      getOrderId(response?.finalAmount, response?.customerInfo?.billing?.customerName, response?.customerInfo?.billing?.email, response?.customerInfo?.billing?.phone);
    } catch (error) {
      console.error('Error fetching drafts:', error);
    }
  }
  useEffect(() => {
    getDraft()
  }, [])
  return (
    <>
      {/* desktop view */}
      <Box>
        <div className='main' style={{ padding: "30px", width: "100%", margin: "auto" }}>
          <img width={300} src={razorpay} alt="" />
          <h5>Complete User Payment </h5>
          <div className='card px-5 py-4 mt-5'>
            <form className=''>
              <div className='col-12 '>
                <p>Amount: {finalAmount}{" "}₹</p>
              </div>

              <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
                <input type="hidden" name="key_id" value={key_secret} />
                <input type="hidden" name="amount" value={finalAmount} />
                <input type="hidden" name="order_id" value={orderid} />
                <input type="hidden" name="name" value={name} />


                <input type="hidden" name="callback_url" value={data.callback_url} />
                <input type="hidden" name="cancel_url" value={data.cancel_url} />

                <hr />
                <div className='text-center'>
                  {loading2 && <p className='m-0 my-3 text-danger'>generating order_id...</p>}
                </div>
                <div className='text-center'>
                  {orderid && <p className='m-0 my-3 text-success'>order id generated successfully!</p>}
                  {orderid && <p className='m-0'>{orderid}</p>}
                </div>
                {!loading2 ? <div className='col-12 center'>
                  <button  className='w-100' type="submit" style={{ background: "#0D94FB", width: "10%", marginTop: "50px", height: "50px", fontSize: "20px", color: "white", margin: "auto" }} disabled={orderid?false:true}>Pay Now</button>
                </div>
                  :
                  <div className='col-12 center'>
                    <button  style={{ background: "#0D94FB", width: "10%", marginTop: "50px", height: "50px", fontSize: "20px", color: "white", margin: "auto" }} className='w-100 text-center' type="submit">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Wait...</span>
                      </div>
                    </button>
                  </div>
                }

              </form>
            </form>
          </div>
        </div>
      </Box>

    </>
  )
}

export default Razorpay