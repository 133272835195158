import React, { useState } from 'react';
import { ChakraProvider, Text, Image, UnorderedList, ListItem, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const SelectNeedles = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%" border="2px solid #C9F8E8"
                    mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading as="h2" borderBottom="2px solid #74D69E"
                        paddingBottom="10px" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" mb={4}>
                        Selecting Knitting Needles
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        Knitting needles come in a stunning assortment of materials and sizes to mesh with your knitting style, the particular project you're working on, your aesthetics, and your budget.
                    </Text>
                    <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" mb={2}>
                        Sizing up Needles
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        A needle's size is determined by its diameter. The smaller the size, the narrower the needle and the smaller the stitch it makes. The following figure shows needle sizes and their US and metric equivalents.
                    </Text>
                    <UnorderedList fontSize="lg" mb={4}>
                        <ListItem>If you aren't sure what needle sizes you'll need in the future, try a circular knitting needle set with interchangeable tips. Even though the needle is designed for circular knitting, you can also use it to knit back and forth. Some sets feature plastic needle tips, some metal. These sets allow you to combine different-sized needle tips with different connector cords to make a very large range of needle sizes on the fly. An interchangeable circular needle is especially handy when you're unsure which needle size to use for a given yarn. If the current size isn't giving you the right gauge, simply switch the tip up or down one size instead of starting over on another needle.</ListItem>
                    </UnorderedList>

                    <Image
                        src="https://www.knitsilk.com/images/silk-blog/blog-7.jpg"
                        alt="Burn Test"
                        maxW="100%"
                        margin={"auto"}
                        h="auto"
                        mt={6}
                        mb={6}
                    />
                    <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" mb={2}>
                        Accounting For Needle Makeup And Tip Type
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        Knitting needles, which were first mass-produced in steel, have been made in ivory, tortoiseshell, silver, whale bone, and more. Today you can find them made in ebony and rosewood, sherbet-colored pearly plastic, Teflon-coated aluminum, and even 14-carat gold-plated (no kidding!). And that's only the beginning. Whatever your needles are made of, the material does contribute more or less to your knitting comfort, speed, and the quality of your stitches. Here are some recommendations:
                    </Text>
                    <UnorderedList fontSize="lg" mb={4}>
                        <ListItem>If you're new to knitting, you're working on double-pointed needles, or you're executing color patterns, good choices include wood (bamboo, walnut, and so on) and plastic. Wood and some plastics have a very slight grip, giving you more control over your work and discouraging dropped stitches.</ListItem>
                        <ListItem>If you're knitting in stockinette or a straightforward stitch pattern, a slippery needle makes sense. The fastest ones are nickel-plated brass and call themselves Turbo. Use these needles and watch your stitches fly by before your eyes. (Also watch for more-easily dropped stitches.)</ListItem>
                        <ListItem>Although all needles look pretty much alike, you do notice a difference in the feel of various kinds of needles and in their interaction with your knitting style and the yarn you're using. If you find that some feature of their construction or material is annoying you or interfering with the flow of your project, try a different kind of needle. Switching may make the difference between a knitting experience on cruise control and one that stops and starts and sputters along.</ListItem>
                    </UnorderedList>
                    <Text fontSize="lg" mb={4}>
                        Needle tips can be long and tapered or rounder and blunter. If you're working a project with a lot of stitch manipulation (as in lace or cables), or if you're a snug knitter, you'll have an easier time if you use a needle with a long, tapered tip. If you're knitting with a loosely spun yarn and/or you're a relaxed knitter with looser stitches, you may prefer a blunter point.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        Two kinds of needle tips.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        Though they don't fall directly into the category of different needle composition or tip type, square needles can be a great choice if you're new to knitting. They're made of metal or wood, and the shape makes them easier for the hands to hold. Stitches don't fall off these square needles as easily as the standard round ones.
                    </Text>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default SelectNeedles;
