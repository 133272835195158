import React from 'react';
import {
    Box,
    Container,
    Text,
    VStack,
    Link as ChakraLink,
    Heading,
    ChakraProvider,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Footer from '../components/HomePageComponents/Footer';
import Nav from '../components/HomePageComponents/Nav';
import TopBanner from '../components/HomePageComponents/TopBanner';
import { Link } from 'react-router-dom';
import UserChatButton from '../components/Chat/ChatButton'

const ShippingCancellationPage = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Shipping & Cancellation Policy" content="KnitSilk | Shipping & Cancellation Policy" />
                <title>KnitSilk | Shipping & Cancellation Policy</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Box py="8" backgroundColor="#f6faf2">
                <Container maxW="1200px">
                    <VStack spacing="4" align="left">
                        <Heading
                            as="h2"
                            fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                            textAlign={"center"}
                        >
                            Shipping & Cancellation
                        </Heading>

                        <Heading
                            as="h2"
                            fontSize="35px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                            my="2%"
                            textAlign={"center"}
                        >
                            Shipping Policy
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            INTERNATIONAL ORDERS: All international orders containing back-ordered or pre-ordered items will ship in one shipment as soon as the entire order is available for shipping.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            International
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Please Note: Import duties, taxes, and charges are not included in the item price or shipping cost. These charges are the buyer's responsibility and must be paid at the time of delivery. To remain ethical in our business practices, we cannot mark international shipments as "gifts". Thank you for understanding.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Shipping Geographies
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We ship worldwide.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Logistics Partners
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Shipments are fulfilled by our international logistics partner India Postal Services. By placing an international order, you acknowledge that the financial transaction and shipment will be processed by India Postal Services.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We also ship through DHL and FedEx subject to additional charges. To use them as your product carrier, please contact us through the contact page.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Deliveries
                        </Heading>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Standard
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Your order should reach you in 14 - 21 days from the time of your order.
                        </Text>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Expedited
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Should you need your order in advance, you could choose the expedited shopping option at an additional charge. You will receive your package in 7 days from the time of the order.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Exchange Rates
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Once the destination country is selected, customers will see pricing in the local or their desired currency.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Foreign exchange rates are updated on a daily basis and determined by XE.com based on interbank rates of exchange.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Payment Methods
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Visa, MasterCard, American Express, and PayPal are available for international customers.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Other Information
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Knitsilk ships Monday through Friday excluding holidays. The shipping cut-off time is 3 pm IST. Orders placed after 3 pm IST will be processed the next business day. All shipping dates are pending credit card verification for in-stock merchandise. Please allow 1-2 business days for your order to process before it is shipped. Deliveries occur Monday - Friday.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We need a little more time to create your amazing, personalized yarn stashes. Please allow an additional 3 - 5 business days processing time for custom dye lots. Once your personalized items have been created, your order will ship with the shipping method selected at checkout.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Signature Confirmation
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Some orders may require a signature at the time of delivery. If a signature is required, the carrier will make three delivery attempts, excluding weekends and holidays. The carrier will not deliver unless they acquire a signature.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Mail
                        </Heading>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            International customs charges/taxes
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            To remain ethical in our business practices, we cannot mark international shipments as "gifts." Therefore, you will be charged customs taxes on the items you purchase from us. Thank you for your understanding with this.
                        </Text>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Lost/damaged by postal service
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We are not responsible for any errors made by the postal service. However, if your package has not been received within 3 weeks of receiving your shipping notification email (6 weeks for international), please contact us via our contact page, and we will direct you on how to file a claim with the postal service. Thank you for your understanding in this regard.
                        </Text>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Returned mail
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If a package is returned because the address provided to us was incorrect, we are not responsible for the cost of re-shipping. We will contact the buyer for the correct address, and the buyer will need to re-pay shipping and handling costs before we will send the package again.
                        </Text>

                        <Heading as="h5" fontSize="20px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Refused/unclaimed mail
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If delivery is refused by the recipient or the package is unclaimed for any reason, the order will not be refunded unless the package returns to Knitsilk. If the package does return, a refund will be applied for the cost of the items only.
                        </Text>

                        <Heading
                            as="h2"
                            fontSize="35px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                            my="2%"
                            textAlign={"center"}
                        >
                            Cancellation Policy
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Knitsilk reserves the right to cancel any order without any explanation for doing so, as per the circumstances where the requirement could not be met. The company will ensure that any communication of cancellation of an order or any applicable refund will be made in a reasonable time.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Images & Representations of Products/Services
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Actual Product may differ from the image. Please refer to the product specification for accurate information.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If you have any questions or concerns, please{' '}
                            <ChakraLink as={Link} to="/contact" color="#6FA82F" textDecoration="underline">
                                contact us
                            </ChakraLink>
                            .
                        </Text>
                    </VStack>
                </Container>
            </Box>
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    );
};

export default ShippingCancellationPage;
