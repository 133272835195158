import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  Heading,
  Text,
  Stack,
  StackDivider,
  CircularProgress,
  Alert,
  AlertIcon,
  Flex,
  Button,
  Badge
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const ReturnRequestDetail = () => {
  const { id } = useParams();
  const [returnRequest, setReturnRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReturnRequest = async () => {
      try {
        const response = await fetch(
          `https://api.digiblocks.tech/order-return/returnRequests/${id}`
        );
        const data = await response.json();
        console.log(data);

        if (response.ok) {
          setReturnRequest(data);
        } else {
          setError(data.message || "Failed to fetch return request");
        }
      } catch (err) {
        setError("Server error while fetching return request");
      } finally {
        setLoading(false);
      }
    };

    fetchReturnRequest();
  }, [id]);

  if (loading) {
    return (
      <Flex justifyContent="center" mt="20px">
        <CircularProgress isIndeterminate color="teal.300" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" mt="20px">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  if (!returnRequest) {
    return null;
  }

  const { order, status, rejectionReason } = returnRequest;

  const currencyIcon = order.userCurrency === "INR" ? "₹" : "$";

  return (
    <Box maxW="80%" mx="auto" mt="20px" p="20px">
      <Flex justify="space-between" align="center" mb="4">
        <Button
          leftIcon={<ArrowBackIcon />}
          colorScheme="teal"
          variant="outline"
          onClick={() => navigate("/manager/return-orders")}
        >
          Back to Return Requests
        </Button>
      </Flex>
      <Card
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        shadow="md"
        overflow="hidden"
      >
        <CardBody>
          <Heading size="lg" mb="6" color="teal.600">
            Return Request Details
          </Heading>
          <Stack spacing={6} divider={<StackDivider borderColor="gray.200" />}>
            <Box>
              <Heading size="md" color="teal.500" mb="2">
                Customer Information
              </Heading>
              <Text fontSize="lg">
                <b>Name:</b> {order?.customerInfo?.shipping?.customerName || "N/A"}
              </Text>
              <Text fontSize="lg">
                <b>Email:</b> {order?.customerInfo?.shipping?.email || "N/A"}
              </Text>
            </Box>
            <Box>
              <Heading size="md" color="teal.500" mb="2">
                Order Information
              </Heading>
              <Text fontSize="lg">
                <b>Order Date:</b>{" "}
                {new Date(order.timeOfPayment).toLocaleString()}
              </Text>
              <Text fontSize="lg">
                <b>Total Amount:</b> {currencyIcon} {order.finalAmount}
              </Text>
              <Text fontSize="lg">
                <b>Discount:</b> {currencyIcon} {order.discount}
              </Text>
              <Text fontSize="lg">
                <b>Payment Method:</b> {order.paymentMethod}
              </Text>
            </Box>
            <Box>
              <Heading size="md" color="teal.500" mb="2">
                Products
              </Heading>
              <Stack spacing={3}>
                {order.cartProducts.map((product, index) => (
                  <Box
                    key={index}
                    p="3"
                    borderRadius="md"
                    shadow="sm"
                  >
                    <Text fontSize="lg">
                      <b>Item Name:</b> {product.title}
                    </Text>
                    <Text fontSize="lg">
                      <b>Quantity:</b> {product.quantity}
                    </Text>
                    <Text fontSize="lg">
                      <b>Price Per Item:</b> {currencyIcon}
                      {order.userCurrency === "INR"
                        ? product.priceINR
                        : product.priceUSD}
                    </Text>
                  </Box>
                ))}
              </Stack>
            </Box>
            <Box>
              <Heading size="md" color="teal.500" mb="2">
                Status
              </Heading>
              <Text fontSize="lg">
                <b>Status:</b>{" "}
                <Badge colorScheme={status === "Rejected" ? "red" : "green"}>
                  {status}
                </Badge>
              </Text>
              {status === "Rejected" && (
                <Text fontSize="lg" color="red.500">
                  <b>Rejection Reason:</b> {rejectionReason}
                </Text>
              )}
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default ReturnRequestDetail;
