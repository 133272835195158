import React, { useState } from 'react';
import { ChakraProvider, Text, List, ListItem, Image, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const IdentifySilk = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%" border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display"  borderBottom="2px solid #74D69E"
                    paddingBottom="10px" mb={4}>
                        Identifying Silk
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        How To Tell Genuine Silk From Fake Silk?
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        It seems that more and more silk fabrics are appearing in the market. Customers have big doubts about its purity when they want to buy 100% natural silk home. Therefore, there are a number of ways for reference which can be used together to try to determine if a fabric is genuine silk or a synthetic man-made fabric that looks like silk.
                    </Text>
                    <List spacing={2} mb={4}>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Price:</strong> It is the easiest way to tell pure silk from others. Genuine and high-quality silk will always be relatively expensive. Synthetic fibres like polyester can be made to look like real silk to the untrained eye. Although synthetic fabrics can be deliberately priced high, low prices usually indicate the fabric is not genuine silk. Real silk usually costs at least ten times as much as synthetics to produce.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Lustre:</strong> Silk Lustre is different from other fabrics. It is shimmering. The shimmering appearance of silk is due to the triangular prism-like structure of the silk fiber, which allows silk cloth to refract incoming light at different angles, thus producing different colors. This "shot" effect gives a surface that "shines" and appears to change color as the angle of light on it changes. Synthetic fabrics shine white, no matter what the angle of the light.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Weave:</strong> Silk can be hand-woven or machine-woven. Hand-woven silk has its own individual characteristics. Each piece is unique. Look for snubbing, and slight, very minor variations in the evenness of the weave. These are natural and to be expected. Machine-woven silk will have a perfectly even weave with no flaws and very little character. Synthetic fabrics will mostly also look perfect, although a very few artificial fabrics are made to look very realistic, including snubbing and slight imperfections.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Pattern:</strong> Genuine silk with a printed pattern (not the same as a woven pattern) will have the pattern visible on one side and an outline of the pattern on the reverse side. Genuine silk with a woven pattern will have the pattern visible on both sides but the pattern on the reverse side may appear slightly "fuzzy". Synthetic fabrics with a printed pattern will have the pattern visible on one side and a plain color on the reverse side.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Hand Touch:</strong> Smoothness! Touch it by hand, and feel the smoothness. Soft, waxy feeling is a unique characteristic of silk products. When pressed on the surface of the product, a pulling feeling will be created. If there is no such feeling from the product, it should not be the real silk.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Friction:</strong> Mutual friction of silk products will create a crisp sound, commonly known as wire-ming. If there is no wire-ming phenomenon, it is not real fiber silk.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Burn Test:</strong> This is not a standard test performed on silk! However, it is a fairly definitive test. Extracted from the edge off a bunch of silk yarn, lit with a match. The bunch of Silk will burn slowly with a faint glow. Firstly, it will curl into a ball with a similar smell of burning human hair or bird hair. Then, after burning into the dark brown globular, once you touch the globule, it will turn into ash. Finally, when leaving the flame, it stops burning immediately. The burning silk should smell like burning hair. (Both substances mainly consist of a fibrous protein-fibroin in the case of silk and keratin in the case of hair.) A synthetic fabric will burn with a smell like burning plastic and will drip, form a black ball of residue (not ash), and produce black smoke. It will continue to burn even after the flame is taken away.
                            </Text>
                        </ListItem>
                        <Image
                            src="https://narasilk.files.wordpress.com/2013/04/silk-burn-test.jpg"
                            alt="Burn Test"
                            maxW="100%"
                            margin={"auto"}
                            borderRadius={"30px"}
                            h="auto"
                            mt={6}
                            mb={6}
                        />
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>ATTENTION PLEASE!</strong> The reaction of the burn test on silk thread is very similar to the reaction of the same test with wool yarn. Make sure that there is a bucket of water closing by. Some fabrics that look like silk are actually extremely flammable and will need to be doused immediately.
                            </Text>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default IdentifySilk;
