import React from 'react'
import AdminNav from './AdminNav'
import { Box, Flex } from '@chakra-ui/react'
import B2BInquiryList from './B2BInquiryList'

const B2BADminPage = () => {
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"} >
          <B2BInquiryList />
        </Box>
      </Flex>
    </>
  )
}

export default B2BADminPage
