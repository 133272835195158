import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Nav from '../components/HomePageComponents/Nav'
import Footer from '../components/HomePageComponents/Footer';
import MyOrders from '../components/MyAccount/MyOrders';
import { Helmet } from 'react-helmet';
import UserChatButton from '../components/Chat/ChatButton'

const UserOrders = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | My Orders" content="KnitSilk | My Orders" />
                <title>KnitSilk | My Orders</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <MyOrders />
            <UserChatButton />
            <Footer />
        </ChakraProvider>
    )
}

export default UserOrders;
