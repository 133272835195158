import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import AdminNav from './AdminNav'
import OrdersSingle from './OrdersSingle'

const SingleOrder = () => {
  return (
    <Flex direction={["column", "column", "row"]}>
    <AdminNav/>
    <Box ml={["0px", "0px", "15%"]} w={"100%"}>
      <OrdersSingle/>
    </Box>

  </Flex>
  )
}

export default SingleOrder
