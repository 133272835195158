import React from 'react';
import { Helmet } from 'react-helmet';
import TopBanner from '../components/HomePageComponents/TopBanner';
import Nav from '../components/HomePageComponents/Nav';
import Footer from '../components/HomePageComponents/Footer';
import B2B from '../components/B2BComponents/B2B';
import UserChatButton from '../components/Chat/ChatButton'

const B2BPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | B2B Solutions" content="KnitSilk | B2B Solutions" />
                <title>KnitSilk | B2B Solutions</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <B2B />
            <UserChatButton/>
            <Footer />
        </>
    );
}

export default B2BPage;
