import {Text } from '@chakra-ui/react';
import React from 'react';

const SaleBanner = ({ percent }) => {
  if (percent <= 0) {
    return null;
  }

  return (
    <>
      <Text
        p={2}
        borderRadius={'20px'}
        bg={'rgb(111,168,47)'}
        color={'white'}
        left={2}
        top={2}
        position={'absolute'}
        _hover={{ transform: "scale(1.2)" }}
        animation="zoomInOut 1s infinite alternate"
        size={"12px"}
        zIndex={"999"}
      >
        {percent} % Off
      </Text>
      <style>
        {`
          @keyframes zoomInOut {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(1.1);
            }
          }
        `}
      </style>
    </>
  );
};

export default SaleBanner;
