import { ChakraProvider, Box } from '@chakra-ui/react'
import React from 'react'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Nav from '../components/HomePageComponents/Nav'
import Footer from '../components/HomePageComponents/Footer';
import vid from '../assets/Yellow Brown Playful Abstract Product Thankyou Card .mp4'
import { Helmet } from 'react-helmet';


const ThankYouOrderPage = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | ThankYou" content="KnitSilk | ThankYou" />
                <title>KnitSilk | ThankYou</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Box width={"60%"} m={"auto"}>
                <video
                    autoPlay
                    loop
                    muted
                    controls={false}
                    width="100%"
                    height="auto"
                    style={{ objectFit: 'cover' }}
                >
                    <source src={vid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>
            <Footer />
        </ChakraProvider>
    )
}

export default ThankYouOrderPage
