import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Image,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Heading,
    Text,
    Box,
    Center,
    IconButton,
    useToast,
    Tooltip,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import logo from '../../src/assets/knitsilk black logo.png';

const DiscountModal = ({ onClose }) => {
    // const [seconds, setSeconds] = useState(3);
    const [discountCode, setDiscountCode] = useState(null);
    const toast = useToast();

    let addblocked = sessionStorage.getItem('addblocked');
    useEffect(() => {
        let addblocked = sessionStorage.getItem('addblocked');
        if (!addblocked) {
            const timer = setInterval(() => {
                fetchDiscountCode();
                setTimeout(() => {
                    clearInterval(timer);
                    sessionStorage.setItem("addblocked", true)
                    onClose();
                }, 4000); // Close modal after 8 seconds
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [onClose]);


    const fetchDiscountCode = async () => {

        try {
            const response = await fetch('https://api.digiblocks.tech/discount/coupons');
            const data = await response.json();

            // Filter coupons with Active status
            const activeCoupons = data.filter((coupon) => coupon.status === 'Active');

            if (Array.isArray(activeCoupons) && activeCoupons.length > 0) {
                const randomCoupon = activeCoupons[Math.floor(Math.random() * activeCoupons.length)];
                if (randomCoupon && randomCoupon.code) {
                    setDiscountCode(randomCoupon.code);
                } else {
                    setDiscountCode('');
                }
            } else {
                // No active coupons, set a default code
                setDiscountCode('No Coupons Found');
            }
        } catch (error) {
            console.error('Error fetching discount code:', error.message);
        }
    };


    const handleCopyCode = () => {
        if (discountCode) {
            navigator.clipboard.writeText(discountCode);
            toast({
                title: 'Discount Code Copied',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setTimeout(() => {
                onClose();
            }, 3000);
        }
    };

    return (

        <Modal isOpen={addblocked ? false : true} onClose={onClose} borderRadius="xl" size={["sm", "sm", "sm"]} motionPreset="slideInBottom">
            {
                discountCode && <>
                    <ModalOverlay />

                    <ModalContent
                        bgGradient="linear(to-r, #ff0080, #7928ca)"
                        borderRadius="30px"
                        position="relative"
                    >
                        <ModalHeader alignItems={'center'} textAlign="center">
                            <Image ml={'7%'} src={logo} alt="Logo" maxH="100px" />
                            <Heading mt="20px" color={'white'} fontFamily={'DM Serif Display'} size="lg">
                                Exclusive Offer!
                            </Heading>
                        </ModalHeader>
                        <ModalCloseButton color="white" onClick={() => {
                            localStorage.setItem("addblocked", true)
                        }} />
                        <ModalBody>
                            <Center>
                                <Box>
                                    <Text fontSize="xl" textAlign="center" fontWeight="bold" color="white" mb="2">
                                        Your Discount Code:
                                    </Text>
                                    <Box border={"1px dotted white"} margin="auto" justifyContent="center" display="flex">
                                        <Text mt={2} textAlign="center" fontSize="2xl" fontWeight="bold" color="white">
                                            {discountCode}
                                        </Text>
                                        <Tooltip bg={'white'} color={'black'} label="Copy Code" hasArrow>
                                            <IconButton
                                                icon={<FaCopy />}
                                                colorScheme="white"
                                                mt={2}
                                                onClick={handleCopyCode}
                                                ml="2"
                                            />
                                        </Tooltip>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="xl" fontWeight="bold" color="white" mt="4">
                                            Hurry Up, this offer expires soon!
                                        </Text>
                                        <Text fontSize="md" color="white" mt="4">
                                            ** This offer is valid for the first order only. **
                                        </Text>
                                    </Box>
                                </Box>

                            </Center>
                        </ModalBody>
                    </ModalContent>
                </>
            }

        </Modal>
    );
};

export default DiscountModal;
