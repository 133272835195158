import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
    Box,
    Container,
    Text,
    Input,
    Button,
    Flex,
    Link,
    VStack,
    Image,
    useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import logo from '../../assets/knitsilk black logo.png';
import silkmark from '../../assets/silkmark.png';
import ReCAPTCHA from "react-google-recaptcha";

const Footer = () => {
    const twitterLink = 'https://twitter.com/i/flow/login?redirect_after_login=%2Fknitsilk';
    const etsyLink = 'https://www.etsy.com/shop/knitsilk';
    const instagramLink = 'https://www.instagram.com/knitsilk/';
    const facebookLink = 'https://www.facebook.com/knitsilk/';
    const pinterestLink = 'https://www.pinterest.com/knitsilk/';

    const [email, setEmail] = useState('');
    const toast = useToast();

    const [verified, setVerified] = useState(false);

    //recaptcha function
    function onChange(value) {
        // console.log("Captcha value:", value);
        setVerified(true)
    }

    const handleSubscribe = async () => {
        if (!verified) {
            toast({
                title: "Captcha Not Verified",
                description: "Please verify the captcha first to subscribe.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            // Frontend validation (you may add more validation as needed)
            if (!email || !email.includes('@')) {
                toast({
                    title: "Invalid Email",
                    description: "Please enter a valid email address.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }

            // Backend request
            const response = await axios.post('https://api.digiblocks.tech/subscribe/subscribe', { email });

            // Display relevant toast message
            if (response.data === 'Subscription successful!') {
                // Subscription success
                toast({
                    title: "Subscription Successful",
                    description: response.data,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else if (response.data === 'Email already subscribed!') {
                // Email already subscribed
                toast({
                    title: "Email Already Subscribed",
                    description: "This email is already subscribed. Thank you!",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }

            // Clear the email input
            setEmail('');
        } catch (error) {
            console.error('Error subscribing:', error);
            toast({
                title: "Subscription Failed",
                description: "An error occurred while subscribing. Please try again later.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box bg="#6FA82F" color="white" py={10}>
            <Container maxW="container.xl">
                <Flex direction={["column", "column", "row"]} gap={"40px"} justifyContent={"space-evenly"}>
                    <Box>
                        <Image width={"200px"} margin={["auto", "auto", "0"]} src={logo} alt="logo"></Image>
                        <Box mt={30}>
                            <Text fontSize="18px" fontWeight={"bold"}>Contact Us</Text>
                            <Text>enquiry@knitsilk.com <br />No. 71, Jumma Masjid Road, Ashira Avenue <br />Bangalore (560002), Karnataka, India</Text>
                        </Box>
                    </Box>
                    <Box>
                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                            Knitsilk Products
                        </Text>

                        {/* All Categories */}
                        <VStack align="flex-start">
                            <Link as={RouterLink} to="/products?category=Yarns&subcategories=All">Yarns</Link>
                            <Link as={RouterLink} to="/products?category=Ribbons&subcategories=All">Ribbons</Link>
                            <Link as={RouterLink} to="/products?category=Scarves&subcategories=All">Scarves</Link>
                            <Link as={RouterLink} to="/products?category=Clothing&subcategories=All">Clothing</Link>
                            <Link as={RouterLink} to="/products?category=Home Decor&subcategories=All">Home Decor</Link>
                            <Link as={RouterLink} to="/products?category=Fibres&subcategories=All">Fibres</Link>
                            <Link as={RouterLink} to="/products?category=Accessories&subcategories=All">Accessories</Link>
                        </VStack>
                    </Box>
                    <Box>
                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                            Quick Links
                        </Text>
                        <VStack align="flex-start">
                            <Link as={RouterLink} to="/">Home</Link>
                            <Link as={RouterLink} to="/about">About</Link>
                            <Link as={RouterLink} to="/products">New Arrivals</Link>
                            <Link as={RouterLink} to="/blogs">Silk Blog</Link>
                            <Link as={RouterLink} to="/contact">Contact Us</Link>
                            <Link as={RouterLink} to="/reviews">Customer Reviews</Link>
                        </VStack>
                    </Box>
                    <Box>
                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                            Let us Help you!
                        </Text>
                        <VStack align="flex-start">
                            <Link as={RouterLink} to="/login">Your Account</Link>
                            <Link as={RouterLink} to="/login">Your Order</Link>
                            <Link as={RouterLink} to="/shipping&cancellation">Shipping Policy</Link>
                            <Link as={RouterLink} to="/return&exchange">Returns & Exchange</Link>
                        </VStack>
                    </Box>
                    <Box>
                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                            General Links
                        </Text>
                        <VStack align="flex-start">
                            <Link as={RouterLink} to="/faq">FAQ</Link>
                            <Link as={RouterLink} to="/privacy-policy">Privacy Statement</Link>
                            <Link as={RouterLink} to="/terms">Terms of Service</Link>
                            <Link as={RouterLink} to="/disclaimer">Disclaimer</Link>
                        </VStack>
                    </Box>
                    <Box>
                        <Text fontSize="18px" fontWeight="bold" mb={4}>
                            Follow us On
                        </Text>
                        <VStack align="flex-start">
                            <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                                <Link >Facebook</Link>
                            </a>
                            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                                <Link>Instagram</Link>
                            </a>
                            <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                                <Link >Twitter</Link>
                            </a>
                            <a href={etsyLink} target="_blank" rel="noopener noreferrer">
                                <Link >Etsy</Link>
                            </a>
                            <a href={pinterestLink} target="_blank" rel="noopener noreferrer">
                                <Link>Pinterest</Link>
                            </a>
                            <a href="https://amazon.com/stores/KnitSilk/page/0F991B5C-06AC-4DEB-8176-0691B19AAF66?ref_=ast_bln" target="_blank" rel="noopener noreferrer">
                                <Link>Amazon</Link>
                            </a>
                        </VStack>
                    </Box>
                </Flex>
                <Flex justifyContent={"space-between"}>
                    <Flex justify={"flex-start"} ml={["", "", "20px"]} gap={"10px"} direction={["column", "column", ""]}>
                        <Flex direction={"column"}>
                            <Text fontSize="18px" fontWeight="bold">
                                Stay up to date
                            </Text>
                            <Text>Subscribe our newsletter & <br />get all the latest information</Text>
                        </Flex>
                        <Flex direction={"column"} alignItems={"center"}>
                            <Flex>
                                <Input
                                    placeholder="Enter Your email"
                                    borderRadius="0"
                                    borderColor="white"
                                    bg={"white"}
                                    value={email}
                                    color={"black"}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button
                                    borderRadius={0}
                                    border={"1px solid white"}
                                    bg="#6FA82F"
                                    color="white"
                                    _hover={{ bg: "#fff", color: "#6FA82F" }}
                                    disabled={!verified}
                                    onClick={handleSubscribe}
                                >
                                    Subscribe
                                </Button>
                            </Flex>
                            <Flex mt="2">
                                <ReCAPTCHA
                                    sitekey="6LdipecpAAAAAAtUQzQkTxn9oaZKHmIurcFFeV8c"
                                    onChange={onChange}
                                />
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex display={["none", "", "flex"]}
                        direction={"column"}
                        flexWrap={"wrap"}
                        justifyContent={["flex-end", "", "none"]}
                        gap={"5"}   >
                        <Flex gap={8} >
                            <Image
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR14GyMwGoBN1K9sNRIkm2YRcPmyazZUluFJU1o2ZSpj1G6wqCCF_vWz2-BnHJGPbeF8sc&usqp=CAU"
                                alt="PayPal Acceptance Mark"
                                width="120px"
                                height={"60px"}
                                bg={"white"}

                            />

                            <Image
                                src="https://sugermint.com/wp-content/uploads/2022/01/Razorpay-Startup-Story.jpg"
                                alt="RP"
                                width="120px"
                                height={"60px"}
                                bg={"white"}

                            />
                            <Image src="https://t4.ftcdn.net/jpg/05/33/02/07/360_F_533020758_0XMfvXWfOeXAzC4oE0tDI7gzOJyLBaXz.jpg"
                                alt="SSL"
                                height={"60px"}
                                width="120px">
                            </Image>
                        </Flex>
                        <Flex gap={8}>
                            <Image src={silkmark}
                                alt="SSL"
                                height={"90px"}
                                width="120px">
                            </Image>
                            <Image src="https://i0.wp.com/www.wedoimport.com/w/wp-content/uploads/projects/8/cover-trustseal.png"
                                alt="SSL"
                                height={"90px"}
                                width="120px">
                            </Image>
                        </Flex>
                    </Flex>
                </Flex>


                <Flex mt={4} display={["flex", "", "none"]} pl={5}
                    direction={"column"}
                    justifyContent={["center", "", "none"]}
                    gap={"8"} align="center"  >
                    <Flex direction={"column"} gap={3} w={"100%"}>
                        <Image
                            src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/IN/logo-center/logo-center-solution-graphics.png"
                            alt="PayPal Acceptance Mark"
                            width="120px"
                            bg={"white"}
                        />
                        <Image
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Razorpay_logo.svg/1599px-Razorpay_logo.svg.png"
                            alt="RP"
                            width="120px"
                            bg={"white"}

                        />
                        <Image src="https://t4.ftcdn.net/jpg/05/33/02/07/360_F_533020758_0XMfvXWfOeXAzC4oE0tDI7gzOJyLBaXz.jpg"
                            alt="SSL"
                            width="150px">
                        </Image>
                    </Flex>
                    <Flex w={"100%"} justifyContent={"space-between"} gap={3}>
                        <Image src={silkmark}
                            alt="SSL"
                            width="120px">
                        </Image>
                        <Image src="https://i0.wp.com/www.wedoimport.com/w/wp-content/uploads/projects/8/cover-trustseal.png"
                            alt="SSL"
                            width="120px">
                        </Image>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
};

export default Footer;