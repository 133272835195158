import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, Heading, Input, Select, Text, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { FaEye, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import AdminNav from './AdminNav';

const Createuser = () => {
  const [passwordVisibility, setPasswordVisibility] = useState([]);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    userRole: '',
  });
  const [show, setShow] = useState('create');
  const [allUsers, setAllUsers] = useState([]);
  const toast = useToast();

  const togglePasswordVisibility = (index) => {
    let newarr = [...passwordVisibility];
    newarr[index] = !newarr[index];
    setPasswordVisibility(newarr);
  };

  const createNewUserFunction = async () => {
    try {
      const response = await axios.post('https://api.digiblocks.tech/api/createsubusers', {
        email: newUser.email,
        password: newUser.password,
        userRole: newUser.userRole,
      });
      toast({
        title: 'Success',
        description: 'New user created',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setNewUser({
        email: '',
        password: '',
        userRole: '',
      });
      getAllSubUsers();
    } catch (e) {
      // console.log(e);
    }
  };

  const getAllSubUsers = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/api/getsubusers');
      setAllUsers(response.data);
      const defaultVisibility = Array(response.data.length).fill(false);
      setPasswordVisibility(defaultVisibility);
    } catch (error) {
      console.error('Error fetching sub-users:', error);
    }
  };

  useEffect(() => {
    getAllSubUsers();
  }, []);

  let deleteuser = async (id) => {
    try {
      let response = await axios.delete(`https://api.digiblocks.tech/api/deletesubusers/${id}`);
      getAllSubUsers();
      toast({
        title: 'Success',
        description: 'User deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const inputRef = useRef(null);

  const handleCopy = () => {
    const value = inputRef.current.innerText;
    try {
      navigator.clipboard.writeText(value);
      toast({
        title: 'Copied',
        description: 'Password copied to clipboard',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Copy failed:', error);
      toast({
        title: 'Error',
        description: 'Failed to copy password to clipboard',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <AdminNav />
      <Box ml={['', '', '250px']} as={Flex} direction="column" w="80%" gap={5}>
        <Center mt={5}>
          <Button
            _hover={{ bg: 'teal', color: 'white' }}
            bg="green"
            color="white"
            onClick={() => setShow(show === 'create' ? 'list' : 'create')}
          >
            {show === 'create' ? 'List of Users' : 'Create Users'}
          </Button>
        </Center>
        {show === 'create' ? (
          <Box border="1px solid gray" borderRadius="xl" p="6" m="auto" display="flex" gap="8" flexDirection="column" maxWidth="500px">
            <Heading mt={3} textAlign="center">Create New User</Heading>
            <Input
              placeholder="Email"
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              value={newUser.email}
              variant="filled"
            />
            <Input
              placeholder="Password"
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              value={newUser.password}
              variant="filled"
            />
            <Select
              placeholder="Select User Role"
              onChange={(e) => setNewUser({ ...newUser, userRole: e.target.value })}
              value={newUser.userRole}
              variant="filled"
            >
              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
              <option value="marketing">Marketing</option>
              <option value="manager">Manager</option>
            </Select>
            <Button
              bg="green"
              color="white"
              _hover={{ bg: 'teal', color: 'white' }}
              onClick={() => {
                if (newUser.email !== "" && newUser.password !== "" && newUser.userRole !== "") {
                  createNewUserFunction();
                } else {
                  toast({
                    title: "Incomplete Information",
                    description: "Please enter all information",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
              variant="solid"
            >
              Create User
            </Button>
          </Box>

        ) : (
          <Box w="100%" p={4} boxShadow="md" borderRadius="md" bg="white">
            <Heading as="h2" size="md" mb={4}>
              All Users
            </Heading>
            <Flex direction="column" w="100%" bg="gray.100" p={4} borderRadius="md">
              <Flex fontWeight="bold" w="100%" mb={2}>
                <Text flex={1}>Email</Text>
                <Text flex={1}>Password</Text>
                <Text flex={1}>Role</Text>
                <Text flex={1}>Actions</Text>
              </Flex>
              {allUsers.map((user, index) => (
                <Flex key={index} justifyContent="space-between" alignItems="center" bg="white" p={2} borderRadius="md" boxShadow="sm" mb={2}>
                  <Text flex={1}>{user.email}</Text>
                  <Flex flex={1} alignItems="center">
                    {passwordVisibility[index] ? (
                      <Text ref={inputRef}>{user.password}</Text>
                    ) : (
                      <Text>{'*'.repeat(user.password.length)}</Text>
                    )}
                    <Box display={"flex"} mt="-5" ml="4">
                      <FaEye cursor="pointer" onClick={() => togglePasswordVisibility(index)} ml={2} />
                      {passwordVisibility[index] && <CopyIcon onClick={handleCopy} cursor="pointer" ml={2} />}
                    </Box>
                  </Flex>
                  <Text flex={1}>{user.userRole}</Text>
                  <Button flex={1} onClick={() => deleteuser(user._id)} colorScheme="red" variant="outline">
                    <FaTrash />
                  </Button>
                </Flex>
              ))}
            </Flex>
          </Box>

        )}
      </Box>
    </div>
  );
};

export default Createuser;
