import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Heading,
    VStack,
    HStack,
    Input,
    Button,
    useToast,
    FormControl,
    FormLabel,
    Spinner,
    InputGroup,
    Flex,
    InputRightElement,
    Text,
    SimpleGrid,
    MenuButton,
    Menu,
    MenuList,
    MenuItem,
    Image,
    Select,
} from '@chakra-ui/react';
import { FaLongArrowAltLeft, FaEye } from "react-icons/fa";
import {
    FaRupeeSign,
    FaDollarSign,
    FaPoundSign,
    FaEuroSign,
    FaYenSign,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminNav from './AdminNav';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';

const VariationPage = () => {
    let [showUI, setShowUI] = useState("");
    const apiUrl = "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_1tt8wr2ipN8IvW2CeX3dgfWzv5lrOwWH4yIxz6ts&currencies=EUR%2CUSD%2CCAD%2CGBP%2CJPY%2CAUD";
    const { id } = useParams();
    const [colornum, setcolornum] = useState(1);
    const [lengthnum, setlengthnum] = useState(1);
    const [quantitynum, setquantitynum] = useState(1);
    const [product, setProduct] = useState({});
    const [bullet, setBullet] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [originalProduct, setOriginalProduct] = useState({});
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();
    const [allColors, setallColors] = useState([]);
    let [showinput1, setshowinput1] = useState(false)
    let [customcolor1, setCustomcolor1] = useState("")
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/colors');
                const colorsData = response.data;
                setallColors(colorsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    const [conversionRates, setConversionRates] = useState({
        EUR: "",
        USD: "",
        CAD: "",
        GBP: "",
        JPY: "",
        AUD: ""
    });
    let [usdPrice, setUsdPrice] = useState(product.priceUSD);
    const fetchConversionRates = async () => {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (data && data.data) {
                const rates = data.data;
                setConversionRates({
                    EUR: rates.EUR,
                    USD: usdPrice,
                    CAD: rates.CAD,
                    GBP: rates.GBP,
                    JPY: rates.JPY,
                    AUD: rates.AUD
                })
            }
        } catch (error) {
            console.error("Error fetching conversion rates:", error);
        }
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get(`https://api.digiblocks.tech/products/${id}`);
                fetchConversionRates();
                setBullet(response.data.bulletPoints)
                setPhotos([...response.data.photos]);
                setProduct(response.data);
                setOriginalProduct(response.data);
                setUsdPrice(response.data.priceUSD);
            } catch (error) {
                console.error('Error fetching product details:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch product details',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchProductDetails();
    }, [id, toast]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'priceUSD') {
            setUsdPrice(value)
            setProduct({
                ...product,
                priceUSD: value,
                priceAUD: (usdPrice * conversionRates.AUD).toFixed(2),
                priceCAD: (usdPrice * conversionRates.CAD).toFixed(2),
                priceEUR: (usdPrice * conversionRates.EUR).toFixed(2),
                priceGBP: (usdPrice * conversionRates.GBP).toFixed(2),
                priceJPY: (usdPrice * conversionRates.JPY).toFixed(2)
            })
        }
        if (name === 'priceGBP') {
            setProduct({ ...product, priceGBP: value })
        }
        if (name === 'priceAUD') {
            setProduct({ ...product, priceAUD: value })
        }
        if (name === 'priceCAD') {
            setProduct({ ...product, priceCAD: value })
        }
        if (name === 'priceJPY') {
            setProduct({ ...product, priceJPY: value })
        } else {
            setProduct({ ...product, [name]: value });
        }
    };

    const handleCancel = () => {
        setProduct(originalProduct);
    };

    const handlePublish = async () => {
        try {
            await axios.post(`https://api.digiblocks.tech/products/`, product);
            toast({
                title: 'Success',
                description: 'Copied Product Added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/manager/listing')
        } catch (error) {
            console.error('Error updating product:', error);
            toast({
                title: 'Error',
                description: 'Failed to update product',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    let setcustomcolorfunc = (e) => {
        setCustomcolor1(e.target.value)
        setProduct({ ...product, color: e.target.value })
    }
    const handleColor = (e) => {
        if (e.target.value === "custom") {
            setshowinput1(true);
        } else {
            setCustomcolor1(e.target.value);
            setProduct({ ...product, color: e.target.value });
            setshowinput1(false);
        }
    }

    const removeImage = (i) => {
        const updatedPhotos = [...photos];
        updatedPhotos.splice(i, 1);
        setPhotos(updatedPhotos);
        setProduct({ ...product, photos: updatedPhotos });
    };

    let [load, setlaod] = useState(false);
    const onPhotoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 9 - Number(photos.length)) {
            alert(`You can add more ${9 - Number(photos.length)} images`)
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                setlaod(true)
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                let arr = response.data.photos.map(photo => photo.url);
                setPhotos([...photos, ...arr])
                setProduct({
                    ...product,
                    photos: [...product.photos, ...arr]
                });
                setlaod(false)

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };
    const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
        onDrop: onPhotoDrop,
        accept: 'image/*',
    });
    const dropzoneStyles = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    return (
        <>
            <AdminNav />
            <Box ml={["", "", "250px"]} p={[3, 3, 4]}>
                <Flex flex={"3"} justifyContent={"space-between"}> <Heading flex={"2"} fontSize={["20px", "", "32px"]}>Add Variations</Heading>
                    <Flex flex={"1"} flexWrap={"wrap"} gap={[4, 3, 2]} >
                        <Button
                            color="white"
                            bg="black"
                            borderRadius={"none"}
                            _hover={{ bg: "black", color: "white" }}
                            leftIcon={<FaLongArrowAltLeft />}
                            onClick={() => navigate(`/manager/listing`)}
                        >
                            Back To Listings
                        </Button>

                        <Button
                            color="white"
                            bg="black"
                            borderRadius={"none"}
                            _hover={{ bg: "black", color: "white" }}
                            leftIcon={<FaEye />}
                            onClick={() => navigate(`/products/${id}`)}
                        >
                            View on Knitsilk
                        </Button>
                    </Flex>
                </Flex>
                <Flex direction="column" gap={3} w="30%" mt={5}>
                    <Button borderRadius={"0px"} bg={"black"} color={"white"} onClick={() => {
                        setShowUI("color")
                    }}>Color Variations</Button>
                    <Button borderRadius={"0px"} bg={"black"} color={"white"} onClick={() => {
                        setShowUI("quantity")
                    }}>Quantity Variations</Button>
                    <Button borderRadius={"0px"} bg={"black"} color={"white"} onClick={() => {
                        setShowUI("length")
                    }}>Length Variations</Button>
                </Flex>
                {isLoading ? (
                    <Spinner color='green' size="xl" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : <>
                    {showUI == "" && <>
                        <h1 style={{ textAlign: "center", marginTop: "20px" }}>Please Select One Variation from above</h1>
                    </>}
                    {
                        // clolor
                        showUI == "color" && <VStack spacing={4} mt={4} align="stretch">
                            <Heading>Color Variations</Heading>
                            {/* Pricing */}
                            {Array.from({ length: colornum }).map((_, index) => (
                                <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"20px"}>
                                    <>
                                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                                            <Heading fontSize={"24px"}>Pricing & Inventory</Heading>
                                            <SimpleGrid columns={[2, 2, 4]} gap={4}>
                                                <FormControl>
                                                    <FormLabel>Price in INR</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" name="priceINR" value={product.priceINR} onChange={handleInputChange} />
                                                        <InputRightElement children={<FaRupeeSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Price in USD</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" value={usdPrice} name="priceUSD" onChange={handleInputChange} />
                                                        <InputRightElement children={<FaDollarSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Color</FormLabel>
                                                    <Box>
                                                        <Menu>
                                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                                Select color
                                                            </MenuButton>
                                                            <MenuList maxH={"400px"} overflowY={"scroll"}>
                                                                {allColors.map((color, i) => (
                                                                    <MenuItem keys={i} onClick={handleColor} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                                                ))}
                                                                <MenuItem value={"custom"} onClick={handleColor}>custom</MenuItem>

                                                            </MenuList>

                                                        </Menu>
                                                        {showinput1 &&
                                                            <>
                                                                <Text>Enter Custom Color</Text>
                                                                <Input onChange={setcustomcolorfunc}></Input>
                                                            </>
                                                        }
                                                        {customcolor1 && <Text>Selected Color : {customcolor1}</Text>}
                                                    </Box>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Quantity In Stock</FormLabel>
                                                    <Input type="number" name="qtyInStock" value={product.qtyInStock} onChange={handleInputChange} />
                                                </FormControl>
                                                <FormControl display={"none"}>
                                                    <FormLabel>Price in GBP</FormLabel>
                                                    <InputGroup>
                                                        {/* xxx */}
                                                        <Input type="number" name="priceGBP" value={(usdPrice * conversionRates.GBP).toFixed(2)} onChange={handleInputChange} />
                                                        <InputRightElement children={<FaPoundSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl display={"none"}>
                                                    <FormLabel>Price in EUR</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" name="priceEUR" value={(usdPrice * conversionRates.EUR).toFixed(2)} onChange={handleInputChange} />
                                                        <InputRightElement children={<FaEuroSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                            </SimpleGrid>
                                            <HStack spacing={4} display={"none"}>
                                                <FormControl>
                                                    <FormLabel>Price in CAD</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" value={(usdPrice * conversionRates.CAD).toFixed(2)} name="priceCAD" onChange={handleInputChange} />
                                                        <InputRightElement children={<FaDollarSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Price in AUD</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" name="priceAUD" value={(usdPrice * conversionRates.AUD).toFixed(2)} onChange={handleInputChange} />
                                                        <InputRightElement children={<FaDollarSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Price in JPY</FormLabel>
                                                    <InputGroup>
                                                        <Input type="number" name="priceJPY" value={(usdPrice * conversionRates.JPY).toFixed(2)} onChange={handleInputChange} />
                                                        <InputRightElement children={<FaYenSign />} />
                                                    </InputGroup>
                                                </FormControl>
                                            </HStack>
                                        </Box>
                                    </>
                                    <FormControl>
                                        <Heading fontSize={"24px"}>Media</Heading>
                                        <SimpleGrid gap={5} columns={[1, 2, 4]} spacing={4} p={3}>
                                            {/* show all previous photos here  */}
                                            {
                                                photos && load ? <Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='blue.500'
                                                    size='xl'
                                                /> : photos.map((e, index) => {
                                                    return <Flex p={2} border={"1px solid teal"} borderRadius={"12px"} justifyContent={"flex-end"} direction={"column"} alignItems={"center"} gap={2}>
                                                        {index == 0 && <Text >Thumbnail image</Text>}
                                                        <Image w={"120px"} src={e} ></Image>
                                                        <Button _active={{ bg: "white" }}
                                                            _focus={{ bg: "white" }} bg={"white"} onClick={() => {
                                                                removeImage(index)
                                                            }}><DeleteIcon color={"red"}></DeleteIcon></Button>
                                                    </Flex>
                                                })
                                            }
                                        </SimpleGrid>

                                        <FormLabel>Upload upto 9 Images</FormLabel>
                                        <>
                                            <div>
                                                <SimpleGrid gap={5} columns={1}>
                                                    <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
                                                        <input  {...photoInputProps()} />
                                                        <Text>Drag & drop some photos here, or click to select photos </Text>
                                                    </Box>
                                                </SimpleGrid>
                                            </div>
                                        </>
                                    </FormControl>
                                </Box>
                            ))}
                            {/* Submit button */}
                            <Flex flex={"8"} gap={1} flexWrap={"wrap"} mt={2} w={"100%"} justifyContent={"space-between"}>
                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    bg={"black"}
                                    onClick={handleCancel}
                                    color={"white"}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    colorScheme="green"
                                    onClick={handlePublish}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Publish
                                </Button>

                            </Flex>
                        </VStack>
                    }
                    {
                        // quantity
                        showUI == "quantity" && <VStack spacing={4} mt={4} align="stretch">
                            <Heading>Quantity Variations</Heading>

                            {/* Pricing */}
                            {quantitynum && <>

                                {Array.from({ length: quantitynum }).map((_, index) => (
                                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"20px"}>
                                        <>
                                            <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                                                <Heading fontSize={"24px"}>Pricing & Inventory</Heading>
                                                <SimpleGrid columns={[2, 2, 4]} gap={4}>
                                                    <FormControl>
                                                        <FormLabel>Price in INR</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceINR" value={product.priceINR} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaRupeeSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in USD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" value={usdPrice} name="priceUSD" onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel>Units Of Quantity</FormLabel>
                                                        <Select placeholder="Select option" name="qtyUnit" value={product.qtyUnit} onChange={handleInputChange}>
                                                            <option value="Packs Of">Packs Of</option>
                                                            <option value="gram(g)">Gram (g)</option>
                                                            <option value="kilogram(kg)">Kilogram (Kg)</option></Select>

                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Value</FormLabel>
                                                      <Input onChange={handleInputChange} name="qtyVariationValue" ></Input>

                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel>Quantity In Stock</FormLabel>
                                                        <Input type="number" name="qtyInStock" value={product.qtyInStock} onChange={handleInputChange} />
                                                    </FormControl>
                                                    <FormControl display={"none"}>
                                                        <FormLabel>Price in GBP</FormLabel>
                                                        <InputGroup>
                                                            {/* xxx */}
                                                            <Input type="number" name="priceGBP" value={(usdPrice * conversionRates.GBP).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaPoundSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl display={"none"}>
                                                        <FormLabel>Price in EUR</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceEUR" value={(usdPrice * conversionRates.EUR).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaEuroSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                </SimpleGrid>
                                                <HStack spacing={4} display={"none"}>
                                                    <FormControl>
                                                        <FormLabel>Price in CAD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" value={(usdPrice * conversionRates.CAD).toFixed(2)} name="priceCAD" onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in AUD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceAUD" value={(usdPrice * conversionRates.AUD).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in JPY</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceJPY" value={(usdPrice * conversionRates.JPY).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaYenSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                </HStack>
                                            </Box>
                                        </>
                                    </Box>
                                ))}
                            </>}
                            {/* Submit button */}
                            <Flex flex={"8"} gap={1} flexWrap={"wrap"} mt={2} w={"100%"} justifyContent={"space-between"}>
                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    bg={"black"}
                                    onClick={handleCancel}
                                    color={"white"}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    colorScheme="green"
                                    onClick={handlePublish}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Publish
                                </Button>

                            </Flex>
                        </VStack>
                    }
                    {
                        // length
                        showUI == "length" && <VStack spacing={4} mt={4} align="stretch">
                            <Heading>Length Variations</Heading>

                            {/* Pricing */}
                            {lengthnum && <>

                                {Array.from({ length: lengthnum }).map((_, index) => (
                                    <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"20px"}>
                                        <>
                                            <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                                                <Heading fontSize={"24px"}>Pricing & Inventory</Heading>
                                                <SimpleGrid columns={[2, 2, 4]} gap={4}>
                                                    <FormControl>
                                                        <FormLabel>Price in INR</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceINR" value={product.priceINR} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaRupeeSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in USD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" value={usdPrice} name="priceUSD" onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Units Of Length</FormLabel>
                                                        <Select placeholder="Select option" name="lengthVariationUnit" value={product.lengthVariationUnit} onChange={handleInputChange}>
                                                            <option value="cm">Centimeter(cm)</option>
                                                            <option value="m">Meter(m)</option>
                                                            <option value="inch">Inch</option>
                                                            <option value="mm">MM</option>
                                                            <option value="yards">Yards</option>
                                                        </Select>

                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Value</FormLabel>
                                                      <Input onChange={handleInputChange} name="lengthVariationValue" ></Input>

                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Quantity In Stock</FormLabel>
                                                        <Input type="number" name="qtyInStock" value={product.qtyInStock} onChange={handleInputChange} />
                                                    </FormControl>
                                                    <FormControl display={"none"}>
                                                        <FormLabel>Price in GBP</FormLabel>
                                                        <InputGroup>
                                                            {/* xxx */}
                                                            <Input type="number" name="priceGBP" value={(usdPrice * conversionRates.GBP).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaPoundSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl display={"none"}>
                                                        <FormLabel>Price in EUR</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceEUR" value={(usdPrice * conversionRates.EUR).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaEuroSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                </SimpleGrid>
                                                <HStack spacing={4} display={"none"}>
                                                    <FormControl>
                                                        <FormLabel>Price in CAD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" value={(usdPrice * conversionRates.CAD).toFixed(2)} name="priceCAD" onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in AUD</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceAUD" value={(usdPrice * conversionRates.AUD).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaDollarSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Price in JPY</FormLabel>
                                                        <InputGroup>
                                                            <Input type="number" name="priceJPY" value={(usdPrice * conversionRates.JPY).toFixed(2)} onChange={handleInputChange} />
                                                            <InputRightElement children={<FaYenSign />} />
                                                        </InputGroup>
                                                    </FormControl>
                                                </HStack>
                                            </Box>
                                        </>
                                    </Box>
                                ))}
                            </>}
                            {/* Submit button */}
                            <Flex flex={"8"} gap={1} flexWrap={"wrap"} mt={2} w={"100%"} justifyContent={"space-between"}>
                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    bg={"black"}
                                    onClick={handleCancel}
                                    color={"white"}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    flex={"2"}
                                    borderRadius={"none"}
                                    colorScheme="green"
                                    onClick={handlePublish}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    Publish
                                </Button>

                            </Flex>
                        </VStack>
                    }
                </>}
            </Box>
        </>
    );
};

export default VariationPage;






     