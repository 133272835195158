import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    Input,
    Tag,
    Button,
    TagLabel,
    TagCloseButton,
    IconButton,
    useToast,
    Switch,
    Box,
} from '@chakra-ui/react';
import { FaEdit, FaSave } from 'react-icons/fa';

const CourierSettings = () => {
    const [couriers, setcouriers] = useState([]);
    const [newcourier, setNewcourier] = useState('');
    const [editingcourier, setEditingcourier] = useState(null);
    const [editedValue, setEditedValue] = useState('');
    const [showAllcouriers, setShowAllcouriers] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const toast = useToast();

    useEffect(() => {
        fetchcouriers();
    }, []);

    const fetchcouriers = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/couriers');
            const data = await response.json();
            setcouriers(data.map(courier => ({ ...courier })));
        } catch (error) {
            console.error('Error fetching couriers:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewcourier(e.target.value);
        setIsButtonDisabled(!e.target.value.trim()); // Disable the button if the input is empty or contains only whitespace
    };

    const handleAddcourier = async () => {
        try {
            const response = await fetch('https://api.digiblocks.tech/adminsetting/couriers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ couriers: newcourier }),
            });

            if (response.ok) {
                setNewcourier('');
                setIsButtonDisabled(true); // Disable the button after adding a courier
                fetchcouriers();
                showToast('courier added successfully', 'success');
            } else {
                console.error('Error adding courier:', response.statusText);
                showToast('Error adding courier', 'error');
            }
        } catch (error) {
            console.error('Error adding courier:', error);
            showToast('Error adding courier', 'error');
        }
    };

    const handleDeletecourier = async (courier) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/couriers/${courier._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchcouriers();
                showToast('courier deleted successfully', 'success');
            } else {
                console.error('Error deleting courier:', response.statusText);
                showToast('Error deleting courier', 'error');
            }
        } catch (error) {
            console.error('Error deleting courier:', error);
            showToast('Error deleting courier', 'error');
        }
    };

    const handleEditcourier = (courier) => {
        setEditingcourier(courier._id);
        setEditedValue(courier.couriers);
    };

    const handleSaveEdit = async (courier) => {
        try {
            const response = await fetch(`https://api.digiblocks.tech/adminsetting/couriers/${courier._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ couriers: editedValue }),
            });

            if (response.ok) {
                setEditingcourier(null);
                fetchcouriers();
                showToast('courier updated successfully', 'success');
            } else {
                console.error('Error updating courier:', response.statusText);
                showToast('Error updating courier', 'error');
            }
        } catch (error) {
            console.error('Error updating courier:', error);
            showToast('Error updating courier', 'error');
        }
    };

    const showToast = (message, status) => {
        toast({
            title: message,
            status,
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box border={"1px solid teal"} borderRadius={"3xl"} p={4} my={4}>
            <Text fontSize="2xl" mb={4} fontWeight="bold">Manage Courier Partners</Text>
            <Flex justifyContent="space-between" direction={["column", "column", "row"]} mb={4}>
                <Flex>
                    <Input
                        width={"100%"}
                        placeholder="Enter a new courier"
                        value={newcourier}
                        borderRadius={"2xl"}
                        onChange={handleInputChange}
                    />
                    <Button
                        ml={2}
                        borderRadius={"2xl"}
                        onClick={handleAddcourier}
                        colorScheme="green"
                        isDisabled={isButtonDisabled}
                    >
                        Add
                    </Button>
                </Flex>
                <Flex>
                    <Switch
                        colorScheme="green"
                        size="lg"
                        mt={["10px", "10px", "0px"]}
                        m={["auto", "", ""]}
                        fontWeight={"semibold"}
                        isChecked={showAllcouriers}
                        onChange={() => setShowAllcouriers(!showAllcouriers)}
                    >
                        Show All Courier Partners
                    </Switch>
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                {couriers.map((courier) => (
                    <Tag
                        key={courier._id}
                        m={1}
                        size="lg"
                        variant="solid"
                        colorScheme="blue"
                        borderRadius="full"
                        bg={"darkblue"}
                        _hover={{ cursor: 'pointer', opacity: 0.8 }}
                        display={showAllcouriers ? 'flex' : editingcourier === courier._id ? 'flex' : 'none'}
                    >
                        {editingcourier === courier._id ? (
                            <>
                                <Input
                                    value={editedValue}
                                    onChange={(e) => setEditedValue(e.target.value)}
                                    borderRadius={"full"}
                                    marginRight={2}
                                />
                                <IconButton
                                    bg={"none"}
                                    icon={<FaSave />}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleSaveEdit(courier)}
                                    borderRadius={"full"}
                                />
                                <TagCloseButton color="white" onClick={() => setEditingcourier(null)} />
                            </>
                        ) : (
                            <>
                                <TagLabel>{courier.couriers}</TagLabel>
                                <TagCloseButton color="white" onClick={() => handleDeletecourier(courier)} />
                                <IconButton
                                    icon={<FaEdit />}
                                    bg={"none"}
                                    color="white"
                                    colorScheme="blue"
                                    onClick={() => handleEditcourier(courier)}
                                    borderRadius={"full"}
                                    ml={2}
                                />
                            </>
                        )}
                    </Tag>
                ))}
            </Flex>
        </Box>
    );
};

export default CourierSettings;
