import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Icon,
  Modal,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import {
  FaTwitter,
  FaPinterestP,
  FaEtsy,
  FaFacebook,
  FaInstagram,
  FaAmazon,
} from 'react-icons/fa';
import { InfoIcon } from '@chakra-ui/icons';

const TopBanner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const twitterLink = 'https://twitter.com/i/flow/login?redirect_after_login=%2Fknitsilk';
  const etsyLink = 'https://www.etsy.com/shop/knitsilk';
  const instagramLink = 'https://www.instagram.com/knitsilk/';
  const facebookLink = 'https://www.facebook.com/knitsilk/';
  const pinterestLink = 'https://www.pinterest.com/knitsilk/';
  const currencies = ['INR', 'USD', 'EUR', 'GBP', 'AUD', 'JPY', 'CAD'];
  const [userCurrency, setUserCurrency] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  useEffect(() => {
    const storedCurrency = localStorage.getItem('userCurrency');
    setUserCurrency(storedCurrency || '');
    const fetchUserCurrency = async () => {
      try {
        if (!storedCurrency) {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          const currencyToStore = currencies.includes(data.currency) ? data.currency : 'USD';
          setUserCurrency(currencyToStore);
          localStorage.setItem('userCurrency', currencyToStore);
        }
      } catch (error) {
        console.error('Error fetching user currency:', error.message);
      }
    };
    fetchUserCurrency();
  }, [currencies]);
  const handleCurrencyChange = (e) => {
    const selected = e.target.value;
    const newCurrency = currencies.includes(selected) ? selected : 'USD';
    setUserCurrency(newCurrency);
    localStorage.setItem('userCurrency', newCurrency);
    setSelectedCurrency('');
    window.location.reload();
  };

  return (
    <Box bg="#6FA82F" color="white" p={2} px={{ base: '5%', md: '10%', lg: '7%' }} >
      <Flex justifyContent="space-between" alignItems="center" h={"30px"}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Follow us on</ModalHeader>
            <ModalCloseButton />
            <ModalBody bg={'#6FA82F'} gap={'10px'}>
              <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
                <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    aria-label="Twitter"
                    icon={<Icon as={FaTwitter} boxSize={5} color="white" />}
                    variant="ghost"
                    fontSize="lg"
                    mr={2}
                    _hover={{
                      bg: 'whiteAlpha.300',
                      color: '#6FA82F',
                    }}
                  />
                </a>
                <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    aria-label="Facebook"
                    icon={<Icon as={FaFacebook} boxSize={5} color="white" />}
                    variant="ghost"
                    fontSize="lg"
                    mr={2}
                    _hover={{
                      bg: 'whiteAlpha.300',
                      color: '#6FA82F',
                    }}
                  />
                </a>
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    aria-label="Instagram"
                    icon={<Icon as={FaInstagram} boxSize={5} color="white" />}
                    variant="ghost"
                    fontSize="lg"
                    mr={2}
                    _hover={{
                      bg: 'whiteAlpha.300',
                      color: '#6FA82F',
                    }}
                  />
                </a>
                <a href={etsyLink} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    aria-label="Etsy"
                    icon={<Icon as={FaEtsy} boxSize={5} color="white" />}
                    variant="ghost"
                    fontSize="lg"
                    mr={2}
                    _hover={{
                      bg: 'whiteAlpha.300',
                      color: '#6FA82F',
                    }}
                  />
                </a>
                <a href={pinterestLink} target="_blank" rel="noopener noreferrer">
                  <IconButton
                    aria-label="Pinterest"
                    icon={<Icon as={FaPinterestP} boxSize={5} color="white" />}
                    variant="ghost"
                    fontSize="lg"
                    mr={2}
                    _hover={{
                      bg: 'whiteAlpha.300',
                      color: '#6FA82F',
                    }}
                  />
                </a>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Flex alignItems={'center'} display={['none', 'none', 'none']}>
          <InfoIcon onClick={onOpen} />
        </Flex>
        <Flex alignItems="center" display={['flex', 'flex', 'flex']}>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Twitter"
              icon={<Icon as={FaTwitter} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Facebook"
              icon={<Icon as={FaFacebook} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Instagram"
              icon={<Icon as={FaInstagram} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
          <a href={etsyLink} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Etsy"
              icon={<Icon as={FaEtsy} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
          <a href={pinterestLink} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Pinterest"
              icon={<Icon as={FaPinterestP} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
          <a href="https://www.amazon.com/stores/KnitSilk/page/0F991B5C-06AC-4DEB-8176-0691B19AAF66?ref_=ast_bln" target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="Pinterest"
              icon={<Icon as={FaAmazon} boxSize={{ base: 4, md: 5 }} color="white" />}
              variant="ghost"
              fontSize="lg"
              mr={{ base: 1, md: 2 }}
              _hover={{
                bg: 'whiteAlpha.300',
                color: '#6FA82F',
              }}
            />
          </a>
        </Flex>
        {/* Currency select dropdown */}
        <Select
          w="90px"
          size={"12px"}
          ml={2}
          color="black"
          bg="#6FA82F"
          fontSize="sm"
          variant="unstyled"
          borderRadius="none"
          borderBottom="2px solid white"
          _focus={{
            borderColor: 'none',
          }}
          value={selectedCurrency || userCurrency}
          onChange={handleCurrencyChange}
          _hover={{ bg: '#8DC26F', cursor: 'pointer' }}
          transition="background-color 0.3s"
        >
          {currencies.map((currency) => (
            <option bg="#6FA82F" color="white" key={currency} value={currency}>
              {currency}
            </option>
          ))}
        </Select>
      </Flex>
    </Box>
  );
};

export default TopBanner;
