import React from 'react';
import {
    Box,
    Container,
    Text,
    VStack,
    Heading,
    ChakraProvider,
} from '@chakra-ui/react';
import Footer from '../components/HomePageComponents/Footer';
import { Helmet } from 'react-helmet';
import Nav from '../components/HomePageComponents/Nav';
import TopBanner from '../components/HomePageComponents/TopBanner';
import UserChatButton from '../components/Chat/ChatButton'


const PrivacyPolicyPage = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Privacy Policy" content="KnitSilk | Privacy Policy" />
                <title>KnitSilk | Privacy Policy</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Box py="8" backgroundColor="#f6faf2">
                <Container maxW="1200px">
                    <VStack spacing="4" align="left">
                        <Heading
                            as="h2"
                            fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                            textAlign={"center"}
                        >
                            Privacy Policy
                        </Heading>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            What do we do with your information?
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address, and email address.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            When you browse our store, we also automatically receive your computer's internet protocol (IP) address to provide us with information that helps us learn about your browser and operating system.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Email marketing (if applicable): With your permission, we may send you emails about our store, new products, and other updates.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Consent
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            How do you get my consent?
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery, or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            How do I withdraw my consent?
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use, or disclosure of your information, at any time, by contacting us at enquiry@knitsilk.com
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Disclosure
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We do not and will never share, disclose, sell, rent, or otherwise provide personal information to other companies for the marketing of their own products or services.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Payment
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If you choose a direct payment gateway to complete your purchase, then Shopify stores your credit card data. It is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is deleted.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Third-party services
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            In general, the third-party providers used by us will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Once you leave our store's website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Links
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Security
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with an AES-256 encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Cookies
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Here is a list of cookies that we use. We've listed them here so that you can choose if you want to opt-out of cookies or not.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            _session_id, unique token, sessional, Allows Shopify to store information about your session (referrer, landing page, etc).
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            cart, unique token, persistent for 2 weeks, Stores information about the contents of your cart.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            _secure_session_id, unique token, sessional.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            storefront_digest, unique token, indefinite If the shop has a password, this is used to determine if the current visitor has access.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Age of consent
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Changes to this privacy policy
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                        </Text>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Questions & Contact Information
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If you would like to: access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer by e-mail at enquiry@knitsilk.com
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            [Re: Privacy Compliance Officer]
                        </Text>
                    </VStack>
                </Container>
            </Box>
            <UserChatButton />
            <Footer />
        </ChakraProvider>
    );
};

export default PrivacyPolicyPage;
