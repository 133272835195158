import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Heading,
    VStack,
    HStack,
    Input,
    Textarea,
    Select,
    Button,
    useToast,
    FormControl,
    FormLabel,
    Spinner,
    InputGroup,
    Flex,
    InputRightElement,
    Text,
    SimpleGrid,
    MenuButton,
    Menu,
    MenuList,
    MenuItem,
    Image,
    Center,
} from '@chakra-ui/react';
import { FaLongArrowAltLeft, FaEye } from "react-icons/fa";
import {
    FaRupeeSign,
    FaDollarSign,
    FaPoundSign,
    FaEuroSign,
    FaYenSign,
} from 'react-icons/fa';
import YarnTypes from './YarnTypes.js';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AdminNav from './AdminNav';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';

const EditListing = () => {
    const apiUrl = "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_1tt8wr2ipN8IvW2CeX3dgfWzv5lrOwWH4yIxz6ts&currencies=EUR%2CUSD%2CCAD%2CGBP%2CJPY%2CAUD";
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [bullet, setBullet] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [originalProduct, setOriginalProduct] = useState({});
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();
    const [cat, setCat] = useState([]);
    const [subCat, setsubCat] = useState([]);
    const [allColors, setallColors] = useState([]);
    const [allUnits, setAllUnits] = useState([]);
    const [bulletPoints, setBulletPoints] = useState([]);
    const [newBulletPoint, setNewBulletPoint] = useState('');
    const [pricerefresh, setpricerefresh] = useState(false);
    // color
    let [showinput1, setshowinput1] = useState(false)
    let [customcolor1, setCustomcolor1] = useState("")
    // primary
    let [showinput2, setshowinput2] = useState(false)
    let [customcolor2, setCustomcolor2] = useState("")
    // secondary
    let [showinput3, setshowinput3] = useState(false)
    let [customcolor3, setCustomcolor3] = useState("")
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/colors');
                const colorsData = response.data;
                setallColors(colorsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);


    const [conversionRates, setConversionRates] = useState({
        EUR: "",
        USD: "",
        CAD: "",
        GBP: "",
        JPY: "",
        AUD: ""
    });
    let [usdPrice, setUsdPrice] = useState(product.priceUSD);
    let handleremoveimage = () => {

    }

    const fetchConversionRates = async () => {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (data && data.data) {
                const rates = data.data;
                setConversionRates({
                    EUR: rates.EUR,
                    USD: usdPrice,
                    CAD: rates.CAD,
                    GBP: rates.GBP,
                    JPY: rates.JPY,
                    AUD: rates.AUD
                })

            }
        } catch (error) {
            console.error("Error fetching conversion rates:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/categories');
                const categoriesData = response.data;
                setCat(categoriesData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);

    let getrespectivesubcategory = async (category) => {
        try {
            let res = await axios.post("https://api.digiblocks.tech/getallsubcategory", { category });
            setsubCat(res.data.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/adminsetting/units');
                const unitsData = response.data;
                setAllUnits(unitsData);
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        };
        fetchData();
    }, []);
    let [currcategory, setcurrcategory] = useState("");

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get(`https://api.digiblocks.tech/products/${id}`);
                fetchConversionRates();
                setBullet(response.data.bulletPoints)
                setPhotos([...response.data.photos]);
                setProduct(response.data);
                setaplusimages(response.data.aPlusPhotos)
                setCustomcolor1(response.data.color);
                setCustomcolor1(response.data.color);
                setCustomcolor2(response.data.primaryColor);
                setCustomcolor3(response.data.secondaryColor);
                setOriginalProduct(response.data);
                setUsdPrice(response.data.priceUSD);
                setcurrcategory(response.data.category);
                getrespectivesubcategory(response.data.category)
            } catch (error) {
                console.error('Error fetching product details:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch product details',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductDetails();
    }, [id, toast]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'priceUSD') {
            setUsdPrice(value)
            setProduct({
                ...product,
                priceUSD: value,
                priceAUD: (usdPrice * conversionRates.AUD).toFixed(2),
                priceCAD: (usdPrice * conversionRates.CAD).toFixed(2),
                priceEUR: (usdPrice * conversionRates.EUR).toFixed(2),
                priceGBP: (usdPrice * conversionRates.GBP).toFixed(2),
                priceJPY: (usdPrice * conversionRates.JPY).toFixed(2)
            })
        }
        else if (name === 'priceGBP') {
            setProduct({ ...product, priceGBP: value })
        }
        else if (name === 'priceAUD') {
            setProduct({ ...product, priceAUD: value })
        }
        else if (name === 'priceCAD') {
            setProduct({ ...product, priceCAD: value })
        }
        else if (name === 'priceJPY') {
            setProduct({ ...product, priceJPY: value })
        } else if (name == "category") {
            setcurrcategory(value)
            getrespectivesubcategory(value)
            setProduct({ ...product, [name]: value });
        } else {
            setProduct({ ...product, [name]: value });
        }
    };

    const handleCancel = () => {
        setProduct(originalProduct);
    };

    const handlePublish = async () => {
        try {
            if (
                product.priceINR &&
                product.priceUSD &&
                product.priceGBP &&
                product.priceEUR &&
                product.priceCAD &&
                product.priceAUD &&
                product.priceJPY &&
                product.category &&
                product.title &&
                product.description &&
                product.bulletPoints &&
                product.deliveryOption &&
                product.color &&
                product.qtyInStock &&
                product.primaryMaterial
            ) {
                await axios.put(`https://api.digiblocks.tech/products/${id}`, product);
                toast({
                    title: 'Success',
                    description: 'Product updated successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/manager/listing');
            } else {
                const missingFields = [];
                if (!product.priceINR) missingFields.push('priceINR');
                if (!product.priceUSD) missingFields.push('priceUSD');
                if (!product.priceGBP) missingFields.push('priceGBP');
                if (!product.priceEUR) missingFields.push('priceEUR');
                if (!product.priceCAD) missingFields.push('priceCAD');
                if (!product.priceAUD) missingFields.push('priceAUD');
                if (!product.priceJPY) missingFields.push('priceJPY');
                if (!product.category) missingFields.push('category');
                if (!product.title) missingFields.push('title');
                if (!product.description) missingFields.push('description');
                if (!product.bulletPoints) missingFields.push('bulletPoints');
                if (!product.deliveryOption) missingFields.push('deliveryOption');
                if (!product.color) missingFields.push('color');
                if (!product.qtyInStock) missingFields.push('qtyInStock');
                if (!product.primaryMaterial) missingFields.push('primaryMaterial');

                toast({
                    title: 'Missing Information',
                    description: `Please fill in all the required fields: ${missingFields.join(', ')}`,
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error updating product:', error);
            toast({
                title: 'Error',
                description: 'Failed to update product',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    let setcustomcolorfunc = (e) => {
        setCustomcolor1(e.target.value)
        setProduct({ ...product, color: e.target.value })
    }
    let setcustomcolorprimaryfunc = (e) => {
        setCustomcolor2(e.target.value)
        setProduct({ ...product, primaryColor: e.target.value })
    }
    let setcustomcolorsecondaryfunc = (e) => {
        setCustomcolor3(e.target.value)
        setProduct({ ...product, secondaryColor: e.target.value })

    }

    const handleColor = (e) => {
        if (e.target.value === "custom") {
            setshowinput1(true);
        } else {
            setCustomcolor1(e.target.value);
            setProduct({ ...product, color: e.target.value });
            setshowinput1(false);
        }
    }
    const handleColorprimary = (e) => {
        if (e.target.value === "custom") {
            setshowinput2(true);
        } else {
            setCustomcolor2(e.target.value);
            setProduct({ ...product, primaryColor: e.target.value });
            setshowinput2(false);
        }
    }
    const handleColorsecondary = (e) => {
        if (e.target.value === "custom") {
            setshowinput3(true);
        } else {
            setCustomcolor3(e.target.value);
            setProduct({ ...product, secondaryColor: e.target.value });
            setshowinput3(false);
        }
    }

    const removeImage = (i) => {
        // Create a copy of the current photos array
        const updatedPhotos = [...photos];
        // Remove the photo at index i
        updatedPhotos.splice(i, 1);
        // Update the state with the new photos array
        setPhotos(updatedPhotos);
        // Update the product state with the new photos array
        setProduct({ ...product, photos: updatedPhotos });
    };

    let [load, setlaod] = useState(false);
    //   Primary (Main) Image image
    const onPhotoDrop = async (acceptedFiles) => {

        if (acceptedFiles.length > 9 - Number(photos.length)) {
            alert(`You can add more ${9 - Number(photos.length)} images`)
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                setlaod(true)
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                let arr = response.data.photos.map(photo => photo.url)
                setPhotos([...photos, ...arr])
                setProduct({
                    ...product,
                    photos: [...product.photos, ...arr]
                });
                setlaod(false)

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };


    let [aplusimages, setaplusimages] = useState([]);
    const onPhotoDropaplus = async (acceptedFiles) => {
        if (acceptedFiles.length > 5) {
            alert(`Please insert upto 1 image files`)
        } else {
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('photos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                let allurls = response.data.photos.map(obj => obj.url)
                setaplusimages([...aplusimages, ...allurls])
                setProduct({
                    ...product,
                    aPlusPhotos: [...product.aPlusPhotos, ...allurls]
                });

            } catch (error) {
                console.error('Error uploading photos:', error);
            }
        }
    };

    const onVideoDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert("Maximum 1 video can be uploaded!")
        }
        else {

            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('videos', file);
            });
            try {
                const response = await axios.post('https://api.digiblocks.tech/api/upload/videos', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setProduct({ ...product, video: response.data.videos[0].url });
            } catch (error) {
                console.error('Error uploading videos:', error);
            }
        }

    };

    const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
        onDrop: onPhotoDrop,
        accept: 'image/*',
    });

    const { getRootProps: photoRootPropsaplus, getInputProps: photoInputPropsaplus } = useDropzone({
        onDrop: onPhotoDropaplus,
        accept: 'image/*',
    });
    let removeaplusimage = (ind) => {
        let x = [...aplusimages]
        x.splice(ind, 1);
        setaplusimages(x);
        setProduct({ ...product, aPlusPhotos: x })
    }

    const { getRootProps: videoRootProps, getInputProps: videoInputProps } = useDropzone({
        onDrop: onVideoDrop,
        accept: 'video/*',
    });
    const dropzoneStyles = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    const handleDraft = async () => {
        try {
            await axios.post(`https://api.digiblocks.tech/drafts/products/`, product);
            toast({
                title: 'Success',
                description: 'Copied Product Added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/manager/listing')
        } catch (error) {
            console.error('Error updating product:', error);
            toast({
                title: 'Error',
                description: 'Failed to update product',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }




    const handleInputChangebullet = (e) => {
        setNewBulletPoint(e.target.value);
    };

    const handleAddBulletPoint = () => {
        if (newBulletPoint.trim() === '') {
            return;
        }

        if (bulletPoints.length < 5) {
            setBulletPoints((prevPoints) => [...prevPoints, newBulletPoint]);
            setBullet((prevPoints) => [...prevPoints, newBulletPoint]);
            setNewBulletPoint('');
            setProduct(prevProduct => ({
                ...prevProduct,
                bulletPoints: [...bullet, newBulletPoint]
            }));
        } else {
            alert('Maximum 5 bullet points allowed.');
        }
        setNewBulletPoint('');
    };

    const deleteBulletPoint = (index) => {
        const updatedBulletPoints = bullet.filter((_, i) => i !== index);
        setBulletPoints(updatedBulletPoints);
        setBullet(updatedBulletPoints);
    };

    const [shippingProfiles, setShippingProfiles] = useState([]);
    useEffect(() => {
        axios.get('https://api.digiblocks.tech/shipping-profiles')
            .then(response => {
                const shippingProfileNames = response.data.map(profile => profile.shippingProfileName);
                setShippingProfiles(shippingProfileNames);
            })
            .catch(error => {
                console.error('Error fetching shipping profiles:', error);
            });
    }, []);

    return (
        <>
            <AdminNav />
            <Box ml={["", "", "250px"]} p={[3, 3, 4]}>
                <Flex flex={"3"} justifyContent={"space-between"}> <Heading flex={"2"} fontSize={["20px", "", "32px"]}>Edit Listing</Heading>
                    <Flex flex={"1"} flexWrap={"wrap"} gap={[4, 3, 2]} >
                        <Button
                            color="white"
                            bg="black"
                            borderRadius={"none"}
                            _hover={{ bg: "black", color: "white" }}
                            leftIcon={<FaLongArrowAltLeft />}
                            onClick={() => navigate(`/manager/listing`)}
                        >
                            Back To Listings
                        </Button>

                        <Button
                            color="white"
                            bg="black"
                            borderRadius={"none"}
                            _hover={{ bg: "black", color: "white" }}
                            leftIcon={<FaEye />}
                            onClick={() => navigate(`/products/${id}`)}
                        >
                            View on Knitsilk
                        </Button>
                    </Flex>
                </Flex>
                {isLoading ? (
                    <Spinner color='green' size="xl" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : (
                    <VStack spacing={4} mt={4} align="stretch">
                        {/* Basic Info */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}> Basic Information</Heading>
                            <FormControl>
                                <FormLabel>Title</FormLabel>
                                <Input name="title" value={product.title} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea value={product.description} name="description" onChange={handleInputChange} />
                            </FormControl>
                            <Flex direction="column" gap={3} p={3}>
                                <Text fontWeight="600">Bullet Points</Text>
                                <Flex gap={3}>
                                    <Input value={newBulletPoint} onChange={handleInputChangebullet} placeholder="Enter max 5 bullet points"></Input>
                                    <Button onClick={handleAddBulletPoint}>Add</Button>
                                </Flex>
                                {
                                    bullet && bullet?.length > 0 && bullet.map((e, index) => {
                                        return <Flex alignItems={"center"} w={"100%"} key={index} flex={7}>
                                            <Text w={"80%"}>{e}</Text>
                                            <DeleteIcon w={"20%"} onClick={() => {
                                                deleteBulletPoint(index)
                                            }} />
                                        </Flex>
                                    }
                                    )
                                }
                            </Flex>
                        </Box>

                        {/* Categorization */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Categorization</Heading>
                            <HStack spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Category</FormLabel>
                                    <Select value={currcategory} name="category" onChange={handleInputChange}>
                                        <option value="">Select A Category </option>
                                        {cat.map((color) => (
                                            <option key={color} value={color.categories}>{color.categories.charAt(0).toUpperCase() + color.categories.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Sub-Category</FormLabel>
                                    <Select name="subCategory" value={product.subCategory} onChange={handleInputChange}>
                                        <option value="">Select A SubCategory </option>
                                        {subCat.map((color) => (
                                            <option key={color} value={color.subcategories}>{color.subcategories.charAt(0).toUpperCase() + color.subcategories.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl flex={2}>
                                    <FormLabel>Home Page Section</FormLabel>
                                    <Select name="shopSection" value={product.shopSection} onChange={handleInputChange}>
                                        <option value="">Select A Shop Section</option>
                                        <option value="BestSellers">Best Sellers - Loved by all </option>
                                        <option value="Featured">Featured - Crafted for you</option>
                                        <option value="NewArrivals">New Arrivals - New & now</option>
                                        <option value="MostViewed">Most Viewed - Seen by many</option>
                                        <option value="SpotlightDeals">Spotlight Deals
                                        </option>
                                    </Select>
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Delivery Option <span style={{ marginLeft: '-5px', fontSize: '1.5em', color: 'red', fontWeight: 'bold' }}>
                                        *
                                    </span></FormLabel>
                                    <Select name="deliveryOption" value={product.deliveryOption} onChange={handleInputChange}>
                                        <option value="">Select a Delivery Option</option>
                                        {shippingProfiles.map((profileName, index) => (
                                            <option key={index} value={profileName}>{profileName}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Manufacturer</FormLabel>
                                    <Input name="manufacturer" value="M.Jiju Silk Mills" onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                        </Box>


                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Specifications</Heading>
                            <SimpleGrid columns={[2, 2, 3]} mt={2} spacing={4}>
                                <FormControl flex={2} pt={3}>
                                    <FormLabel>Item Weight</FormLabel>
                                    <Input name="itemWeight" onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Item Dimensions (LxWxH)</FormLabel>
                                    <HStack spacing={2}>
                                        <Input name="productLength" placeholder="Length" onChange={handleInputChange} />
                                        <Input name="productWidth" placeholder="Width" onChange={handleInputChange} />
                                        <Input name="productHeight" placeholder="Height" onChange={handleInputChange} />
                                    </HStack>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Item Dimensions Unit</FormLabel>
                                    <Select
                                        name="productDimensionsUnit"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Unit </option>
                                        {allUnits.map((color) => (
                                            <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </SimpleGrid>
                            <SimpleGrid columns={[2, 2, 3]} mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Package Weight</FormLabel>
                                    <Input name="packageWeight" onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Package Dimensions  (LxWxH)</FormLabel>
                                    <HStack spacing={2}>
                                        <Input name="packageLength" placeholder="Length" onChange={handleInputChange} />
                                        <Input name="packageWidth" placeholder="Width" onChange={handleInputChange} />
                                        <Input name="packageHeight" placeholder="Height" onChange={handleInputChange} />
                                    </HStack>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Package Dimensions Unit</FormLabel>
                                    <Select
                                        name="packageDimensionsUnit"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Unit </option>
                                        {allUnits.map((color) => (
                                            <option key={color} value={color.units}>{color.units.charAt(0).toUpperCase() + color.units.slice(1)}</option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl flex={2} pt={3}>
                                    <FormLabel>Item Size</FormLabel>
                                    <Input name="itemSize" onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Number Of Units</FormLabel>
                                    <Input name="units" onChange={handleInputChange} />
                                </FormControl>
                            </SimpleGrid>

                        </Box>

                        {/* Pricing */}
                        <>
                            <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                                <Heading fontSize={"24px"}>Pricing</Heading>

                                <SimpleGrid columns={[2, 2, 4]} gap={4}>
                                    <FormControl>
                                        <FormLabel>Price in INR</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceINR" value={product.priceINR} onChange={handleInputChange} />
                                            <InputRightElement children={<FaRupeeSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in USD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" value={usdPrice} name="priceUSD" onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in GBP</FormLabel>
                                        <InputGroup>
                                            {/* xxx */}
                                            <Input type="number" name="priceGBP" value={(usdPrice * conversionRates.GBP).toFixed(2)} onChange={handleInputChange} />
                                            <InputRightElement children={<FaPoundSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in EUR</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceEUR" value={(usdPrice * conversionRates.EUR).toFixed(2)} onChange={handleInputChange} />
                                            <InputRightElement children={<FaEuroSign />} />
                                        </InputGroup>
                                    </FormControl>
                                </SimpleGrid>
                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Price in CAD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" value={(usdPrice * conversionRates.CAD).toFixed(2)} name="priceCAD" onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in AUD</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceAUD" value={(usdPrice * conversionRates.AUD).toFixed(2)} onChange={handleInputChange} />
                                            <InputRightElement children={<FaDollarSign />} />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Price in JPY</FormLabel>
                                        <InputGroup>
                                            <Input type="number" name="priceJPY" value={(usdPrice * conversionRates.JPY).toFixed(2)} onChange={handleInputChange} />
                                            <InputRightElement children={<FaYenSign />} />
                                        </InputGroup>
                                    </FormControl>
                                </HStack>
                            </Box>
                        </>

                        {/* Inventory */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Inventory</Heading>
                            <SimpleGrid gap="4" columns={["2", "2", "4"]}>
                                <FormControl>

                                    <FormLabel>Color</FormLabel>
                                    <Box>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                Select color
                                            </MenuButton>
                                            <MenuList maxH={"400px"} overflowY={"scroll"}>
                                                {allColors.map((color, i) => (
                                                    <MenuItem keys={i} onClick={handleColor} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                                ))}
                                                <MenuItem value={"custom"} onClick={handleColor}>custom</MenuItem>

                                            </MenuList>

                                        </Menu>
                                        {showinput1 &&
                                            <>
                                                <Text>Enter Custom Color</Text>
                                                <Input onChange={setcustomcolorfunc}></Input>
                                            </>
                                        }
                                        {customcolor1 && <Text>Selected Color : {customcolor1}</Text>}
                                    </Box>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Listing Status</FormLabel>
                                    <Select name="listingStatus" value={product.listingStatus} onChange={handleInputChange}>
                                        <option value="Active">Active</option>
                                        <option value="Sold out">Sold out</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Expired">Expired</option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Quantity In Stock</FormLabel>
                                    <Input type="number" name="qtyInStock" value={product.qtyInStock} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>SKU</FormLabel>
                                    <Input type="text" name="sku" value={product.sku} onChange={handleInputChange} />
                                </FormControl>
                            </SimpleGrid>
                        </Box>
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"20px"}>
                            <FormControl>
                                <Heading fontSize={"24px"}>Media</Heading>
                                <SimpleGrid gap={5} columns={[1, 1, 3]} spacing={4} p={3}>
                                    {/* show all previous photos here  */}
                                    {
                                        photos && load ? <Spinner
                                            thickness='4px'
                                            speed='0.65s'
                                            emptyColor='gray.200'
                                            color='blue.500'
                                            size='xl'
                                        /> : photos.map((e, index) => {
                                            return <Flex p={2} border={"1px solid teal"} borderRadius={"12px"} justifyContent={"flex-end"} direction={"column"} alignItems={"center"} gap={2}>
                                                {index == 0 && <Text >Primary (Main) Image</Text>}
                                                <Image w={"120px"} src={e} ></Image>
                                                <Button _active={{ bg: "white" }}
                                                    _focus={{ bg: "white" }} bg={"white"} onClick={() => {
                                                        removeImage(index)
                                                    }}><DeleteIcon color={"red"}></DeleteIcon></Button>
                                            </Flex>
                                        })
                                    }
                                </SimpleGrid>

                                <FormLabel>Upload upto 9 images & 1 video</FormLabel>
                                <>
                                    <div>
                                        <SimpleGrid gap={5} columns={1}>

                                            <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
                                                <input  {...photoInputProps()} />
                                                <Text>Drag & drop some photos here, or click to select photos </Text>
                                            </Box>




                                        </SimpleGrid>
                                        <>

                                        </>

                                    </div>
                                </>
                            </FormControl>
                            {/* Display Video */}
                            <FormControl>
                                <FormLabel>Video</FormLabel>
                                <InputGroup width="100%" as={VStack}>
                                    <Input
                                        name="video"
                                        value={product.video}
                                        onChange={handleInputChange}
                                        placeholder="Enter Video URL"
                                    />
                                    <div {...videoRootProps()} style={dropzoneStyles}>
                                        <input {...videoInputProps()} />
                                        <p>To Replace/Upload video Drag & drop a video here, or click to select a video</p>
                                    </div>
                                    <InputRightElement width="4.5rem">
                                        <Button
                                            as="a"
                                            href={product.video}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            size="md"
                                            fontSize="sm"
                                            colorScheme="blue"
                                            variant="outline"
                                        >
                                            View
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </Box>
                        {/* Attributes */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Attributes</Heading>
                            <Flex flexWrap={"wrap"} mt={2} gap={[2, 2, 4]} alignItems={"flex-end"}>
                                <FormControl>
                                    <FormLabel>Primary Color</FormLabel>
                                    <Box>
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                Primary color
                                            </MenuButton>
                                            <MenuList maxH={"400px"} overflowY={"scroll"}>
                                                {allColors.map((color, i) => (
                                                    <MenuItem keys={i} onClick={handleColorprimary} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                                ))}
                                                <MenuItem value={"custom"} onClick={handleColorprimary}>custom</MenuItem>

                                            </MenuList>

                                        </Menu>
                                        {showinput2 &&
                                            <>
                                                <Text>Enter Custom Color</Text>
                                                <Input onChange={setcustomcolorprimaryfunc}></Input>
                                            </>
                                        }
                                        {customcolor2 && <Text>Selected Color : {customcolor2}</Text>}
                                    </Box>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Secondary Color</FormLabel>


                                    <Box>
                                        {/*yyy */}
                                        <Menu>
                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                Secondary color
                                            </MenuButton>
                                            <MenuList maxH={"400px"} overflowY={"scroll"}>
                                                {allColors.map((color, i) => (
                                                    <MenuItem keys={i} onClick={handleColorsecondary} key={color} value={color.colors}>{color.colors.charAt(0).toUpperCase() + color.colors.slice(1)}</MenuItem>
                                                ))}
                                                <MenuItem value={"custom"} onClick={handleColorsecondary}>custom</MenuItem>

                                            </MenuList>

                                        </Menu>
                                        {showinput3 &&
                                            <>
                                                <Text>Enter Custom Color</Text>
                                                <Input onChange={setcustomcolorsecondaryfunc}></Input>
                                            </>
                                        }
                                        {customcolor3 && <Text>Selected Color : {customcolor3}</Text>}
                                    </Box>
                                </FormControl>
                            </Flex>


                            <SimpleGrid
                                columns={3}
                                mt={5}
                                spacing={{ base: 4, md: 8 }}
                                justify="center"
                                align="center"
                                w="100%"
                            >
                                <FormControl>
                                    <FormLabel fontSize="md">Allow Personalization for buyers</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.personalization}
                                        onChange={(e) => handleInputChange(e)}
                                        name="personalization"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>

                                {/* hand painted */}
                                <FormControl>
                                    <FormLabel fontSize="md">Hand Painted</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.handPainted}
                                        onChange={(e) => handleInputChange(e)}
                                        name="handPainted"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>
                                {/* hand dyed */}
                                <FormControl>
                                    <FormLabel fontSize="md">Hand Dyed</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.handDyed}
                                        onChange={(e) => handleInputChange(e)}
                                        name="handDyed"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>
                                {/* hand made */}
                                <FormControl>
                                    <FormLabel fontSize="md">Hand Made</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.handMade}
                                        onChange={(e) => handleInputChange(e)}
                                        name="handMade"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>
                                {/* Organic */}
                                <FormControl>
                                    <FormLabel fontSize="md">Organic</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        onChange={(e) => handleInputChange(e)}
                                        name="organic"
                                        value={product.organic}
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>




                                <FormControl>
                                    <FormLabel fontSize="md">Cut to Size</FormLabel>
                                    <Select
                                        colorScheme="green"
                                        value={product.cutToSize}
                                        onChange={(e) => handleInputChange(e)}
                                        name="cutToSize"
                                    >
                                        <option value="true">YES</option>
                                        <option value="false">NO</option>
                                    </Select>
                                </FormControl>
                            </SimpleGrid>
                        </Box>

                        {/* Technical Details */}
                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading fontSize={"24px"}>Technical Details</Heading>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Primary Material</FormLabel>
                                    <Input name="primaryMaterial" value={product.primaryMaterial} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Secondary Material</FormLabel>
                                    <Input name="secondaryMaterial" value={product.secondaryMaterial} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Yarn Weight</FormLabel>
                                    <Select
                                        name="yarnWeight"
                                        value={product.yarnWeight}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select An Yarn Weight Option</option>
                                        {Object.keys(YarnTypes).map((key) => (
                                            <option key={key} value={key}>
                                                {YarnTypes[key].name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>m/100g</FormLabel>
                                    <Input name="meterPer100g" value={product.meterPer100g} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Other Names</FormLabel>
                                    <Input name="otherNames" value={product.otherNames} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack spacing={4} mt={2}>

                                <FormControl flex={2}>
                                    <FormLabel>Wash care</FormLabel>
                                    <Select name="care" value={product.care} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="machine-wash">Machine Wash</option>
                                        <option value="hand-wash">Hand Wash</option>
                                        <option value="dry-clean">Dry Clean</option>
                                    </Select>
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Needle Size (mm)</FormLabel>
                                    <Input name="needleSize" value={product.needleSize} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Hook Size (mm)</FormLabel>
                                    <Input name="hookSize" value={product.hookSize} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                            <HStack mt={2} spacing={4}>
                                <FormControl flex={2}>
                                    <FormLabel>Yardage</FormLabel>
                                    <Input type="number" name="yardage" value={product.yardage} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>Type (Make Content)</FormLabel>
                                    <Input name="makeContent" value={product.makeContent} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl flex={2}>
                                    <FormLabel>HSN Code</FormLabel>
                                    <Input name="HSNCode" value={product.HSNCode} onChange={handleInputChange} />
                                </FormControl>
                            </HStack>
                        </Box>

                        <Box border={"1px solid teal"} bg={"white"} p={6} borderRadius={"30px 0 30px 0"}>
                            <Heading>A+ Images</Heading>
                            <Box>
                                <Box>

                                    <Box mr={2} w={"100%"} {...photoRootPropsaplus()} style={dropzoneStyles}>
                                        <input  {...photoInputPropsaplus()} />
                                        <Text>Drag & drop some photos here, or click to select photos </Text>
                                    </Box>
                                </Box>
                            </Box>
                            <SimpleGrid columns={[1, 1, 3]}>
                                {aplusimages.length > 0 && aplusimages.map((e) => {
                                    return <Box textAlign={"center"}>
                                        <Image w={"200px"} src={e}></Image>
                                        <Center>
                                            <DeleteIcon onClick={(e, idx) => {
                                                removeaplusimage(idx)
                                            }}></DeleteIcon>
                                        </Center>
                                    </Box>
                                })}
                            </SimpleGrid>
                        </Box>



                        {/* Submit button */}
                        <Flex flex={"8"} gap={1} flexWrap={"wrap"} mt={2} w={"100%"} justifyContent={"space-between"}>
                            <Button
                                flex={"2"}
                                borderRadius={"none"}
                                bg={"black"}
                                onClick={handleCancel}
                                color={"white"}
                                _hover={{ bg: "black", color: "white" }}
                            >
                                Cancel
                            </Button>

                            {/* <Button
                                flex={"2"}
                                borderRadius={"none"}
                                colorScheme="green"
                                _hover={{ bg: "black", color: "white" }}
                                onClick={handleDraft}
                            >
                                Save as Draft
                            </Button> */}

                            <Button
                                flex={"2"}
                                borderRadius={"none"}
                                colorScheme="green"
                                onClick={handlePublish}
                                _hover={{ bg: "black", color: "white" }}
                            >
                                Publish
                            </Button>

                        </Flex>
                    </VStack>
                )}
            </Box>
        </>
    );
};

export default EditListing;