import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Flex,
    Text,
    VStack,
    Heading,
    Spinner,
    Button,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { CopyIcon } from '@chakra-ui/icons';
import * as XLSX from 'xlsx';

const B2BInquiryList = () => {
    const [inquiries, setInquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [replyMessage, setReplyMessage] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        const fetchInquiries = async () => {
            try {
                const response = await axios.get('https://api.digiblocks.tech/b2bInquiry');
                setInquiries(response.data);
            } catch (error) {
                console.error('Error fetching B2B inquiries:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInquiries();
    }, []);

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(inquiries);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'B2BInquiries');
        XLSX.writeFile(wb, 'B2BInquiries.xlsx');
    };

    const openModal = (inquiry) => {
        setSelectedInquiry(inquiry);
        onOpen();
    };

    const closeModal = () => {
        setSelectedInquiry(null);
        onClose();
    };

    const handleReply = async () => {
        try {
          let res = await axios.post('https://api.digiblocks.tech/b2bInquiry/b2bReply', {
                name: selectedInquiry.name,
                email: selectedInquiry.email,
                messages: replyMessage,
            });
            setReplyMessage('');
            setTimeout(() => {
                onClose();
            }, 1000)
            toast({
                title: "Reply sent.",
                description: "The reply has been sent successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error replying to B2B inquiry:', error);
            toast({
                title: "Error.",
                description: "An error occurred while replying to the inquiry.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.digiblocks.tech/b2bInquiry/${id}`);
            setInquiries(inquiries.filter(inquiry => inquiry._id !== id));
            toast({
                title: "Inquiry deleted.",
                description: "The B2B inquiry has been deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting B2B inquiry:', error);
            toast({
                title: "Error.",
                description: "An error occurred while deleting the inquiry.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const copyEmailToClipboard = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => {
                toast({
                    title: "Copied",
                    description: "Email copied to clipboard",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error('Error copying email to clipboard:', error);
                toast({
                    title: "Error",
                    description: "Failed to copy email",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <Container maxW="container.lg">
            <Flex mt={"2%"} justifyContent={"space-between"}>
                <Heading as="h2" mb={6} textAlign="center">
                    B2B Inquiry List
                </Heading>
                <Button onClick={exportToExcel} bg={"black"} borderRadius={"none"} size={"sm"} colorScheme="teal" mb={4}>
                    Export Inquiries to Excel
                </Button>
            </Flex>
            {loading ? (
                <Spinner size="lg" color="green" />
            ) : (
                <VStack align="start" spacing={4}>
                    {inquiries.length === 0 ? (
                        <Text>No B2B inquiries found.</Text>
                    ) : (
                        <>
                            {inquiries.map((inquiry) => (
                                <Box
                                    key={inquiry._id}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    overflow="hidden"
                                    p={4}
                                    w="100%"
                                >
                                    <Flex direction={["column", "row", "row"]} justifyContent={"space-between"} align="start" spacing={2}>
                                        <Box>
                                            <Text fontSize="lg" fontWeight="bold">
                                                {inquiry.name}
                                            </Text>
                                            <Text>
                                                <b>Email:</b> {inquiry.email}
                                                <CopyIcon ml={2} cursor="pointer" onClick={() => copyEmailToClipboard(inquiry.email)} />
                                            </Text>
                                            <Text><b>Contact No:</b> {inquiry.phone}</Text>
                                        </Box>
                                        <Box alignItems={"center"}>
                                            <Button size={"sm"} colorScheme='green' borderRadius={"none"} onClick={() => openModal(inquiry)}>
                                                View / Reply
                                            </Button>
                                            <Button size={"sm"} colorScheme='red' borderRadius={"none"} ml={2} onClick={() => handleDelete(inquiry._id)}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Box>
                            ))}
                        </>
                    )}
                </VStack>
            )}

            <Modal isOpen={isOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedInquiry ? `Replying to ${selectedInquiry.name}` : 'B2B Inquiry Details'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedInquiry ? (
                            <>
                                <VStack align="start" spacing={0}>
                                    <Text><b>Name:</b> {selectedInquiry.name}</Text>
                                    <Text><b>Email:</b> {selectedInquiry.email}</Text>
                                    <Text><b>Phone:</b> {selectedInquiry.phone}</Text>
                                    <Text><b>Message:</b> {selectedInquiry.messages}</Text>
                                </VStack>
                                <Textarea
                                    value={replyMessage}
                                    onChange={(e) => setReplyMessage(e.target.value)}
                                    placeholder="Type your reply here..."
                                    size="md"
                                    resize="vertical"
                                    mt={4}
                                />
                            </>
                        ) : (
                            <VStack align="start" spacing={0}>
                                <Text>No inquiry selected.</Text>
                            </VStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button size={"sm"} mr="3" borderRadius={"none"} colorScheme="blue" onClick={handleReply} disabled={!replyMessage.trim() || !selectedInquiry}>
                            Send Reply
                        </Button>
                        <Button size={"sm"} borderRadius={"none"} colorScheme="red" onClick={closeModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default B2BInquiryList;
