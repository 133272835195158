import React from 'react';
import {
    Box,
    Container,
    Text,
    VStack,
    Heading,
    ChakraProvider,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import Footer from '../components/HomePageComponents/Footer';
import Nav from '../components/HomePageComponents/Nav';
import TopBanner from '../components/HomePageComponents/TopBanner';
import UserChatButton from '../components/Chat/ChatButton'


const ReturnExchangePolicyPage = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | Return & Exchange Policy" content="KnitSilk | Return & Exchange Policy" />
                <title>KnitSilk | Return & Exchange Policy</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Box py="8" backgroundColor="#f6faf2">
                <Container maxW="1200px">
                    <VStack spacing="4" align="left">
                        <Heading
                            as="h2"
                            fontSize="46px"
                            color="#6FA82F"
                            textAlign={"center"}
                            fontFamily="DM Serif Display"
                            fontWeight="400"
                        >
                            Return & Exchange Policy
                        </Heading>

                        <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Returns & Exchange Policy
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Gift Cards and items marked "final sale" or under offers or scheme are not eligible for return or exchange. To ensure that you will receive either a refund or store credit for returned items, all products must be in their original form.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            If an item being returned or exchanged is determined damaged or is the result of another error on our behalf, we will cover the return shipping expense and will ship the replacement item to you free of charge. Otherwise, the customer is responsible for these shipping costs.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Returns
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Items should be returned within 7 days from receipt of the order and are eligible for your choice of a refund or store credit.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Customer is responsible for the cost of return shipping. Returns are only to be sent to the address mentioned at the bottom of the page.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            You can download and print a Return Form here.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We will process your refund or store credit and notify you via email within 3-5 business days of receiving the returned items.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Please review the "Returns & Exchanges Policies" section at the top of this page before sending items back to us.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Exchanges
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Due to fluctuating inventory, seasonal items, and differing item prices and shipping charges, we honor exchange requests by issuing store credit for the cost of the original item purchased, which can then be applied toward purchasing the item you'd like to order in exchange.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Items returned to us within 60 days of purchase are eligible for store credit, which you can apply toward the item you wish to order instead.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            To exchange an item, please mail it back to us at the below address, being sure to include the Return Form found at the bottom of your packing slip, and indicating your request for store credit. If you no longer have it, you can download and print a Return Form here.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Please ensure that you have created an account with us on our website, using the email address you used when placing your order, as this is where we will issue your store credit.
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            We will process your store credit and notify you via email within 3-5 business days of receiving the returned items.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Exchanges for promotional items
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Should you desire to exchange an item purchased during a promotion, please be advised that there are no returns or exchanges for promotional items.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Holiday Returns & Exchanges
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Please contact us via our contact page if you have purchased or received an item during the holiday time-frame that you would like to return or exchange.
                        </Text>

                        <Heading as="h4" fontSize="22px" color="#6FA82F" fontFamily="DM Serif Display" fontWeight="400" textAlign="left">
                            Send returns to
                        </Heading>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Knitsilk
                            C/O M JIJU SILK MILLS
                            Attn: Returns
                            71, Ashira Avenue, JM Road
                            Bangalore - 560 002
                            Karnataka
                            India
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            E-mail: <span style={{ color: "#6FA82F" }}>enquiry@knitsilk.com</span>
                        </Text>

                        <Text fontSize={["16px", "18px"]} lineHeight={1.6} fontFamily="Inter">
                            Please save your tracking number when shipping items back to us, as we cannot issue refunds or store credit for any items that are lost by the post office or do not make it to us for any other reason.
                        </Text>
                    </VStack>
                </Container>
            </Box>
            <UserChatButton/>
            <Footer />
        </ChakraProvider>
    );
};

export default ReturnExchangePolicyPage;
