import React from "react";
import {
    Box,
    Flex,
    Text,
    Link,
    IconButton,
    Tooltip,
    VStack,
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Icon,
    ModalCloseButton,
    ModalBody,
    useClipboard, useDisclosure
} from "@chakra-ui/react";
import {
    FaGlobe,
    FaEtsy,
    FaAmazon,
    FaEbay,
    FaInstagram,
    FaFacebook,
    FaTwitter,
    FaYoutube,
    FaShareAlt,
    FaPinterest,
} from "react-icons/fa";
import Logo from "../assets/knitsilk black logo.png";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import { HiClipboardCopy } from 'react-icons/hi';


const ShareComponent = () => {
    const { onCopy, hasCopied } = useClipboard('https://www.knitsilk.com/global');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const shareUrl = 'https://www.knitsilk.com/global';
    const title = 'Check out this amazing website!';

    const handleShareButtonClick = (e, network) => {
        e.preventDefault();
        switch (network) {
            case 'email':
                window.location.href = `mailto:?subject=${title}&body=${shareUrl}`;
                break;
            default:
                navigator.clipboard.writeText(shareUrl);
                onCopy();
                onClose();
        }
    };

    return (
        <Box>
            <IconButton onClick={onOpen} icon={<FaShareAlt />}  size="lg" color="white" borderRadius="full"
                transition="all 0.2s"
                _hover={{ transform: "scale(1.2)" }} colorScheme="green">
            </IconButton>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Share this on</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex justifyContent="space-between" alignItems="center">
                            <FacebookShareButton url={shareUrl} quote={title} onClick={(e) => handleShareButtonClick(e, 'facebook')}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton url={shareUrl} title={title} onClick={(e) => handleShareButtonClick(e, 'twitter')}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton url={shareUrl} title={title} onClick={(e) => handleShareButtonClick(e, 'whatsapp')}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton url={shareUrl} title={title} onClick={(e) => handleShareButtonClick(e, 'linkedin')}>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton url={shareUrl} subject={title} body="" onClick={(e) => handleShareButtonClick(e, 'email')}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(shareUrl);
                                    onCopy();
                                }}
                                variant="ghost"
                                display="flex"
                                alignItems="center"
                                _hover={{ textDecoration: 'none', bg: 'transparent' }}
                            >
                                <Icon as={HiClipboardCopy} boxSize={6} />
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {hasCopied && (
                <Box mt="2" p="2" bg="green.200" color="green.800" rounded="md">
                    Copied to clipboard!
                </Box>
            )}
        </Box>
    );
};


const SocialLink = ({ icon, label, url, color }) => (
    <Tooltip label={label} bg="white" color="black" hasArrow placement="top">
        <Link href={url} isExternal>
            <IconButton
                aria-label={label}
                icon={icon}
                size="lg"
                colorScheme={color}
                borderRadius="full"
                transition="all 0.2s"
                _hover={{ transform: "scale(1.2)" }}
            />
        </Link>
    </Tooltip>
);

const LinkTreePage = () => {
    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            bgImage={`url(https://i.postimg.cc/QCZ5GrF4/abstract-paper-background-concept.jpg)`}
            bgSize="cover"
            color="white"
            p={8}
        >
            <Image src={Logo} width={"200px"} alt="Logo" mb={4} />
            <Box
                rounded="lg"
                overflow="hidden"
                p={6}
                textAlign="center"
            >
                <VStack spacing={4}>
                    <SocialLink
                        icon={<FaGlobe />}
                        label="Our Website"
                        url="http://www.knitsilk.com/"
                        color="pink"
                    />
                    <SocialLink
                        icon={<FaEtsy />}
                        label="Etsy"
                        url="https://www.etsy.com/shop/knitsilk/?etsrc=sdt"
                        color="purple"
                    />
                    <SocialLink
                        icon={<FaAmazon />}
                        label="Amazon"
                        url="https://www.amazon.com/stores/KnitSilk/page/0F991B5C-06AC-4DEB-8176-0691B19AAF66?ref_=ast_bln"
                        color="orange"
                    />
                    <SocialLink
                        icon={<FaEbay />}
                        label="Ebay"
                        url="https://www.ebay.com/usr/knitsilk"
                        color="blue"
                    />
                    <SocialLink
                        icon={<FaPinterest />}
                        label="Pinterest"
                        url="https://www.pinterest.com/knitsilk/"
                        color="red"
                    />
                    <SocialLink
                        icon={<FaInstagram />}
                        label="Instagram"
                        url="https://www.instagram.com/knitsilk/"
                        color="yellow"
                    />
                    <SocialLink
                        icon={<FaFacebook />}
                        label="Facebook"
                        url="https://www.facebook.com/knitsilk/"
                        color="blue"
                    />
                    <SocialLink
                        icon={<FaTwitter />}
                        label="Twitter"
                        url="https://twitter.com/knitsilk"
                        color="cyan"
                    />
                    <SocialLink
                        icon={<FaYoutube />}
                        label="YouTube"
                        url="https://www.youtube.com/@Knitsilk"
                        color="red"
                    />
                    <ShareComponent />

                </VStack>
            </Box>
            <Text mt={8} fontSize="md" fontWeight={"bold"} textAlign="center">
                Explore and connect with us on various platforms.
            </Text>
        </Flex >
    );
};

export default LinkTreePage;
