import { Box, Flex, SimpleGrid, Text, Input, Card, CardBody, Image, Stack, Divider, CardFooter, Spinner } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react';
import AdminNav from './AdminNav';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, } from '@chakra-ui/icons';


const AllDrafts = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://api.digiblocks.tech/drafts/products/')
            .then(response => response.json())
            .then(data => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                setLoading(false);
            });
    }, []);

    const filteredProducts = products.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDelete = async (productId) => {
        const isConfirmed = await showConfirmationAlert('Delete Draft', 'Are you sure you want to delete this Draft?');

        if (!isConfirmed) {
            return;
        }
        try {
            const response = await fetch(`https://api.digiblocks.tech/drafts/products/${productId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error deleting product:', errorData.error);
                showAlert('Error', 'Failed to delete the product', 'error');
            } else {
                setProducts(prevProducts => prevProducts.filter(product => product._id !== productId));
                showAlert('Success', 'Product deleted successfully', 'success');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            showAlert('Error', 'An unexpected error occurred', 'error');
        }
    };

    const showConfirmationAlert = async (title, text) => {
        const { value: isConfirmed } = await Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        return isConfirmed;
    };
    const showAlert = (title, message, type) => {
        Swal.fire({
            title,
            text: message,
            icon: type,
        });
    };
    return (
        <>
            {loading && (
                <Flex
                    justify="center"
                    align="center"
                    w="100%"
                    h="100vh"
                    position="fixed"
                    top="0"
                    left="0"
                    bg="rgba(255, 255, 255, 0.8)"
                    zIndex="1000"
                >
                    <Spinner color="green.500" size="xl" />
                </Flex>
            )}
            <Flex direction={["column", "column", "row"]}>
                <AdminNav />

                <Flex ml={["0px", "0px", "15%"]} w={"100%"} flex={"6"}>
                    <Box flex={["6", "4"]} p={3}>
                        <Flex direction={["column", "column", "row"]} alignItems={"center"} justifyContent={"space-between"} p={2}>
                            <Text fontSize={"22px"} fontWeight={"500"}>All Drafts</Text>
                            <Flex direction={["column", "column", "row"]} alignItems={"center"} gap={3}>
                                <Input
                                    w={["100%", "100%", "100%"]}
                                    borderRadius={"0px"}
                                    border={"1px solid gray"}
                                    placeholder="Search By Title/Tag..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Flex>
                        </Flex>
                        <Divider></Divider>

                        <SimpleGrid columns={[1, 2, 4]} spacing='40px'>
                            {filteredProducts.map(product => (
                                <Box key={product._id}>
                                    <Card maxW='sm' border={"1px solid lightgray"} borderRadius={"5px"}>
                                        <CardBody>
                                            <Image
                                                w={"100%"}
                                                h={"200px"}
                                                src={product?.photos[0]}
                                                alt={product.title}
                                                borderRadius='lg'
                                            />
                                            <Stack mt='6'>
                                                <Text m={0} fontSize={["18px", "20px"]} fontWeight={"600"}>{product.title}</Text>
                                                <Text m={0} fontSize={"14px"}>
                                                    {product.qtyInStock} in Stock
                                                </Text>
                                                <Text fontSize={"14px"} m={0}>
                                                    {`$${product.priceINR} in India`}
                                                </Text>
                                                <Text fontSize={"14px"} m={0}>
                                                    {`$${product.priceUSD} in Everywhere Else`}
                                                </Text>
                                            </Stack>
                                        </CardBody>

                                        <CardFooter borderTop={"1px solid lightgray"}>
                                            <Flex w={"100%"} justifyContent={"space-between"}>
                                                <EditIcon m={"auto"} size="md" onClick={() => navigate(`/manager/drafts/edit/${product._id}`)} _hover={{ color: "#6FA82F" }} />
                                                <DeleteIcon
                                                    m={"auto"}
                                                    size="md"
                                                    _hover={{ color: "#6FA82F" }}
                                                    onClick={() => handleDelete(product._id)}
                                                />
                                            </Flex>
                                        </CardFooter>
                                    </Card>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box >
                </Flex >
            </Flex >
        </>
    );
}

export default AllDrafts;
