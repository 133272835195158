import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    Input,
    Button,
    useToast,
    Flex,
    ChakraProvider,
    SimpleGrid,
    Select,
} from '@chakra-ui/react';
import AdminNav from './AdminNav';
import countriesData from './CountriresData'

const EditShippingProfile = () => {
    const { id } = useParams();
    const [shippingProfile, setShippingProfile] = useState(null);
    const [editedProfile, setEditedProfile] = useState({});
    const [selectedCountries, setSelectedCountries] = useState([]);
    const toast = useToast();
    let navigate = useNavigate();
    useEffect(() => {
        fetch(`https://api.digiblocks.tech/shipping-profiles/${id}`)
            .then(response => response.json())
            .then(data => {
                let selectedCountriesList = [];
                Object.entries(data.data).forEach(([region, subregions]) => {
                    Object.entries(subregions).forEach(([subregion, countries]) => {
                        selectedCountriesList = selectedCountriesList.concat(countries.countries);
                    });
                });
                setSelectedCountries(selectedCountriesList);
                setShippingProfile(data);
                setEditedProfile(data);
            })
            .catch(error => console.error('Error fetching shipping profile:', error));
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProfile(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (region, subregion, country) => {
        const updatedProfile = { ...editedProfile };
        if (!updatedProfile.data[region]) {
            updatedProfile.data[region] = {};
        }
        if (!updatedProfile.data[region][subregion]) {
            updatedProfile.data[region][subregion] = {
                countries: [],
                shippingFees: {
                    standard: { perOrder: 0, perItem: 0 },
                    expedited: { perOrder: 0, perItem: 0 },
                },
                transitTimesStandard: '',
                transitTimesExpedited: '',
            };
        }

        const countryIndex = selectedCountries.indexOf(country);
        if (countryIndex > -1) {
            const newSelectedCountries = selectedCountries.filter((c) => c !== country);
            setSelectedCountries(newSelectedCountries);
            updatedProfile.data[region][subregion].countries = updatedProfile.data[region][subregion].countries.filter(
                (c) => c !== country
            );
        } else {
            setSelectedCountries([...selectedCountries, country]);
            updatedProfile.data[region][subregion].countries.push(country);
        }
        setEditedProfile(updatedProfile);
    };

    const handleShippingFeeChange = (region, subregion, type, field, value) => {
        setEditedProfile(prevState => {
            const updatedData = { ...prevState.data };
            updatedData[region][subregion].shippingFees[type][field] = value;

            return {
                ...prevState,
                data: updatedData,
            };
        });
    };

    const transitTimeOptions = {
        standard: ['3-7', '10-20', '20-28', '26-34'],
        expedited: ['1-2', '1-3', '2-4', '3-5', '5-9'],
    };

    const handleTransitTimeChange = (region, subregion, type, value) => {
        setEditedProfile(prevState => {
            const updatedData = { ...prevState.data };
            updatedData[region][subregion][`transitTimes${type}`] = value;
            return {
                ...prevState,
                data: updatedData,
            };
        });
    };

    const handleSaveChanges = () => {
        // Function to check if all required fields are filled
        const isProfileComplete = () => {
            if (!editedProfile.shippingProfileName) return false;
            for (const [region, subregions] of Object.entries(editedProfile.data)) {
                for (const [subregion, details] of Object.entries(subregions)) {
                    if (!details.countries || details.countries.length === 0) return false;
                    if (!details.shippingFees.standard.perOrder) return false;
                    if (!details.shippingFees.standard.perItem) return false;
                    if (!details.shippingFees.expedited.perOrder) return false;
                    if (!details.shippingFees.expedited.perItem) return false;
                    if (!details.transitTimesStandard) return false;
                    if (!details.transitTimesExpedited) return false;
                }
            }
            return true;
        };

        if (isProfileComplete()) {
            fetch(`https://api.digiblocks.tech/shipping-profiles/${id}`, {
                method: 'DELETE',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete shipping profile');
                    }
                    return fetch('https://api.digiblocks.tech/shipping-profiles', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(editedProfile),
                    });
                })
                .then(response => response.json())
                .then(data => {
                    toast({
                        title: 'Profile Updated',
                        description: 'Shipping profile has been edited successfully.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    navigate('/manager/allshippingprofile')
                })

                .catch(error => {
                    console.error('Error creating shipping profile:', error);
                    toast({
                        title: 'Error',
                        description: 'Failed to create shipping profile. Please try again.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        } else {
            toast({
                title: 'Incomplete Data',
                description: 'Please fill all input fields.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };


    if (!shippingProfile) {
        return <div>Loading...</div>;
    }

    const getRegionBySubregion = (subregion) => {
        for (let region in countriesData) {
            if (countriesData[region][subregion]) {
                return region;
            }
        }
        return null;
    };

    return (
        <ChakraProvider>
            <AdminNav />
            <Box ml={['', '', '15%']} p={3} textAlign="center">
                <Heading as="h2" size="xl" mb={4}>
                    Edit Shipping Profile
                </Heading>
                <Table colorScheme="teal" size="sm">
                    <Thead>
                        <Tr>
                            <Th>Field</Th>
                            <Th>Value</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Shipping Profile Name:</Td>
                            <Td>
                                <Input
                                    type="text"
                                    name="shippingProfileName"
                                    value={editedProfile.shippingProfileName || ''}
                                    onChange={handleInputChange}
                                />
                            </Td>
                        </Tr>
                        {/* Add similar rows for other fields */}
                    </Tbody>
                </Table>
                {Object.entries(countriesData).map(([region, subregions]) => (
                    <Box p="4" border={"1px solid teal"} borderRadius="20px" key={region} mt={6}>
                        <Heading as="h3" textAlign={"left"} size="lg" mb={2}>
                            {region}
                        </Heading>
                        <Table colorScheme="teal" size="sm">
                            <Thead>
                                <Tr>
                                    <Th width="10%">Subregion</Th>
                                    <Th width="40%">Countries</Th>
                                    <Th width="15%">Standard Shipping Fee</Th>
                                    <Th width="15%">Expedited Shipping Fee</Th>
                                    <Th width="10%">Standard Transit Time</Th>
                                    <Th width="10%">Expedited Transit Time</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Object.entries(subregions).map(([subregion, countries]) => (
                                    <Tr key={subregion}>
                                        <Td>{subregion}</Td>
                                        <Td>
                                            <SimpleGrid gap={3} columns={3}>
                                                {countries.map(country => (
                                                    <Box key={country}>
                                                        <Checkbox
                                                            isChecked={selectedCountries.includes(country)}
                                                            onChange={() => handleCheckboxChange(region, subregion, country)}
                                                        >
                                                            {country}
                                                        </Checkbox>
                                                    </Box>
                                                ))}
                                            </SimpleGrid>
                                        </Td>
                                        <Td>
                                            <Input
                                                type="text"
                                                value={editedProfile.data?.[getRegionBySubregion(subregion)]?.[subregion]?.shippingFees?.standard?.perOrder || ''}
                                                onChange={e =>
                                                    handleShippingFeeChange(getRegionBySubregion(subregion), subregion, 'standard', 'perOrder', e.target.value)
                                                }
                                            />
                                            {' per order + '}
                                            <Input
                                                type="text"
                                                value={editedProfile.data?.[getRegionBySubregion(subregion)]?.[subregion]?.shippingFees?.standard?.perItem || ''}
                                                onChange={e =>
                                                    handleShippingFeeChange(getRegionBySubregion(subregion), subregion, 'standard', 'perItem', e.target.value)
                                                }
                                            />
                                            {' per item'}
                                        </Td>
                                        <Td>
                                            <Input
                                                type="text"
                                                value={editedProfile.data?.[getRegionBySubregion(subregion)]?.[subregion]?.shippingFees?.expedited?.perOrder || ''}
                                                onChange={e =>
                                                    handleShippingFeeChange(getRegionBySubregion(subregion), subregion, 'expedited', 'perOrder', e.target.value)
                                                }
                                            />
                                            {' per order + '}
                                            <Input
                                                type="text"
                                                value={editedProfile.data?.[getRegionBySubregion(subregion)]?.[subregion]?.shippingFees?.expedited?.perItem || ''}
                                                onChange={e =>
                                                    handleShippingFeeChange(getRegionBySubregion(subregion), subregion, 'expedited', 'perItem', e.target.value)
                                                }
                                            />
                                            {' per item'}
                                        </Td>
                                        <Td>
                                            <Select
                                                value={editedProfile.data[getRegionBySubregion(subregion)]?.[subregion]?.transitTimesStandard || ''}
                                                onChange={e => handleTransitTimeChange(getRegionBySubregion(subregion), subregion, 'Standard', e.target.value)}
                                            >
                                                <option key={""}>Select</option>
                                                {transitTimeOptions.standard.map((option) => (

                                                    <option key={option} value={option}>{option} days</option>

                                                ))}
                                            </Select>
                                        </Td>
                                        <Td>
                                            <Select
                                                value={editedProfile.data[getRegionBySubregion(subregion)]?.[subregion]?.transitTimesExpedited || ''}
                                                onChange={e => handleTransitTimeChange(getRegionBySubregion(subregion), subregion, 'Expedited', e.target.value)}
                                            >
                                                <option key={""}>Select</option>
                                                {transitTimeOptions.expedited.map((option) => (
                                                    <option key={option} value={option}>{option} days</option>
                                                ))}
                                            </Select>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                ))}
                <Flex mt={8} justify="flex-end">
                    <Button colorScheme="teal" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Flex>
            </Box>
        </ChakraProvider>
    );
};

export default EditShippingProfile;
