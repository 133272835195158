import AdminNav from './AdminNav';
import {
  Box, Modal, Button, Image, ModalOverlay, useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
  ModalBody,
  useToast,
  ModalCloseButton, Flex, Heading, Badge, Input, Avatar, Checkbox
} from '@chakra-ui/react';
import axios from 'axios';
import { FaPaperPlane, FaRegImage } from 'react-icons/fa';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AppContext } from '../../Context/AppContext';
import { DeleteIcon } from '@chakra-ui/icons';
import { BiDownArrow } from 'react-icons/bi';


const Messages = () => {
  let toast = useToast();
  const [allusers, setAllusers] = useState([]);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [reciever, setReciever] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [photo, setPhoto] = useState('');
  const [nameoremail, setnameoremail] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [deleteall, setdeleteall] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { refresh, setRefresh } = useContext(AppContext);
  const divRef = useRef(null);

  const [savedMessages, setSavedMessages] = useState([
    "Hello, how can I help you?",
    "Please wait while I check that for you.",
    "Thank you for your message.",
    "I will look into this and get back to you shortly.",
    "Is there anything else I can assist you with?",
    "Your request has been received.",
  ]);

  const scrollToBottom = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight + 100;
    }
  };
  useEffect(() => {
    // Scroll to the bottom of the div when component mounts
    scrollToBottom();
  }, [refresh]);
  const getallusers = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/messages/getallusers');
      const users = [];
      response.data.messages.forEach((e) => {
        if (e.sender === 'Admin' && !users.includes(e.reciever)) {
          users.push(e.reciever);
        }
        if (e.reciever === 'Admin' && !users.includes(e.sender)) {
          users.push(e.sender);
        }
      });
      let arrOfNameEmail = [];

      // Map each user to a promise that fetches the name or email
      let promises = users.map(async (id) => {
        try {
          // Fetch user details asynchronously
          let userDetails = await getuserNameorEmail(id);
          return userDetails;
        } catch (error) {
          console.error("Error fetching user details:", error);
          return null;
        }
      });

      // Wait for all promises to resolve
      Promise.all(promises)
        .then((results) => {
          // Filter out null results and push the valid user details to arrOfNameEmail
          arrOfNameEmail = results
          setAllusers(results)
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
      setAllusers(users);
      const storedReciever = JSON.parse(localStorage.getItem('reciever'));
      if (storedReciever) {
        setReciever(storedReciever);
        setSelectedUser(storedReciever);
      } else {
        setReciever(null);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const handleCheckboxChange = (e, userID) => {
    if (e.target.checked) {
      setSelectedUserIDs(prevSelectedUserIDs => [...prevSelectedUserIDs, userID]);
    } else {
      setSelectedUserIDs(prevSelectedUserIDs => prevSelectedUserIDs.filter(id => id !== userID));
    }
  };
  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    // Update selectedUserIDs based on isChecked value
    if (isChecked) {
      const allUserIDs = allusers.map((user) => user.userID);
      setSelectedUserIDs(allUserIDs);
    } else {
      setSelectedUserIDs([]);
    }
  };
  const handleDelete = async () => {
    if (selectedUserIDs.length === 0) {
      alert("Please select at least one user to delete");
      return;
    } else {
      try {
        let res = await axios.post(`https://api.digiblocks.tech/delete/messages`, { selectedUserIDs });
        console.log(res)
        setRefresh(!refresh);
        setSelectedUserIDs([]);
        setSelectedUser(null)
        toast({
          title: 'Messages Deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

      } catch (error) {
        console.error('Error deleting messages:', error);
      }
    }
  };

  const getallmessages = async () => {
    try {
      const response = await axios.get(`https://api.digiblocks.tech/messages/admin?reciever=${reciever}`);
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    getallusers();
  }, [refresh]);

  useEffect(() => {
    getallmessages();
  }, [reciever, refresh]);

  let getuseremail = async (id) => {
    let response = await axios.get(`https://api.digiblocks.tech/getuser/${id}`);
    return response.data.email;

  }
  let sendemailtouser = async (reciever) => {
    let useremail = await getuseremail(reciever);

    try {
      const response = await axios.post(
        'https://api.digiblocks.tech/messageConfirmation/user',
        { email: useremail } // Assuming useremail is a variable holding the email address
      );
    } catch (error) {
      console.error('Error sending confirmation email to user:', error);
    }
  }

  const handlesend = async () => {
    try {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.post(`https://api.digiblocks.tech/messages/admin?reciever=${reciever}`, {
        sender: 'Admin',
        message,
        reciever,
      });
      setMessage('');
      setRefresh(!refresh);
      scrollToBottom();
      sendemailtouser(reciever)
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSavedMessageClick = (message) => {
    setMessage(message); // Set the message in the input field
  };

  const handlesendImage = async () => {
    if (photo) {
      const userToken = localStorage.getItem('userToken');
      const response = await axios.post(
        `https://api.digiblocks.tech/messages/admin?reciever=${reciever}`,
        { sender: 'Admin', message: photo, reciever },
      );
      setMessages([...messages, { sender: userToken, message }]);
      setMessage('');
      setPhoto('');
      setRefresh(!refresh)
      onClose();
    }
    else {
      onClose();
      alert("Please select a photo")
    }
  };


  const onPhotoDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 1 || acceptedFiles.length < 1) {
      alert("Please insert upto 1 image files")
    } else {

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('photos', file);
      });
      try {
        const response = await axios.post('https://api.digiblocks.tech/api/upload/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setPhoto(response?.data?.photos[0]?.url);

      } catch (error) {
        console.error('Error uploading photos:', error);
      }
    }
  };

  const { getRootProps: photoRootProps, getInputProps: photoInputProps } = useDropzone({
    onDrop: onPhotoDrop,
    accept: 'image/*',
  });
  let getuserNameorEmail = async (id) => {
    let response = await axios.get(`https://api.digiblocks.tech/getuser/${id}`);
    return response.data.name && { userID: id, userName: response?.data?.name } || response?.data?.email && { userID: id, userEmail: response?.data?.email }
  }

  const dropzoneStyles = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };
  return (
    <>
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Box ml={["0px", "0px", "15%"]} w={"100%"} >
          <Flex direction="column" w={"100%"}>
            <Flex direction={["column", "", "row"]} justifyContent={"space-between"} w={"100%"}>
              <Heading mb="4" textAlign="center" display={["block", "", "none"]}>
                Messages
              </Heading>
              <Flex display={["flex", "flex", "none"]} gap={3} h={"30vh"} overflowY={"scroll"} direction="column" w={["100%", "", ""]} p="4" borderRadius="lg" boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)">
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Box as={Flex} gap={2} alignItems={"center"}>
                    <Box as={Flex} gap={2} alignItems={"center"} border={"1px solid teal"} borderRadius={"10px"} p={2}>
                      <Checkbox colorScheme='green' />
                      <BiDownArrow />
                    </Box>
                    <Text fontWeight={"bold"} m={0}>Select All</Text>
                  </Box>
                  <Flex alignItems={"center"} gap={3}>
                    <Text m={0}>Delete Selected : </Text>
                    <Button onClick={handleDelete}>
                      <DeleteIcon color={"red"}></DeleteIcon>
                    </Button>
                  </Flex>
                </Flex>
                {allusers.map((user) => (
                  <Flex onClick={() => {
                    JSON.stringify((localStorage.setItem('reciever', user)));
                    setReciever(user.userID);
                    setSelectedUser(user);
                  }} border={"1px solid teal"} borderRadius={"10px"} alignItems={"center"} cursor={"pointer"}>
                    <Checkbox colorScheme='green' onChange={(e) => handleCheckboxChange(e, user.userID)}
                      isChecked={selectedUserIDs.includes(user.userID)} />
                    <Box
                      key={user.userID}
                      fontWeight={"bold"}
                    >
                      <Flex align="center" p="2" cursor="pointer" overflowX="hidden">
                        <Avatar name={user?.userName ? user.userName : ""} size="sm" mr="2" />
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                          {user?.userName ? user?.userName : user?.userEmail}
                        </div>
                      </Flex>
                    </Box>
                  </Flex>
                ))}
              </Flex>
              {selectedUser && <Flex direction={"column"} w={"100%"}>
                {selectedUser && (
                  <Flex align="center" p="2" cursor="pointer" overflowX="hidden">
                    <Avatar name={selectedUser?.userName ? selectedUser?.userName : selectedUser?.userEmail} size="sm" mr="2" />
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                      {selectedUser?.userName ? selectedUser?.userName : selectedUser?.userEmail}
                    </div>
                  </Flex>
                )}
                <Box h={"400px"} mb={3} p={"md"} pb={["35px", "", ""]} ref={divRef} overflowY={"scroll"}>
                  {messages.length > 0 &&
                    messages.map((e, ind) => (
                      <Flex
                        key={ind}
                        mb="2"
                        px="8"
                        justifyContent={e.sender === 'Admin' ? 'flex-end' : 'flex-start'}
                        transition="background 0.3s ease"
                      >
                        {e.message.includes("https://res.cloudinary.com") ? <>
                          {e.sender == "Admin" ? <Flex justifyContent={"flex-end"}>
                            <Image w={"50%"} src={e.message}></Image>
                          </Flex>
                            : <Flex id={messages.length - 1 == ind ? "latest" : ""} justifyContent={"flex-start"}>
                              <Image w={"50%"} alt="Uploaded" src={e.message}></Image>
                            </Flex>}
                        </> : <Flex key={ind} mb="2" alignSelf={e.sender === 'Admin' ? 'flex-start' : 'flex-end'} transition="background 0.3s ease">
                          <Flex direction="column" alignItems={e.sender === 'Admin' ? 'flex-start' : 'flex-end'}>
                            <Badge
                              variant="solid"
                              colorScheme={e.sender === 'Admin' ? 'gray' : 'green'}
                              bg={e.sender === 'Admin' ? '#6FA82F' : 'gray'}
                              p="2"
                              borderRadius="30px"
                              id={messages.length - 1 === ind ? "latest" : ""}
                              mb="1"
                            >
                              {e.message}
                            </Badge>
                            <Text fontSize="xs" color="gray.500" textAlign="right">
                              {new Date(e.createdAt).toLocaleDateString()} {new Date(e.createdAt).toLocaleTimeString()}
                            </Text>
                          </Flex>
                        </Flex>
                        }
                      </Flex>
                    ))}
                </Box>
                <Flex px="8" align="center" p={3}>
                  <Input
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    mr="2"
                    flex="1"
                    borderRadius="full"
                    border="2px solid #6FA82F"
                    focusBorderColor="#6FA82F"
                    _hover={{ borderColor: '#6FA82F' }}
                  />
                  <Button mr="2" onClick={() => {
                    onOpen();
                  }} colorScheme="green"
                    bg="#6FA82F"
                    borderRadius="full" position={"absoulute"} right={0} bottom={0}><FaRegImage /> </Button>
                  <Button onClick={handlesend} colorScheme="green" bg="#6FA82F" borderRadius="full" _hover={{ bg: '#5C8B24' }}>
                    <FaPaperPlane />
                  </Button>
                </Flex>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  maxWidth="fit-content"
                  mx="2"
                >
                  {savedMessages.map((msg, index) => (
                    <Box
                      key={index}
                      onClick={() => handleSavedMessageClick(msg)}
                      cursor="pointer"
                      color="blue.500"
                      p="2"
                      border="1px solid lightgray"
                      borderRadius={"20px"}
                      _hover={{ textDecoration: 'underline' }}
                      mb="1"
                      mr="1"
                    >
                      {msg}
                    </Box>
                  ))}
                </Box>
              </Flex>

              }
              <Flex gap={3} m={"auto"} p={2} display={["none", "none", "flex"]} direction="column" h={"100vh"} overflowY={"scroll"} w={["100%", "", "70%"]} borderRadius="lg" boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)">
                <Heading mb="4" textAlign="center">
                  Messages
                </Heading>
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Box as={Flex} gap={2} alignItems={"center"}>
                    <Box as={Flex} gap={2} alignItems={"center"} border={"1px solid teal"} borderRadius={"10px"} p={2}>
                      <Checkbox colorScheme='green' isChecked={selectAllChecked}
                        onChange={handleSelectAllChange} />
                      <BiDownArrow />
                    </Box>
                    <Text fontWeight={"bold"} m={0}>Select All</Text>
                  </Box>
                  <Flex alignItems={"center"} gap={3}>
                    <Text m={0}>Delete Selected : </Text>
                    <Button onClick={handleDelete}>
                      <DeleteIcon color={"red"}></DeleteIcon>
                    </Button>
                  </Flex>
                </Flex>
                {allusers.map((user) => (
                  <Flex gap={2} onClick={() => {
                    localStorage.setItem('reciever', user);
                    setReciever(user.userID);
                    setSelectedUser(user);
                  }} cursor={"pointer"} border={"1px solid teal"} borderRadius={"10px"} alignItems={"center"} px={2}>
                    <Checkbox colorScheme='green' onChange={(e) => handleCheckboxChange(e, user.userID)}
                      isChecked={selectedUserIDs.includes(user.userID)} />
                    <Box
                      key={user.userID}
                      fontWeight={"bold"}
                    >
                      <Flex align="center" p="2" cursor="pointer" overflowX="hidden" gap={2}>
                        <Avatar name={user?.userName ? user.userName : ""} size="sm" />
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                          {user?.userName ? user?.userName : user?.userEmail}
                        </div>

                      </Flex>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {/* image upload */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mr={2} w={"100%"} {...photoRootProps()} style={dropzoneStyles}>
              <input {...photoInputProps()} />
              <Text>Drag & drop some photos here, or click to select photos</Text>
            </Box>
            {photo &&
              <>
                <Text>Preview</Text>
                <Image w={"50%"} alt="Uploaded" src={photo}></Image>
              </>}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={() => {
              handlesendImage();
            }}>Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Messages;