import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Center, Text, Heading, Button } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import DogsComponent from '../components/DogsComponent';

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Page Not Found" content="KnitSilk | Page Not Found" />
                <title>KnitSilk | Page Not Found</title>
            </Helmet>
            <Box maxW="100%" bg="white" p={4} mt={"2%"}>
                <Box height={"50%"}>
                    <DogsComponent />
                </Box>
                <Center mt={4}>
                    <Heading as="h1" fontSize="4xl" color="#6FA82F" fontFamily={"DM Serif Display"}>
                        Page Not Found
                    </Heading>
                </Center>
                <Center mt={4}>
                    <Text fontSize="xl">
                        The page you are looking for doesn't exist or has been moved.
                    </Text>
                </Center>
                <Center mt={4}>
                    <Button
                        colorScheme="green"
                        bg={"#6FA82F"}
                        borderRadius={0}
                        _hover={{ bg: "#232424" }}
                        rightIcon={<BsArrowRight />}
                        size="lg"
                    >
                        Back to Home
                    </Button>
                </Center>
            </Box>
        </>
    );
};

export default NotFoundPage;
