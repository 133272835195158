import { useEffect, useState } from 'react';
import {
    ChakraProvider,
    Text,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    Button,
    Spinner,
    useToast
} from '@chakra-ui/react';
import AdminNav from './AdminNav';
import * as XLSX from 'xlsx';
import { useQuery, useQueryClient, useMutation } from 'react-query';

function Users() {
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 50;
    const [userOrders, setUserOrders] = useState({});
    const queryClient = useQueryClient();
    const toast = useToast();

    const { isLoading: isUsersLoading, data: users = [] } = useQuery('users', async () => {
        const response = await fetch('https://api.digiblocks.tech/getallusers');
        return response.json();
    });

    const deleteUser = async (userId) => {
        const response = await fetch(`https://api.digiblocks.tech/deleteuser/${userId}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            throw new Error('Failed to delete user');
        }
    };

    const mutation = useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('users');
            toast({
                title: "User deleted.",
                description: "The user has been deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        },
        onError: () => {
            toast({
                title: "Error deleting user.",
                description: "There was an error deleting the user.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const handleDeleteUser = (userId) => {
        mutation.mutate(userId);
    };

    useEffect(() => {
        const fetchOrdersForUsers = async () => {
            try {
                const ordersStatus = {};
                await Promise.all(users.map(async (user) => {
                    const ordersResponse = await fetch(`https://api.digiblocks.tech/findByEmail?email=${user.email}`);
                    const ordersData = await ordersResponse.json();
                    ordersStatus[user.email] = ordersData;
                }));
                setUserOrders(ordersStatus);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        if (users.length > 0) {
            fetchOrdersForUsers();
        }
    }, [users]);

    const exportToExcel = () => {
        const exportData = users.map(user => {
            const orderInfo = userOrders[user.email] && userOrders[user.email].length > 0
                ? userOrders[user.email][0].customerInfo.shipping
                : { phone: 'No Orders Yet', street: 'No Orders Yet', city: '', state: '', country: '', zipCode: '' };
    
            return {
                Name: user.name,
                Email: user.email,
                'Mobile No.': orderInfo.phone,
                Address: orderInfo.street === 'No Orders Yet' ? 'No Orders Yet' : `${orderInfo.street}, ${orderInfo.city}, ${orderInfo.state}, ${orderInfo.country}, ${orderInfo.zipCode}`
            };
        });
    
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');
        XLSX.writeFile(wb, 'users.xlsx');
    };

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(users.length / usersPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <ChakraProvider>
            <AdminNav />
            <Box ml={["0px", "0px", "15%"]} w={["100%", "100%", "82%"]} p={4}>
                <Flex mx="2%" justifyContent={"space-between"} alignItems="center" flexWrap="wrap">
                    <Text fontSize="3xl" fontWeight="bold" mb={4}>
                        Registered Users List
                    </Text>
                    <Button mt="2" bg={"black"} borderRadius={"none"} size={"sm"} colorScheme="teal" mb={4} onClick={exportToExcel}>
                        Export to Excel
                    </Button>
                </Flex>
                {isUsersLoading ? (
                    <Flex justifyContent="center" mt={10}>
                        <Spinner size="xl" />
                    </Flex>
                ) : (
                    <Box overflowX="auto">
                        <Table variant="striped" size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Email</Th>
                                    <Th>Mobile No.</Th>
                                    <Th>Address</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentUsers.map((user) => (
                                    <Tr key={user._id}>
                                        <Td>{userOrders[user.email] && userOrders[user.email].length > 0 ? (
                                            userOrders[user.email][0].customerInfo.shipping.customerName
                                        ) : (
                                            ''
                                        ) || user.name}</Td>
                                        <Td>{user.email}</Td>
                                        <Td>
                                            {userOrders[user.email] && userOrders[user.email].length > 0 ? (
                                                userOrders[user.email][0].customerInfo.shipping.phone
                                            ) : (
                                                'No Orders Yet'
                                            )}
                                        </Td>
                                        <Td>
                                            {userOrders[user.email] && userOrders[user.email].length > 0 ? (
                                                `${userOrders[user.email][0].customerInfo.shipping.street}, ${userOrders[user.email][0].customerInfo.shipping.city}, ${userOrders[user.email][0].customerInfo.shipping.state}, ${userOrders[user.email][0].customerInfo.shipping.country}, ${userOrders[user.email][0].customerInfo.shipping.zipCode}`
                                            ) : (
                                                'No Orders Yet'
                                            )}
                                        </Td>
                                        <Td>
                                            <Button size="sm" colorScheme="red" borderRadius={"none"} ml={2} onClick={() => handleDeleteUser(user._id)}>
                                                Delete
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                )}
                <Flex justifyContent="space-between" mt={4}>
                    <Button colorScheme='blue' borderRadius={"none"} size={"sm"} onClick={handlePreviousPage} disabled={currentPage === 1}>
                        Previous
                    </Button>
                    <Text>
                        Page {currentPage} of {Math.ceil(users.length / usersPerPage)}
                    </Text>
                    <Button colorScheme='blue' borderRadius={"none"} size={"sm"} onClick={handleNextPage} disabled={currentPage === Math.ceil(users.length / usersPerPage)}>
                        Next
                    </Button>
                </Flex>
            </Box>
        </ChakraProvider>
    );
}

export default Users;
