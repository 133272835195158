import React, { useState } from 'react';
import { ChakraProvider, Text, Image, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const NeedlesTypes = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%"   border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading as="h2"  borderBottom="2px solid #74D69E"
                    paddingBottom="10px" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" mb={4}>
                        Knitting Needle Types
                    </Heading>
                    <Image
                        src="https://images.immediate.co.uk/production/volatile/sites/32/2020/12/Knitting-needle-sizes-32dc877.jpg?quality=90&webp=true&resize=900,600"
                        alt="Needles Types"
                        maxW="100%"
                        margin={"auto"}
                        borderRadius={"30px"}
                        h="auto"
                        mt={6}
                        mb={6}
                    />
                    <Text fontSize="lg" mb={4}>
                        One thing is for sure: with the variety of knitting needles available today, knitting has been perfected and has evolved to a creative art! Knitting needles have developed from simple sticks to the diverse assortment available on the market today. Knitting needles are made in a variety of materials: plastic, wood, bamboo, metal, and acrylic. Which type you use comes down to personal preference.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        <strong>Single Point Needles [Straight Needles]:</strong> These needles have a point at one end and a cap at the other, which keeps the stitches from falling off. They are great for making flat items, such as scarves, shawls, table runners, and afghans. The cap allows multiple stitches to be worked at the same time on large knits without the fear of stitches falling off the end.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        <strong>Double Point Needles:</strong> Double point needles are used to make small circular garments such as socks, mittens, the sleeves on jackets or sweaters, and other items that benefit from a seamless finish. The garments are worked with a set of four needles. The stitches remain permanently on three of the needles. The forth needle is used to knit into the stitches, transferring them to the 4th needle as they are worked. In patterns, you will usually see them referred to as "dp" needles.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        <strong>Circular Needles:</strong> Circular needles are not created as a full circle. They are two needles connected by a nylon fiber cord. They allow a larger circular garment, such as a large sweater, to be worked as a seamless product. The connector cord will hold many stitches, without the bulk that would normally occur when using double pointed needles. They also come in handy when transporting your unfinished items, as the stitches won't fall off the needles as easily. Circular needles can also be used to make large blankets, which would otherwise need to be completed in sections and then sewn together. The circular needles allow a great number of stitches to be cast on. The item can then be knitted back and forth in much the same manner as flat needles.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        <strong>Cable Stitch Needles:</strong> Cable stitch needles have a curved section in them, so the stitches can settle in the indentation as cable stitch is worked. The stitches need to be moved from side to side when working cables. The indentation allows easy identification of the stitches being manipulated to produce the cables.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        As a very general rule, use a slippery needle like metal for yarns that are sticky or catchy, such as mohair or chenille. Conversely, with slippery yarns, such as some ribbons and novelty yarns, try needles that are a little less smooth, like bamboo. As you take on new projects, you'll likely need different-sized needles every now and then. Why not try a needle made from something new the next time you buy?
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        <strong>Gauge:</strong> When knitting a garment, it is immensely helpful to know from the onset whether the finished item will fit. This is where gauge comes in. To check for gauge, a swatch is knitted to predict the size of your knitted item. Generally, you work a swatch that is 10 stitches long and 10 rows high. The swatch can be placed on a flat surface and measured to see how many stitches equal one inch. Multiply the number of stitches in an inch is by the number of inches needed to estimate how many stitches need to be cast on. For example, if gauge measures four stitches per inch, and your garment needs to be 25 inches, you will need to cast on 100 stitches. This will avoid the disappointment of finishing a garment, only to have it not fit as intended. With garment patterns, especially sweaters, the stitch count can be crucial to the pattern design. The needle size can be adjusted so that the swatch test results comply with those specified in the pattern. Moving to larger or smaller sized needles would adjust the stitch size without making complicated adjustments to the pattern instructions.
                    </Text>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};



export default NeedlesTypes;
