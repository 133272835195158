import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Nav from '../components/HomePageComponents/Nav'
import Footer from '../components/HomePageComponents/Footer';
import Dashboard from '../components/MyAccount/Dashboard';
import { Helmet } from 'react-helmet';
import UserChatButton from '../components/Chat/ChatButton'

const UserAccount = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | MyAccount" content="KnitSilk | MyAccount" />
                <title>KnitSilk | MyAccount</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <Dashboard />
            <UserChatButton />
            <Footer />
        </ChakraProvider>
    )
}

export default UserAccount;
