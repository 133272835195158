import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Heading,
    Flex,
    Button,
    Spacer,
    Text,
    Select,
    VStack,
    HStack,
    IconButton,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Tooltip,
    SimpleGrid,
    useToast
} from '@chakra-ui/react';
import { FaEdit, FaPlus, FaTrash, FaUser, FaPhone, FaEnvelope } from 'react-icons/fa';
import ChatWithAdmin from '../../pages/ChatWithAdmin';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom/dist';
import DogsComponent from '../DogsComponent';



const AddressCard = ({ callCode, address, onEdit, onRemove }) => (
    <Box
        borderWidth="1px"
        borderRadius="30px"
        overflow="hidden"
        py={["4", "4", "4"]}
        px={["4", "4", "6"]}
        shadow="md"
        bg="white"
    >

        <VStack width={"100%"} align="start" spacing={2}>
            <HStack>
                <Box as={FaUser} boxSize={5} color="blue.500" />
                <Text fontFamily="DM Serif Display" m={"auto"} fontSize="lg" fontWeight="bold">
                    {address.shipping.customerName}
                </Text>
            </HStack>
            <HStack>
                <Box as={FaPhone} boxSize={4} color="teal.500" />
                <Text m={"auto"}>{callCode}{" "}{address.shipping.phone}</Text>
            </HStack>
            <HStack>
                <Box as={FaEnvelope} boxSize={4} color="orange.500" />
                <Text m={"auto"}>{address.shipping.email}</Text>
            </HStack>
            <Text mt={"-1"}>{address.shipping.street}</Text>
            <Text mt={"-5"}>{address.shipping.city}</Text>
            <Text mt={"-5"}>{`${address.shipping.state}, ${address.shipping.zipCode}, ${address.shipping.country}`}</Text>
        </VStack>
        <Spacer />
        <HStack alignItems="center" spacing={4}>
            <Tooltip label="Edit Address" bg="white" color="green" aria-label="edit-tooltip">
                <IconButton
                    icon={<FaEdit />}
                    size="sm"
                    borderRadius="full"
                    bg="green.200"
                    color="green.800"
                    onClick={onEdit}
                />
            </Tooltip>
            <Tooltip label="Delete Address" bg="white" color="red" aria-label="delete-tooltip">
                <IconButton
                    icon={<FaTrash />}
                    size="sm"
                    borderRadius="full"
                    bg="red.200"
                    color="red.800"
                    onClick={onRemove}
                />
            </Tooltip>
        </HStack>
    </Box>
);

const AddressesPage = () => {
    const [addresses, setAddresses] = useState([]);
    const userToken = localStorage.getItem('userToken');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedAddress, setSelectedAddress] = useState(null);
    const toast = useToast();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        customerName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        isBillingSameAsShipping: false,
    });

    const [callCode, setCallCode] = useState(null);
    const fetchIPDetails = async () => {
        const response = await axios.get('https://ipapi.co/json/');
        setCallCode(response.data.country_calling_code);
    }
    useEffect(() => {
        fetchAddresses();
        fetchIPDetails();
        getCountries();
    }, []);


    const getCountries = async () => {
        try {
            const response = await axios.get('https://api.countrystatecity.in/v1/countries', {
                headers: {
                    'X-CSCAPI-KEY': 'Y21vYlNQV0VQQzY2ajBkVDJ2cndBZjJpMGhNY2JhbEJjWVBpelFwWQ==',
                },
            });
            if (response.data && Array.isArray(response.data)) {
                setCountries(response.data);
            } else {
                console.error('Invalid response structure for countries:', response.data);
            }
        } catch (error) {
            console.error('Error getting countries:', error);
        }
    };

    const getStates = async (countryCode) => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${countryCode}/states`, {
                headers: {
                    'X-CSCAPI-KEY': 'Y21vYlNQV0VQQzY2ajBkVDJ2cndBZjJpMGhNY2JhbEJjWVBpelFwWQ==',
                },
            });
            setStates(response.data);
        } catch (error) {
            console.error('Error getting states:', error);
        }
    };
    const getCities = async (countryCode, stateCode) => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`, {
                headers: {
                    'X-CSCAPI-KEY': 'Y21vYlNQV0VQQzY2ajBkVDJ2cndBZjJpMGhNY2JhbEJjWVBpelFwWQ==',
                },
            });
            // console.log('cities' + response)
            setCities(response.data.map(city => city.name));
        } catch (error) {
            console.error('Error getting cities:', error);
        }
    };
    const fetchAddresses = async () => {
        try {
            const response = await axios.get('https://api.digiblocks.tech/user/address', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                },
            });
            setAddresses(response.data.addresses);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    const handleOpenModal = (address) => {
        if (address) {
            setSelectedAddress(address);
            setFormData({
                customerName: address.shipping.customerName,
                email: address.shipping.email,
                phone: address.shipping.phone,
                street: address.shipping.street,
                city: address.shipping.city,
                state: address.shipping.state,
                zipCode: address.shipping.zipCode,
                country: address.shipping.country,
                isBillingSameAsShipping: address.isBillingSameAsShipping,
            });
        } else {
            setSelectedAddress(null);
            setFormData({
                customerName: '',
                email: '',
                phone: '',
                street: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                isBillingSameAsShipping: false,
            });
        }
        onOpen();
    };

    const handleAddOrUpdateAddress = async () => {
        try {
            const userToken = localStorage.getItem('userToken');
            const apiUrl = selectedAddress ? `https://api.digiblocks.tech/user/address/${selectedAddress._id}` : 'https://api.digiblocks.tech/user/address';
            const method = selectedAddress ? 'put' : 'post';

            const response = await axios[method](apiUrl, {
                shipping: {
                    customerName: formData.customerName,
                    email: formData.email,
                    phone: formData.phone,
                    street: formData.street,
                    city: formData.city,
                    state: formData.state,
                    zipCode: formData.zipCode,
                    country: formData.country,
                },
                callCode: callCode,
                is_billing_same_as_shipping: formData.isBillingSameAsShipping,
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                onClose();
                fetchAddresses();
                toast({
                    title: selectedAddress ? 'Address Updated' : 'Address Added',
                    description: `Your address was ${selectedAddress ? 'updated' : 'added'} successfully.`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                console.error('Failed to add/update address:', response.data);
                toast({
                    title: 'Error',
                    description: 'Failed to add/update address. Please try again.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error adding/updating address:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while adding/updating the address. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRemoveAddress = async (selectedAddress) => {

        try {
            const apiUrl = `https://api.digiblocks.tech/user/address/${selectedAddress._id}`;

            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.status === 200) {
                onClose();
                fetchAddresses();
                toast({
                    title: 'Address Removed',
                    description: 'Your address was removed successfully.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                console.error('Failed to remove address:', response.data);
                toast({
                    title: 'Error',
                    description: 'Failed to remove address. Please try again.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error removing address:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while removing the address. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    let navigate = useNavigate();

    return (
        <>
            {
                userToken ? (
                    <>
                        <Flex m="auto" w={['90%', '', '70%']} align="center" justify="space-between">
                            <Flex>
                                <Heading fontFamily='DM Serif Display' textAlign="center" my={8}>
                                    My Addresses                                </Heading>
                            </Flex>
                            <Flex alignItems={"center"}>
                                <ChatWithAdmin />
                                <Button onClick={() => navigate('/my-account')}
                                    size="sm"
                                    borderRadius="none"
                                    colorScheme="green"
                                    bg="#6FA82F"
                                    width="150px"
                                    ml={"2"}
                                    _hover={{ bg: "black", color: "white" }}
                                >
                                    <ArrowLeftIcon />&nbsp; My Account
                                </Button>
                            </Flex>

                        </Flex>
                        <Flex justifyContent={"space-between"} gap={"16"} direction={["column", "column", "row"]} p={8} m={"auto"} width={"90%"}>
                            <Box display={"flex"} m={"auto"} direction={"column"} justifyContent={"center"} alignItems={"center"} fontFamily='DM Serif Display' height={["200px", "", "300px"]} onClick={() => handleOpenModal()} p={3} width={["200px", "", "300px"]} border={"1px dashed gray"} borderRadius={"3xl"} >
                                <Text fontSize={["xl", "xl", "3xl"]} fontWeight={"bold"} ><FaPlus /></Text>
                                <Text fontSize={["xl", "xl", "3xl"]} fontWeight={"bold"} >Add Address</Text>
                            </Box>
                            <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                                {addresses.map((address) => (
                                    <AddressCard
                                        key={address._id}
                                        address={address}
                                        callCode={callCode}
                                        onEdit={() => handleOpenModal(address)}
                                        onRemove={() => handleRemoveAddress(address)}
                                    />
                                ))}
                            </SimpleGrid>
                            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>{selectedAddress ? 'Edit Address' : 'Add New Address'}</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <VStack spacing={4}>
                                            <HStack spacing={4} w="100%">
                                                <FormControl isRequired flex="1">
                                                    <FormLabel>Full Name</FormLabel>
                                                    <Input
                                                        type="text"
                                                        border={'1px solid #6FA82F'}
                                                        value={formData.customerName}
                                                        onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
                                                    />
                                                </FormControl>

                                            </HStack>
                                            <FormControl isRequired >
                                                <FormLabel>Mobile Number</FormLabel>
                                                <Flex>
                                                    <Input value={callCode} border={'1px solid #6FA82F'} mr="2" width="100px" />
                                                    <Input
                                                        type="tel"
                                                        value={formData.phone}
                                                        border={'1px solid #6FA82F'}
                                                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                                    />
                                                </Flex>
                                            </FormControl>
                                            <FormControl isRequired width={"100%"}>
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    type="email"
                                                    value={formData.email}
                                                    border={'1px solid #6FA82F'}
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                />
                                            </FormControl>
                                            <FormControl isRequired w="100%">
                                                <FormLabel>Street</FormLabel>
                                                <Input
                                                    type="text"
                                                    border={'1px solid #6FA82F'}
                                                    value={formData.street}
                                                    onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                                                />
                                            </FormControl>
                                            <HStack spacing={4} width={"100%"}>
                                                <FormControl isRequired flex={"1"}>
                                                    <FormLabel>Country</FormLabel>
                                                    <Select
                                                        border={'1px solid #6FA82F'}
                                                        placeholder="Select Your Country"
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, country: e.target.value })
                                                            getStates(e.target.value);
                                                        }}
                                                        value={formData.country}
                                                    >
                                                        {countries.map((country) => (
                                                            <option key={country.iso2} value={country.iso2}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl isRequired flex="1">
                                                    <FormLabel>State</FormLabel>
                                                    <Select
                                                        borderRadius="md"
                                                        border={'1px solid #6FA82F'}
                                                        placeholder="Select state"
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, state: e.target.value })
                                                            getCities(formData.country, e.target.value);
                                                        }}
                                                        value={formData.state}
                                                    >
                                                        {states.map((state) => (
                                                            <option key={state.iso2} value={state.iso2}>
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </HStack>
                                            <HStack spacing={4} w="100%">
                                                <FormControl isRequired flex="1">
                                                    <FormLabel>City</FormLabel>
                                                    <Input
                                                        type="text"
                                                        border={'1px solid #6FA82F'}
                                                        value={formData.city}
                                                        onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl isRequired flex="1">
                                                    <FormLabel>Zip Code</FormLabel>
                                                    <Input
                                                        type="text"
                                                        border={'1px solid #6FA82F'}
                                                        value={formData.zipCode}
                                                        onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                                                    />
                                                </FormControl>
                                            </HStack>
                                        </VStack>
                                    </ModalBody>

                                    <ModalFooter display={"flex"} justifyContent={"space-between"}>
                                        <Button colorScheme="red"
                                            p={'0 70px'} borderRadius={"none"} onClick={onClose}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme="green"
                                            color="white"
                                            bg="#6FA82F"
                                            borderRadius={0}
                                            _hover={{ bg: 'black', color: 'white' }}
                                            p={'0 50px'} ml={3} onClick={handleAddOrUpdateAddress}>
                                            {selectedAddress ? 'Save Changes' : 'Add Address'}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Flex>
                    </>
                ) : (
                    <>
                        <Text textAlign="center" mt="4" onClick={() => navigate("/login")} cursor={"pointer"} fontFamily='DM Serif Display' fontSize="xl" fontWeight="bold" color="gray.600">
                            Please Login First !!!
                        </Text>
                        <DogsComponent />
                    </>
                )}
            );
        </>
    )
};

export default AddressesPage;
