import React, { useState } from 'react';
import { ChakraProvider, Text, ListItem,UnorderedList, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const YarnTypes = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%"  border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                <Heading                         borderBottom="2px solid #74D69E"
                paddingBottom="10px" as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" mb={4}>
                  Type of Yarn Fibers
                </Heading>
                <Text fontSize="lg" mb={4}>
                  All types of yarn for knitting or crocheting are made from natural or synthetic fibers. Different types of yarn fibers have specific qualities - some good, some not so good. Often, manufacturers blend different types of yarn fiber to offset an undesirable characteristic.
                </Text>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Wool:</Text>
                <Text fontSize="lg" mb={2}>Wool (made from the fleece of sheep) is the queen of yarns, and it remains a popular choice for knitters. Here are some of your wool yarn options:</Text>
                <UnorderedList fontSize="lg" mb={4}>
                  <ListItem>Lamb's wool: Comes from a young lamb's first shearing.</ListItem>
                  <ListItem>Merino wool: Considered the finest of the fine breeds.</ListItem>
                  <ListItem>Pure new wool/virgin wool: Wool that's made directly from animal fleece and not recycled from existing wool garments.</ListItem>
                  <ListItem>Shetland wool: Made from the small and hardy native sheep of Scotland's Shetland Islands.</ListItem>
                  <ListItem>Icelandic wool: A rustic, soft yarn.</ListItem>
                  <ListItem>Washable wool: Treated chemically or electronically to destroy the outer fuzzy layer of fibers.</ListItem>
                </UnorderedList>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Fleece:</Text>
                <Text fontSize="lg" mb={2}>Examples include mohair and cashmere, which come from Angora and Kashmir goats, respectively. Angora comes from the hair of Angora rabbits.</Text>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Silk, cotton, linen, and rayon:</Text>
                <Text fontSize="lg" mb={2}>The slippery, smooth, and often shiny yarns.</Text>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Synthetic:</Text>
                <Text fontSize="lg" mb={2}>Including nylon, acrylic, and polyester. Straddling the border between natural and synthetic are soy, bamboo, corn, and other unusual yarns made by using plant-based materials.</Text>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Novelty:</Text>
                <Text fontSize="lg" mb={2}>Novelty yarns are easy to recognize because their appearance is so different from traditional yarns:</Text>
                <UnorderedList fontSize="lg" mb={4}>
                  <ListItem>Ribbon: A knitted ribbon in rayon or a rayon blend.</ListItem>
                  <ListItem>Boucle: This highly bumpy, textured yarn is composed of loops.</ListItem>
                  <ListItem>Chenille: Although tricky to knit with, this yarn has an attractive appearance and velvety texture.</ListItem>
                  <ListItem>Thick-thin: Alternates between very thick and thin sections, which lends a bumpy look to knitted fabric.</ListItem>
                  <ListItem>Railroad ribbon: Has tiny "tracks" of fiber strung between two parallel strands of thread.</ListItem>
                  <ListItem>Faux fur: Fluffy fiber strands on a strong base thread of nylon resemble faux fur when knitted.</ListItem>
                </UnorderedList>
              
                <Text fontSize="lg" fontWeight="bold" mb={2}>Specialty:</Text>
                <Text fontSize="lg" mb={2}>These traditional types of yarn create special looks in knitted items:</Text>
                <UnorderedList fontSize="lg" mb={4}>
                  <ListItem>Tweed: Has a background color flecked with bits of fiber in different colors.</ListItem>
                  <ListItem>Heather: Blended from a number of different-colored or dyed fleeces, and then spun.</ListItem>
                  <ListItem>Marled (ragg): A plied yarn in which the plies are different colors.</ListItem>
                  <ListItem>Variegated: Dyed in several different colors or shades of a single color.</ListItem>
                </UnorderedList>
              </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default YarnTypes;
