import React from 'react';
import { Helmet } from 'react-helmet';
import { Center, Heading, Image, Text, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import UserChatButton from '../components/Chat/ChatButton'

const Fail = () => {
    let navigate = useNavigate();

    return (
        <>
            <Helmet>
                <meta name="KnitSilk | Payment Failed" content="KnitSilk | Payment Failed" />
                <title>KnitSilk | Payment Failed</title>
            </Helmet>
            <Center>
                <Flex direction={"column"} gap={"10px"}>
                    <Heading textAlign={"center"}>Payment Failed</Heading>
                    <Image  alt="Uploaded" src='https://i0.wp.com/nrifuture.com/wp-content/uploads/2022/05/comp_3.gif?fit=800%2C600&ssl=1'></Image>
                    <Text fontSize={"md"}>For any further query, reach out to us at <b>support@knitsilk.com</b></Text>
                    <Button m={"auto"} variant={"outline"} bg={"teal"} color='white' onClick={() => { navigate("/") }}>
                        Continue Shopping
                    </Button>
                </Flex>
            </Center>
            <UserChatButton />
        </>
    );
}

export default Fail;
