import React, { useState } from 'react';
import { ChakraProvider, Text, List, ListItem, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const ChooseYarn = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%" border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display"  borderBottom="2px solid #74D69E"
                    paddingBottom="10px" mb={4}>
                        How to Choose Yarn for Your Project
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        When choosing yarn, remember that yarns, garment shapes, and stitch patterns must work together for a successful knitting or crochet project. Choosing yarn can be hard when there are so many yarn selections available. Here are some points to keep in mind:
                    </Text>
                    <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" mb={2}>
                        Match the yarn to the stitch:
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        The yarn you choose can either accentuate the effect you're trying to create or camouflage it. As a general rule, the <strong>smooth-plied yarn in a solid color</strong> should be used for cables and more complex stitch patterns. They give your stitches a crisp look, showcasing your effort. In general, plied and twisted yarns are sophisticated and classic. Single plies are rustic and relaxed.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        If you're using <strong>variegated and novelty yarns</strong>, don't knock yourself out with tricky stitch work. The stitches won't show up, and all your stitch-making effort will be for naught. Simple stitches, such as stockinette and garter, are best with these yarns.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        For <strong>Cotton, silk, soy, bamboo, and other inelastic yarns</strong>, look for patterns that don't depend on ribbing for fit. Find patterns that hang straight to highlight the drape of these yarns.
                    </Text>
                    <Heading as="h3" fontSize="24px" color="#6FA82F" fontFamily="DM Serif Display" mb={2}>
                        Substitute one yarn for another:
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        If you don't want to use or can't find the yarn specified on a pattern, the safest option - at least until you're experienced enough to take into account all the factors - is to talk to a sales associate in a specialty yarn shop.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        When substituting yarn, consider the following:
                    </Text>
                    <List spacing={2} mb={4}>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Yardage:</strong> Be sure to pay attention to actual yardage listed on the label, not just the number of grams or ounces.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Weight:</strong> The weights (thicknesses) should be the same. If the pattern you've chosen expects you to get 4 stitches and 6 rows to the inch and you substitute a yarn that gives you a different gauge, your sweater will turn out a different size than the one given in the pattern.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text fontSize="lg">
                                <strong>Fiber:</strong> Yarns of different fibers, even if they have the same gauge, will have different characteristics. Be sure you know the characteristics of the yarn and are comfortable with the way these differences will affect the finished piece. Just because two yarns have the same gauge doesn't mean that they can substitute for each other successfully in a given pattern. If the yarns have different characteristics - texture, drape, fiber, and color - then the final garment will look and feel different from the one pictured in the pattern.
                            </Text>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default ChooseYarn;
