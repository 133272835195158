import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Flex,
    SimpleGrid,
    useToast,
    Text,
    Input,
    Button,
    Card,
    CardBody,
    Image,
    Stack,
    Divider,
    CardFooter,
    Spinner,
    Heading,
} from '@chakra-ui/react';
import anim from "../../assets/LOADING.mp4";
import Cookies from 'js-cookie';
import { FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductsSidebarOffers from './ProductsSidebarOffers';
import ProductsSidebarPhoneOffers from './ProductsSidebarPhoneOffers';
import ShareButton from '../ProductsPageComponents/ShareButton';
import axios from 'axios';
import { AppContext } from '../../Context/AppContext';
import { useContext } from 'react';
import { FaHeart, FaShoppingCart } from 'react-icons/fa';
import DogsComponent from '../DogsComponent';
import SaleBanner from "../ProductsPageComponents/SaleBanner"
import BigOrderUi from '../Checkout.jsx/BigOrderUi';

const currencyIcons = {
    INR: '₹',
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    CAD: '$',
    AUD: '$',
};

let userToken = localStorage.getItem('userToken');
const userCurrency = localStorage.getItem('userCurrency');

const ITEMS_PER_PAGE = 32;

const Offers = () => {
    const [showStyle, setshowStyle] = useState('grid');
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    let toast = useToast();
    let { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [suggestions, setSuggestions] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryFilter = queryParams.get('category');
    const subcategoryfilter = queryParams.get('subcategories');



    let getProductsByCategory = async () => {
        try {
            setLoading(true)
            let response = await axios.post("https://api.digiblocks.tech/products/offers/filterBycategories", {
                category: categoryFilter,
            });
            setLoading(false)
            console.log(response.data)
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products by category:', error);
        }
    };

    let getProductsBySubcategory = async () => {
        try {
            setLoading(true)
            let response = await axios.post("https://api.digiblocks.tech/products/offers/filterBySubcategories", {
                category: categoryFilter,
                subcategories: subcategoryfilter,
            });
            setLoading(false)
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products by subcategory:', error);
        }
    };

    let getAllProducts = async () => {
        try {
            setLoading(true)
            let response = await axios.get("https://api.digiblocks.tech/productsWithDiscount");
            setProducts(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching all products with discount:', error);
        }
    };


    useEffect(() => {
        if (categoryFilter && !subcategoryfilter) {
            console.log("1st")
            getProductsByCategory();
        } else if (categoryFilter && subcategoryfilter) {
            console.log("2nd")
            getProductsBySubcategory();
        } else {
            console.log("3rd")
            getAllProducts();
        }
    }, [categoryFilter, subcategoryfilter, refresh]);

    const filteredProducts = Array.isArray(products) ? products.filter((product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];


    const indexOfLastProduct = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstProduct = indexOfLastProduct - ITEMS_PER_PAGE;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500); // 1000 milliseconds = 1 second
    };

    let getcartLength = async () => {
        let userToken = localStorage.getItem('userToken') || '';
        if (userToken) {
            let response = await axios.get('https://api.digiblocks.tech/getcartitems', {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setCartLength(response.data.data.length);
        }
    };


    const addtocartfunctionGrid = async (id, e) => {
        setRefresh(!refresh);
        const userToken = localStorage.getItem('userToken');
        e.stopPropagation();
        if (userToken) {
            try {
                const data = await axios.post(
                    'https://api.digiblocks.tech/addtocart',
                    { productId: id },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (data.data.message === 'Item added to cart successfully.') {
                    setRefresh(!refresh);
                    toast({
                        title: 'Added to Cart.',
                        description: 'Item added to cart successfully.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                } else if (data.data.msg === 'Product already in cart') {
                    toast({
                        title: 'Already in Cart.',
                        description: 'Product already in cart.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                getcartLength();
            } catch (error) {
                console.error('Error adding to cart:', error);
            }
        } else {
            // Get current cart from cookies
            const cart = JSON.parse(Cookies.get('guestCart') || '[]');

            // Add product to cart
            if (!cart.includes(id)) {
                cart.push(id);
                Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
                toast({
                    title: "Product added to the cart.",
                    description: 'Please login to proceed to checkout.',
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in the cart.',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    let getwishlistlength = async () => {
        let userToken = localStorage.getItem('userToken') || '';
        if (userToken) {
            let response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setWishlistLength(response.data.data.length);
        }
    };

    const addToWishlistFunction = async (id) => {
        let userToken = localStorage.getItem('userToken');
        if (!userToken) {
            toast({
                title: 'Please login first to add this item to your wishlist.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            let wishlistdata = await axios.post(
                'https://api.digiblocks.tech/addtowishlist',
                {
                    productId: id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            toast({
                title: 'Item added to wishlist successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            getwishlistlength();
        } catch (error) {
            console.error('Error adding to wishlist:', error);

            toast({
                title: 'Failed to add item to wishlist. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionsRef = useRef(null);

    const handleInputChange = (e) => {
        const input = e.target.value;
        setSearchTerm(input);

        // Filter products based on the input
        const filteredSuggestions = products
            .filter((product) => product.title.toLowerCase().includes(input.toLowerCase()))
            .slice(0, 5);

        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);
    };

    const handleCardClick = (productId) => {
        navigate(`/products/${productId}`);
        setShowSuggestions(false);
    };

    const handleOutsideClick = (e) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(e.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);




    return (
        <>
            {loading && (
                    <Flex width="100%" direction="column" alignItems="center" justifyContent="center" minHeight="100vh">
                    <video autoPlay loop muted width="700">
                        <source src={anim} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Flex>
            )}
    
            <Flex direction={['column', 'column', 'row']} mb={'2%'}>
                <ProductsSidebarPhoneOffers products={products} setCurrentPage={setCurrentPage} setProducts={setProducts} setshowStyle={setshowStyle} />
                <Flex mt={["", "", '2%']} ml={['0px', '0px', '5%']} mr={['0px', '0px', '5%']} w={'100%'} flex={'6'}>
                    <Box flex={['', '', '1']} p={3}  >
                        <ProductsSidebarOffers products={products} setCurrentPage={setCurrentPage} setProducts={setProducts} setshowStyle={setshowStyle} />
                    </Box>
                    <Box flex={['6', '6', '5']} p={3}>
                        <Flex direction={['column', 'column', 'row']} alignItems={'center'} justifyContent={'space-between'} p={2}>
                            <Text fontSize={'22px'} fontWeight={'bolder'}>
                                All Products
                            </Text>
                         
                            <Flex>
                                <Text fontSize={'18px'} fontWeight={''}>{products.length} {"   "}{"   "}{"   "}Products</Text>
                            </Flex>
                            <Input
                                w={['100%', '100%', '30%']}
                                border="1px solid gray"
                                borderRadius={'0px'}
                                placeholder="Search Products in this collection ..."
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                        </Flex>
                       
                        {searchTerm && showSuggestions && (
                            <Box position="absolute" right={["", "10", "16"]} zIndex="1000" w={["90%", "50%", "25%"]} ref={suggestionsRef}>
                                <Card border="1px solid lightgray" borderRadius="5px">
                                    <CardBody>
                                        <Stack spacing={2}>
                                            {suggestions.map((suggestion) => (
                                                <Flex key={suggestion._id} justifyContent="space-between">
                                                    <Text
                                                        cursor="pointer"
                                                        onClick={() => handleCardClick(suggestion._id)}
                                                        _hover={{ textDecoration: 'underline' }}
                                                    >
                                                        {suggestion.title.slice(0, 70)} {suggestion.title.length > 70 && '...'}
                                                    </Text>
                                                    <Image _hover={{ cursor: "pointer" }} height="70px" alt="Uploaded" src={suggestion.photos[0]} ml={4}
                                                    />
                                                </Flex>
                                            ))}
                                        </Stack>
                                    </CardBody>
                                    <Box
                                        textAlign="right"
                                        p={2}
                                        bg={"none"}
                                        cursor="pointer"
                                        onClick={() => setShowSuggestions(false)}
                                        position="absolute"
                                        top={1}
                                        right={1}
                                        color={"red"}
                                    >
                                        <FaTimes />
                                    </Box>
                                </Card>
                            </Box>
                        )}
                        <Divider></Divider>
                        <BigOrderUi/>
                        {/* Grid view */}
                        <SimpleGrid display={showStyle === 'grid' ? 'grid' : 'none'} columns={[1, 1, 4]} spacing="40px">
                           
                                {
                                   products.filter((product) => product.discount > 0).length == 0  && products.length>0 && loading==false && <Flex width={"100%"} ml={["", "", "100%"]} direction={"column"}>
                                      <Text fontSize={"24px"} textAlign={"center"} fontFamily='DM Serif Display'
                                      >No offers currently,
                                          <br />
                                          but stay tuned for updates!
                                      </Text>
                                      <DogsComponent />
                                  </Flex>
                                }



                                {
                                     loading==true ? <>
                                     <Flex width="100%" direction="column" alignItems="center" justifyContent="center" minHeight="100vh">
                                    <video autoPlay loop muted width="700">
                                        <source src={anim} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </Flex>
                                     </>:<>
                                    { currentProducts
                                .filter((product) => product.discount > 0)
                                .map((product) => (
                                    <Box key={product._id}>
                                        <Card maxW="sm" border={'1px solid lightgray'} borderRadius={'5px'}>
                                            <SaleBanner percent={product.discount} />
                                            <CardBody onClick={() => handleCardClick(product._id)}>
                                                <Image _hover={{ cursor: "pointer" }} w={'100%'} h={"150px"} src={product?.photos?.[0] || ''} alt={product.title} borderRadius="lg" onClick={() => {
                                                    // Get the existing recently viewed products from localStorage
                                                    const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

                                                    // Add the current product ID to the recently viewed products array
                                                    const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];

                                                    // Ensure that only unique product IDs are stored
                                                    const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];

                                                    // Store the updated recently viewed products array back into localStorage
                                                    localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));

                                                    // Redirect to the single product page
                                                    navigate(`/products/${product._id}`);
                                                }} />
                                                <Stack mt="6">
                                                    <Text m={0}  height={"100px"}   fontSize={['18px', '18px']} fontWeight={'600'}>
                                                        {product.title.split(' ').slice(0, 6).join(' ')}...
                                                    </Text>
                                                    <Text m={0} textAlign={"center"} fontSize={'16px'}>
                                                        {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
                                                    </Text>

                                                    <Flex justifyContent={"space-between"} mt={2}>
                                                        <Box display="flex"   alignItems={"center"}>
                                                           <Text mt="-1" fontSize={'16px'}>{currencyIcons[userCurrency]}</Text> 
                                                            {
                                                                product.discount && (
                                                                    <Text mt="-1" fontSize={'16px'}>
                                                                        {(product[`price${userCurrency}`] - (product[`price${userCurrency}`] * product.discount / 100).toFixed(2)).toFixed(2)}
                                                                    </Text>
                                                                )
                                                            }
                                                        </Box>
                                                        <Box color="gray" display="flex"  alignItems={"center"}>
                                                        <Text mt="-1" fontSize={'16px'}> {currencyIcons[userCurrency]}</Text>
                                                            <Text mt="-1" fontSize={'16px'} textDecoration="line-through" color="gray" ml={1}>
                                                                {product[`price${userCurrency}`]}
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                </Stack>
                                            </CardBody>
                                            <CardFooter>
                                                <Flex w={'100%'} justifyContent={'center'}>
                                                    <Flex p={2} direction={"column"} alignItems={"center"} gap={5} position={"absolute"} top={0} right={0}>
                                                        <Button
                                                            _hover={{ transform: "scale(1.3)", transition: "transform 0.3s ease-in-out" }}
                                                            size={'md'}
                                                            borderRadius={'50%'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                addToWishlistFunction(product._id);
                                                            }}
                                                        >
                                                            <FaHeart color="rgb(111,168,47)" />
                                                        </Button>
                                                        <ShareButton shareUrl={'DUMMY:localhost:3030/products/productID'} shareTitle={'Dummy TITLE'} />

                                                    </Flex>

                                                    <Button
                                                        colorScheme="green"
                                                        bg="#e8eee4"
                                                        color="#6FA82F"
                                                        borderRadius={0}
                                                        _hover={{ bg: '#6FA82F', color: 'white' }}
                                                        rightIcon={<FaShoppingCart />}
                                                        onClick={(e) => {
                                                            addtocartfunctionGrid(product._id, e);
                                                        }}
                                                    >
                                                        Add to Cart
                                                    </Button>
                                                </Flex>
                                            </CardFooter>
                                        </Card>
                                    </Box>
                                ))}
                                    </>
}
                        </SimpleGrid>

                        {/* List view */}
                        <Flex direction={'column'} display={showStyle === 'list' ? 'flex' : 'none'} gap={5}>
                            {filteredProducts.filter((product) => product.discount > 0).length == 0 ? <Heading>No Matching Items</Heading> : filteredProducts.filter((product) => product.discount > 0).map((product) => (
                                <Box display={"flex"} key={product._id}>
                                    <Card border={'1px solid lightgray'} borderRadius={'5px'}>
                                        <Box>
                                            <CardBody display={"flex"} onClick={() => handleCardClick(product._id)}>
                                                <Image _hover={{ cursor: "pointer" }} w={'250px'} src={product?.photos?.[0] || ''} alt={product.title} borderRadius="lg" onClick={() => {
                                                    // Get the existing recently viewed products from localStorage
                                                    const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

                                                    // Add the current product ID to the recently viewed products array
                                                    const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];

                                                    // Ensure that only unique product IDs are stored
                                                    const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];

                                                    // Store the updated recently viewed products array back into localStorage
                                                    localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));

                                                    // Redirect to the single product page
                                                    navigate(`/products/${product._id}`);
                                                }} />
                                                <Stack ml={"2%"} w={"40%"} mt="6">
                                                    <Text  fontFamily='DM Serif Display' m={0} fontSize={['18px', '20px']} fontWeight={'600'}>
                                                        {product.title.split(' ').slice(0, 14).join(' ')}...
                                                    </Text>
                                                    <Text m={0} textAlign={"center"} fontSize={'16px'}>
                                                        {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
                                                    </Text>
                                                    <Flex justifyContent={"space-between"}>
                                                        <Box display="flex" align="center">
                                                            {currencyIcons[userCurrency]}
                                                            {
                                                                product.discount && (
                                                                    <Text mt="-1" fontSize={'16px'}>
                                                                        {(product[`price${userCurrency}`] - (product[`price${userCurrency}`] * product.discount / 100).toFixed(2)).toFixed(2)}
                                                                    </Text>
                                                                )
                                                            }
                                                        </Box>
                                                        <Box color="gray" display="flex" align="center">
                                                            {currencyIcons[userCurrency]}
                                                            <Text mt="-1" fontSize={'16px'} textDecoration="line-through" color="gray" ml={1}>
                                                                {product[`price${userCurrency}`]}
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                </Stack>
                                                <Stack m={"auto"} textAlign={"center"} alignContent={"center"}>
                                                    <Button
                                                        colorScheme="green"
                                                        bg="#e8eee4"
                                                        color="#6FA82F"
                                                        borderRadius={0}
                                                        mt={4}
                                                        mb={4}
                                                        _hover={{ bg: '#6FA82F', color: 'white' }}
                                                        rightIcon={<FaShoppingCart />}
                                                        onClick={(e) => {
                                                            addtocartfunctionGrid(product._id, e);
                                                        }}
                                                    >
                                                        Add to Cart
                                                    </Button>
                                                </Stack>
                                            </CardBody>
                                        </Box>
                                        <Box>
                                            <Flex w={''} justifyContent={'center'}>
                                                <Flex p={2} direction={"column"} alignItems={"center"} gap={5} position={"absolute"} top={0} right={0}>
                                                    <Button
                                                        _hover={{ transform: "scale(1.3)", transition: "transform 0.3s ease-in-out" }}
                                                        size={'md'}
                                                        borderRadius={'50%'}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addToWishlistFunction(product._id);
                                                        }}
                                                    >
                                                        <FaHeart color="rgb(111,168,47)" />
                                                    </Button>
                                                    <ShareButton shareUrl={'DUMMY:localhost:3030/products/productID'} shareTitle={'Dummy TITLE'} />

                                                </Flex>
                                            </Flex>
                                        </Box>
                                    </Card>
                                </Box>
                            ))}
                        </Flex>

                        {/* Pagination */}
                        <Flex mt={5} justify="center" flexWrap={"wrap"}>
                            {Array.from({ length: Math.ceil(products.length / ITEMS_PER_PAGE) }).map((_, index) => (
                                <Button
                                    mt={2}
                                    key={index}
                                    size="md"
                                    borderRadius={0}
                                    mx={1}
                                    onClick={() => paginate(index + 1)}
                                    colorScheme={currentPage === index + 1 ? 'green' : 'gray'}
                                >
                                    {index + 1}
                                </Button>
                            ))}
                        </Flex>

                   
                    </Box>
                </Flex>
            </Flex >
        </>
    );
};

export default Offers;
