import React from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';
import ExperienceComponent from '../HomePageComponents/Experience';

function TextContent({ children }) {
  return (
    <Text fontSize="16px" w={"100%"}>
      {children}
    </Text>
  )
}

const AboutUsSection = () => {
  return (
    <Box p={5} px={"10%"}>
      <VStack spacing={4} alignItems="start">
        <Heading
          as="h2"
          fontSize="46px"
          color="#6FA82F"
          fontFamily="DM Serif Display"
          fontWeight="400"
        >
          About Us
        </Heading>

        <TextContent>
          Knitsilk is a distinguished brand under the ownership of M. Jiju Silk Mills, a renowned manufacturer with an unwavering legacy in the silk industry.
        </TextContent>

        <TextContent>
          Our processed products are highly sought after by large-scale manufacturers of silk materials and silk-based products. Crafted in adherence to rigorous international quality standards, we have garnered a vast clientele base spanning across the Indian subcontinent, APAC, and EMEA regions.
        </TextContent>

        <TextContent>
          Our offered raw materials play an instrumental role in crafting the finest and rarest quality of silk. Additionally, our silk yarns are integral in creating shrink-resistant fabrics, widely appreciated for their impeccable finish, color-fastness, and skin-friendly attributes.
        </TextContent>

        <TextContent>
          Our extensive variety of silk yarns caters to the creation of exquisite attire. The shimmering allure of silk owes itself to the triangular prism-like structure of its fibers. It is exceptionally soft and comfortable to wear. Furthermore, our team of experts excels in customizing products to meet your unique requirements.
        </TextContent>

        <TextContent>
          Our manufacturing units are staffed by skilled artisans who painstakingly hand-spin our fibers and yarns. These units also house craftspeople who artfully dye yarns in a diverse palette of colors, tailored to your specific needs.
        </TextContent>


        <ExperienceComponent/>

        <Heading
          as="h3"
          fontSize="24px"
          mt="12"
          color="#6FA82F"
          fontFamily="DM Serif Display"
          fontWeight="400"
        >
          Infrastructure
        </Heading>

        <TextContent>
          We boast state-of-the-art infrastructure and an adept team of seasoned professionals, enabling us to offer unparalleled silk solutions to our clients. Our company houses a fully-equipped printing house with full dyeing capacity and specialized printing facilities, manned by experts well-versed in cutting-edge dyeing and printing techniques. This empowers us to meet bulk demands within the shortest timeframes.
        </TextContent>

        <Heading
          as="h3"
          fontSize="24px"
          color="#6FA82F"
          fontFamily="DM Serif Display"
          fontWeight="400"
        >
          Quality Assurance
        </Heading>

        <TextContent>
          At Knitsilk, excellence is non-negotiable. We uphold the highest quality standards at every stage of our production process, ensuring unparalleled output. The entire process is vigilantly monitored by a team of seasoned engineers, eliminating any possibility of quality compromise. Our products are presented in an array of designs and patterns, tailored to the preferences of today's discerning clientele. Additionally, our distinct departments, each designated for specific functions, allow us to meticulously attend to details and guarantee the production of only top-tier silk.
        </TextContent>

        <Heading
          as="h3"
          fontSize="24px"
          color="#6FA82F"
          fontFamily="DM Serif Display"
          fontWeight="400"
        >
          Our Offerings
        </Heading>

        <TextContent>
          We globally supply an extensive variety of silk products to our esteemed clientele. Our mission is to provide bespoke silk products to independent artisans and businesses worldwide. We manufacture sumptuous silk yarns, hand-painted by skilled Indian artisans, exclusively for you. Our in-house design team excels in customizing yarns to align with your unique specifications. We continually explore new yarn varieties and innovative color combinations.
        </TextContent>
      </VStack>
    </Box>
  );
};

export default AboutUsSection;
