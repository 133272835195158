import { Box, Button, Center, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom'


import AOS from "aos";
const ExperienceComponent = () => {

    const navigate = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
        });
    }, []);
    return (
        <>
            <Flex
                direction={["column", "column", "row"]}
                gap={["100px", "100px", "10px"]}
                w={"100%"}
                mt={"6%"}
            >
                <Box w={["100%", "100%", "50%"]} position={"relative"}>
                    <Text
                        fontSize={["18px", "30px", "35px"]}
                        letterSpacing={1}
                        fontFamily={"DM Serif Display"}
                        top={["0", "10px", "0px"]}
                        display={["none", "block", "block"]}
                        position={"absolute"}
                        w={"100%"}
                        m={"auto"}
                        textAlign={"center"}
                    >
                        Experience
                    </Text>
                    <Text
                        fontSize={["20px", "30px", "35px"]}
                        letterSpacing={1}
                        display={["none", "block", "block"]}
                        transform={"rotate(270deg)"}
                        position={"absolute"}
                        left={"-30px"}
                        fontFamily={"DM Serif Display"}
                        top={"35%"}
                    >
                        30+ years of{" "}
                    </Text>
                    <Text
                        fontSize={["18px", "30px", "35px"]}
                        letterSpacing={1}
                        fontFamily={"DM Serif Display"}
                        display={["block", "none", "none"]}
                        gap={0}
                        position={"absolute"}
                        w={"100%"}
                        m={"auto"}
                        textAlign={"center"}
                    >
                        10 years of Experience
                    </Text>
                    <Image
                        w={["100%", "100%", "90%"]}
                        alt="Uploaded"
                        src={
                            "https://assets.website-files.com/64cb64ee93daf9d341522213/64cb73bddae4f2e5a04b6f95_about-us-bg-image.png"
                        }
                    ></Image>
                    <Box
                        overflow={"hidden"}
                        position={"absolute"}
                        w={["60%", "60%", "80%"]}
                        top={"15%"}
                        left={"18%"}
                    >
                        <Image
                            _hover={{
                                transform: "scale(1.1)",
                                transition: "transform 0.3s ease",
                            }}
                            alt="Uploaded"
                            src="https://i.postimg.cc/FRWqDD1H/close-up-woman-crocheting-according-model.jpg"
                        ></Image>
                    </Box>
                </Box>
                <Box w={["100%", "100%", "50%"]} pr={["0px", "0px", "5%"]} mt={["-20px auto", "-30px auto", "0px auto"]}  >
                    <Flex direction={"column"} w={"100%"} gap={"20px"} p={"10px"}>
                        <Flex
                            w={"100%"}
                            justifyContent={"center"}
                            direction={["column", "column", "row"]}
                            gap={"20px"}
                        >
                            <Text
                                textAlign={"center"}
                                p={"0 10px"}
                                fontSize={["18px", "32px", "42px"]}
                                fontFamily={"DM Serif Display"}
                            >
                                Our Story
                            </Text>
                        </Flex>
                        <Text w={"100%"} p={"0 0px"} fontSize={"16px"}>
                            {" "}
                            Knitsilk is founded by a father and daughter duo. The father comes with his own import export firm, amid years of experience in silk and the young daughter, is an MBA with a good amount of multinational corporate experience in her kitty.
                        </Text>



                        <Text w={"100%"} p={"0 0px"} fontSize={"16px"} >
                            {" "}
                            Despite this, they felt something was missing.

                            In an era of technical sophistication and machine made goods, the father and daughter still believed in the magic of hand made products. They wanted use their expertise to help women make luxurious yarns for their clientele across the globe.
                        </Text>

                        <Text w="100%" p="0" fontSize={"16px"}>
                            Here is their story, in their own words, "We believe in doing everything with a good heart and good intentions. Being in the cottage industry for over two decades, we always knew we wanted to make a difference that matters, we wanted to help others. Thus, came about Knitsilk-a friend in need for women who want to become self-sufficient." At Knitsilk, we are passionate about silk, knitting and design.
                        </Text>

                        <Center >
                            <Button
                                _hover={{ bg: "#232424" }}
                                bg={"#6FA82F"}
                                fontSize={["14px", "14px", "18px"]}
                                p={["25px", "30px"]}
                                color={"white"}
                                borderRadius={"0"}
                                m={"auto"}
                            // mt={"30px"}
                            >
                                <Flex onClick={() => navigate('/products')} gap={"10px"} alignItems={"center"} >
                                    <Text mt={3}>Explore Our Products</Text>
                                    <BsArrowRight />
                                </Flex>
                            </Button>
                        </Center>
                    </Flex>
                </Box>

            </Flex>
        </>
    );
};

export default ExperienceComponent;
