import React, { useState } from 'react';
import { ChakraProvider, Table, Tbody, Td, Th, Thead, Tr, Box, VStack, Heading, Divider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import TopBanner from '../../components/HomePageComponents/TopBanner';
import Nav from '../../components/HomePageComponents/Nav';
import Footer from '../../components/HomePageComponents/Footer';
import { css } from '@emotion/react';

const KnittingPatterns = () => {
    const [selectedNavItem, setSelectedNavItem] = useState('All about silk');

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
    };

    const sidebarStyles = css`
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;
    return (
        <ChakraProvider>
            <TopBanner />
            <Nav />
            <Box display="flex" py={10} maxW={"1200px"} margin={"auto"}>
                <Box width="350px" display={{ base: 'none', md: 'none', lg: 'block' }} css={sidebarStyles} position="sticky" top="100px">
                    <VStack alignItems="flex-start">
                        <Heading as="h2" fontSize="46px"
                            color="#6FA82F"
                            fontFamily="DM Serif Display"
                            fontWeight="400">KnitSilk Blogs</Heading>
                        <Link
                            fontSize="lg"
                            to={"/blogs/all-about-silk"}
                            fontWeight={selectedNavItem === 'All about silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'All about silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('All about silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            All about silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/estimating-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Estimating how much yarn you need' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Estimating how much yarn you need' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Estimating how much yarn you need')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Estimating how much yarn you need ?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/choose-yarn"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'How to choose yarn for your project?' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'How to choose yarn for your project?' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('How to choose yarn for your project?')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            How to choose yarn for your project?
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/identify-silk"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Identifying silk' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Identifying silk' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Identifying silk')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Identifying silk
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/needles-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting needle types' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting needle types' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting needle types')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting needle types
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/knitting-patterns"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Knitting patterns' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Knitting patterns' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Knitting patterns')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Knitting patterns
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/selecting-knitting-needles"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Selecting knitting needles' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Selecting knitting needles' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Selecting knitting needles')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Selecting knitting needles
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-types"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn fibers' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn fibers' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn fibers')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn fibers
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-packaging"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Type of yarn packaging' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Type of yarn packaging' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Type of yarn packaging')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Type of yarn packaging
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                        <Link
                            to={"/blogs/yarn-weight"}
                            fontSize="lg"
                            fontWeight={selectedNavItem === 'Guide to yarn weights' ? 'bold' : 'normal'}
                            color={selectedNavItem === 'Guide to yarn weights' ? '#6FA82F' : 'black'}
                            onClick={() => handleNavItemSelect('Guide to yarn weights')}
                            fontFamily="DM Serif Display"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline', color: "#6FA82F" }}
                        >
                            Guide to yarn weights
                        </Link>
                        <Divider my={2} color={"#6FA82F"} />
                    </VStack>
                </Box>
                <Box width="90%"  border="2px solid #C9F8E8"
                mb={5} bg={"#f6faf2"} padding="20px" overflowY="auto">
                    <Heading  borderBottom="2px solid #74D69E"
                    paddingBottom="10px" as="h2" fontSize="32px" color="#6FA82F" fontFamily="DM Serif Display" mb={4}>
                        Knitting Patterns Abbreviations and Terminology
                    </Heading>
                    <Table variant="striped" colorScheme="green" size="md">
                        <Thead>
                            <Tr>
                                <Th>Abbreviation</Th>
                                <Th>Meaning</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td><strong>BO</strong></Td>
                                <Td>Bind off (cast off)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>CC</strong></Td>
                                <Td>Contrasting color</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>cm</strong></Td>
                                <Td>Centimeters</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>cn</strong></Td>
                                <Td>Cable needle</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>CO</strong></Td>
                                <Td>Cast on</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>dec</strong></Td>
                                <Td>Decrease</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>dpn(s)</strong></Td>
                                <Td>Double-pointed needle(s)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>g</strong></Td>
                                <Td>Grams</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>inc</strong></Td>
                                <Td>Increase</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>k</strong></Td>
                                <Td>Knit</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>k2tog</strong></Td>
                                <Td>Knit 2 together (a decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>kfb</strong></Td>
                                <Td>Knit into the front and back of the stitch (an increase)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>knitwise</strong></Td>
                                <Td>As if to knit</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>LT</strong></Td>
                                <Td>Left twist</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>m</strong></Td>
                                <Td>Meters</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>m1</strong></Td>
                                <Td>Make 1 (an increase)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>MC</strong></Td>
                                <Td>Main color</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>mm</strong></Td>
                                <Td>Millimeters</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>oz</strong></Td>
                                <Td>Ounce</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>p</strong></Td>
                                <Td>Purl</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>p2tog</strong></Td>
                                <Td>Purl 2 together (a decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>pfb</strong></Td>
                                <Td>Purl into the front and back of the stitch (an increase)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>pm</strong></Td>
                                <Td>Place marker</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>psso</strong></Td>
                                <Td>Pass the slipped stitch over</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>purlwise</strong></Td>
                                <Td>As if to purl</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>rnd(s)</strong></Td>
                                <Td>Round(s)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>RS</strong></Td>
                                <Td>Right (public) side</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>RT</strong></Td>
                                <Td>Right twist</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>skp</strong></Td>
                                <Td>Slip 1, knit 1, pass the slipped stitch over (a decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>s2kp</strong></Td>
                                <Td>Slip 2, knit 1, pass the 2 slipped stitches over (a double decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>sk2p</strong></Td>
                                <Td>Slip 1, k2tog, pass the slipped stitch over (a double decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>sl</strong></Td>
                                <Td>Slip</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>slst</strong></Td>
                                <Td>Slip stitch</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>ssk</strong></Td>
                                <Td>Slip, slip, knit (a decrease)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>st(s)</strong></Td>
                                <Td>Stitch(es)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>tbl</strong></Td>
                                <Td>Through the back loop(s)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>tog</strong></Td>
                                <Td>Together</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>work even</strong></Td>
                                <Td>Work without increasing or decreasing</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>WS</strong></Td>
                                <Td>Wrong (non-public) side</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>wyib</strong></Td>
                                <Td>With yarn in back</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>wyif</strong></Td>
                                <Td>With yarn in front</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>yd(s)</strong></Td>
                                <Td>Yard(s)</Td>
                            </Tr>
                            <Tr>
                                <Td><strong>yo</strong></Td>
                                <Td>Yarn over (an increase)</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
            </Box>
            <Footer />
        </ChakraProvider>
    );
};

export default KnittingPatterns;
