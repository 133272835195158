import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import TopBanner from '../components/HomePageComponents/TopBanner'
import Nav from '../components/HomePageComponents/Nav'
import Footer from '../components/HomePageComponents/Footer';

import { Helmet } from 'react-helmet';
import UserChatButton from '../components/Chat/ChatButton'
import SIngleOrder from '../components/MyAccount/SIngleOrder';

const UserSingleOrder = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="KnitSilk | My Orders" content="KnitSilk | My Orders" />
                <title>KnitSilk | My Orders | Order Details</title>
            </Helmet>
            <TopBanner />
            <Nav />
            <SIngleOrder />
            <UserChatButton />
            <Footer />
        </ChakraProvider>
    )
}

export default UserSingleOrder;
