import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Center,
    Badge,
    Button,
    Input,
    Select,
    useColorModeValue,
    Text,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const ReturnRequestTable = () => {
    const [returnRequests, setReturnRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [rejectionReason, setRejectionReason] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
    useEffect(() => {
        fetch('https://api.digiblocks.tech/order-return/returnRequests')
            .then((response) => response.json())
            .then((data) => {
                setReturnRequests(data);
                setFilteredRequests(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching return requests:', error);
                setLoading(false);
            });
    }, []);

    // Filter by status and search term
    useEffect(() => {
        const filtered = returnRequests.filter((request) => {
            const matchesSearchTerm = request.orderId && request.orderId.includes(searchTerm);
            const matchesStatus = filterStatus ? request.status === filterStatus : true;
            return matchesSearchTerm && matchesStatus;
        });

        setFilteredRequests(filtered);
    }, [searchTerm, filterStatus, returnRequests]);

    // Toggle sorting order
    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
        const sorted = [...filteredRequests].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });
        setFilteredRequests(sorted);
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Rejected':
                return 'red';
            case 'Pending':
            default:
                return 'yellow';
        }
    };

    const handleUpdateClick = (request) => {
        setSelectedRequest(request);
        setNewStatus(request.status);
        setRejectionReason(request.rejectionReason || '');
        onOpen();
    };
    const handleSubmitUpdate = () => {

        const updateData = { status: newStatus };
        if (newStatus === 'Rejected') {
            updateData.rejectionReason = rejectionReason;
        }
    
        fetch(`https://api.digiblocks.tech/order-return/returnRequests/${selectedRequest._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateData),
        })
            .then((response) => response.json())
            .then((updatedRequest) => {
                setReturnRequests((prevRequests) =>
                    prevRequests.map((request) =>
                        request._id === selectedRequest._id ? { ...request, ...updatedRequest } : request
                    )
                );
                toast({
                    title: 'Return Request Updated.',
                    description: "The return request status has been updated successfully.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
            })
            .catch((error) => {
                console.error('Error updating return request:', error);
                toast({
                    title: 'Error Updating Request.',
                    description: "There was an error updating the return request. Please try again.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    
        const emailData = {
            orderID: selectedRequest.orderId,
            requestStatus: newStatus,
            rejectionReason: newStatus === 'Rejected' ? rejectionReason : undefined, // Include rejectionReason only if status is 'Rejected'
        };
    
        // Send email notification
        fetch(`https://api.digiblocks.tech/return-status-update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        })
            .then((emailResponse) => emailResponse.json())
            .then((emailResult) => {
                console.log('Email sent successfully:', emailResult);
                toast({
                    title: 'Email Sent.',
                    description: "Email notification sent successfully.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
            })
            .catch((emailError) => {
                console.error('Error sending email:', emailError);
                toast({
                    title: 'Error Sending Email.',
                    description: "There was an error sending the email notification. Please try again.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };
    

    return (
        <Box w="full" boxShadow="lg" bg={useColorModeValue('white', 'gray.700')} rounded="md" p={5} overflowX="auto">
            <HStack justifyContent="space-between" mb={4}>
                <Heading size="lg">Return Requests</Heading>
                <Text fontWeight={"semibold"}>Total Requests: {filteredRequests.length}</Text>
            </HStack>
            <HStack mb={4} spacing={4}>
                <Input
                    placeholder="Search by Order ID"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    w="30%"
                />
                <Select
                    placeholder="Filter by Status"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    w="30%"
                >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                </Select>
                <Button colorScheme='green' onClick={() => { setSearchTerm(''); setFilterStatus(''); }}>Clear Filters</Button>
            </HStack>
            {loading ? (
                <Center py={10}>
                    <Spinner size="xl" />
                </Center>
            ) : (
                <Table variant="striped" colorScheme="green">
                    <Thead>
                        <Tr color="white" bg="#6FA82F">
                            <Th color="white">Order ID</Th>
                            <Th color="white">Return Reason</Th>
                            <Th color="white">Status</Th>
                            <Th color="white">
                                <HStack alignItems={"center"}>
                                    <Text>Requested At</Text>
                                    <IconButton
                                        color="white"
                                        bg="none"
                                        colorScheme='white'
                                        icon={<ArrowUpDownIcon />}
                                        size="sm"
                                        onClick={toggleSortOrder}
                                        aria-label="Sort by Date"
                                    />
                                </HStack>
                            </Th>
                            <Th color="white">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredRequests.map((request) => (
                            <Tr key={request._id}>
                                <Td textDecoration={"underline"} color={"blue"} onClick={()=>navigate(`/manager/return-orders/${request._id}`)}>{request.orderId}</Td>
                                <Td>{request.returnReason}</Td>
                                <Td>
                                    <Badge colorScheme={getStatusBadgeColor(request.status)}>
                                        {request.status}
                                    </Badge>
                                </Td>
                                <Td>{new Date(request.createdAt).toLocaleString()}</Td>
                                <Td>
                                    <Button colorScheme="blue" size="sm" onClick={() => handleUpdateClick(request)}>
                                        Update
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

            {/* Modal for updating status */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Return Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Select value={newStatus} onChange={(e) => setNewStatus(e.target.value)} mb={3}>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                        </Select>
                        {newStatus === 'Rejected' && (
                            <Textarea
                                placeholder="Enter rejection reason"
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                                mb={3}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmitUpdate}>
                            Submit
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ReturnRequestTable;
