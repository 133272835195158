import React, { useState } from 'react';
import { Box, Image, Center } from '@chakra-ui/react';


const DogsComponent = () => {
  const dogImages = ["https://i.postimg.cc/90960yFX/cute-golden-retriever.jpg", "https://i.postimg.cc/K8hZPvds/cute-shepherd-dog-posing-isolated-white-background.jpg", "https://i.postimg.cc/7ZSZdT3k/isolated-happy-smiling-dog-white-background-portrait-4.jpg", "https://i.postimg.cc/ydsgvytt/long-haired-brown-dog-with-tongue-out.jpg", "https://i.postimg.cc/tJHjhBcn/pug-dog-isolated-white-background.jpg", "https://i.postimg.cc/gJzynpzx/small-funny-dog-beagle-posing-isolated-white-wall.jpg", "https://i.postimg.cc/9MQTcBbT/studio-shot-papillon-fallen-little-dogs-isolated-white-studio-wall.jpg"];
  const [selectedImage, setSelectedImage] = useState(() =>
    dogImages[Math.floor(Math.random() * dogImages.length)]
  );

  const handleImageChange = () => {
    const newImage = dogImages[Math.floor(Math.random() * dogImages.length)];
    setSelectedImage(newImage);
  };

  return (
    <Center >
      <Box>
        <Image
          src={selectedImage}
          alt="Random Dog"
          boxSize={['300px', '400px', '500px']}
          objectFit="cover"
          borderRadius="md"
          onClick={handleImageChange}
          cursor="pointer"
        />
      </Box>
    </Center>
  );
};

export default DogsComponent;
