import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Textarea,
  Stack,
  Checkbox,
  Button,
  VStack,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import AdminNav from './AdminNav';


const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  // New state variables for search and sort
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('productName'); // Default sort option

  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.digiblocks.tech/products');
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Filter and sort products based on search term and sort option
  const filteredAndSortedProducts = useMemo(() => {
    let filteredProducts = products.filter((product) =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filteredProducts = [...filteredProducts].sort((a, b) => {
      const keyA = a[sortOption].toLowerCase();
      const keyB = b[sortOption].toLowerCase();

      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return filteredProducts;
  }, [products, searchTerm, sortOption]);

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditModalOpen(true);
  };

  const handleDelete = async (productId) => {
    try {
      // Implement delete logic here
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this product!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      });

      if (result.isConfirmed) {
        await fetch(`https://api.digiblocks.tech/products/${productId}`, {
          method: 'DELETE',
        });

        // Update the products array after successful deletion
        const updatedProducts = products.filter((product) => product._id !== productId);
        setProducts(updatedProducts);

        Swal.fire('Deleted!', 'Product has been deleted.', 'success');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      Swal.fire('Error!', 'Failed to delete product.', 'error');
    }
  };

  const handleDeleteSelected = async () => {
    try {
      if (selectedProducts.length === 0) {
        // No selected products, show an alert or some indication
        return;
      }

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover the selected products!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete them!',
        cancelButtonText: 'No, keep them',
      });

      if (result.isConfirmed) {
        const productIds = selectedProducts.map((product) => product._id);

        await fetch('https://api.digiblocks.tech/products/delete-multiple', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ productIds }),
        });

        // Close the modal and update the products array after successful deletion
        setDeleteModalOpen(false);
        const updatedProducts = products.filter((product) => !productIds.includes(product._id));
        setProducts(updatedProducts);
        setSelectedProducts([]);

        Swal.fire('Deleted!', 'Selected products have been deleted.', 'success');
      }
    } catch (error) {
      console.error('Error deleting products:', error);
      Swal.fire('Error!', 'Failed to delete selected products.', 'error');
    }
  };

  const handleEditSubmit = async () => {
    try {
      // Implement edit/update logic here
      // You can use a PUT or PATCH request to update the product
      await fetch(`https://api.digiblocks.tech/products/${selectedProduct._id}`, {
        method: 'PUT', // or 'PATCH'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedProduct),
      });

      // Close the modal and update the products array after successful update
      setEditModalOpen(false);
      const updatedProducts = [...products];
      const index = updatedProducts.findIndex((product) => product._id === selectedProduct._id);
      updatedProducts[index] = selectedProduct;
      setProducts(updatedProducts);

      Swal.fire('Updated!', 'Product has been updated.', 'success');
    } catch (error) {
      console.error('Error updating product:', error);
      Swal.fire('Error!', 'Failed to update product.', 'error');
    }
  };

  const handleInputChange = (field, value) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value,
    }));
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const handleCheckboxChange = (productId) => {
    const updatedSelectedProducts = [...selectedProducts];
    const index = updatedSelectedProducts.findIndex((product) => product._id === productId);

    if (index !== -1) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      const selectedProduct = products.find((product) => product._id === productId);
      updatedSelectedProducts.push(selectedProduct);
    }

    setSelectedProducts(updatedSelectedProducts);
  };

  const [isNewProductModalOpen, setNewProductModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    productName: '',
    description: '',
    // Add more initial properties for the new product
    // Example:
    price: 0,
    quantityInStock: 0,
    sku: '',
    category: '',
    brand: '',
    // Add more properties as needed
  });

  const handleNewProductSubmit = async () => {
    try {
      // Implement logic to add a new product
      const response = await fetch('https://api.digiblocks.tech/products/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newProduct),
      });

      const addedProduct = await response.json();

      // Close the modal and update the products array after successful addition
      setNewProductModalOpen(false);
      setProducts([...products, addedProduct]);

      Swal.fire('Added!', 'New product has been added.', 'success');
    } catch (error) {
      console.error('Error adding new product:', error);
      Swal.fire('Error!', 'Failed to add new product.', 'error');
    }
  };

  const handleNewProductInputChange = (field, value) => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value,
    }));
  };

  const iconButtonSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <>
      <AdminNav />
      <Box ml={["0px", "0px", "250px"]} p={4}>
        {/* Search and Sort Controls */}
        <Box mb={4} textAlign="center" ml={4} justifyContent={"space-between"} display={"flex"}>
          <Input
            type="text"
            placeholder="Search by Product Name"
            value={searchTerm}
            width={"70%"}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button colorScheme="green" onClick={() => setNewProductModalOpen(true)}>
            Add New Product
          </Button>
        </Box>

        {/* Centralized "Delete Selected Products" button */}
        {selectedProducts.length > 0 && (
          <Box mb={4} textAlign="center">
            <IconButton
              colorScheme="red"
              aria-label="Delete Selected Products"
              icon={<DeleteIcon />}
              size={iconButtonSize}
              onClick={handleDeleteSelected}
            />
          </Box>
        )}

        {filteredAndSortedProducts.map((product) => (
          <Flex key={product._id} borderWidth="1px" p={4} mb={4} align="center">
            <Checkbox
              onChange={() => handleCheckboxChange(product._id)}
              isChecked={selectedProducts.some((selectedProduct) => selectedProduct._id === product._id)}
            />
            <Image src={product.images[0]} alt={product.productName} boxSize="100px" />
            <Box ml={4}>
              <Text fontSize="xl" fontWeight="bold">
                {product.productName}
              </Text>
              <Text>{product.description}</Text>
            </Box>
            <Stack ml="auto" spacing={4} direction="row">
              <IconButton
                colorScheme="blue"
                aria-label="Edit Product"
                icon={<EditIcon />}
                size={iconButtonSize}
                onClick={() => handleEdit(product)}
              />
              {/* Each product has its own delete button */}
              <IconButton
                colorScheme="red"
                aria-label="Delete Product"
                icon={<DeleteIcon />}
                size={iconButtonSize}
                onClick={() => handleDelete(product._id)}
              />
            </Stack>
          </Flex>
        ))}

        {/* Selected Products Section */}
        {selectedProducts.length > 0 && (
          <VStack align="start" mt={4} mb={8} spacing={2}>
            <Text fontWeight="bold">Selected Products:</Text>
            {selectedProducts.map((selectedProduct) => (
              <Box key={selectedProduct._id}>
                <Text>{selectedProduct.productName}</Text>
              </Box>
            ))}
          </VStack>
        )}

        {/* Edit Modal */}
        <Modal isOpen={isEditModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Product</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Input
                  type="text"
                  placeholder="Product Name"
                  value={selectedProduct?.productName || ''}
                  onChange={(e) => handleInputChange('productName', e.target.value)}
                />
                <Textarea
                  placeholder="Description"
                  value={selectedProduct?.description || ''}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                />
                <Input
                  type="number"
                  placeholder="Price"
                  value={selectedProduct?.price || ''}
                  onChange={(e) => handleInputChange('price', e.target.value)}
                />
                <Input
                  type="number"
                  placeholder="Quantity in Stock"
                  value={selectedProduct?.quantityInStock || ''}
                  onChange={(e) => handleInputChange('quantityInStock', e.target.value)}
                />
                <Input
                  type="text"
                  placeholder="SKU"
                  value={selectedProduct?.sku || ''}
                  onChange={(e) => handleInputChange('sku', e.target.value)}
                />
                {/* Add more input fields for other product properties */}
                <Input
                  type="text"
                  placeholder="Category"
                  value={selectedProduct?.category || ''}
                  onChange={(e) => handleInputChange('category', e.target.value)}
                />
                <Input
                  type="text"
                  placeholder="Brand"
                  value={selectedProduct?.brand || ''}
                  onChange={(e) => handleInputChange('brand', e.target.value)}
                />
                {/* Add as many input fields as needed */}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <IconButton
                colorScheme="blue"
                aria-label="Save Changes"
                icon={<EditIcon />}
                size={iconButtonSize}
                onClick={handleEditSubmit}
              />
              <IconButton
                aria-label="Cancel"
                icon={<DeleteIcon />}
                size={iconButtonSize}
                onClick={handleCloseModal}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Delete Modal */}
        <Modal isOpen={isDeleteModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Products</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Are you sure you want to delete the selected products?</Text>
            </ModalBody>
            <ModalFooter>
              <IconButton
                colorScheme="red"
                aria-label="Delete"
                icon={<DeleteIcon />}
                size={iconButtonSize}
                onClick={handleDeleteSelected}
              />
              <IconButton
                aria-label="Cancel"
                icon={<DeleteIcon />}
                size={iconButtonSize}
                onClick={handleCloseModal}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* New Product Modal */}
        <Modal isOpen={isNewProductModalOpen} onClose={() => setNewProductModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Product</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Input
                  type="text"
                  placeholder="Product Name"
                  value={newProduct.productName}
                  onChange={(e) => handleNewProductInputChange('productName', e.target.value)}
                />
                <Textarea
                  placeholder="Description"
                  value={newProduct.description}
                  onChange={(e) => handleNewProductInputChange('description', e.target.value)}
                />
                {/* Add more input fields for other new product properties */}
                <Input
                  type="number"
                  placeholder="Price"
                  value={newProduct.price}
                  onChange={(e) => handleNewProductInputChange('price', e.target.value)}
                />
                <Input
                  type="number"
                  placeholder="Quantity in Stock"
                  value={newProduct.quantityInStock}
                  onChange={(e) => handleNewProductInputChange('quantityInStock', e.target.value)}
                />
                <Input
                  type="text"
                  placeholder="SKU"
                  value={newProduct.sku}
                  onChange={(e) => handleNewProductInputChange('sku', e.target.value)}
                />
                {/* Add more input fields for other new product properties */}
                <Input
                  type="text"
                  placeholder="Category"
                  value={newProduct.category}
                  onChange={(e) => handleNewProductInputChange('category', e.target.value)}
                />
                <Input
                  type="text"
                  placeholder="Brand"
                  value={newProduct.brand}
                  onChange={(e) => handleNewProductInputChange('brand', e.target.value)}
                />
                {/* Add as many input fields as needed */}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" onClick={handleNewProductSubmit}>
                Add Product
              </Button>
              <Button onClick={() => setNewProductModalOpen(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default ProductList;
